export const GraphBackgrounds = [
  "#F3E2FF",
  "#E6FDD7",
  "#DEFCFF",
  "#FFE7F5",
  "#E6FDD7",
  "#D2FAD2",
  "#D6F5ED",
];
export const GraphBorders = [
  "#6610A6",
  "#255900",
  "#004D54",
  "#68003E",
  "#255900",
  "#004700",
  "#00553F",
];
