import {
  AddPurchaseDeliveryPODSchema,
  PurchaseOrderAction,
  PurchaseOrderActionTypes,
} from "./purchaseOrderTypes";
import {
  ClientPurchaseOrderDetailSchema,
  ProductDeliverySchedule,
} from "../../models/ClientPurchaseOrderDetail";
import { ClientPurchaseRequestComment } from "../../models/ClientPurchaseOrderDetail";

export interface PurchaseOrderDetailState {
  purchaseOrderDetails: null | ClientPurchaseOrderDetailSchema;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: PurchaseOrderDetailState = {
  purchaseOrderDetails: null,
  loading: false,
  error: null,
};

const purchaseOrderReducer = (
  state = initialState,
  action: PurchaseOrderActionTypes,
): PurchaseOrderDetailState => {
  switch (action.type) {
    case PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL_SUCCESS: {
      const payload = action.payload as ClientPurchaseOrderDetailSchema;
      return {
        ...state,
        purchaseOrderDetails: payload,
        loading: false,
        error: null,
      };
    }
    case PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT:
      return {
        ...state,
        loading: true,
      };

    case PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT_SUCCESS: {
      const payload = action.payload as ClientPurchaseRequestComment;
      const detail = state.purchaseOrderDetails
        ? { ...state.purchaseOrderDetails }
        : null;
      if (detail?.comments && payload) {
        detail.comments = [...detail.comments, payload];
      }
      return {
        ...state,
        purchaseOrderDetails: detail,
        loading: true,
      };
    }

    case PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD:
      return {
        ...state,
        loading: true,
      };

    case PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD_SUCCESS: {
      const payload = action.payload as AddPurchaseDeliveryPODSchema;
      let purchaseOrderDetails = state.purchaseOrderDetails;
      if (purchaseOrderDetails && purchaseOrderDetails.product_details) {
        const products = purchaseOrderDetails?.product_details.map(
          (product) => {
            const schedules = product.delivery_schedules.map((schedule) => {
              if (schedule.id === payload.id) {
                return {
                  ...schedule,
                  pod: payload.url,
                  actual_delivery_date: payload.actual_delivery_date,
                };
              }
              return schedule;
            });
            return { ...product, delivery_schedules: schedules };
          },
        );
        purchaseOrderDetails = {
          ...purchaseOrderDetails,
          product_details: products,
        };
      }
      return {
        ...state,
        purchaseOrderDetails,
        loading: true,
      };
    }

    case PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE:
      return {
        ...state,
        loading: true,
      };

    case PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS: {
      const payload = action.payload as ProductDeliverySchedule;
      const prp_id = payload.procurement_request_product_id;
      const purchaseOrderDetail = state.purchaseOrderDetails;

      if (purchaseOrderDetail && purchaseOrderDetail.product_details) {
        const productIndex = purchaseOrderDetail.product_details.findIndex(
          (product_detail) => {
            return product_detail.procurement_request_product_id === prp_id;
          },
        );

        if (productIndex !== -1) {
          const product = purchaseOrderDetail.product_details[productIndex];

          // Insert the payload in the delivery_schedules array of the product
          const updatedProduct = {
            ...product,
            delivery_schedules: [...product.delivery_schedules, payload],
          };

          // Create a new array with the updated product
          const updatedProductDetails = [
            ...purchaseOrderDetail.product_details.slice(0, productIndex),
            updatedProduct,
            ...purchaseOrderDetail.product_details.slice(productIndex + 1),
          ];

          return {
            ...state,
            purchaseOrderDetails: {
              ...purchaseOrderDetail,
              product_details: updatedProductDetails,
            },
            loading: false,
          };
        }
      }
      return {
        ...state,
        loading: false, // Set loading to false if no matching product is found
      };
    }

    case PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE:
      return {
        ...state,
        loading: true,
      };

    case PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS: {
      const payload = action.payload as {
        id: number;
        purchase_request_product_id: number;
      };
      const prp_id = payload.purchase_request_product_id;
      const delivery_schedule_id = payload.id;
      const purchaseOrderDetail = state.purchaseOrderDetails;

      if (purchaseOrderDetail && purchaseOrderDetail.product_details) {
        const productIndex = purchaseOrderDetail.product_details.findIndex(
          (product_detail) => {
            return product_detail.procurement_request_product_id === prp_id;
          },
        );

        if (productIndex !== -1) {
          const product = purchaseOrderDetail.product_details[productIndex];

          // DELETE the payload in the delivery_schedules array of the product
          const updatedProduct = {
            ...product,
            delivery_schedules: product.delivery_schedules.filter(
              (ds) => ds.id != delivery_schedule_id,
            ),
          };

          // Create a new array with the updated product
          const updatedProductDetails = [
            ...purchaseOrderDetail.product_details.slice(0, productIndex),
            updatedProduct,
            ...purchaseOrderDetail.product_details.slice(productIndex + 1),
          ];

          return {
            ...state,
            purchaseOrderDetails: {
              ...purchaseOrderDetail,
              product_details: updatedProductDetails,
            },
            loading: false,
          };
        }
      }
      return {
        ...state,
        loading: false, // Set loading to false if no matching product is found
      };
    }

    case PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE:
      return {
        ...state,
        loading: true,
      };

    case PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCCESS: {
      const payload = action.payload as ProductDeliverySchedule;
      const prp_id = payload.procurement_request_product_id;
      const delivery_schedule_id = payload.id;
      const purchaseOrderDetail = state.purchaseOrderDetails;

      if (purchaseOrderDetail && purchaseOrderDetail.product_details) {
        const productIndex = purchaseOrderDetail.product_details.findIndex(
          (product_detail) => {
            return product_detail.procurement_request_product_id === prp_id;
          },
        );

        if (productIndex !== -1) {
          const product = purchaseOrderDetail.product_details[productIndex];

          const index = product.delivery_schedules.findIndex(
            (ds) => ds.id === delivery_schedule_id,
          );
          product.delivery_schedules[index] = payload;

          return {
            ...state,
            purchaseOrderDetails: {
              ...purchaseOrderDetail,
              product_details: purchaseOrderDetail.product_details,
            },
            loading: false,
          };
        }
      }
      return {
        ...state,
        loading: false, // Set loading to false if no matching product is found
      };
    }

    case PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default purchaseOrderReducer;
