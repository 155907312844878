import React, { useEffect, useMemo, useState } from "react";
import "./RaiseQuoteSKU.scss";
import Filter from "../Filter/Filter";
import { useSelector } from "react-redux";
import {
  getWarehouseUserAddresses,
  userDetails,
} from "../../redux/user/userSelector";
import { FilterOptionSchema } from "../Filter/filter.data";
import { VendorSchema } from "../../models/Vendor";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { UserAddress } from "../../redux/user/userTypes";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { BlobProvider, pdf } from "@react-pdf/renderer";
import QuoteReport from "../../reports/QuoteReport/QuoteReport";
import { SkuFormData } from "../../models/Quote";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";

interface Sku {
  id: number;
  uuid?: string;
  name: string;
  description?: string;
  unit?: string;
}

const RaiseQuoteSKU: React.FC = () => {
  const user = useSelector(userDetails);
  const navigate = useNavigate();
  const addresses = useSelector(getWarehouseUserAddresses);
  const [skuAddresses, setSkuAddresses] = useState<UserAddress[]>([]);
  const [vendorDataList, setVendorDataList] = useState<VendorSchema[]>();
  const [filteredVendorList, setFilteredVendorList] = useState([]);
  const [selectedVendorList, setSelectedVendorList] = useState<VendorSchema[]>(
    [],
  );
  const [skus, setSkus] = useState<Sku[]>([]);
  const [selectedSku, setSelectedSku] = useState<Sku>();
  const [currentSkuQuantity, setCurrentSkuQuantity] = useState<number>(0);
  const [quoteMessage, setQuoteMessage] = useState("");
  const [EditingIndex, setEditingIndex] = useState(-1);
  const [skuFormData, setSkuFormData] = useState<SkuFormData[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress>();
  const [fileList, setFileList] = useState<File[]>([]);
  const [displayNames, setDisplayNames] = useState<string[]>([]);

  useEffect(() => {
    fetchVendors();
    fetchSkus();
  }, []);

  const fetchVendors = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDORS,
        {
          params: {
            client_id: user.company_id,
          },
        },
      );

      const transformedData = data.vendors
        .filter(
          (vendor: VendorSchema) => vendor.vendor_form_status === "APPROVED",
        )
        .map((vendor: VendorSchema) => ({
          id: vendor.vendor_id,
          name: vendor.vendor_name,
        }));
      setVendorDataList(data.vendors);
      setFilteredVendorList(transformedData);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSkus = async () => {
    try {
      const { data } = await ApiService().client.get(URLS.SKU.OPTIONS);
      setSkus(data.skus);
    } catch (e) {
      console.error(e);
    }
  };

  const skuSelected = (val: FilterOptionSchema[]) => {
    setSelectedSku(val[0]);
    const skuAddresses = addresses.filter(
      (entry) => val[0].locations && val[0].locations?.indexOf(entry.id) > -1,
    );
    setSkuAddresses(skuAddresses);
    setSelectedAddress(skuAddresses[0]);
  };

  const handleVendorSelection = (selectedVendorList: FilterOptionSchema[]) => {
    const updatedList: VendorSchema[] = [];

    vendorDataList?.map((vendor, _index) => {
      selectedVendorList.map((selectedVendor) => {
        if (selectedVendor.id == vendor.vendor_id) {
          updatedList.push(vendor);
        }
      });
    });
    setSelectedVendorList(updatedList);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });

      setFileList((prevState) => [...prevState, ...fileList]);
      setDisplayNames((prevState) => [...prevState, ...displayNameList]);
    }
  };

  const removeFile = (index: number) => {
    setFileList((prevFileList) => prevFileList.filter((_, i) => i !== index));
    setDisplayNames((prevDisplayNames) =>
      prevDisplayNames.filter((_, i) => i !== index),
    );
  };

  const addProduct = async () => {
    if (currentSkuQuantity && currentSkuQuantity <= 0) {
      toast.error("Please Enter quantity", toastOptions);
      return;
    }
    if (!selectedSku) {
      toast.error("Please Select Sku", toastOptions);
      return;
    }

    // FETCH THE SELECTED SKU's QUALITY CHECKS AND QUALITY TESTS AND IMAGES

    const { data } = await ApiService().client.get(
      URLS.SKU.GET_SKU_QUALITY_CHECKS_AND_ATTACHMENTS(selectedSku.id),
    );

    if (selectedAddress) {
      const skuFormDataInstance: SkuFormData = {
        id: selectedSku.id,
        name: selectedSku.name,
        uuid: selectedSku.uuid || "",
        description: selectedSku.description || "",
        quantity: currentSkuQuantity || 0,
        address: selectedAddress,
        unit: selectedSku.unit || "",
        qualityCheckParameters: data.data.sku_quality_checks,
        qualityCheckTests: data.data.sku_quality_check_tests,
        images: data.data.images,
      };
      skuFormData.push(skuFormDataInstance);
      setSkuFormData([...skuFormData]);
      setSelectedAddress(undefined);
      setSkuAddresses([]);
      setCurrentSkuQuantity(parseInt(""));
      setSelectedSku(undefined);
    }
  };

  const deleteSkuFormData = (index: number) => {
    const updatedSkuFormData = skuFormData.filter(
      (sku, skuIndex) => skuIndex !== index,
    );

    setSkuFormData(updatedSkuFormData);
  };

  const editSkuFormData = (index: number) => {
    setEditingIndex(index);
    setCurrentSkuQuantity(skuFormData[index].quantity);
    setSelectedAddress(skuFormData[index].address);
    skus.map((sku) => {
      if (sku.id === skuFormData[index].id) {
        setSelectedSku(sku);
      }
    });
  };

  const completeEditing = () => {
    if (currentSkuQuantity && currentSkuQuantity <= 0) {
      toast.error("Please Enter quantity", toastOptions);
      return;
    }
    if (!selectedSku) {
      toast.error("Please Select Sku", toastOptions);
      return;
    }

    if (selectedAddress) {
      const skuFormDataInstance: SkuFormData = {
        id: selectedSku.id,
        name: selectedSku.name,
        uuid: selectedSku.uuid || "",
        description: selectedSku.description || "",
        quantity: currentSkuQuantity || 0,
        address: selectedAddress,
        unit: selectedSku.unit || "",
      };

      const updatedSkuFormData = skuFormData.map((sku, skuIndex) => {
        if (skuIndex === EditingIndex) {
          return skuFormDataInstance;
        }
        return sku;
      });
      setEditingIndex(-1);
      setSkuFormData(updatedSkuFormData);
      setSelectedAddress(undefined);
      setSkuAddresses([]);
      setCurrentSkuQuantity(parseInt(""));
      setSelectedSku(undefined);
    }
  };

  const generatePdfBlob = async (
    quoteUuid: string,
    skuFormData: SkuFormData[],
    vendor: VendorSchema,
    quote_attachments: { name: string; url: string }[],
  ) => {
    const today = dayjs().format("DD-MM-YY");
    const document = (
      <QuoteReport
        skuFormData={skuFormData}
        vendor={vendor}
        user={user}
        quoteId={quoteUuid}
        attachments={quote_attachments}
        currentDate={today}
      />
    );
    const pdfInstance = pdf(document);
    const blob = await pdfInstance.toBlob();
    return blob;
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("files[]", file);
        formData.append("displayNames[]", file.name);
      });
    }

    if (selectedVendorList && selectedVendorList.length > 0) {
      selectedVendorList.forEach((vendor) => {
        formData.append("vendors[]", vendor.vendor_id.toString());
      });
    } else {
      toast.error("Please Add Vendor", toastOptions);
      return;
    }

    if (quoteMessage.length > 0) {
      formData.append("message", quoteMessage);
    }

    if (skuFormData.length > 0) {
      formData.append(
        "skus",
        JSON.stringify(
          skuFormData.map((row) => ({
            id: row.id,
            quantity: row.quantity,
            address: row.address.id,
          })),
        ),
      );
    }

    try {
      const res = await ApiService().client.post(
        URLS.QUOTES.RAISE_QUOTE,
        formData,
      );
      if (res.status === 201) {
        await sendQuoteReportsToVendor(
          res.data.quote.id,
          res.data.quote.uuid,
          res.data.quote_attachments,
        ).then((r) => r);
        navigate(`/quotes/${res.data.quote.id}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const sendQuoteReportsToVendor = async (
    quoteId: number,
    quoteUuId: string,
    quote_attachments: { name: string; url: string }[],
  ) => {
    const quoteReportFormData = new FormData();
    const pdfPromises = selectedVendorList.map(async (vendor) => {
      // Generate PDF
      const pdfBlob = await generatePdfBlob(
        quoteUuId,
        skuFormData,
        vendor,
        quote_attachments,
      );
      // Convert Blob to File
      const vendorSkuQuotePdfName = `${vendor.vendor_name}-sku-quote.pdf`;
      const vendorSkuQuotePdf = new File(
        [pdfBlob as BlobPart],
        vendorSkuQuotePdfName,
        {
          type: "application/pdf",
        },
      );
      return vendorSkuQuotePdf;
    });

    const vendorSkuQuotes = await Promise.all(pdfPromises);
    vendorSkuQuotes.forEach((pdf) => {
      quoteReportFormData.append("vendorSkuQuotes[]", pdf);
    });

    selectedVendorList.forEach((vendor) => {
      quoteReportFormData.append("vendors[]", vendor.vendor_id.toString());
    });

    const res = await ApiService().client.post(
      URLS.QUOTES.SEND_QUOTE_EMAIL(quoteId),
      quoteReportFormData,
    );
  };

  return (
    <div className="raise-quote-sku-main-page flex-column">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">
          <span className="cursor-pointer" onClick={() => navigate("/quotes")}>
            Quotes
          </span>
          <span className="sub-title xetgo-font-tag"> / Raise</span>
        </p>
      </div>
      <div className="raise-quote-lower-container-main flex-column gap-12 p-16 flex-1 overflow-hidden">
        <div className="sku-form-main-container flex-row gap-12 xetgo-font-tag">
          <div className="flex-column gap-8 flex-1">
            <p className="bold">SKU {selectedSku?.name}</p>
            <Filter
              option={{
                key: "skuName",
                name: "SKU",
              }}
              optionList={skus}
              multipleSelection={false}
              onSelection={skuSelected}
              preSelected={selectedSku ? [selectedSku] : []}
              mode="showNames"
              showPlaceholder={true}
              showId={false}
              direction="left"
              addNew={false}
              classname="sku-raise-quote-form-input"
            />
          </div>
          <div className="flex-column gap-8 flex-1 ">
            <p className="bold">Quantity</p>
            <div className="quantity-input-container position-relative xetgo-font-tag">
              <input
                type="number"
                onChange={(e) =>
                  setCurrentSkuQuantity(parseInt(e.target.value))
                }
                value={currentSkuQuantity}
                className="sku-raise-quote-form-input sku-raise-quantity-input xetgo-font-tag px-8 py-6 sku-raise-message bold full-width"
                placeholder="Enter Quantity"
              />
              <div className="unit-container flex-row gap-4">
                <p>|</p>
                <p>{selectedSku?.unit ? selectedSku.unit : "unit"}</p>
              </div>
            </div>
          </div>
          <div className="sku-form-address-main-container flex-row gap-12 xetgo-font-tag flex-1">
            <div className="flex-1 flex-column gap-8 ">
              <p>Address</p>
              <AddressDropdown
                showId={false}
                selected={selectedAddress || skuAddresses[0]}
                onSelection={setSelectedAddress}
                addresses={skuAddresses}
                classname="sku-raise-quote-form-input"
              />
            </div>
            {EditingIndex != -1 ? (
              <div
                onClick={completeEditing}
                className="sku-form-next-btn flex-row align-items-center justify-content-center py-6 px-24 cursor-pointer"
              >
                Edit & Add
              </div>
            ) : (
              <div
                onClick={addProduct}
                className="sku-form-next-btn flex-row align-items-center justify-content-center py-6 px-24 cursor-pointer"
              >
                {skuFormData.length > 0 ? "Add next Product" : "Next"}
              </div>
            )}
          </div>
        </div>
        <div className="middle-products-main-container flex-1 flex-column overflow-hidden">
          <div className="sku-product-row">
            <p className="index"></p>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703142823-Frame+1321314713.svg"
                alt="sku-id-icon"
                height={12}
                width={12}
              />
              <p>SKU ID</p>
            </div>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
                alt="sku-list-icon"
                height={12}
                width={12}
              />
              <p>SKU </p>
            </div>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
                alt="qantity-icon"
                height={12}
                width={12}
              />
              <p>Quantity </p>
            </div>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
                alt="description-icon"
                height={12}
                width={12}
              />
              <p>Description </p>
            </div>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143408-building-2.svg"
                alt="location-icon"
                height={12}
                width={12}
              />
              <p>Location </p>
            </div>
          </div>
          <div className="multi-sku-wrapper-box flex-1 overflow-scroll">
            <div className="all-sku-products-main-container">
              {skuFormData.length === 0 && (
                <p
                  style={{ height: "100%", color: "#636363" }}
                  className="flex-row align-items-center justify-content-center full-width xetgo-font-tag bold"
                >
                  No records have been added to this list yet.
                </p>
              )}
              {skuFormData.length > 0 &&
                skuFormData.map((sku, index) => {
                  return (
                    <div
                      key={index}
                      className="sku-product-row sku-product-container xetgo-font-tag"
                    >
                      <p className="index p-12 title">
                        {index + 1 < 10
                          ? `00${index + 1}`
                          : index + 1 < 99
                            ? `0${index + 1}`
                            : `${index + 1}`}
                      </p>
                      <p className="sku-id p-12">{sku.uuid}</p>
                      <p className="p-12 title right-side-border">{sku.name}</p>
                      <p className="p-12 title right-side-border">
                        {sku.quantity} {sku.unit}
                      </p>
                      <p className="p-12 right-side-border">
                        {sku.description}
                      </p>
                      <div className="p-12 address-edit-main-container position-relative">
                        <div className="location-container flex-column gap-4">
                          <p className="title city bold">{sku.address.city}</p>
                          <div className="flex-column">
                            <p>{sku.address.address_line} , </p>
                            <p>{sku.address.pincode}</p>
                          </div>
                        </div>
                        <div className="editing-main-container flex-row gap-8">
                          <div
                            className="delete-option p-4 cursor-pointer flex-row gap-10 align-items-center xetgo-font-tag"
                            onClick={() => deleteSkuFormData(index)}
                          >
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-trash-2.svg"
                              alt="delete"
                              width={16}
                              height={16}
                            />
                          </div>
                          <div
                            className="edit-option p-4 cursor-pointer flex-row gap-10 align-items-center xetgo-font-tag"
                            onClick={() => editSkuFormData(index)}
                          >
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
                              alt="edit"
                              width={16}
                              height={16}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {skuFormData.length > 0 && (
          <div className="vendor-form-main-container xetgo-font-tag flex-column gap-12">
            <div className="vendor-form-vendor-attachment-container">
              <div className="flex-column gap-8 ">
                <p>Vendor</p>
                <Filter
                  option={{
                    key: "vendors",
                    name: "Select Vendors",
                  }}
                  optionList={filteredVendorList || []}
                  multipleSelection={true}
                  onSelection={(val, _option) => {
                    handleVendorSelection(val);
                  }}
                  mode="showNames"
                  showPlaceholder={true}
                  direction="right"
                  addNew={false}
                  classname="category-filter-input"
                  vertical="top"
                />
                {selectedVendorList.length > 0 && (
                  <div className="flex-row gap-8 all-attachment-container">
                    {selectedVendorList.map((vendor, index) => {
                      return (
                        <div
                          key={index}
                          className="sku-raise-vendor-pill px-8 py-4 flex-row gap-10 align-items-center"
                        >
                          <p className="bold">{vendor.vendor_name},</p>
                          <p className="poc-name">
                            {vendor.company_poc.poc_name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="flex-column gap-8">
                <p>Attachments</p>
                <div>
                  <input
                    onChange={(event) => {
                      handleFileChange(event);
                    }}
                    id="sku-raise-fileInput"
                    name="file"
                    type="File"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                      display: "none",
                    }}
                    multiple
                  />
                  <label htmlFor="sku-raise-fileInput">
                    <p className="sku-raise-quote-form-input sku-raise-attachment xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer">
                      <img
                        height={16}
                        width={16}
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240510114359-upload.svg"
                        alt="upload-icon"
                      />
                    </p>
                  </label>
                </div>
                {fileList.length > 0 && (
                  <div className="flex-row gap-8 all-attachment-container">
                    {fileList.map((file, index) => {
                      return (
                        <div
                          key={index}
                          className="sku-raise-attachment-pill px-8 py-4 flex-row gap-10 align-items-center"
                        >
                          <p>{displayNames[index]}</p>
                          <img
                            height={12}
                            width={12}
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507184147-x.svg"
                            alt="remove-icon"
                            className="cursor-pointer"
                            onClick={() => removeFile(index)}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className=" flex-row gap-12 align-items-end">
              <div className="flex-column gap-8 flex-1">
                <p>Message</p>
                <input
                  onChange={(e) => setQuoteMessage(e.target.value)}
                  value={quoteMessage}
                  className="sku-raise-quote-form-input sku-raise-message sku-raise-message-vendor-select xetgo-font-tag px-12 py-8 bold"
                  placeholder="Enter message ..."
                />
              </div>
              <div
                onClick={handleSubmit}
                className="raise-quote-btn flex-row align-items-center justify-content-center px-24 py-8 gap-4 cursor-pointer"
              >
                <p>Raise Quote</p>
                <img
                  className="raise-quote-forward"
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308090308-arrow-right+%281%29.svg"
                  alt="next-icon"
                  height={16}
                  width={16}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RaiseQuoteSKU;
