import { debounce } from "lodash";
import AddressDropdown from "../../components/AddressDropdown/AddressDropdown";
import "./QualityCheck.scss";
import React, { useEffect, useMemo, useState } from "react";
import { UserAddress } from "../../redux/user/userTypes";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { useSelector } from "react-redux";
import {
  getUserWarehouseAddresses,
  userDetails,
} from "../../redux/user/userSelector";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDateWithYear } from "../../utils/date-util";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/QuotesData";
import { useNavigate } from "react-router-dom";

interface QualityCheckListSchema {
  procurement_request_id: number;
  procurement_request_product_delivery_id: number;
  vendor_details: {
    vendor_name: string;
    vendor_poc: string;
  };
  po_number: string;
  po_status: string;
  sku_name: string;
  uuid: string;
  delivered_quantity: number;
  approved_quantity: number | null;
  actual_delivery_date: string;
  measurement_unit: string;
}

const QualityCheck: React.FC = () => {
  const perPage = 20;
  const navigate = useNavigate();
  const warehouseAddresses = useSelector(getUserWarehouseAddresses);
  const [searchedText, setSearchedText] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(
    warehouseAddresses[0],
  );
  const companyId = useSelector(userDetails).company_id;
  const [hasMore, setHasMore] = useState(true);
  const [qualityCheckList, setQualityCheckList] = useState<
    QualityCheckListSchema[]
  >([]); //need to mention the schema that we willl get for the data

  useEffect(() => {
    getQualityCheckList(searchedText, 1);
  }, [selectedAddress]);

  const getQualityCheckList = async (searchText: string, page: number) => {
    try {
      const { data } = await ApiService().client.get(
        URLS.SKU.GET_QUALITY_CHECK_LIST,
        {
          params: {
            search_string: searchText,
            pageNumber: page,
            perPage: perPage,
            address: selectedAddress?.id,
            clientId: companyId,
          },
        },
      );
      setQualityCheckList(data.data);
      if (data.data.length > 0) {
        const updatedSkuList =
          page > 1 ? [...qualityCheckList, ...data.data] : data.data;
        setQualityCheckList(updatedSkuList);
        setPageNumber(pageNumber + 1);
        if (updatedSkuList.length == perPage * pageNumber) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } else {
        setQualityCheckList([]);
        setPageNumber(1);
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching quotes:", error);
      return [];
    }
  };

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      getQualityCheckList(searchText, 1);
    }, 500);
  }, [selectedAddress]);

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    setPageNumber(1);
    debouncedSearch(searchText);
  };

  const handleAddressSelection = (val: any) => {
    if (selectedAddress && selectedAddress.id === val.id) {
      setSelectedAddress(null);
    } else {
      setSelectedAddress(val);
    }
  };

  const handleNavigationToDetail = (id: number) => {
    navigate(`/quality-checks/detail/${id}`);
  };
  return (
    <div className="quality-check-main-page full-width flex-column">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">
          Quality Check <span className="sub-title xetgo-font-tag">/ List</span>
        </p>
      </div>
      <div className="quality-check-lower-main-container flex-1 flex-column gap-12">
        <div className="quality-check-upper-container flex-row justify-content-space-between align-items-center p-16">
          <div className="search-bar-container">
            <input
              className="py-8 px-34 search-input border-box xetgo-font-tag"
              value={searchedText}
              onChange={handleSearchFile}
              placeholder="Search ..."
            />
            <img
              height={16}
              width={16}
              className="search-icon "
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
              alt="search-icon"
            />
          </div>

          <div className="flex-row align-items-center gap-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
              alt="filter"
            />
            <AddressDropdown
              showId={false}
              selected={selectedAddress || null}
              onSelection={handleAddressSelection}
              addresses={warehouseAddresses}
              classname={"sku-category-filter"}
            />
          </div>
        </div>
        <div className="quality-check-list-lower-container flex-1 flex-column p-16">
          <div className="quality-check sku-list-heading-container">
            <p className="quality-check-empty"></p>
            <div className="sku-heading-skuId border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703142823-Frame+1321314713.svg"
                alt="barcode-icon"
                height={12}
                width={12}
              />
              <p>SKU </p>
            </div>
            <div className="sku-heading-name border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
                alt="list-icon"
                height={12}
                width={12}
              />
              <p>PO Number </p>
            </div>
            <div className="sku-heading-skuId border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
                alt="barcode-icon"
                height={12}
                width={12}
              />
              <p>Quantity</p>
            </div>
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-building-2.svg"
                alt="user-icon"
                height={12}
                width={12}
              />
              <p>Delivery Date </p>
            </div>{" "}
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-building-2.svg"
                alt="user-icon"
                height={12}
                width={12}
              />
              <p>Vendor</p>
            </div>{" "}
            <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-building-2.svg"
                alt="user-icon"
                height={12}
                width={12}
              />
              <p>Status </p>
            </div>
            {/* </div> */}
          </div>
          <div id="scrollableDiv" className="sku-list-scroll-wrapper flex-1">
            <InfiniteScroll
              dataLength={qualityCheckList.length}
              next={() => getQualityCheckList(searchedText, pageNumber)}
              hasMore={hasMore}
              loader={<h4></h4>}
              className="infiniteScrollComponent"
              scrollableTarget="scrollableDiv"
            >
              {qualityCheckList.length > 0 &&
                qualityCheckList.map((qualityCheck, index) => {
                  return (
                    <div
                      onClick={() =>
                        handleNavigationToDetail(
                          qualityCheck.procurement_request_product_delivery_id,
                        )
                      }
                      key={index}
                      className="quality-check sku-products-wrapper flex-row xetgo-font-tag"
                    >
                      <p
                        style={{ color: "#AA5200" }}
                        className="flex-row index p-12 border-box"
                      >
                        {qualityCheck.uuid}
                      </p>
                      <p className="sku-name border-box flex-row gap-8 p-12 xetgo-font-tag  bold">
                        {qualityCheck.sku_name}
                      </p>
                      <p className="sku-name border-box flex-row gap-8 p-12 xetgo-font-tag  bold">
                        <p className="uuid-container px-12 py-4">
                          {qualityCheck.po_number}
                        </p>
                      </p>{" "}
                      <div className="sku-name flex-column gap-12 align-items-center p-12">
                        <p className="border-box flex-row gap-8 xetgo-font-tag  justify-content-space-between full-width bold">
                          <span style={{ color: "#4A4A4A" }}>
                            {" "}
                            {qualityCheck.delivered_quantity}
                          </span>
                          <span style={{ color: "#A3A3A3" }}>
                            {qualityCheck.measurement_unit}
                          </span>
                        </p>
                        {qualityCheck.approved_quantity && (
                          <p className="approved-pill bold xetgo-font-mini px-8 py-4">
                            {qualityCheck.approved_quantity} - Approved
                          </p>
                        )}
                      </div>
                      <p
                        style={{ color: "#4A4A4A" }}
                        className="sku-name border-box p-12 xetgo-font-tag bold"
                      >
                        {formatDateWithYear(qualityCheck.actual_delivery_date)}
                      </p>
                      <div className="flex-1 p-12 description flex-column gap-12 sku-name">
                        <p style={{ color: "#111111" }} className="bold ">
                          {qualityCheck.vendor_details.vendor_name}
                        </p>
                        <p style={{ color: "#919191" }}>
                          {qualityCheck.vendor_details.vendor_poc}
                        </p>
                      </div>{" "}
                      <div className="flex-1 p-12 description">
                        <p
                          className="quality-check-current-status flex-row align-items-center justify-content-center p-6 xetgo-font-tag bold"
                          style={{
                            color:
                              QUOTE_STATUS_PROPERTY_MAP[qualityCheck.po_status]
                                ?.color ?? "white",
                            backgroundColor:
                              QUOTE_STATUS_PROPERTY_MAP[qualityCheck.po_status]
                                ?.backgroundColor ?? "#880000",
                          }}
                        >
                          {qualityCheck.po_status
                            ? qualityCheck.po_status
                            : "Pending"}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {qualityCheckList.length === 0 && (
                <div className="empty-state-qc-list flex-column gap-12 align-items-center justify-content-center xetgo-font-tag bold">
                  <img
                    height={21}
                    width={20}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240808061403-Document+add.svg"
                    alt="add-file-icon"
                  />
                  <p className="empty-text-state">
                    You don’t have any SKU for quality check Either upload POD
                    first or wait for delivery.
                  </p>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualityCheck;
