import React, { useState } from "react";
import "./VendorFormDetail.scss";
import PreDefinedVendorForm from "../PreDefinedVendorForm/PreDefinedVendorForm";

interface VendorFormDetailProps {
  formTitle: string;
  setFormTitle: (title: string) => void;
  formDescription: string;
  setFormDescription: (description: string) => void;
}
const VendorFormDetail: React.FC<VendorFormDetailProps> = ({
  formTitle,
  setFormTitle,
  formDescription,
  setFormDescription,
}) => {
  return (
    <div className="vendor-form-detail-main-container flex-column align-items-center justify-content-center">
      <div className="vendor-detail-form py-25 px-19 flex-column gap-18">
        <div className="form-info-main-container xetgo-font-tag flex-column gap-18">
          <p className="xetgo-font-button bold">Form Information</p>
          <div className="form-info-input-containers p-20 flex-column gap-12">
            <input
              className="vendor-detail-form-input p-12 xetgo-font-tag"
              value={formTitle}
              onChange={(e) => setFormTitle(e.target.value)}
              placeholder="Type Form Title"
            />
            <textarea
              style={{ color: "#454C58" }}
              className="vendor-detail-form-input p-12 xetgo-font-tag"
              value={formDescription}
              onChange={(e) => setFormDescription(e.target.value)}
              placeholder="Type Form Description"
            ></textarea>
          </div>
        </div>
        <div className="form-info-main-container xetgo-font-tag flex-column gap-18">
          <p className="xetgo-font-button bold">Predefined Questions</p>
          <PreDefinedVendorForm inputDisabled={true} />
        </div>
      </div>
    </div>
  );
};

export default VendorFormDetail;
