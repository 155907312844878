import React from "react";
import "./XetToggle.scss";

interface XetToggleProps {
  toggleValue: boolean | undefined;
  onToggle: (value: boolean) => void;
  backgroundColor?: string;
  disableColor?: string;
  enableColor?: string;
}

const XetToggle: React.FC<XetToggleProps> = ({
  toggleValue,
  onToggle,
  backgroundColor,
  disableColor,
  enableColor,
}) => {
  return (
    <div
      className={`preference-toggle ${toggleValue ? "on" : "off"}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <span
        className={`toggle-option flex-row align-items-center justify-content-center ${
          !toggleValue ? " off" : null
        }`}
        style={{
          backgroundColor: !toggleValue ? disableColor : "",
        }}
        onClick={() => onToggle(false)}
      >
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240119133054-x.svg"
          alt="off"
        />
      </span>
      <span
        className={`toggle-option flex-row align-items-center justify-content-center ${
          toggleValue ? " on" : null
        }`}
        style={{
          backgroundColor: toggleValue ? enableColor : "",
        }}
        onClick={() => onToggle(true)}
      >
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240119133121-check.svg"
          alt="on"
        />
      </span>
    </div>
  );
};

export default XetToggle;
