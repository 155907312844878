import React, { useEffect, useState } from "react";
import "./SkuPricing.scss";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { formatDate } from "../../utils/Helper";
import environment from "../../environments/environment";

interface SkuPricingProps {
  sku: SkuDetailSchema;
}

interface SkuVendorPrice {
  sku_price_id: number;
  price: number;
  unit: string;
  quantity: number;
  lead_time: number;
  name: string;
  vendor_id: number;
  created_at: string;
  uuid: string;
  quote_id: number;
}

interface SkuVendorPriceHistory {
  price: number;
  quantity: number;
  lead_time: number;
  created_at: string;
  uuid: string;
  quote_id: number;
}

const SkuPricing: React.FC<SkuPricingProps> = ({ sku }) => {
  const [skuDetail, setSkuDetail] = useState<{
    [id: number]: SkuVendorPrice[];
  }>({});
  const [priceId, setPriceId] = useState(-1);
  const [priceHistory, setPriceHistory] = useState<SkuVendorPriceHistory[]>([]);

  useEffect(() => {
    getVendorPrices();
  }, []);

  useEffect(() => {
    if (priceId !== -1) {
      getVendorPriceHistory();
    }
  }, [priceId]);

  const getVendorPrices = async () => {
    const { data } = await ApiService().client.get(
      URLS.SKU.GET_SKU_VENDOR_PRICES(sku.id),
    );
    setSkuDetail(data);
  };

  const toggleHistory = (price_id: number) => {
    if (priceId === price_id) {
      setPriceId(-1);
      setPriceHistory([]);
    } else {
      setPriceHistory([]);
      setPriceId(price_id);
    }
  };

  const getVendorPriceHistory = async () => {
    const { data } = await ApiService().client.get(
      URLS.SKU.GET_SKU_VENDOR_PRICE_HISTORY(sku.id, priceId),
    );
    setPriceHistory(data.history);
  };

  const handleVendorDetailNavigation = (id: number) => {
    const url = `${environment.clientUrl}/vendor/details/${id}`;
    window.open(url, "_blank");
  };

  return (
    <div className="sku-pricing-container">
      <div className="sku-pricing-header sku-pricing-main-grid">
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Vendor
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240716182234-boxes.svg"
            alt="moq"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            MOQ
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240709111945-%E2%82%B9.svg"
            alt="price"
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Price
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-table-properties.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Lead Time
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Start Time
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
            alt="source"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Source
          </p>
        </div>
      </div>
      {sku.locations.map((row, index) => (
        <div key={`address-container-${index}`}>
          <div>
            <div className="flex-row align-items-center gap-8 px-12 py-12 address-container">
              <div className="flex-row gap-10 align-items-center address-name-container">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240716103610-business.svg"
                  alt="warehouse"
                  width={12}
                  height={12}
                />
                <p className="xetgo-font-tag bold" style={{ color: "#000000" }}>
                  {row.address_city}
                </p>
              </div>
              <p
                className="address-name-container xetgo-font-tag bold"
                style={{ color: "#686868" }}
              >
                {row.address_line}
              </p>
            </div>
            {skuDetail &&
              (skuDetail[row.id] || []).map((entry, index) => (
                <div
                  key={`price-entry-${index}`}
                  onClick={() => toggleHistory(entry.sku_price_id)}
                >
                  <div className="sku-pricing-entry">
                    <p
                      className="px-12 py-8 xetgo-font-tag price-entry bold cursor-pointer "
                      style={{
                        color: "#AA5200",
                        borderBottom: "1px solid #e8e8e8",
                      }}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleVendorDetailNavigation(entry.vendor_id);
                        }}
                        className="vendor-name"
                      >
                        {entry.name}
                      </span>
                    </p>
                    <div>
                      <div className="sku-pricing-history-grid">
                        <p
                          className="px-12 py-8 xetgo-font-tag price-entry bold"
                          style={{ background: "#fff0e2" }}
                        >
                          {entry.quantity} {entry.unit}
                        </p>
                        <p
                          className="px-12 py-8 xetgo-font-tag price-entry bold"
                          style={{ background: "#E8FFEA" }}
                        >
                          ₹ {entry.price}
                        </p>
                        <p
                          className="px-12 py-8 xetgo-font-tag price-entry bold"
                          style={{ background: "#FFE8F4" }}
                        >
                          {entry.lead_time} days
                        </p>
                        <p className="px-12 py-8 xetgo-font-tag bold price-entry">
                          {formatDate(entry.created_at)}
                        </p>
                        <div className="flex-row align-items-center py-4 px-12">
                          <a
                            href={`/quotes/${entry.quote_id}`}
                            className="px-12 py-4 xetgo-font-tag bold quote-tag"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {entry.uuid}
                          </a>
                        </div>
                      </div>
                      {entry.sku_price_id === priceId &&
                        priceHistory.map((history) => (
                          <div
                            className="sku-pricing-history-grid"
                            key={`history-price-${history.uuid}`}
                          >
                            <p
                              className="px-12 py-8 xetgo-font-tag price-entry bold"
                              style={{ background: "#fff0e2" }}
                            >
                              {history.quantity} {entry.unit}
                            </p>
                            <p
                              className="px-12 py-8 xetgo-font-tag price-entry bold"
                              style={{ background: "#E8FFEA" }}
                            >
                              ₹ {history.price}
                            </p>
                            <p
                              className="px-12 py-8 xetgo-font-tag price-entry bold"
                              style={{ background: "#FFE8F4" }}
                            >
                              {history.lead_time} days
                            </p>
                            <p className="px-12 py-8 xetgo-font-tag bold price-entry">
                              {formatDate(history.created_at)}
                            </p>
                            <div className="flex-row align-items-center py-4 px-12">
                              <a
                                className="px-12 py-4 xetgo-font-tag bold quote-tag"
                                href={`/quotes/${history.quote_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {history.uuid}
                              </a>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkuPricing;
