import React, { useEffect, useState } from "react";
import "./SkuDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import SkuDetailCard from "../../components/SkuDetailCard/SkuDetailCard";
import SkuPricing from "../../components/SkuPricing/SkuPricing";
import SkuDelivery from "../../components/SkuDelivery/SkuDelivery";
import SkuPurchaseOrders from "../../components/SkuPurchaseOrders/SkuPurchaseOrders";
import SkuAnalytics from "../../components/SkuAnalytics/SkuAnalytics";
import SkuQualityCheck from "../../components/SkuQualityCheck/SkuQualityCheck";
import Quotes from "../Quotes/Quotes";

const SkuDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [addNewQuality, setAddNewQuality] = useState(false);
  const [addNewQualityTest, setAddNewQualityTest] = useState(false);
  const [skuDetail, setSkuDetail] = useState<SkuDetailSchema>();

  useEffect(() => {
    getSkuDetails();
  }, []);

  const getSkuDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.SKU.GET_SKU_DETAILS(Number(id)),
      );
      setSkuDetail(data);
    } catch (error) {
      console.error("error fetching Sku Detail", error);
    }
  };

  const handleSkuDetailsUpdate = (data: SkuDetailSchema) => {
    setSkuDetail(data);
  };

  return (
    <div className="sku-detail-main-page flex-column full-width">
      <div className="upper-bar">
        <p
          className="xetgo-font-tag bold px-16 py-12 flex-row gap-4 cursor-pointer"
          onClick={() => navigate("/skus")}
        >
          SKU
          <span className="slash">/</span>
          <span className="sub-title xetgo-font-tag">
            {skuDetail?.uuid} - {skuDetail?.sku_name}
          </span>
        </p>
      </div>
      <div className="sku-detail-Lower-main-container flex-column gap-12 p-16 flex-1">
        <div className="sku-details-tab-main-container flex-row justify-content-space-between xetgo-font-tag">
          <div className="all-tab-container flex-row align-items-center p-2">
            <p
              onClick={() => setActiveTab(0)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 0 && "active"
              } `}
            >
              Details
            </p>
            <p
              onClick={() => setActiveTab(1)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 1 && "active"
              } `}
            >
              Quality Check
            </p>
            <p
              onClick={() => setActiveTab(2)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 2 && "active"
              } `}
            >
              Pricing
            </p>
            <p
              onClick={() => setActiveTab(3)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 3 && "active"
              } `}
            >
              Delivery
            </p>
            <p
              onClick={() => setActiveTab(4)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 4 && "active"
              } `}
            >
              Quote
            </p>
            <p
              onClick={() => setActiveTab(5)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 5 && "active"
              } `}
            >
              Purchase Orders
            </p>
            <p
              onClick={() => setActiveTab(6)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 6 && "active"
              } `}
            >
              Analytics
            </p>
          </div>
          {activeTab === 1 && (
            <div className="flex-row align-items-center gap-8">
              <div
                onClick={() => setAddNewQuality(true)}
                className="quality-add-btn flex-row align-items-center justify-content-center gap-8 px-12 py-8 cursor-pointer"
              >
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
                  alt="add-icon"
                />
                <p>Add Property</p>
              </div>
              <div
                onClick={() => setAddNewQualityTest(true)}
                className="quality-add-btn flex-row align-items-center justify-content-center gap-8 px-12 py-8 cursor-pointer"
              >
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
                  alt="add-icon"
                />
                <p>Add Quality Test</p>
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 tab-details-container">
          {activeTab === 0 && skuDetail && (
            <SkuDetailCard
              sku={skuDetail}
              handleUpdate={handleSkuDetailsUpdate}
            />
          )}

          {activeTab === 1 && skuDetail && (
            <SkuQualityCheck
              addNewQuality={addNewQuality}
              setAddNewQuality={setAddNewQuality}
              skuId={skuDetail.id}
              addNewQualityTest={addNewQualityTest}
              setAddNewQualityTest={setAddNewQualityTest}
            />
          )}
          {activeTab === 2 && skuDetail && <SkuPricing sku={skuDetail} />}
          {activeTab === 3 && skuDetail && <SkuDelivery sku={skuDetail} />}
          {activeTab === 4 && skuDetail && <Quotes sku_id={Number(id)} />}
          {activeTab === 5 && skuDetail && (
            <SkuPurchaseOrders sku={skuDetail} />
          )}
          {activeTab === 6 && skuDetail && <SkuAnalytics sku={skuDetail} />}
        </div>
      </div>
    </div>
  );
};

export default SkuDetail;
