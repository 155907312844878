import React, { useEffect, useRef, useState } from "react";
import "./QuoteCard.scss";
import { Quote } from "../../models/Quote";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/QuotesData";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface QuoteCardProps {
  quote: Quote;
}

const QuoteCard: React.FC<QuoteCardProps> = ({ quote }) => {
  const navigate = useNavigate();
  const vendorsInitialsColorProperties = [
    { backgroundColor: "#FFE7E7", color: "#FC5E5F" },
    { backgroundColor: "#FBEFD9", color: "#FFA217" },
    { backgroundColor: "#E9FFD9", color: "#71C338" },
  ];
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const checkOverflow = () => {
    if (carouselRef.current) {
      const isOverflowing =
        carouselRef.current.scrollWidth > carouselRef.current.clientWidth;
      setIsOverflow(isOverflowing);
    }
  };

  const scrollLeft = (e: React.UIEvent<HTMLElement>) => {
    if (carouselRef.current) {
      e.stopPropagation();
      carouselRef.current.scrollBy({ left: -50, behavior: "smooth" });
    }
  };

  const scrollRight = (e: React.UIEvent<HTMLElement>) => {
    if (carouselRef.current) {
      e.stopPropagation();
      carouselRef.current.scrollBy({ left: 50, behavior: "smooth" });
    }
  };

  return (
    <div
      className="sku-quote-card-wrapper cursor-pointer"
      onClick={() => navigate(`/quotes/${quote.id}`)}
    >
      <div
        className="card-status flex-row align-items-center justify-content-center gap-4 py-8"
        style={{
          background: QUOTE_STATUS_PROPERTY_MAP[quote.status].backgroundColor,
          color: QUOTE_STATUS_PROPERTY_MAP[quote.status].color,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <img src={QUOTE_STATUS_PROPERTY_MAP[quote.status].url} />
        <span className="status-text xetgo-font-tag bold">{quote.status}</span>
      </div>

      <div className="card-content flex-column gap-16 p-20">
        <div className="enquiry-details flex-row align-items-center justify-content-space-between">
          <div className="enquiry-detail flex-column">
            <span className="card-detail-label xetgo-font-tag bold">
              Enquiry ID
            </span>
            <span className="card-detail-value xetgo-font-tag bold">
              {quote.uuid}
            </span>
          </div>

          <div className="enquiry-detail flex-column">
            <span className="card-detail-label xetgo-font-tag bold">
              Enquiry on
            </span>
            <span className="card-detail-value xetgo-font-tag bold">
              {dayjs(quote.created_at).format("D MMM YYYY")},
              <span className="enquiry-time">
                {dayjs(quote.created_at).format("h:mm a")}
              </span>
            </span>
          </div>
        </div>

        <div className="locations flex-column gap-8">
          <span className="location-label xetgo-font-tag bold">Locations</span>
          <div className="location-carousel-wrapper">
            {isOverflow && (
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240912174942-chevron-right.svg"
                className="carousel-arrow left-arrow"
                onClick={(e) => scrollLeft(e)}
                alt="left-arrow"
              />
            )}

            <div className="location-carousel" ref={carouselRef}>
              {quote.addresses.map((address, addressIndex) => (
                <span
                  key={addressIndex}
                  className="address-pill xetgo-font-tag bold"
                >
                  {address}
                </span>
              ))}
            </div>

            {isOverflow && (
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240912174942-chevron-right+%281%29.svg"
                className="carousel-arrow right-arrow"
                onClick={(e) => scrollRight(e)}
                alt="right-arrow"
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="vendors-section flex-row align-items-center justify-content-space-between px-20 py-8"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex-row align-items-center gap-8">
          <div className="vendors-initals flex-row align-items-center">
            {quote.vendors.map((vendor, vendorIndex) => {
              if (vendorIndex >= 3) return;

              return (
                <span
                  key={vendorIndex}
                  className="vendor-name-initals-circle flex-row align-items-center justify-content-center xetgo-font-mini"
                  style={{
                    background:
                      vendorsInitialsColorProperties[vendorIndex]
                        .backgroundColor,
                    color: vendorsInitialsColorProperties[vendorIndex].color,
                    border: `1px solid ${vendorsInitialsColorProperties[vendorIndex].color}`,
                    marginLeft: vendorIndex > 0 ? "-5px" : "",
                  }}
                >
                  {vendor.split(" ")[0].charAt(0) +
                    vendor.split(" ")[vendor.split(" ").length - 1].charAt(0)}
                </span>
              );
            })}
          </div>
          <span className="more-vendors-label xetgo-font-mini">
            {quote.vendors.length > 3
              ? `+${quote.vendors.length - 3} Vendors`
              : null}
          </span>
        </div>

        <div className="sku-count xetgo-font-tag">
          <span className="xetgo-font-tag" style={{ color: "#9d9d9d" }}>
            {" "}
            SKU :{" "}
          </span>
          {quote.number_of_products}
        </div>
      </div>
    </div>
  );
};

export default QuoteCard;
