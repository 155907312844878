import React from "react";
import { StatusTimelineSchema } from "../../models/StatusTimelineSchema";
import { formatDateWithYear, formatTime } from "../../utils/date-util";
import "./StatusTimeline.scss";
import { QuoteTimelineSchema, TimelineSchema } from "../../models/Quote";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/QuotesData";

interface StatusTimelineProps {
  statusTimelineData: TimelineSchema[] | null;
}

const StatusTimeline: React.FC<StatusTimelineProps> = ({
  statusTimelineData,
}) => {
  return (
    <div className="status-timeline flex-column gap-22">
      {statusTimelineData?.map((timeline, index) => (
        <div
          key={index}
          className="timeline-item flex-row align-items-flex-start"
        >
          <div
            style={{ background: `${index === 0 ? "#5151ec" : "white"}` }}
            className="status-disc"
          ></div>
          <div className="timeline-content flex-column ">
            <span
              className="timeline-status"
              style={{
                background:
                  QUOTE_STATUS_PROPERTY_MAP[timeline.value].backgroundColor,
                color: QUOTE_STATUS_PROPERTY_MAP[timeline.value].color,
              }}
            >
              {timeline.value.toUpperCase()}
            </span>
            <span className="timeline-created-at xetgo-font-mini">
              {formatDateWithYear(timeline.created_at) +
                ", " +
                formatTime(timeline.created_at)}
            </span>
          </div>
          {index !== statusTimelineData.length - 1 && (
            <div className="line">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240317084627-Line+130.svg"
                alt="line"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StatusTimeline;
