import { put, call, takeLatest, all } from "redux-saga/effects";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import {
  AddTaskCommentAction,
  DeleteQuoteAssetRecordAction,
  DeleteQuoteProductAction,
  GetQuoteDetailAction,
  QuoteActions,
  UpdateQuoteAssetRecordsAction,
  UpdateQuoteDetailsAction,
  UpdateQuoteProductAction,
} from "./quoteTypes";
import {
  addTaskCommentFailure,
  addTaskCommentSuccess,
  deleteQuoteAssetRecordFailure,
  deleteQuoteAssetRecordSuccess,
  deleteQuoteProductFailure,
  deleteQuoteProductSuccess,
  getQuoteDetailsFailure,
  getQuoteDetailsSuccess,
  updateQuoteAssetRecordsFailure,
  updateQuoteAssetRecordsSuccess,
  updateQuoteDetailsFailure,
  updateQuoteDetailsSuccess,
  updateQuoteProductFailure,
  updateQuoteProductSuccess,
} from "./quoteAction";

function* getQuotesDetailSaga(action: GetQuoteDetailAction) {
  try {
    const { data } = yield call(
      ApiService().client.get,
      `${URLS.QUOTES.GET_QUOTE_DETAILS(action.payload.id)}`,
    );

    yield put(getQuoteDetailsSuccess(data));
  } catch (error) {
    yield put(getQuoteDetailsFailure("Error fetching quote details."));
  }
}

function* watchGetQuoteDetails() {
  yield takeLatest(QuoteActions.GET_QUOTE_DETAIL, getQuotesDetailSaga);
}

function* updateQuoteProduct(action: UpdateQuoteProductAction) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URLS.QUOTES.UPDATE_QUOTE_PRODUCT(
        parseInt((action.payload.id || "").toString()),
      ),
      action.payload,
    );
    yield put(updateQuoteProductSuccess(data));
  } catch (error) {
    yield put(updateQuoteProductFailure("Quote update failed!!"));
  }
}

export function* watchUpdateQuoteProduct() {
  yield takeLatest(QuoteActions.UPDATE_QUOTE_PRODUCT, updateQuoteProduct);
}

function* deleteQuoteProduct(action: DeleteQuoteProductAction) {
  try {
    const { data } = yield call(
      ApiService().client.delete,
      URLS.QUOTES.DELETE_QUOTE_PRODUCT(
        parseInt((action.payload.quote_product_id || "").toString()),
      ),
      { data: action.payload },
    );
    yield put(deleteQuoteProductSuccess(data));
  } catch (error) {
    yield put(deleteQuoteProductFailure("Quote Product deletion failed!!"));
  }
}

export function* watchDeleteQuoteProduct() {
  yield takeLatest(QuoteActions.DELETE_QUOTE_PRODUCT, deleteQuoteProduct);
}

function* addTaskComment(action: AddTaskCommentAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URLS.QUOTES.ADD_TASK_COMMENT(
        parseInt((action.payload.get("task_id") || "").toString()),
      ),
      action.payload,
    );
    yield put(addTaskCommentSuccess(data));
  } catch (error) {
    yield put(addTaskCommentFailure("Comment addition failed!!"));
  }
}

export function* watchAddTaskComment() {
  yield takeLatest(QuoteActions.ADD_TASK_COMMENT, addTaskComment);
}

function* updateQuoteAssetRecords(action: UpdateQuoteAssetRecordsAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URLS.QUOTES.UPDATE_ASSET_RECORDS(
        parseInt((action.payload.get("task_id") || "").toString()),
      ),
      action.payload,
    );
    yield put(updateQuoteAssetRecordsSuccess({ asset_records: data }));
  } catch (error) {
    yield put(updateQuoteAssetRecordsFailure("Comment addition failed!!"));
  }
}

export function* watchUpdateQuoteAssetRecords() {
  yield takeLatest(
    QuoteActions.UPDATE_QUOTE_ASSET_RECORDS,
    updateQuoteAssetRecords,
  );
}

function* deleteQuoteAssetRecord(action: DeleteQuoteAssetRecordAction) {
  try {
    const { data } = yield call(
      ApiService().client.delete,
      URLS.QUOTES.DELETE_QUOTE_ASSET_RECORDS(action.payload.id),
      { data: action.payload },
    );
    yield put(deleteQuoteAssetRecordSuccess(action.payload));
  } catch (error) {
    yield put(deleteQuoteAssetRecordFailure("File deletion failed!!"));
  }
}

export function* watchDeleteQuoteAssetRecord() {
  yield takeLatest(
    QuoteActions.DELETE_QUOTE_ASSET_RECORD,
    deleteQuoteAssetRecord,
  );
}

function* updateQuoteDetails(action: UpdateQuoteDetailsAction) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URLS.QUOTES.UPDATE_QUOTE_DETAILS(action.payload.id),
      action.payload,
    );
    yield put(updateQuoteDetailsSuccess(action.payload));
  } catch (error) {
    yield put(updateQuoteDetailsFailure("Description update failed!!"));
  }
}

export function* watchUpdateQuoteDetails() {
  yield takeLatest(QuoteActions.UPDATE_QUOTE_DETAILS, updateQuoteDetails);
}

export default function* quoteSaga() {
  yield all([
    watchGetQuoteDetails(),
    watchAddTaskComment(),
    watchUpdateQuoteProduct(),
    watchDeleteQuoteProduct(),
    watchUpdateQuoteAssetRecords(),
    watchDeleteQuoteAssetRecord(),
    watchUpdateQuoteDetails(),
  ]);
}
