export const DELIVERY_CARD_STATUSES = {
  same_day: {
    custom_class: "same-day",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240122081125-map-pin.svg",
    text: "",
    uploadIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240607091817-upload+%281%29.svg",
    fileIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610081847-file-text.svg",
  },
  delayed_expected_yellow: {
    custom_class: "delayed-expected-yellow",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240122101916-yellow-map-pin.svg",
    text: "Delayed",
    uploadIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610085006-upload+%283%29.svg",
    fileIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610085006-file-text+%282%29.svg",
  },
  delayed_actual_red: {
    custom_class: "delayed-actual-red",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240122101916-red-map-pin.svg",
    text: "Delayed",
    uploadIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610084111-upload+%282%29.svg",
    fileIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610084111-file-text+%281%29.svg",
  },
  early_actual: {
    custom_class: "early-actual",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240123202338-dark-green-map-pin.svg",
    text: "Delivered early",
    uploadIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610085447-upload+%285%29.svg",
    fileIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610085447-file-text+%284%29.svg",
  },
  early_expected: {
    custom_class: "early-expected",
    imageUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240123141512-green-map-pin.svg",
    text: "Delivered early ",
    uploadIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610085224-upload+%284%29.svg",
    fileIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240610085224-upload+%284%29.svg",
  },
};
