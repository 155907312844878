import React, { useEffect, useState } from "react";
import "./VendorAnalytics.scss";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import { useSelector } from "react-redux";
import { getWarehouseUserAddresses } from "../../redux/user/userSelector";
import { UserAddress } from "../../redux/user/userTypes";
import { Chart } from "chart.js/auto";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { VendorDetailSchema } from "../../models/Quote";
import Filter from "../Filter/Filter";
import { commaFormat, getLast12MonthList } from "../../utils/Helper";
import { FilterOptionSchema } from "../Filter/filter.data";
import { GraphBackgrounds, GraphBorders } from "../../constants/Graph";

interface VendorAnalyticsProps {
  vendorDetail: VendorDetailSchema;
}

const VendorAnalytics: React.FC<VendorAnalyticsProps> = ({ vendorDetail }) => {
  const warehouseAddresses = useSelector(getWarehouseUserAddresses);
  const [skuAddresses, setSkuAddresses] = useState<UserAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(
    skuAddresses[0],
  );
  const [months, _setMonths] =
    useState<FilterOptionSchema[]>(getLast12MonthList());
  const [selectedMonth, setSelectedMonth] = useState<FilterOptionSchema[]>([
    months[0],
  ]);

  const handleAddressSelection = (val: any) => {
    if (selectedAddress && selectedAddress.id === val.id) {
      setSelectedAddress(null);
    } else {
      setSelectedAddress(val);
    }
  };

  useEffect(() => {
    const addresses = warehouseAddresses.filter(
      (row) => vendorDetail.sku_address_ids.indexOf(row.id) > -1,
    );
    setSkuAddresses(addresses);
    setSelectedAddress(addresses[2]);
  }, []);

  useEffect(() => {
    if (skuAddresses.length > 0) {
      getLocationAmountData();
    }
  }, [skuAddresses]);

  useEffect(() => {
    getLocationSkuWiseData();
  }, [selectedMonth, selectedAddress]);

  const getLocationSkuWiseData = async () => {
    try {
      const res = await ApiService().client.get(
        URLS.VENDORS.LOCATION_SKU_CONSUMPTION(vendorDetail.vendor_id),
        {
          params: {
            address_id: selectedAddress?.id,
            month: selectedMonth[0].id,
          },
        },
      );
      if (res.status === 200) {
        locationSkuRender(res.data.chart);
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const getLocationAmountData = async () => {
    try {
      const res = await ApiService().client.get(
        URLS.VENDORS.LOCATION_AMOUNT(vendorDetail.vendor_id),
      );
      if (res.status === 200) {
        locationAmountRender(res.data.chart, res.data.months);
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const locationSkuRender = async (
    data: { sku_name: string; consumption: number }[],
  ) => {
    await clearVendorChart();
    const canvas = document.getElementById("vendor-graph");
    if (canvas) {
      canvas.setAttribute("height", `${10 * 34 + 24}px`);
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "bar",
        data: {
          labels: data.map((row) => row.sku_name),
          datasets: [
            {
              label: "Consumption",
              data: data.map((row) => row.consumption),
              barThickness: 10,
              backgroundColor: "#FFE7F5",
              borderColor: "#68003E",
              borderWidth: 1,
              borderRadius: 10,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: "#9CA3AF",
                font: {
                  size: 12,
                  weight: 500,
                  family: "Inter",
                },
                align: "center",
              },
              grid: {
                display: true,
              },
              border: {
                display: false,
              },
            },
            x: {
              offset: true,
              grid: {
                display: false,
              },
              ticks: {
                color: "#AA7000",
                font: {
                  size: 12,
                  lineHeight: 1.5,
                  weight: 500,
                  family: "Inter",
                },
              },
            },
          },
        },
      });
    }
  };

  const clearVendorChart = async () => {
    const chartStatus = Chart.getChart("vendor-graph"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  const locationAmountRender = async (
    data: { [key: number]: number[] },
    months: string[],
  ) => {
    await clearLocationAmountChart();
    const stepSize = stepSizeValue(data);
    const canvas = document.getElementById("location-billing-graph");
    if (canvas) {
      canvas.setAttribute("height", `${10 * 34 + 24}px`);
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "line",
        data: {
          labels: months,
          datasets: skuAddresses.map((row, index) => {
            return {
              label: row.city,
              data: data[row.id] || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              borderColor: GraphBorders[index % GraphBorders.length],
              pointStyle: "circle",
              borderWidth: 1,
              pointRadius: 4,
              backgroundColor:
                GraphBackgrounds[index % GraphBackgrounds.length],
            };
          }),
        },
        options: {
          elements: {
            line: {
              tension: 0.5,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              align: "end",
              labels: {
                font: {
                  size: 12,
                  weight: 400,
                  family: "Inter",
                },
                color: "#4a4a4a",
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: "#9CA3AF",
                font: {
                  size: 12,
                  weight: 500,
                  family: "Inter",
                },
                callback: (label, index, labels) => {
                  return "₹ " + commaFormat(label.toString());
                },
                stepSize,
              },
              grid: {
                display: true,
              },
              border: {
                display: false,
              },
            },
            x: {
              offset: true,
              grid: {
                display: false,
              },
              ticks: {
                color: "#AA7000",
                font: {
                  size: 12,
                  lineHeight: 1.5,
                  weight: 500,
                  family: "Inter",
                },
              },
            },
          },
        },
      });
    }
  };

  const clearLocationAmountChart = async () => {
    const chartStatus = Chart.getChart("location-billing-graph"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  const stepSizeValue = (data: { [key: number]: number[] }) => {
    let max = 100;
    const rt = Object.keys(data);
    Object.keys(data).forEach((key: string) => {
      data[parseInt(key)].forEach((ent) => {
        max = Math.max(max, ent);
      });
    });
    let stepSize = Math.ceil(max / 10);
    stepSize = Math.ceil((stepSize + 1) / 10) * 10;
    return stepSize;
  };

  return (
    <div className="sku-analytics-detail-page flex-column full-width gap-36">
      <div className="vendor-graph-detail-container p-16 flex-column gap-16 ">
        <div className="flex-row jusitfy-content-space-between align-items-center justify-content-space-between">
          <p className="xetgo-font-caption bold" style={{ color: "#000000" }}>
            SKU Consumption
          </p>
          <div
            className="flex-row align-items-center justify-content-flex-end gap-12"
            style={{ width: "fit-content" }}
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
              alt="filter"
            />
            <Filter
              option={{ key: "month", name: "Month" }}
              optionList={months}
              multipleSelection={false}
              onSelection={(val, _option) => {
                setSelectedMonth(val);
              }}
              showPlaceholder={true}
              mode="showNames"
              addNew={false}
              showId={false}
              preSelected={selectedMonth}
              classname="sku-month-filter"
              direction="left"
              sort={false}
            />
            <AddressDropdown
              showId={false}
              selected={selectedAddress || null}
              onSelection={handleAddressSelection}
              addresses={skuAddresses}
              classname="sku-month-filter"
              placeholder="Location"
            />
          </div>
        </div>
        <div className="vendor-graph-container">
          <canvas id="vendor-graph"></canvas>
        </div>
      </div>
      <div className="sku-consumption-container p-16 flex-column gap-16">
        <p className="xetgo-font-caption bold" style={{ color: "#000000" }}>
          Total Amount / Month
        </p>
        <div>
          <canvas id="location-billing-graph"></canvas>
        </div>
      </div>
    </div>
  );
};

export default VendorAnalytics;
