import React, { RefObject, useEffect, useRef, useState } from "react";
import "./SkuDetailCard.scss";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import DeliveryLocationSelector from "../DeliveryLocationSelector/DeliveryLocationSelector";
import { useSelector } from "react-redux";
import { getUserWarehouseAddresses } from "../../redux/user/userSelector";
import TagsDropdown from "../TagsDropdown/TagsDropdown";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { FilterOptionSchema } from "../Filter/filter.data";
import { toastOptions } from "../../utils/toast";
import { toast } from "react-toastify";

interface SkuDetailProps {
  sku: SkuDetailSchema;
  handleUpdate: (data: SkuDetailSchema) => void;
}

const SkuDetailCard: React.FC<SkuDetailProps> = ({ sku, handleUpdate }) => {
  const userWarehouses = useSelector(getUserWarehouseAddresses);
  const [skuDetail, setSkuDetail] = useState(sku);
  const [skuName, setSkuName] = useState(sku.sku_name);
  const [description, setDescription] = useState(sku.description);
  const [selectedAddress, setSelectedAddress] = useState<number[]>([]);
  const skuNameRef = useRef<HTMLParagraphElement>(null);
  const [addLocation, setAddLocation] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [editingSku, setEditingSku] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [tagData, setTagData] = useState<FilterOptionSchema[]>([]);
  const [skuTags, setSkuTags] = useState<FilterOptionSchema[] | []>([]);
  const [filterSkuTags, setFilterSkuTags] = useState<FilterOptionSchema[] | []>(
    [],
  );

  const addresses = userWarehouses.filter(
    (warehouseAdd) =>
      !skuDetail.locations.some(
        (selectAdd) => selectAdd.id === warehouseAdd.id,
      ),
  );

  useEffect(() => {
    if (editingSku && skuNameRef.current) {
      (skuNameRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(skuNameRef.current);
    }
  }, [editingSku]);

  useEffect(() => {
    getAllTags();
  }, []);

  const getAllTags = async () => {
    try {
      const { data } = await ApiService().client.get(URLS.SKU.GET_SKU_TAGS);
      setSkuTags(data.tags);
      setFilterSkuTags(data.tags);
    } catch (error) {
      console.error("Error fetching tags:", error);
      return [];
    }
  };

  const addAddress = (id: number) => {
    if (selectedAddress.indexOf(id) > -1) {
      setSelectedAddress((val) => val.filter((row) => row !== id));
    } else {
      setSelectedAddress((val) => [...val, id]);
    }
  };

  const handleBlur = (
    ref: RefObject<HTMLElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setter(ref.current?.innerText || "");
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadType: string,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("sku_id", sku.id.toString());
      const files = Array.from(event.target.files);
      if (uploadType === "artwork") {
        files.forEach((file) => {
          formData.append("artworks[]", file);
          formData.append("artwork_file_names[]", file.name);
        });
      } else {
        files.forEach((file) => {
          formData.append("images[]", file);
          formData.append("image_file_names[]", file.name);
        });
      }
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        formData,
      );
      setSkuDetail(data);
      handleUpdate(data);
    }
  };

  const handleRemoveTag = async (tagId: number) => {
    const { data } = await ApiService().client.put(
      URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
      {
        sku_id: skuDetail.id,
        remove_tag_id: tagId,
      },
    );
    setEditingSku(false);
    setSkuDetail(data);
    handleUpdate(data);
  };

  const handleRemoveArtwork = async (artworkId: number) => {
    const { data } = await ApiService().client.put(
      URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
      {
        sku_id: skuDetail.id,
        remove_artwork_id: artworkId,
      },
    );
    setEditingSku(false);
    setSkuDetail(data);
    handleUpdate(data);
  };

  const handleRemoveImage = async (attachmentId: number) => {
    const { data } = await ApiService().client.put(
      URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
      {
        sku_id: skuDetail.id,
        remove_image_id: attachmentId,
      },
    );
    setEditingSku(false);
    setSkuDetail(data);
    handleUpdate(data);
  };

  const handleDeleteLocation = async (addressId: number) => {
    try {
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        {
          sku_id: skuDetail.id,
          remove_location_id: addressId,
        },
      );
      setEditingSku(false);
      setSkuDetail(data);
      handleUpdate(data);
    } catch (error) {
      console.error("error deleting location", error);
    }
  };

  const handleUpdateSku = async (updateType: string) => {
    try {
      const formData = new FormData();
      formData.append("sku_id", sku.id.toString());
      if (updateType === "name") {
        const description =
          descriptionRef.current?.innerHTML.replace(/\s+$/, "") || "";
        const skuName =
          skuNameRef.current?.innerHTML.replace(/&nbsp;| +/g, " ").trim() || "";
        if (
          skuDetail.sku_name == skuName &&
          skuDetail.description == description
        ) {
          setEditingSku(false);
          return;
        }
        if (skuDetail.sku_name === skuName) {
          formData.append("description", description);
        } else {
          formData.append("name", skuName);
        }
      } else if (updateType === "address") {
        selectedAddress.map((address: number) => {
          formData.append("address_ids[]", address.toString());
        });
      } else if (updateType === "tag") {
        formData.append("sku_tag_names[]", "address_id");
      }

      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        formData,
      );
      setEditingSku(false);
      setSkuDetail(data);
      handleUpdate(data);
    } catch (error) {
      console.error("error Updating Sku Detail", error);
    }
  };

  const handleAddSkuTag = async (tags: FilterOptionSchema[]) => {
    setTagData(tags);
  };

  const addLocationHandler = async () => {
    try {
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        {
          sku_id: skuDetail.id,
          address_ids: selectedAddress,
        },
      );
      setEditingSku(false);
      setSkuDetail(data);
      handleUpdate(data);
      setAddLocation(false);
      setSelectedAddress([]);
    } catch (error) {
      console.error("error adding address", error);
    }
  };

  const setCaretToEnd = (element: HTMLElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLParagraphElement>,
    ref: RefObject<HTMLParagraphElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (
      event.key === "Backspace" &&
      ref.current?.innerText === "" &&
      skuNameRef.current
    ) {
      (skuNameRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(skuNameRef.current);
      event.preventDefault();
    }
  };

  const handleTagSave = async () => {
    try {
      const filteredTags = tagData.filter(
        (tag) => !skuDetail.tags.some((skuTag) => skuTag.id === tag.id),
      );
      const skuTagIds = filteredTags.map((tag) => tag.id);
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_TAG(skuDetail.id),
        {
          sku_id: skuDetail.id,
          sku_tag_ids: skuTagIds,
        },
      );
      setSkuDetail(data);
      setShowAddTag(false);
    } catch (error) {
      console.error(error);
      toast.error(" Tags Adding Failed. Please try again.", toastOptions);
    }
  };

  const newTagAdd = (tag: FilterOptionSchema) => {
    if (skuTags) {
      setSkuTags([...skuTags, tag]);
      setFilterSkuTags([...skuTags, tag]);
    }
  };

  return (
    <div className="sku-detail-card-main-container">
      <div className="name-description-container xetgo-font-tag">
        <div className="flex-row justify-content-space-between p-12 xetgo-font-tag">
          <p
            ref={skuNameRef}
            contentEditable={editingSku}
            suppressContentEditableWarning={true}
            onBlur={() => handleBlur(skuNameRef, setSkuName)}
            className="sku-name xetgo-font-caption bolder"
            onKeyDown={(e) => handleKeyDown(e, skuNameRef)}
          >
            {skuName}
          </p>
          <p className="uuid px-6 py-4 bold">{skuDetail?.uuid}</p>
        </div>
        <div className="flex-row p-12 justify-content-space-between gap-10">
          <div
            ref={descriptionRef}
            contentEditable={editingSku}
            suppressContentEditableWarning={true}
            onBlur={() => handleBlur(descriptionRef, setDescription)}
            className="description"
          >
            {description}
          </div>
          {!editingSku ? (
            <img
              onClick={() => setEditingSku(true)}
              className="cursor-pointer"
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
              alt="edit-icon"
            />
          ) : (
            <div
              onClick={() => handleUpdateSku("name")}
              className="save xetgo-font-tag bold flex-row align-items-end cursor-pointer"
            >
              Save
            </div>
          )}
        </div>
      </div>
      <div className="all-tags-container flex-row gap-4 p-12 xetgo-font-mini align-items-center">
        {skuDetail &&
          skuDetail.tags.length > 0 &&
          skuDetail.tags.map((tag, index) => (
            <div
              key={index}
              className="tag-pill px-6 py-4 flex-row gap-4 align-items-center justify-content-center bold"
            >
              <p>{tag.name}</p>
              <img
                className="cursor-pointer"
                onClick={() => handleRemoveTag(tag.id)}
                height={10}
                width={10}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240713172658-x+%282%29.svg"
                alt="remove-icon"
              />
            </div>
          ))}
        {!showAddTag && (
          <div className="tag-pill tag-pill-add flex-row align-items-center justify-content-center p-4 border-box cursor-pointer">
            <img
              onClick={() => setShowAddTag(true)}
              height={10}
              width={10}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240713173448-add.svg"
              alt="add-icon"
            />
          </div>
        )}
        {showAddTag && (
          <OutsideAlerter action={() => setShowAddTag(false)}>
            <div className="sku-add-tag-dropdown-wrapper-main-container flex-row gap-8 align-items-center">
              <div className="sku-add-tag-dropdown-wrapper">
                <TagsDropdown
                  allTags={skuTags}
                  direction="left"
                  addedTags={handleAddSkuTag}
                  selectedTags={skuDetail.tags.map((tag) => tag.id)}
                  customClass={"custom-dropdown"}
                  newTagAdd={newTagAdd}
                />
              </div>

              <p
                className="xetgo-font-tag bold cursor-pointer px-12"
                style={{ color: "#5151EC" }}
                onClick={() => handleTagSave()}
              >
                Save
              </p>
            </div>
          </OutsideAlerter>
        )}
      </div>
      <div className="artwork-main-container p-12 xetgo-font-tag flex-column gap-12">
        <div className="flex-row align-items-center justify-content-space-between">
          <p>Artwork:</p>

          <div className="add-btn py-4 px-10 flex-row align-items-center gap-4 cursor-pointer">
            <input
              onChange={(event) => {
                handleFileChange(event, "artwork");
              }}
              id="artwork"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="artwork" className="cursor-pointer">
              <p className="flex-row gap-4 align-items-center">
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
                Add artwork
              </p>
            </label>
          </div>
        </div>
        <div className="flex-row gap-4">
          {skuDetail &&
            skuDetail.artworks.length > 0 &&
            skuDetail.artworks.map((art, index) => (
              <div
                key={index}
                className="artwork-pill py-4 px-8 flex-row gap-6 align-items-center "
              >
                <a
                  style={{ color: "#5151ec" }}
                  href={art.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {art.name}
                </a>
                <img
                  onClick={() => handleRemoveArtwork(art.id)}
                  className="cursor-pointer"
                  height={10}
                  width={10}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507184147-x.svg"
                  alt="remove-icon"
                />
              </div>
            ))}
        </div>
      </div>
      <div className="location-main-container  flex-column gap-12 xetgo-font-tag p-12">
        <div
          className="flex-row align-items-center justify-content-space-between"
          style={{ height: "26px" }}
        >
          <p>Location:</p>
          {!addLocation && addresses.length > 0 && (
            <div
              className="add-btn flex-row align-items-center justify-content-center py-4 px-10 gap-4 cursor-pointer"
              onClick={() => setAddLocation(true)}
            >
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                alt="add-icon"
              />
              <p>Add Location</p>
            </div>
          )}
        </div>
        {addLocation && (
          <div className="flex-row align-items-center gap-18 full-width">
            <div className="flex-1">
              <DeliveryLocationSelector
                addAddress={addAddress}
                selectedAddress={selectedAddress}
                addresses={addresses}
              />
            </div>
            <p
              className="xetgo-font-tag bold cursor-pointer"
              style={{ color: "#5151EC" }}
              onClick={addLocationHandler}
            >
              Save
            </p>
          </div>
        )}
        {skuDetail && skuDetail.locations.length > 0 && (
          <div className="all-location-container flex-row gap-8">
            {skuDetail.locations.map((location, index) => (
              <div key={index} className="location-card xetgo-font-tag">
                <p className="city bolder p-8 flex-row align-items-center justify-content-space-between">
                  {location.address_city}{" "}
                  <span
                    onClick={() => handleDeleteLocation(location.id)}
                    className="delete-location xetgo-font-mini bold cursor-pointer"
                  >
                    Delete
                  </span>
                </p>
                <div className="p-8 flex-column gap-34 flex-1 justify-content-space-between">
                  <p className="line-pin-code flex-column gap-4">
                    {location.address_line}{" "}
                    <span>{location.address_pincode}</span>
                  </p>
                  <div className="flex-column gap-4">
                    <p className="poc-name">
                      {location.poc_name}, {location.poc_number}
                    </p>
                    <p>{location.gstin || ""}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="image-main-container flex-column gap-12 xetgo-font-tag p-12">
        <div className="flex-row align-items-center justify-content-space-between">
          <p>Images:</p>

          <div className="add-btn py-4 px-10 flex-row align-items-center gap-4 cursor-pointer">
            <input
              onChange={(event) => {
                handleFileChange(event, "image");
              }}
              id="add-images"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="add-images" className="cursor-pointer">
              <p className="flex-row gap-4 align-items-center">
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                  alt="add-icon"
                />
                Add Image
              </p>
            </label>
          </div>
        </div>
        <div className="all-images-container flex-row gap-8">
          {skuDetail &&
            skuDetail.locations.length > 0 &&
            skuDetail.attachments.map((attachment, index) => (
              <div
                key={index}
                className="sku-image-container position-relative"
              >
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="sku-detail-image cursor-pointer"
                    height={61}
                    width={61}
                    src={attachment.url}
                    alt="images"
                  />
                </a>
                <img
                  onClick={() => handleRemoveImage(attachment.id)}
                  className="remove-sku-image cursor-pointer"
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715055536-Frame+1321314633.svg"
                  alt="remove-icon"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SkuDetailCard;
