import React, { useRef, useState } from "react";
import dayjs from "dayjs";
import "./ClientPoProductCardDetail.scss";
import { useDispatch } from "react-redux";
import {
  ClientPurchaseOrderProductDetails,
  ProductDeliverySchedule,
} from "../../../models/ClientPurchaseOrderDetail";
import {
  addPurchaseOrderDeliverySchedule,
  addPurchaseRequestDeliveryPOD,
  deletePurchaseOrderDeliverySchedule,
  updatePurchaseOrderDeliverySchedule,
} from "../../../redux/purchase_order/purchaseOrderAction";
import SkuQuoteProductProperty from "../../SkuQuoteProductProperty/SkuQuoteProductProperty";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatDate } from "../../../utils/Helper";

interface ClientPoProductCardDetailProps {
  cpo: ClientPurchaseOrderProductDetails;
}

const ClientPoProductCardDetail: React.FC<ClientPoProductCardDetailProps> = ({
  cpo,
}) => {
  const quantityRef = useRef<HTMLInputElement>(null);
  const trackingLinkRef = useRef<HTMLInputElement>(null);
  const inputFile = useRef<{ [key: number]: HTMLInputElement | null }>({});
  const [propertyDialogOpenId, setPropertyDialogOpenId] = useState(-1);
  const [newDeliveryDate, setNewDeliveryDate] = useState<string>("");
  const [newDeliveryOpen, setNewDeliveryOpen] = useState(false);
  const [showTrackLinkInputIndex, setShowTrackLinkInputIndex] = useState(-1);
  const dispatch = useDispatch();

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id.toString());
      dispatch(addPurchaseRequestDeliveryPOD(formData));
    }
  };

  const onUploadPOD = (id: number) => {
    inputFile.current[id]?.click();
  };

  const addDeliverySchedule = () => {
    if (newDeliveryDate && quantityRef.current) {
      dispatch(
        addPurchaseOrderDeliverySchedule({
          prp_id: cpo.procurement_request_product_id,
          expected_date: newDeliveryDate,
          quantity: Number(quantityRef.current.value),
          purchase_request_id: cpo.procurement_request_id,
        }),
      );
      quantityRef.current.value = "";
      setNewDeliveryDate("");
    }
  };

  const handleDeleteSchedule = (schedule: ProductDeliverySchedule) => {
    dispatch(
      deletePurchaseOrderDeliverySchedule({
        po_id: cpo.procurement_request_id,
        id: schedule.id,
      }),
    );
  };

  const toggleShowTrackingLinkInput = (schIndex: number) => {
    showTrackLinkInputIndex === schIndex
      ? setShowTrackLinkInputIndex(-1)
      : setShowTrackLinkInputIndex(schIndex);
  };

  const handleAddTrackingLink = (
    event: React.KeyboardEvent<HTMLInputElement>,
    schedule: ProductDeliverySchedule,
  ) => {
    if (event.key === "Enter") {
      dispatch(
        updatePurchaseOrderDeliverySchedule({
          po_id: cpo.procurement_request_id,
          id: schedule.id,
          track_link: trackingLinkRef?.current?.value as string,
        }),
      );
      setTimeout(() => {
        setShowTrackLinkInputIndex(-1);
      }, 200);
      if (trackingLinkRef.current) {
        trackingLinkRef.current.value = "";
      }
    }
  };

  return (
    <div className="detail-section">
      <div
        className="delivery-schedules-container flex-column gap-4 py-8"
        onClick={(e) => e.stopPropagation()}
      >
        {cpo.delivery_schedules.map((schedule, schIndex) => {
          return (
            <div key={schIndex} className="delivery-schedule-details">
              <p
                key={`qty-${schIndex}`}
                className="xetgo-font-tag quantity-tag px-12 py-8 bold"
              >
                {schedule.delivered_quantity} {cpo.unit}
              </p>
              <p
                key={`exp-${schIndex}`}
                className="xetgo-font-tag expected-tag px-12 py-8 bold"
              >
                {dayjs(schedule.expected_delivery_date).format("DD/MM/YYYY")}
              </p>

              {schedule.actual_delivery_date ? (
                <>
                  <p
                    key={`act-${schIndex}`}
                    className="xetgo-font-tag actual-completed-tag px-12 py-8 bold"
                  >
                    {dayjs(schedule.actual_delivery_date).format("DD/MM/YYYY")}
                  </p>
                  <p
                    key={`status-${schIndex}`}
                    className="xetgo-font-tag actual-completed-tag px-12 py-8 bold"
                  >
                    Completed
                  </p>
                  {schedule.pod && (
                    <>
                      <a
                        target="_blank"
                        href={schedule.pod}
                        rel="noopener noreferrer"
                        key={`pod-${schIndex}`}
                        className="pod-uploaded-tag px-12 py-8 flex-row align-items-center justify-content-space-between"
                      >
                        <p className="xetgo-font-tag bold">POD</p>
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240510114359-arrow-right+%282%29.svg"
                          alt="uploaded"
                          width={12}
                          height={12}
                        />
                      </a>
                      {/* <span className="name xetgo-font-tag flex-row gap-8 align-items-center">
                          <img
                            onClick={() => {
                              setPropertyDialogOpenId(schedule.id);
                            }}
                            className="cursor-pointer"
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620074716-info.svg"
                            alt="info-btn"
                            height={12}
                            width={12}
                          />
                          {propertyDialogOpenId === schedule.id && (
                            <SkuQuoteProductProperty
                              quoteProductId={cpo.quote_product_id}
                              handleClose={() => setPropertyDialogOpenId(-1)}
                              purchaseOrder={true}
                              scheduleId={schedule.id}
                            />
                          )}
                        </span> */}
                    </>
                  )}
                </>
              ) : (
                <>
                  <p
                    key={`na-status-${schIndex}`}
                    className="xetgo-font-tag no-actual-tag px-12 py-8 bold"
                  >
                    NA
                  </p>
                  {schedule.track_link ? (
                    <>
                      <a
                        target="_blank"
                        href={schedule.track_link}
                        rel="noopener noreferrer"
                        key={`track-link-${schIndex}`}
                        className="actual-dispatched-tag px-12 py-8 flex-row align-items-center justify-content-space-between"
                      >
                        <p
                          className="xetgo-font-tag bold"
                          style={{ fontSize: "11.5px" }}
                        >
                          Dispatched
                        </p>
                        {/* <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240513125223-arrow-right+%283%29.svg"
                          alt="uploaded"
                          width={12}
                          height={12}
                        /> */}
                      </a>
                      <div
                        key={`upload-pod-${schedule.id}`}
                        className="upload-pod-tag px-12 py-8 flex-row align-items-center justify-content-space-between"
                        onClick={() => onUploadPOD(schedule.id)}
                      >
                        <input
                          type="file"
                          id="select-file-pod"
                          ref={(el) => (inputFile.current[schedule.id] = el)}
                          onChange={(e) => handleFileChange(e, schedule.id)}
                          multiple={false}
                          style={{ display: "none" }}
                          key={`upload-pod-${schedule.id}`}
                        />
                        <p className="xetgo-font-tag bold">Upload POD</p>
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240510114359-upload.svg"
                          alt="uploaded"
                          width={12}
                          height={12}
                        />
                      </div>
                      <span className="name xetgo-font-tag flex-row gap-8 align-items-center">
                        {" "}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="scheduled-status-track-link-input flex-row align-items-center gap-8">
                        {showTrackLinkInputIndex === schIndex && (
                          <input
                            type="text"
                            placeholder="Add tracking link"
                            className="tracking-link-input py-8 px-12"
                            ref={trackingLinkRef}
                            onKeyDown={(event) =>
                              handleAddTrackingLink(event, schedule)
                            }
                          />
                        )}
                        <p
                          key={`sch-${schIndex}`}
                          className="xetgo-font-tag actual-scheduled-tag px-12 cursor-pointer py-8 bold"
                          onClick={() => toggleShowTrackingLinkInput(schIndex)}
                        >
                          Scheduled
                        </p>
                      </span>
                      <p
                        key={`no-sch-${schIndex}`}
                        className="xetgo-font-tag no-pod-tag px-12 py-8 bold"
                      >
                        --
                      </p>

                      <div
                        className="delete-schedule py-8 px-10 cursor-pointer flex-row align-items-center justify-content-center border-box"
                        onClick={() => handleDeleteSchedule(schedule)}
                      >
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523124141-trash-2.svg"
                          height={14}
                          width={14}
                        />
                      </div>
                      <p></p>
                    </>
                  )}
                </>
              )}
            </div>
          );
        })}

        <div className="add-new-schedule">
          <input
            placeholder="0 pcs"
            className="delivery-schedule-quantity xetgo-font-tag"
            ref={quantityRef}
          ></input>
          <div
            style={{ color: "#484848" }}
            className="expected-delivery-date flex-row align-items-center gap-4"
          >
            <p className="xetgo-font-tag">
              {newDeliveryDate
                ? formatDate(newDeliveryDate, false)
                : "Est. Delivery"}
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(newDeliveryDate)}
                format={"DD/MM/YYYY"}
                views={["year", "month", "day"]}
                onChange={(event) => {
                  if (event?.toDate()) {
                    setNewDeliveryDate(event.toDate().toISOString());
                    setNewDeliveryOpen(false);
                  }
                }}
                open={newDeliveryOpen}
                className="delivery-date-selector"
              />
            </LocalizationProvider>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523085546-calendar-days.svg"
              onClick={() => setNewDeliveryOpen((val) => !val)}
              className="cursor-pointer"
              height={10}
              width={10}
            />
          </div>
          <div
            className="add-button py-8 px-10 cursor-pointer flex-row align-items-center justify-content-center cursor-pointer"
            onClick={addDeliverySchedule}
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240523124141-bookmark-plus.svg"
              height={14}
              width={14}
            />
          </div>
        </div>
      </div>
      {cpo.files?.length > 0 && (
        <div
          className="px-8 flex-row gap-12 py-12 attachment-container"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240513044507-folder+1.svg"
            alt="attachments"
            width={16}
            height={16}
          />
          <div className="flex-row px-12 flex-wrap gap-8">
            {cpo.files.map((file, index) => {
              return (
                <a
                  target="_blank"
                  key={index}
                  href={file.url}
                  rel="noopener noreferrer"
                  className="xetgo-font-tag"
                  style={{ color: "#AA5200" }}
                >
                  {file.name}
                </a>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientPoProductCardDetail;
