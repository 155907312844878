import React, { useEffect, useRef, useState } from "react";
import "./AddVendorDropdown.scss";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import { VendorSchema } from "../../models/Vendor";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

interface AddVendorDropdownProps {
  onClose: () => void;
  onAddVendors: (val: boolean) => void;
}

const AddVendorDropdown: React.FC<AddVendorDropdownProps> = ({
  onClose,
  onAddVendors,
}) => {
  const [vendorForms, setVendorForms] = useState<
    { id: number; name: string }[]
  >([]);
  const [selectedVendorForm, setSelectefdVendorForm] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "Select Vendor Form" });
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    handleFetchVendors().then((r) => r);
  }, []);

  const handleFetchVendors = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.GET_VENDOR_FORMS,
      );
      setVendorForms(data.response);
    } catch (error) {
      console.log("Error fetching vendor forms", vendorForms);
    }
  };

  const handleAddVendors = async () => {
    try {
      const formData = new FormData();
      formData.append("vendor_emails", JSON.stringify(selectedVendors));
      formData.append("vendor_form_id", selectedVendorForm.id.toString());

      await ApiService().client.post(
        URLS.VENDORS.SEND_ADD_VENDORS_REQUEST,
        formData,
      );
      onAddVendors(true);
    } catch (error) {
      console.log("Error fetching vendor forms", vendorForms);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      setSelectedVendors([...selectedVendors, inputValue.trim()]);
      setInputValue("");
    } else if (
      e.key === "Backspace" &&
      inputValue === "" &&
      selectedVendors.length > 0
    ) {
      const lastVendor = selectedVendors[selectedVendors.length - 1];
      setSelectedVendors(selectedVendors.slice(0, -1));
      setInputValue(lastVendor);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="add-vendor-dialog">
      <div className="add-vendor-dialog-header flex-row align-items-center justify-content-space-between">
        Add new Vendor
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
          alt="close"
          className="cursor-pointer"
          onClick={onClose}
        />
      </div>

      <div className="dialog-content flex-column p-16">
        <div className="category-filter flex-column gap-8">
          <span className="filter-heading"> Select Vendor Form </span>
          <Filter
            option={{
              key: "vendor_form",
              name: "Vendor Forms",
            }}
            optionList={vendorForms || []}
            multipleSelection={false}
            onSelection={(val, _option) => {
              console.log("Selected Val", val);
              setSelectefdVendorForm(val[0]);
            }}
            preSelected={[selectedVendorForm]}
            mode="showNames"
            showPlaceholder={true}
            direction="left"
            addNew={false}
            classname="category-filter-input"
          />
        </div>

        <div className="vendor-email-section flex-column gap-12">
          <span className="vendor-email-heading"> Enter Vendor Emails </span>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "5px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            {selectedVendors.map((vendor, index) => (
              <span
                key={index}
                style={{
                  backgroundColor: "#e0f7fa",
                  borderRadius: "4px",
                  padding: "2px 6px",
                  margin: "2px",
                  display: "inline-block",
                  fontSize: 13,
                  height: "fit-content",
                }}
              >
                {vendor}
              </span>
            ))}
            <textarea
              ref={inputRef}
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              style={{
                flex: 1,
                minHeight: "150px",
                border: "none",
                outline: "none",
                resize: "none",
                margin: "2px",
              }}
              placeholder="Enter vendor email & press Enter.."
            />
          </div>
        </div>
      </div>

      <div className="actionable flex-row align-items-center justify-content-flex-end gap-8 p-16">
        <span
          className="secondary-action xetgo-font-tag cursor-pointer"
          onClick={onClose}
        >
          Cancel
        </span>
        <span
          className="primary-action xetgo-font-tag cursor-pointer"
          style={{
            background: selectedVendors.length > 0 ? "#5151EC" : "#B9B9F8",
          }}
          onClick={handleAddVendors}
        >
          Add Vendor
        </span>
      </div>
    </div>
  );
};

export default AddVendorDropdown;
