import React, { useState } from "react";
import "./OptionList.scss";
import { OptionListSchema } from "../../models/OptionListSchema";

interface OptionListProps {
  options: OptionListSchema[];
  activeOptionBackground?: string;
  activeOptionColor?: string;
  selectedOption?: string;
  onSelectOption: (selectedOption: OptionListSchema) => void;
}

const OptionList: React.FC<OptionListProps> = ({
  options,
  activeOptionBackground,
  activeOptionColor,
  selectedOption,
  onSelectOption,
}) => {
  const [hoverStates, setHoverStates] = useState(options.map(() => false));

  const handleMouseEnter = (index: number) => {
    setHoverStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });
  };

  const handleMouseLeave = (index: number) => {
    setHoverStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <div className="flex-row gap-4">
      <div className="options-line"></div>
      <div className="options-container flex-column flex-1 gap-2">
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className="option-row flex-row align-items-center gap-8"
              style={{
                color:
                  selectedOption === option.name
                    ? activeOptionColor
                    : "#707070",
                backgroundColor:
                  selectedOption === option.name ? activeOptionBackground : "",
              }}
              onClick={() => onSelectOption(option)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <span className="option-name xetgo-font-tag">{option.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OptionList;
