import React, { useEffect, useState } from "react";
import "./VendorDeliveryFilter.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatDate } from "../../utils/Helper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FilterOptionSchema } from "../Filter/filter.data";
import dayjs from "dayjs";
import Filter from "../Filter/Filter";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import { useSelector } from "react-redux";
import { getUserWarehouseAddresses } from "../../redux/user/userSelector";
import { UserAddress } from "../../redux/user/userTypes";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";

interface VendorDeliveryProps {
  startDate: Date;
  endDate: Date;
  startDateSelected: (date: Date) => void;
  endDateSelected: (date: Date) => void;
  selectedDeliveryType: string;
  toggleSelectedDeliveryType: (val: string) => void;
  selectedAddress: UserAddress | null;
  setSelectedAddress: (address: UserAddress | null) => void;
  vendorAddresses: UserAddress[];
}

const VendorDeliveryFilter: React.FC<VendorDeliveryProps> = ({
  startDate,
  endDate,
  startDateSelected,
  endDateSelected,
  selectedDeliveryType,
  toggleSelectedDeliveryType,
  vendorAddresses,
  selectedAddress,
  setSelectedAddress,
}) => {
  const [startDateOpen, setStartDateOpen] = useState<boolean>(false);
  const [endDateOpen, setEndDateOpen] = useState<boolean>(false);
  const userWarehouses = useSelector(getUserWarehouseAddresses);
  const [vendorAddressList, setVendorAddressList] =
    useState<UserAddress[]>(vendorAddresses);

  const startDateSelecttion = (event: any) => {
    startDateSelected(event.toDate());
    toggleStartDate();
  };

  const endDateSelection = (event: any) => {
    endDateSelected(event.toDate());
    toggleEndDate();
  };

  const toggleStartDate = () => {
    setStartDateOpen((val) => !val);
  };

  const toggleEndDate = () => {
    setEndDateOpen((val) => !val);
  };

  return (
    <div className="vendor-delivery-filter-container flex-column full-width">
      <div className="ds-header flex-row align-items-center justify-content-space-between">
        <div className="delivery-calendar-status-main-container flex-row justify-content-space-between">
          <div className="ds-status-tabs-container flex-row align-items-center gap-4">
            <span
              className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                selectedDeliveryType === "Overdue" && "active-tab"
              }`}
              onClick={() => toggleSelectedDeliveryType("Overdue")}
            >
              Overdue
            </span>
            <span
              className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                selectedDeliveryType === "Due" && "active-tab"
              }`}
              onClick={() => toggleSelectedDeliveryType("Due")}
            >
              Due
            </span>
            <span
              className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                selectedDeliveryType === "Delivered" && "active-tab"
              }`}
              onClick={() => toggleSelectedDeliveryType("Delivered")}
            >
              Delivered
            </span>
          </div>
        </div>

        <div className="ds-filters flex-row align-items-center gap-16">
          <img
            className="delivery-calendar-filter-icon"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231010064729-grey_filter.svg"
            alt="filter-icon"
            height={19}
            width={19}
          />
          <div className="delivery-calendar-multi-filter-box  gap-8">
            <AddressDropdown
              showId={false}
              selected={selectedAddress}
              onSelection={setSelectedAddress}
              addresses={vendorAddressList}
              classname="delivery-filter-container"
            />
            <div className="xetgo-font-tag flex-row align-items-center gap-12 date-container px-8 py-4">
              {formatDate(startDate?.toISOString())}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(startDate)}
                  format={"DD-MM-YYYY"}
                  views={["year", "month", "day"]}
                  onChange={startDateSelecttion}
                  open={startDateOpen}
                  className="delivery-date-selector"
                />
              </LocalizationProvider>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107114755-calendar-range.svg"
                onClick={toggleStartDate}
                className="cursor-pointer"
                height={16}
                width={16}
              />
            </div>
            <div className="date-container xetgo-font-tag flex-row align-items-center gap-12 px-8 py-4">
              {formatDate(endDate?.toISOString())}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(endDate)}
                  format={"DD-MM-YYYY"}
                  views={["year", "month", "day"]}
                  onChange={endDateSelection}
                  open={endDateOpen}
                  className="delivery-date-selector"
                />
              </LocalizationProvider>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107114755-calendar-range.svg"
                onClick={toggleEndDate}
                className="cursor-pointer"
                height={16}
                width={16}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDeliveryFilter;
