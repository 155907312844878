import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import "./SkuQualityCheckTestCard.scss";
import React, { RefObject, useEffect, useRef, useState } from "react";

interface QcDetailSchema {
  id: number;
  name: string;
  description: string;
  test_method: string | null;
}
interface QualityCheckTestProps {
  qcDetail: QcDetailSchema;
  index: number;
  handleDeleteQualityCheckTest: (index: number) => void;
  handleUpdate: (data: QcDetailSchema) => void;
}

const SkuQualityCheckTestCard: React.FC<QualityCheckTestProps> = ({
  qcDetail,
  index,
  handleDeleteQualityCheckTest,
  handleUpdate,
}) => {
  const [qcTestDetail, setQcTestDetail] = useState<QcDetailSchema>(qcDetail);
  const [qualityTestValueIndex, setQualityTestValueIndex] = useState(-1);
  const qualityTestref = useRef<HTMLParagraphElement | null>(null);
  const [editingKey, setEditingKey] = useState(false);
  const [testMethodName, setTestMethodName] = useState(
    qcTestDetail.test_method || "",
  );
  const testNameRef = useRef<HTMLParagraphElement>(null);
  const [testName, setTestName] = useState(qcTestDetail.name);

  const descriptionRef = useRef<HTMLDivElement>(null);
  const [description, setDescription] = useState(qcTestDetail.description);

  useEffect(() => {
    if (editingKey && testNameRef.current) {
      (testNameRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(testNameRef.current);
    }
  }, [editingKey]);

  const handleValueKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    try {
      if (e.shiftKey && e.key === "Enter") {
        e.preventDefault();
        return;
      }

      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        if (qualityTestref.current) {
          if (
            qualityTestref.current.textContent === testMethodName ||
            qualityTestref.current.textContent == ""
          ) {
            qualityTestref.current.textContent = testMethodName;
            setQualityTestValueIndex(-1);
            qualityTestref.current.blur();
            return;
          }
        }
        const { data } = await ApiService().client.put(
          URLS.SKU.UPDATE_SKU_QUALITY_CHECK_TEST(qcTestDetail.id),
          {
            test_method: e.currentTarget.textContent,
          },
        );
        setTestMethodName(data.data.test_method);
        setQualityTestValueIndex(-1);
        if (qualityTestref.current) {
          qualityTestref.current.blur();
        }
      }
    } catch (error) {
      console.error("Error updating Quality Test");
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await ApiService().client.delete(
        URLS.SKU.UPDATE_SKU_QUALITY_CHECK_TEST(qcDetail.id),
      );
      handleDeleteQualityCheckTest(qcDetail.id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNameDescriptionUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("id", qcDetail.id.toString());
      const descriptionToSend =
        descriptionRef.current?.innerHTML.replace(/\s+$/, "") || "";
      const testNameToSend =
        testNameRef.current?.innerHTML.replace(/&nbsp;| +/g, " ").trim() || "";
      if (
        qcTestDetail.name == testNameToSend &&
        qcTestDetail.description == descriptionToSend
      ) {
        setEditingKey(false);
        return;
      }

      if (descriptionRef.current) {
        formData.append("description", descriptionRef.current?.innerText);
      }

      if (testNameRef.current) {
        formData.append("name", testNameRef.current.innerText);
      }

      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_QUALITY_CHECK_TEST(qcDetail.id),
        formData,
      );
      setEditingKey(false);
      setQcTestDetail(data.data);
      handleUpdate(data.data);
    } catch (error) {
      console.error("Error updating QC Test", error);
    }
  };

  const handleBlur = (
    ref: RefObject<HTMLElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setter(ref.current?.innerText || "");
  };

  const setCaretToEnd = (element: HTMLElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLParagraphElement>,
    ref: RefObject<HTMLParagraphElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (
      event.key === "Backspace" &&
      ref.current?.innerText === "" &&
      testNameRef.current
    ) {
      (testNameRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(testNameRef.current);
      event.preventDefault();
    }
  };
  return (
    <div key={index} className="quality-test quality-test-grid xetgo-font-tag">
      <p className="test-index flex-row align-items-center p-12">
        {index + 1 < 10 ? "00" : index + 1 < 100 ? "0" : ""}
        {index + 1}
      </p>
      <div className="qc-test-middle-container flex-column">
        <div className="test-name-container flex-row justify-content-space-between align-items-center gap-8">
          <p
            ref={testNameRef}
            contentEditable={editingKey}
            suppressContentEditableWarning={true}
            onBlur={() => handleBlur(testNameRef, setTestName)}
            className="test-name xetgo-font-tag p-12 bold flex-1"
            onKeyDown={(e) => handleKeyDown(e, testNameRef)}
          >
            {testName}
          </p>
          {!editingKey ? (
            <div className="edit-del-btn flex-row gap-8">
              <div
                onClick={() => handleDelete()}
                className="delete-container flex-row align-items-center justify-content-center p-4 cursor-pointer"
              >
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308111001-trash-2.svg"
                  alt="delete-icon"
                />
              </div>
              <div
                onClick={() => setEditingKey(true)}
                className="edit-container flex-row align-items-center justify-content-center p-4 cursor-pointer"
              >
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506100306-pen-square+%281%29.svg"
                  alt="edit-icon"
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                handleNameDescriptionUpdate();
              }}
              className="save xetgo-font-tag bold flex-row align-items-end cursor-pointer"
            >
              Save
            </div>
          )}
        </div>
        <div
          ref={descriptionRef}
          contentEditable={editingKey}
          suppressContentEditableWarning={true}
          onBlur={() => handleBlur(descriptionRef, setDescription)}
          className="px-12 py-8 test-description"
        >
          {description}
        </div>
      </div>
      <div className="full-width border-box p-12">
        <OutsideAlerter
          className="quality-check-test-method-container full-width"
          action={() => setQualityTestValueIndex(-1)}
        >
          <p
            onClick={() => setQualityTestValueIndex(index)}
            ref={qualityTestref}
            contentEditable={true}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              handleValueKeyDown(e);
            }}
            dangerouslySetInnerHTML={{
              __html: testMethodName || "",
            }}
            className={`quality-check-input test-value px-16 py-8 border-box full-width ${
              qualityTestValueIndex === index && "test-editing-value"
            }`}
          ></p>
        </OutsideAlerter>
      </div>
    </div>
  );
};

export default SkuQualityCheckTestCard;
