import React, { useEffect, useState } from "react";
import "./Quotes.scss";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { Quote } from "../../models/Quote";
import QuoteCard from "../../components/QuoteCard/QuoteCard";

interface QuotesProps {
  sku_id?: number;
}

const Quotes: React.FC<QuotesProps> = ({ sku_id }) => {
  const [quoteList, setQuoteList] = useState<Quote[]>([]);
  const [filteredQuoteList, setFilteredQuoteList] = useState<Quote[]>([]);
  const [searchedText, setSearchedText] = useState("");
  const [selectedFilterTab, setSelectedFilterTab] = useState<
    "all" | "In Progress" | "On Hold" | "Completed"
  >("all");

  useEffect(() => {
    getQuotes().then((r) => {
      return r;
    });
  }, []);

  useEffect(() => {
    if (selectedFilterTab === "all") {
      const updatedQuoteList =
        searchedText.length > 0
          ? quoteList.filter((quote) =>
              quote.uuid.toLowerCase().includes(searchedText.toLowerCase()),
            )
          : quoteList;
      setFilteredQuoteList(updatedQuoteList);
      return;
    }

    const updatedQuoteList = quoteList.filter(
      (quote) =>
        quote.uuid.toLowerCase().includes(searchedText.toLowerCase()) &&
        quote.status === selectedFilterTab,
    );
    setFilteredQuoteList(updatedQuoteList);
  }, [selectedFilterTab]);

  const getQuotes = async () => {
    try {
      const { data } = await ApiService().client.get(URLS.QUOTES.GET_QUOTES, {
        params: { sku_id: sku_id },
      });
      if (data.quotes.length > 0) {
        const updatedQuoteList = [...quoteList, ...data.quotes];
        setQuoteList(updatedQuoteList);
        setFilteredQuoteList(updatedQuoteList);
      }
    } catch (error) {
      console.error("Error fetching quotes:", error);
      return [];
    }
  };

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    const updatedQuoteList =
      selectedFilterTab === "all"
        ? quoteList.filter((quote) =>
            quote.uuid.toLowerCase().includes(searchText.toLowerCase()),
          )
        : quoteList.filter(
            (quote) =>
              quote.uuid.toLowerCase().includes(searchText.toLowerCase()) &&
              quote.status === selectedFilterTab,
          );
    setFilteredQuoteList(updatedQuoteList);
  };

  return (
    <div className="quotes-page-container flex-column">
      <div className="quote-top-section">
        <div className="quote-page-header xetgo-font-tag px-20 py-12">
          <span style={{ color: "#AA5200" }}> Pacpro </span>
          <span style={{ color: "#AEAEAE" }}>/ Quotes </span>
        </div>

        <div className="search-and-filters-section p-20 flex-row align-items-center gap-16">
          <div className="search-bar-container">
            <input
              className="py-8 px-34 search-input border-box xetgo-font-tag"
              value={searchedText}
              onChange={handleSearchFile}
              placeholder="Search quotes using enquiry id..."
            />
            <img
              className="search-icon"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
              alt=""
            />
          </div>

          <div className="filter-by-status flex-row align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
              alt="filter"
              style={{ marginRight: "10px" }}
            />
            <div className="status-filter-tabs flex-row align-items-center">
              <span
                className={`status-filter-tab cursor-pointer xetgo-font-tag bold ${selectedFilterTab === "all" && "active"}`}
                onClick={() => setSelectedFilterTab("all")}
              >
                {" "}
                All{" "}
              </span>
              <span
                className={`status-filter-tab cursor-pointer xetgo-font-tag bold ${selectedFilterTab === "In Progress" && "active"}`}
                onClick={() => setSelectedFilterTab("In Progress")}
              >
                {" "}
                In Progress{" "}
              </span>
              <span
                className={`status-filter-tab cursor-pointer xetgo-font-tag bold ${selectedFilterTab === "On Hold" && "active"}`}
                onClick={() => setSelectedFilterTab("On Hold")}
              >
                {" "}
                On Hold{" "}
              </span>
              <span
                className={`status-filter-tab cursor-pointer xetgo-font-tag bold ${selectedFilterTab === "Completed" && "active"}`}
                onClick={() => setSelectedFilterTab("Completed")}
              >
                {" "}
                Completed{" "}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="quotes-cards-container">
        {filteredQuoteList?.map((quote, index) => {
          return <QuoteCard key={index} quote={quote} />;
        })}
      </div>
    </div>
  );
};

export default Quotes;
