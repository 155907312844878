import React, { useEffect, useState } from "react";
import "./AddNewCompanyAddress.scss";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../redux/user/userAction";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

interface CompanyAddressProps {
  companyId: number | null;
  addressType: string;
  handleClose: () => void;
}

const AddNewCompanyAddress: React.FC<CompanyAddressProps> = ({
  companyId,
  addressType,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [gstInNumber, setGstInNumber] = useState("");
  const [aliasName, setAliasName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");

  const handleAddAddress = () => {
    const formData = new FormData();
    const addressDetails = {
      address_line: addressLine,
      address_type: addressType.toUpperCase(),
      city: city,
      country: "INDIA",
      pincode: pincode,
      state: state,
      gstin: gstInNumber,
      alias_name: aliasName,
      company_name: companyName,
      poc_name: contactPerson,
      contact_number: contactNumber,
    };
    formData.append("company_id", companyId?.toString() || "");
    formData.append("address_details", JSON.stringify(addressDetails));

    // Field validation
    const fields = [
      { value: gstInNumber, message: "Add GSTIN" },
      { value: companyName, message: "Add Company Name" },
      { value: contactPerson, message: "Add POC" },
      { value: contactNumber, message: "Add POC Number" },
      { value: addressLine, message: "Add Address Line" },
      { value: city, message: "Add City" },
      { value: state, message: "Add State" },
      { value: pincode, message: "Add Pincode" },
    ];

    for (const field of fields) {
      if (field.value.length <= 0) {
        toast.error(field.message, toastOptions);
        return;
      }
    }
    dispatch(updateUserDetails(formData));
    handleClose();
  };

  return (
    <div className="new-company-add-modal border-box px-16 py-12">
      <div className=" flex-column gap-12 p-16">
        <p className="xetgo-font-h2 ">Add {addressType} Address</p>
        <p className="billing-sub-heading">
          Please provide your billing address.
        </p>
      </div>
      <form className="flex-column gap-16 p-16 xetgo-font-tag bold">
        <div className="flex-row gap-12">
          <input
            className="company-add-billing-input"
            type="text"
            placeholder="GSTIN Number"
            onChange={(e) => setGstInNumber(e.target.value)}
            value={gstInNumber}
            required
          />
          <input
            className="company-add-billing-input"
            type="text"
            placeholder="Alias Name "
            onChange={(e) => setAliasName(e.target.value)}
            value={aliasName}
            required
          />
        </div>
        <input
          className="company-add-billing-input"
          type="text"
          placeholder="Registered Company Name "
          onChange={(e) => setCompanyName(e.target.value)}
          value={companyName}
          required
        />
        <div className="flex-row gap-12">
          <input
            className="company-add-billing-input"
            type="text"
            placeholder="Person Name "
            onChange={(e) => setContactPerson(e.target.value)}
            value={contactPerson}
            required
          />
          <input
            className="company-add-billing-input"
            type="number"
            placeholder="Contact Number "
            onChange={(e) => setContactNumber(e.target.value)}
            value={contactNumber}
            required
          />
        </div>
        <input
          className="company-add-billing-input"
          type="text"
          placeholder="Address"
          onChange={(e) => setAddressLine(e.target.value)}
          value={addressLine}
          required
        />
        <div className="flex-row gap-12">
          <input
            className="company-add-billing-input"
            type="text"
            placeholder="City  "
            onChange={(e) => setCity(e.target.value)}
            value={city}
            required
          />
          <input
            className="company-add-billing-input"
            type="text"
            placeholder="State  "
            onChange={(e) => setState(e.target.value)}
            value={state}
            required
          />
        </div>
        <div className="flex-row gap-12">
          <input
            className="company-add-billing-input"
            type="number"
            placeholder="Pincode  "
            onChange={(e) => setPincode(e.target.value)}
            value={pincode}
            required
          />
        </div>
      </form>

      <div className="add-product-modal-btns-container justify-content-end flex-row gap-12 new-form-btn-container">
        <div onClick={handleClose} className="cancel-btn xetgo-font-tag">
          Cancel
        </div>
        <div onClick={handleAddAddress} className="add-btn xetgo-font-tag">
          Add
        </div>
      </div>
    </div>
  );
};

export default AddNewCompanyAddress;
