import {
  AddPurchaseDeliveryPODSchema,
  AddPurchaseOrderDeliverySchedule,
  AddPurchaseOrderDeliveryScheduleFailure,
  AddPurchaseOrderDeliverySchedulePayload,
  AddPurchaseOrderDeliveryScheduleSuccess,
  AddPurchaseRequestCommentAction,
  AddPurchaseRequestCommentFailureAction,
  AddPurchaseRequestCommentSuccessAction,
  AddPurchaseRequestDeliveryPOD,
  AddPurchaseRequestDeliveryPODFailure,
  AddPurchaseRequestDeliveryPODSuccess,
  DeletePurchaseOrderDeliverySchedule,
  DeletePurchaseOrderDeliveryScheduleFailure,
  DeletePurchaseOrderDeliveryScheduleSuccess,
  GetPurchaseOrderDetailAction,
  GetPurchaseOrderDetailFailureAction,
  GetPurchaseOrderDetailSuccessAction,
  PurchaseOrderAction,
  UpdatePurchaseOrderDeliverySchedule,
  UpdatePurchaseOrderDeliveryScheduleFailure,
  UpdatePurchaseOrderDeliveryScheduleSuccess,
} from "./purchaseOrderTypes";
import {
  ClientPurchaseOrderDetailSchema,
  ClientPurchaseRequestComment,
  ProductDeliverySchedule,
} from "../../models/ClientPurchaseOrderDetail";

export const getPurchaseOrderDetails = (
  id: number,
): GetPurchaseOrderDetailAction => ({
  type: PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL,
  payload: { id: id },
});

export const getPurchaseOrderDetailsSuccess = (
  response: ClientPurchaseOrderDetailSchema,
): GetPurchaseOrderDetailSuccessAction => ({
  type: PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL_SUCCESS,
  payload: response,
});

export const getPurchaseOrderDetailsFailure = (
  error: string,
): GetPurchaseOrderDetailFailureAction => ({
  type: PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL_FAILURE,
  error,
});

export const addPurchaseRequestComment = (
  payload: FormData,
): AddPurchaseRequestCommentAction => ({
  type: PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT,
  payload: payload,
});

export const addPurchaseRequestCommentSuccess = (
  payload: ClientPurchaseRequestComment,
): AddPurchaseRequestCommentSuccessAction => ({
  type: PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT_SUCCESS,
  payload: payload,
});

export const addPurchaseRequestCommentFailure = (
  error: string,
): AddPurchaseRequestCommentFailureAction => ({
  type: PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT_FAILURE,
  error: error,
});

export const addPurchaseRequestDeliveryPOD = (
  payload: FormData,
): AddPurchaseRequestDeliveryPOD => ({
  type: PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD,
  payload: payload,
});

export const addPurchaseRequestDeliveryPODSuccess = (
  payload: AddPurchaseDeliveryPODSchema,
): AddPurchaseRequestDeliveryPODSuccess => ({
  type: PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD_SUCCESS,
  payload: payload,
});

export const addPurchaseRequestDeliveryPODFailure = (
  error: string,
): AddPurchaseRequestDeliveryPODFailure => ({
  type: PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD_FAILURE,
  error: error,
});

export const addPurchaseOrderDeliverySchedule = (
  payload: AddPurchaseOrderDeliverySchedulePayload,
): AddPurchaseOrderDeliverySchedule => ({
  type: PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE,
  payload: payload,
});

export const addPurchaseOrderDeliveryScheduleSuccess = (
  payload: ProductDeliverySchedule,
): AddPurchaseOrderDeliveryScheduleSuccess => ({
  type: PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS,
  payload: payload,
});

export const addPurchaseOrderDeliveryScheduleFailure = (
  error: string,
): AddPurchaseOrderDeliveryScheduleFailure => ({
  type: PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE,
  error: error,
});

export const deletePurchaseOrderDeliverySchedule = (payload: {
  po_id: number;
  id: number;
}): DeletePurchaseOrderDeliverySchedule => ({
  type: PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE,
  payload: payload,
});

export const deletePurchaseOrderDeliveryScheduleSuccess = (payload: {
  purchase_request_product_id: number;
}): DeletePurchaseOrderDeliveryScheduleSuccess => ({
  type: PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS,
  payload: payload,
});

export const deletePurchaseOrderDeliveryScheduleFailure = (
  error: string,
): DeletePurchaseOrderDeliveryScheduleFailure => ({
  type: PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE,
  error: error,
});

export const updatePurchaseOrderDeliverySchedule = (payload: {
  po_id: number;
  id: number;
  track_link: string;
}): UpdatePurchaseOrderDeliverySchedule => ({
  type: PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE,
  payload: payload,
});

export const updatePurchaseOrderDeliveryScheduleSuccess = (payload: {
  schedule: ProductDeliverySchedule;
}): UpdatePurchaseOrderDeliveryScheduleSuccess => ({
  type: PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCCESS,
  payload: payload,
});

export const updatePurchaseOrderDeliveryScheduleFailure = (
  error: string,
): UpdatePurchaseOrderDeliveryScheduleFailure => ({
  type: PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE,
  error: error,
});
