import React from "react";
import { styles } from "./QuoteReportStyles";
import { Page, Text, View, Document, Link, Image } from "@react-pdf/renderer";
import { SkuFormData } from "../../models/Quote";
import { VendorSchema } from "../../models/Vendor";
import { UserState } from "../../redux/user/userReducer";

interface IQuoteReport {
  quoteId: string;
  skuFormData: SkuFormData[];
  vendor: VendorSchema;
  user: UserState;
  attachments: { name: string; url: string }[];
  currentDate: string;
}

const QuoteReport: React.FC<IQuoteReport> = ({
  quoteId,
  skuFormData,
  vendor,
  user,
  attachments,
  currentDate,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.borderOut}>
          <View style={styles.borderIn}></View>
        </View>
        <View style={styles.pageHeader}>
          <View style={styles.pageHeaderLeftField}>
            <Text>RFQ No.</Text>
            <Text>#{quoteId}</Text>
          </View>
          <Text style={styles.pageHeaderTitle}>Request For Quote</Text>
          <View style={styles.pageHeaderRightField}>
            <Text style={styles.pageHeaderKey}>Date</Text>
            <Text style={styles.pageHeaderValue}>{currentDate}</Text>
          </View>
        </View>

        <View style={styles.addressSection}>
          <View style={styles.addressLeft}>
            <Image src={vendor.logo} style={{ width: "40px" }} />
            <Text style={styles.vendorPOC}>{vendor.vendor_name}</Text>
            <View style={styles.addressDescription}>
              <Text>{vendor.addresses[0].address_line}</Text>
              <Text>
                {vendor.addresses[0].city}, {vendor.addresses[0].state}
              </Text>
              <Text>{vendor.addresses[0].pincode}</Text>
              <Text style={{ marginTop: "8px" }}>
                {vendor.company_poc.poc_phone}
              </Text>
            </View>
          </View>
          <View style={styles.addressRight}>
            <Image src={user.company_details?.logo} style={{ width: "40px" }} />
            <Text style={styles.vendorPOC}>{user.company_details?.name}</Text>
            <View style={styles.addressDescriptionRight}>
              <Text>{user.addresses[0].address_line}</Text>
              <Text>
                {user.addresses[0].city}, {user.addresses[0].state}
              </Text>
              <Text>{user.addresses[0].pincode}</Text>
              <Text style={{ marginTop: "8px" }}>{user.phone_number}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginTop: "20px",
          }}
        >
          <Text>
            Please find attached files and required artwork for the following
            products. We look forward to your prompt response.Let us know if you
            need any further information.
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              marginTop: "10px",
              flexWrap: "wrap",
            }}
          >
            {attachments.map((attachment, index) => {
              return (
                <Link
                  key={index}
                  href={attachment.url}
                  style={{ color: "#AA5200" }}
                >
                  {attachment.name}
                </Link>
              );
            })}
          </View>
        </View>

        <View style={styles.skuSection}>
          {/* Header row */}
          <View style={[styles.row]}>
            <Text style={[styles.cell, styles.sNo, styles.headerCell]}>
              Sl. No.
            </Text>
            <Text style={[styles.cell, styles.name, styles.headerCell]}>
              Name
            </Text>
            <Text style={[styles.cell, styles.quantity, styles.headerCell]}>
              Quantity
            </Text>
            <Text style={[styles.cell, styles.location, styles.headerCell]}>
              Location
            </Text>
            <Text style={[styles.cell, styles.description, styles.headerCell]}>
              Description
            </Text>
          </View>

          {/* Data rows */}
          {skuFormData.map((item, index) => (
            <View
              key={item.id}
              style={
                index % 2 === 0
                  ? styles.skuDataContainerOdd
                  : styles.skuDataContainerEven
              }
            >
              <View style={[styles.row]}>
                <Text
                  style={[
                    styles.cell,
                    styles.sNo,
                    {
                      color: index % 2 === 0 ? "#AA5200" : "21217A",
                      borderColor: index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                      backgroundColor: index % 2 === 0 ? "#FFF5EC" : "#F1EFFF",
                    },
                    styles.answerCell,
                  ]}
                >
                  {index + 1}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.name,
                    {
                      color: index % 2 === 0 ? "#AA5200" : "#21217A",
                      borderColor: index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                    },
                    styles.answerCell,
                  ]}
                >
                  {item.name}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.quantity,
                    { borderColor: index % 2 === 0 ? "#FEE8D0" : "#DDD5F6" },
                    styles.answerCell,
                  ]}
                >
                  {item.quantity}
                  <Text style={{ color: "#949494" }}> {item.unit} </Text>
                </Text>
                <View
                  style={[
                    styles.cell,
                    styles.location,
                    styles.answerCell,
                    { borderColor: index % 2 === 0 ? "#FEE8D0" : "#DDD5F6" },
                  ]}
                >
                  <Text style={{ fontStyle: "italic" }}>
                    {item.address.city.toUpperCase()}{" "}
                  </Text>
                  <Text style={{ color: "#949494" }}>
                    {item.address.address_line}, {item.address.pincode}
                  </Text>
                </View>
                <Text
                  style={[
                    styles.cell,
                    styles.description,
                    { borderColor: index % 2 === 0 ? "#FEE8D0" : "#DDD5F6" },
                  ]}
                >
                  {item.description}
                </Text>
              </View>
              {item.qualityCheckParameters?.map(
                (qualityCheck, qualityCheckIndex) => {
                  return (
                    <View
                      style={[styles.subRowContainer]}
                      key={"qualityCheckParam-" + qualityCheckIndex}
                    >
                      <Text
                        style={[
                          styles.cell,
                          styles.sNo,
                          styles.answerCell,
                          {
                            backgroundColor:
                              index % 2 === 0 ? "#FFF5EC" : "#F1EFFF",
                            borderColor:
                              index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                          },
                        ]}
                      ></Text>
                      <Text
                        style={[
                          styles.subRowLabel,
                          styles.answerCell,
                          {
                            borderColor:
                              index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                          },
                        ]}
                      >
                        {qualityCheck.key} {qualityCheck.unit}
                      </Text>
                      <Text
                        style={[
                          styles.subRowValue,
                          {
                            borderColor:
                              index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                          },
                        ]}
                      >
                        {qualityCheck.value}
                      </Text>
                    </View>
                  );
                },
              )}

              {item.qualityCheckTests?.map(
                (qualityCheckTest, qualityCheckTestIndex) => {
                  return (
                    <View
                      style={[styles.subRowContainer]}
                      key={"qualityCheckTest-" + qualityCheckTestIndex}
                    >
                      <Text
                        style={[
                          styles.cell,
                          styles.sNo,
                          styles.answerCell,
                          {
                            borderColor:
                              index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                            backgroundColor:
                              index % 2 === 0 ? "#FFF5EC" : "#F1EFFF",
                          },
                        ]}
                      ></Text>
                      <View style={[styles.subRowLabelQualityCheckTest]}>
                        <Text
                          style={{
                            borderColor:
                              index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            width: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 4,
                          }}
                        >
                          {qualityCheckTest.name}
                        </Text>
                        <Text
                          style={{
                            color: "#8E8E8E",
                            width: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 4,
                            borderRightWidth: "1px",
                            borderBottomWidth: "1px",
                            borderColor:
                              index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                          }}
                        >
                          {qualityCheckTest.description}
                        </Text>
                      </View>
                      <Text
                        style={[
                          styles.subRowValue,
                          {
                            borderColor:
                              index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                          },
                        ]}
                      >
                        {qualityCheckTest.test_method}
                      </Text>
                    </View>
                  );
                },
              )}

              {/* Images and Artworks */}

              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text
                  style={[
                    styles.cell,
                    styles.sNo,
                    styles.answerCell,
                    {
                      borderColor: index % 2 === 0 ? "#FEE8D0" : "#DDD5F6",
                      backgroundColor: index % 2 === 0 ? "#FFF5EC" : "#F1EFFF",
                    },
                  ]}
                ></Text>

                <View style={[styles.skuAttachmentsContainer, { padding: 6 }]}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text>Artwork : </Text>
                    {item.images
                      ?.filter((i) => i.category === "ARTWORK")
                      .map((image, artworkIndex) => {
                        return (
                          <Link
                            key={"artwork-" + artworkIndex}
                            href={image.url}
                            style={{ color: "#5151EC" }}
                          >
                            {image.name}
                          </Link>
                        );
                      })}
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text>Images : </Text>
                    {item.images
                      ?.filter((i) => i.category === "IMAGE")
                      .map((image, imageIndex) => {
                        return (
                          <Link
                            key={"image-" + imageIndex}
                            href={image.url}
                            style={{ color: "#5151EC" }}
                          >
                            {image.name}
                          </Link>
                        );
                      })}
                  </View>
                </View>
              </View>
            </View>
          ))}
        </View>

        <View style={styles.pageFooter}>
          <Text>Thank You,</Text>
          <View>
            <Text style={styles.pageFooterName}>{user.name}</Text>
            <Text style={styles.pageFooterName}>
              {user.company_details?.name}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default QuoteReport;
