import { put, call, takeLatest, all } from "redux-saga/effects";
import {
  signupUserSuccess,
  signupUserFailure,
  signInUserSuccess,
  newCompanyAddressSuccess,
  newCompanyAddressFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  logOutUser,
} from "./userAction";
import { User, UserDetailsUpdatedResponseSchema } from "../../models/User";
import {
  NewCompanyAddressStartAction,
  SignInUserAction,
  SignupUserAction,
  UpdateUserDetailsAction,
  UserActions,
} from "./userTypes";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

function* signupUserSaga(action: SignupUserAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URLS.USERS.SIGNUP,
      action.payload,
    );
    const user: User = data.user;
    yield put(signupUserSuccess(user));
  } catch (error) {
    yield put(signupUserFailure("Error fetching user data."));
  }
}

function* watchSignupUser() {
  yield takeLatest(UserActions.SIGNUP_USER, signupUserSaga);
}

function* signInUserSaga(action: SignInUserAction) {
  try {
    const { data } = yield call(ApiService().client.get, URLS.USERS.SIGNIN, {
      params: action.payload,
    });
    const user: User = data.user;
    yield put(signInUserSuccess(user));
  } catch (error) {
    yield put(logOutUser());
  }
}

function* watchSignInUser() {
  yield takeLatest(UserActions.SIGNIN_USER, signInUserSaga);
}

function* updateUserDetailsSaga(action: UpdateUserDetailsAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URLS.USERS.UPDATE_USER_COMPANY_DETAILS,
      action.payload,
    );
    const updatedDetailsResponse: UserDetailsUpdatedResponseSchema = data.data;
    yield put(updateUserDetailsSuccess(updatedDetailsResponse));
  } catch (error) {
    yield put(updateUserDetailsFailure("Error Updating User Details"));
  }
}

function* watchUpdateUserDetails() {
  yield takeLatest(UserActions.UPDATE_USER_DETAILS, updateUserDetailsSaga);
}

function* newCompanyAddressSaga(action: NewCompanyAddressStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URLS.USERS.NEW_ADDRESS(action.payload.company_id),
      action.payload,
    );
    yield put(newCompanyAddressSuccess(data));
  } catch (error) {
    yield put(newCompanyAddressFailure("Error adding addresses."));
  }
}

function* watchNewCompanyAddress() {
  yield takeLatest(
    UserActions.NEW_COMPANY_ADDRESS_START,
    newCompanyAddressSaga,
  );
}

export default function* userSaga() {
  yield all([
    watchSignupUser(),
    watchSignInUser(),
    watchUpdateUserDetails(),
    watchNewCompanyAddress(),
  ]);
}
