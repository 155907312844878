import {
  User,
  SignupUserData,
  UserDetailsUpdatedResponseSchema,
  UserAddressPoc,
} from "../../models/User";
export const UserActions = {
  LOGOUT_USER: "LOGOUT_USER",
  TOGGLE_NEW_NOTIFICATION: "TOGGLE_NEW_NOTIFICATION",
  SIGNUP_USER: "SIGNUP_USER",
  SIGNUP_USER_SUCCESS: "SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILURE: "SIGNUP_USER_FAILURE",
  SIGNIN_USER: "SIGNIN_USER",
  SIGNIN_USER_SUCCESS: "SIGNIN_USER_SUCCESS",
  SIGNIN_USER_FAILURE: "SIGNIN_USER_FAILURE",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILURE: "UPDATE_USER_DETAILS_FAILURE",
  NEW_COMPANY_ADDRESS_START: "NEW_COMPANY_ADDRESS_START",
  NEW_COMPANY_ADDRESS_SUCCESS: "NEW_COMPANY_ADDRESS_SUCCESS",
  NEW_COMPANY_ADDRESS_FAILURE: "NEW_COMPANY_ADDRESS_FAILURE",
};

export interface UserAddress {
  id: number;
  address_line: string;
  address_type: string;
  city: string;
  country: string;
  map_link: string;
  pincode: string;
  state: string;
  updated_at: string;
  company_id: string;
  gstin: string;
  address_poc: UserAddressPoc;
}

export interface NewCompanyAddressPayloadSchema {
  line: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  company_id: number;
  map_link?: string;
}

export type LogOutUserAction = {
  type: typeof UserActions.LOGOUT_USER;
  payload?: null;
  error?: string | null;
};

export type ToggleNewNotificationAction = {
  type: typeof UserActions.TOGGLE_NEW_NOTIFICATION;
  payload?: null;
  error?: string | null;
};

export interface SignupUserAction {
  type: typeof UserActions.SIGNUP_USER;
  payload: SignupUserData;
  error?: null;
}

export interface SignupUserSuccessAction {
  type: typeof UserActions.SIGNUP_USER_SUCCESS;
  payload: User;
  error?: null;
}

export interface SignupUserFailureAction {
  type: typeof UserActions.SIGNUP_USER_FAILURE;
  payload?: null;
  error: string;
}

export interface SignInUserAction {
  type: typeof UserActions.SIGNIN_USER;
  payload: { email?: string; password?: string; jwt_token?: string };
  error?: null;
}

export interface SignInUserSuccessAction {
  type: typeof UserActions.SIGNIN_USER_SUCCESS;
  payload: User;
  error?: null;
}

export interface SignInUserFailureAction {
  type: typeof UserActions.SIGNIN_USER_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateUserDetailsAction {
  type: typeof UserActions.UPDATE_USER_DETAILS;
  payload: FormData;
  error?: null;
}

export interface UpdateUserDetailsSuccessAction {
  type: typeof UserActions.UPDATE_USER_DETAILS_SUCCESS;
  payload: UserDetailsUpdatedResponseSchema;
  error?: null;
}

export interface UpdateUserDetailsFailureAction {
  type: typeof UserActions.UPDATE_USER_DETAILS_FAILURE;
  payload?: null;
  error: string;
}

export interface NewCompanyAddressStartAction {
  type: typeof UserActions.NEW_COMPANY_ADDRESS_START;
  payload: NewCompanyAddressPayloadSchema;
  error?: null;
}

export interface NewCompanyAddressSuccessAction {
  type: typeof UserActions.NEW_COMPANY_ADDRESS_SUCCESS;
  payload: UserAddress;
  error?: null;
}

export interface NewCompanyAddressFailureAction {
  type: typeof UserActions.NEW_COMPANY_ADDRESS_FAILURE;
  payload?: null;
  error?: string | null;
}

export type UserActionTypes =
  | LogOutUserAction
  | ToggleNewNotificationAction
  | SignupUserAction
  | SignupUserSuccessAction
  | SignupUserFailureAction
  | SignInUserAction
  | SignInUserSuccessAction
  | SignInUserFailureAction
  | UpdateUserDetailsAction
  | UpdateUserDetailsSuccessAction
  | UpdateUserDetailsFailureAction
  | NewCompanyAddressStartAction
  | NewCompanyAddressSuccessAction
  | NewCompanyAddressFailureAction;
