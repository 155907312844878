import React, { useEffect, useState } from "react";
import "./PurchaseOrderCard.scss";
import { PurchaseOrder } from "../../models/PurchaseOrder";
import { convertIntoWeekDays } from "../../utils/date-util";
import { breakPointObserver } from "../../utils/BreakpointObserver";

interface PurchaseOrdersCardProps {
  serialNumber: string;
  quantity: number;
  onAdd: (purchaseOrder: PurchaseOrder, index: number) => void;
  purchaseOrder: PurchaseOrder;
  poIndex: number;
  selectedPoIndexes: number[];
}

const PurchaseOrdersCard: React.FC<PurchaseOrdersCardProps> = ({
  serialNumber,
  quantity,
  onAdd,
  purchaseOrder,
  poIndex,
  selectedPoIndexes,
}) => {
  const [quantityInputValue, setQuantityInputValue] = useState<number>(
    quantity || 0,
  );
  const [breakpoint, setBreakpoint] = useState("");
  const leadTime = convertIntoWeekDays(purchaseOrder.lead_time);
  const [productFormData, setProductFormData] =
    useState<PurchaseOrder>(purchaseOrder);
  const [selectedPoIndexesList, setSelectedPoIndexesList] =
    useState<number[]>(selectedPoIndexes);

  useEffect(() => {
    setSelectedPoIndexesList(selectedPoIndexes);
  }, [selectedPoIndexes]);

  useEffect(() => {
    const updatedPo: PurchaseOrder = { ...purchaseOrder, quantity: 0 };
    setProductFormData(updatedPo);
    setQuantityInputValue(quantity || 0);
  }, [purchaseOrder]);

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  const handleQuantityChange = (quantity: number) => {
    if (isNaN(Number(quantity))) {
      return;
    }

    const updatedPoIndexesList = selectedPoIndexesList.filter(
      (p) => p !== poIndex,
    );
    setSelectedPoIndexesList(updatedPoIndexesList);
    setQuantityInputValue(quantity);
    setProductFormData((prevFormData) => ({
      ...prevFormData,
      quantity: quantity,
    }));
  };

  const handleAddProduct = (
    productFormData: PurchaseOrder,
    poIndex: number,
  ) => {
    onAdd(productFormData, poIndex);
  };

  return (
    <>
      <div className="flex-row purchase-order-box xetgo-font-tg xetgo-font-tag">
        <div className="xetgo-font-tag bold px-12 py-6">
          <p className="sku-uuid py-6 px-12">{serialNumber}</p>
        </div>
        <p className="xetgo-font-tag p-12">{purchaseOrder.product_name}</p>
        <p className="po-moq bold p-12">
          {purchaseOrder.moq} {purchaseOrder.unit}
        </p>
        <p className="p-12 vendor-lead-time">{purchaseOrder.lead_time} days</p>
        <p className="vendor-price p-12">₹ {purchaseOrder.price_per_unit}</p>
        <div className="flex-row position-relative po-quantity-container po-quantity-container-hide">
          <input
            value={quantityInputValue}
            onChange={(e) => handleQuantityChange(Number(e.target.value))}
            className="po-quantity p-12 xetgo-font-tag bold flex-1"
          />
          <span className="po-quantity-pipe xetgo-font-tag bold">|</span>
          <p className="po-measurement-box xetgo-font-tag bold ">
            {purchaseOrder.unit}
          </p>
          <p
            className={`po-product-add-btn flex-row align-items-center justify-content-center p-8 cursor-pointer ${
              selectedPoIndexesList.includes(poIndex) && "po-edit-icon"
            } `}
            onClick={() => handleAddProduct(productFormData, poIndex)}
          >
            {selectedPoIndexesList.includes(poIndex) ? (
              <img
                width={16}
                height={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240307122951-pencil-line.svg"
                alt="edit"
              />
            ) : (
              <img
                width={16}
                height={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231007090447-white_add.svg"
                alt="plus"
              />
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrdersCard;
