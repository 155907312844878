import {
  User,
  UserCompanyDetails,
  UserDetailsUpdatedResponseSchema,
} from "../../models/User";
import { UserActions, UserActionTypes, UserAddress } from "./userTypes";
import { JWT_TOKEN, removeCookie } from "../../services/cookieService";

export interface UserState {
  loading: boolean;
  error: string | null | undefined;
  newNotification: boolean;
  name: string | null;
  email: string | null;
  avatar: string | null;
  id: number | null;
  role: string[];
  addresses: UserAddress[];
  jwtToken: string | null;
  company_id: number | null;
  company_details: UserCompanyDetails | null;
  phone_number: string | null;
}

const initialState: UserState = {
  name: null,
  email: null,
  avatar: null,
  id: null,
  role: [],
  addresses: [],
  jwtToken: null,
  newNotification: false,
  loading: false,
  error: null,
  company_id: null,
  company_details: null,
  phone_number: null,
};

const userReducer = (
  state = initialState,
  action: UserActionTypes,
): UserState => {
  switch (action.type) {
    case UserActions.SIGNUP_USER:
      return {
        ...state,
        loading: true,
      };
    case UserActions.SIGNUP_USER_SUCCESS: {
      const payload = action.payload as User;
      if (payload) {
        return {
          ...state,
          name: payload.name,
          email: payload.email,
          id: payload.id,
          role: payload.role,
          addresses: [],
          jwtToken: payload.jwtToken,
          loading: false,
          error: null,
          company_id: payload.company_id,
          company_details: payload.company_details,
        };
      }
      return { ...state, loading: true };
    }
    case UserActions.SIGNUP_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UserActions.SIGNIN_USER:
      return {
        ...state,
        loading: true,
      };

    case UserActions.SIGNIN_USER_SUCCESS: {
      const payload = action.payload as User;
      if (payload) {
        return {
          ...state,
          name: payload.name,
          email: payload.email,
          id: payload.id,
          role: payload.role,
          addresses: payload.addresses,
          jwtToken: payload.jwtToken,
          avatar: payload.avatar || "",
          loading: false,
          error: null,
          company_id: payload.company_id,
          company_details: payload.company_details,
        };
      }
      return { ...state, loading: true };
    }

    case UserActions.SIGNIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UserActions.UPDATE_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case UserActions.UPDATE_USER_DETAILS_SUCCESS: {
      const payload = action.payload as UserDetailsUpdatedResponseSchema;
      if (payload) {
        return {
          ...state,
          avatar: payload.avatar_url,
          addresses: payload.new_address
            ? [...state.addresses, payload.new_address]
            : state.addresses,
          company_details: {
            ...state.company_details,
            ...payload.company_details,
          },
          loading: false,
        };
      }
      return { ...state, loading: false };
    }

    case UserActions.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case UserActions.LOGOUT_USER:
      removeCookie(JWT_TOKEN);
      return {
        ...state,
        name: null,
        email: null,
        avatar: null,
        id: null,
        role: [],
        addresses: [],
        jwtToken: null,
      };
    case UserActions.NEW_COMPANY_ADDRESS_START:
      return {
        ...state,
        loading: true,
      };
    case UserActions.NEW_COMPANY_ADDRESS_SUCCESS: {
      const payload = action.payload as any;
      return {
        ...state,
        addresses: [...state.addresses, payload.address],
        loading: false,
      };
    }
    case UserActions.NEW_COMPANY_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActions.TOGGLE_NEW_NOTIFICATION:
      return {
        ...state,
        newNotification: !state.newNotification,
      };
    default:
      return state;
  }
};

export default userReducer;
