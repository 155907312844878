import { FilterOptionSchema } from "../components/Filter/filter.data";

export interface IQuoteProduct {
  id: number;
  qpm_id: number;
  sku: FilterOptionSchema | null;
  quantity: string;
  unit: string;
  address_id: number | null;
  target_price: number | null;
}

export interface IQuoteProductList {
  address_id: number;
  products: IQuoteProduct[];
}

export const NEW_QUOTE_PRODUCT = {
  id: -1,
  qpm_id: -1,
  sku: null,
  quantity: "",
  unit: "",
  address_id: null,
  target_price: null,
};
