import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import "./VendorFormResult.scss";
import React, { useEffect, useState } from "react";
import { ClientFormDataSchema } from "../../models/VendorForm";

interface VendorFormResultProps {
  form_id: number;
}

const VendorFormResult: React.FC<VendorFormResultProps> = ({ form_id }) => {
  const [formDetails, setFormDetails] = useState<ClientFormDataSchema | null>();
  const { id } = useParams();

  useEffect(() => {
    getVendorDetails();
  }, []);

  const getVendorDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDOR_FORM_DETAIL(form_id),
        {
          params: {
            vendor_id: Number(id),
          },
        },
      );
      setFormDetails(data.data);
    } catch (error) {
      console.error("error fetching Sku Detail", error);
    }
  };
  return (
    <div className="vendor-detail-card-main-container">
      <div
        style={{
          borderBottom: "1px solid #E8E8E8",
          textTransform: "uppercase",
        }}
        className="flex-row align-items-center justify-content-space-between p-12 xetgo-font-tag"
      >
        <p className="bold xetgo-font-caption"> {formDetails?.title}</p>
        <p className="uuid px-8 py-4">{formDetails?.uuid}</p>
      </div>
      {formDetails && formDetails.questions.length > 0 && (
        <div className="all-question-main-container p-12 flex-column gap-6">
          {formDetails.questions.map((question, index) => (
            <div key={index} className="question-main-container xetgo-font-tag">
              <p className="container-upper-bg"></p>
              <div className="question-detail flex-column gap-8 px-10 py-8">
                <p className="question">
                  {index + 1}. {question.question}
                </p>
                {question.input_type !== "checkbox" &&
                  question.input_type !== "file_upload" &&
                  question.input_type !== "multi_file_upload" && (
                    <p className="answer">{question.response[0]}</p>
                  )}

                {(question.input_type === "file_upload" ||
                  question.input_type === "multi_file_upload") &&
                  question.response.length > 0 && (
                    <div className="flex-row gap-6 align-items-center">
                      <img
                        height={12}
                        width={12}
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209064755-paperclip.svg"
                        alt="file-icon"
                      />
                      {question.response.map((res, resIndex) => (
                        <a
                          href={res}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="file-value cursor-pointer px-8 py-4"
                          key={resIndex}
                        >
                          {res.split("-").at(-1)}
                        </a>
                      ))}
                    </div>
                  )}

                {question.input_type === "checkbox" &&
                  question.response.length > 0 && (
                    <div className="flex-row gap-6 ">
                      {question.response.map((res, resIndex) => (
                        <p className="checkbox-value px-8 py-4" key={resIndex}>
                          {res}
                        </p>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VendorFormResult;
