export const VENDOR_FORM_STATUS_MAP: {
  [key: string]: {
    backgroundColor: string;
    color: string;
    name?: string;
    url?: string;
    dropdown_icon?: string;
  };
} = {
  REQUEST_RECEIVED: {
    backgroundColor: "#F3E2FF",
    color: "#6610A6",
    name: "Request Sent",
  },
  PENDING_ONBOARDING: {
    color: "#EC5151",
    backgroundColor: "#FCF3F3",
    name: "Pending Onboarding",
  },
  INFORMATION_PENDING: {
    color: "#004D54",
    backgroundColor: "#DEFCFF",
    name: "Information Pending",
  },
  VERIFICATION_PENDING: {
    color: "#8C4D00",
    backgroundColor: "#FFEDD7",
    name: "Verification Pending",
  },
  UPDATE_REQUESTED: {
    color: "#255900",
    backgroundColor: "#E6FDD7",
    name: "Update Requested",
  },
  REJECTED: {
    color: "#8F0000",
    backgroundColor: "#FBE4E4",
    name: "Rejected",
  },
  DECLINED: {
    color: "#8F0000",
    backgroundColor: "#FBE4E4",
    name: "Declined",
  },
  APPROVED: {
    color: "#005703",
    backgroundColor: "#DCFFDD",
    name: "Approved",
  },
};
