import React, { useEffect, useRef, useState } from "react";
import "./QuoteDetails.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectQuoteDetails } from "../../redux/quote/quoteSelector";
import { useDispatch } from "react-redux";
import {
  deleteQuoteProduct,
  getQuoteDetails,
  updateQuoteDetails,
  updateQuoteProduct,
} from "../../redux/quote/quoteAction";
import { commaFormat, formatDate } from "../../utils/Helper";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import RaiseQuoteForm from "../../components/RaiseQuoteForm/RaiseQuoteForm";
import {
  breakPointObserver,
  ScreenSizes,
} from "../../utils/BreakpointObserver";
import { QuoteContext } from "../../context/quote-context";
import { ProductDetailsSchema, QuoteDetail } from "../../models/Quote";
import { formatTime } from "../../utils/date-util";
import { IQuoteProduct, NEW_QUOTE_PRODUCT } from "../../models/QuoteProduct";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/QuotesData";
import VendorTimelineComment from "../../components/VendorTimelineComment/VendorTimelineComment";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import environment from "../../environments/environment";

const QuoteDetails: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const details = useSelector(selectQuoteDetails);
  const [hoveredIndex, setIsHoveredIndex] = useState({
    product_index: -1,
    address_id: -1,
  });
  let cumulativeIndex = 0;
  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
  const [openVendorTimelineCommentIndex, setOpenVendorTimelineCommentIndex] =
    useState(-1);

  const [openUpdateForm, setOpenUpdateForm] = useState(false);

  const descriptionRef = useRef<null | HTMLDivElement>(null);
  const [productToUpdate, setProductToUpdate] = useState<IQuoteProduct[]>([
    { ...NEW_QUOTE_PRODUCT },
  ]);
  const [showDetailsIndex, setShowDetailsIndex] = useState({
    product_index: -1,
    address_id: -1,
  });
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [totalValue, setTotalValue] = useState(0);
  const [breakpoint, setBreakpoint] = useState("");
  const [editingVendor, setEditingVendor] = useState(-1);
  const [editingField, setEditingField] = useState("");
  const [values, setValues] = useState<{
    [key: number]: { [field: string]: string };
  }>({});
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  useEffect(() => {
    dispatch(getQuoteDetails(Number(id)));
  }, []);

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.innerHTML = details?.description || "";
    }
  }, [details]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingVendor, editingField]);

  useEffect(() => {
    if (details) {
      const totalValue = details.product_details.reduce(
        (accumulator, productDetail) => {
          return (
            accumulator +
            productDetail.product_details.reduce(
              (innerAccumulator, product) => {
                const currentValue =
                  product.price * Number(product.product_quantity);
                return innerAccumulator + currentValue;
              },
              0,
            )
          );
        },
        0,
      );

      setTotalValue(totalValue * 1.18);
    }
  }, [details]);

  const handleShowDetails = (index: number, address_id: number) => {
    if (
      showDetailsIndex.product_index === index &&
      showDetailsIndex.address_id === address_id
    ) {
      setShowDetailsIndex({
        product_index: -1,
        address_id: -1,
      });
      setExpandedIndex(-1);
    } else {
      setShowDetailsIndex({
        product_index: index,
        address_id: address_id,
      });
      setExpandedIndex(index);
    }
    setIsHoveredIndex({
      product_index: -1,
      address_id: -1,
    });
    setExpandedIndex(-1);
  };

  const handleEditProduct = async (
    product: ProductDetailsSchema,
    address_id: number,
  ) => {
    const iQuoteProduct = {
      id: Number(id),
      qpm_id: product.qpm_id,
      sku: {
        name: product.product_name,
        id: product.product_id,
      },
      quantity: product.product_quantity,
      unit: product.unit,
      address_id: address_id,
      target_price: product.target_price,
    };
    setProductToUpdate([iQuoteProduct]);
    setOpenUpdateForm(true);
  };

  const handleDeleteProduct = (qpm_id: number) => {
    dispatch(
      deleteQuoteProduct({
        quote_product_id: qpm_id,
      }),
    );
    setIsHoveredIndex({
      product_index: -1,
      address_id: -1,
    });
    setShowDetailsIndex({
      product_index: -1,
      address_id: -1,
    });
  };

  const navigateToQuoteList = () => {
    navigate("/quotes");
  };

  const removeFile = async (asset_id: number) => {
    try {
      const { data } = await ApiService().client.delete(
        URLS.VENDORS.REMOVE_ASSET_RECORDS(asset_id),
        {
          params: { id: asset_id, client_quote_id: Number(id) },
        },
      );

      if (details) {
        const indexOfAssetRecord = details.asset_records.findIndex(
          (asset) => asset.id == asset_id,
        );
        details.asset_records.splice(indexOfAssetRecord, 1);
      }
      toast.success("Deleted File successfully!", toastOptions);
    } catch (error) {
      toast.error("Error Deleting File!", toastOptions);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const formData = new FormData();
      formData.append("client_quote_id", Number(id).toString());
      files.forEach((file, index) => {
        const displayName = displayNames[index]?.trim();
        formData.append("files[]", file);
        formData.append("displayNames[]", displayName);
      });
      try {
        if (details) {
          const { data } = await ApiService().client.put(
            URLS.VENDORS.UPDATE_ASSET_RECORDS(Number(id)),
            formData,
          );
          details.asset_records = data.data.asset_records;
          toast.success("File updated successfully!", toastOptions);
        }
      } catch (error) {
        toast.error("Upload Failed!", toastOptions);
      }
    }
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (details && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      dispatch(
        updateQuoteDetails({
          description: descriptionRef.current?.innerHTML || "",
          id: details.id,
        }),
      );
      descriptionRef.current?.blur();
      toast.success("Title updated successfully!", toastOptions);
    }
  };

  const handleVendorApproved = async (vendorId: number, qpmID: number) => {
    try {
      await ApiService().client.put(
        URLS.VENDORS.UPDATE_VENDOR_STATUS(Number(id)),
        {
          approved: true,
          quote_vendor_id: vendorId,
          qpm_id: qpmID,
          id: Number(id),
        },
      );
      details?.product_details.map((detail, index) => {
        detail.product_details.map((row, pIndex) => {
          row.vendors.map((vendor, innerIndex) => {
            if (vendorId === vendor.id) {
              vendor.approved = true;
            }
          });
        });
      });
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };

  const handleVendorRejection = async (vendorId: number, qpmID: number) => {
    try {
      await ApiService().client.put(
        URLS.VENDORS.UPDATE_VENDOR_STATUS(Number(id)),
        {
          approved: false,
          quote_vendor_id: vendorId,
          qpm_id: qpmID,
          id: Number(id),
        },
      );
      details?.product_details.map((detail, index) => {
        detail.product_details.map((row, pIndex) => {
          row.vendors.map((vendor, innerIndex) => {
            if (vendorId === vendor.id) {
              vendor.approved = false;
            }
          });
        });
      });
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };

  const handleStatusChange = async (status: string) => {
    if (details) {
      details.quote_status = status;
      try {
        await ApiService().client.put(
          URLS.VENDORS.UPDATE_VENDOR_STATUS(Number(id)),
          {
            status: status,
          },
        );

        if (details.quote_status === "Completed") {
          const data: QuoteDetail | any = details.product_details.map(
            (product, index) => {
              product.product_details.map((productDetail, productIndex) => {
                productDetail.vendors.map((vendor, vendorIndex) => {
                  if (vendor.approved === false) {
                    vendor.status = "RFQ Lost";
                  } else {
                    vendor.status = "RFQ Won";
                  }
                });
              });
            },
          );
        }
        setOpenStatusDropdown(false);
      } catch (error) {
        console.error("error fetching statuses", error);
      }
    }
  };

  const handleVendorDetailEditState = (vendorId: number, field: string) => {
    setEditingVendor(vendorId);
    setEditingField(field);
  };

  const handleVendorDetailsChange = (
    vendorId: number,
    field: string,
    value: string,
  ) => {
    setValues((prevValues) => ({
      ...prevValues,
      [vendorId]: {
        ...prevValues[vendorId],
        [field]: value,
      },
    }));
  };

  const handleKeyDownOnVendorDetails = (
    qpm_id: number,
    vendorId: number,
    field: string,
    event: any,
  ) => {
    if (event.key === "Enter") {
      setEditingVendor(-1);
      setEditingField("");
      let lead_time = undefined;
      let price = undefined;

      if (field === "lead_time") {
        lead_time = values[vendorId] ? values[vendorId][field] : 0;
      } else if (field === "price") {
        price = values[vendorId] ? values[vendorId][field] : 0;
      }

      dispatch(
        updateQuoteProduct({
          id: Number(id),
          qpm_id: qpm_id,
          quote_vendor_id: vendorId,
          lead_time: Number(lead_time),
          price: Number(price),
        }),
      );
    }
  };

  const openSkuDetailPage = (id: number) => {
    const url = `${environment.clientUrl}/skus/${id}`;
    window.open(url, "_blank");
  };

  const handleVendorDetailPageOpen = (id: number) => {
    const url = `${environment.clientUrl}/vendor/details/${id}`;
    window.open(url, "_blank");
  };
  return (
    <div className="details-content-page-main-wrapper flex-column full-width">
      <div className="details-content-upper-bar xetgo-font-tag px-20 py-12 flex-row gap-4">
        <p onClick={navigateToQuoteList} className="bold cursor-pointer">
          Pacpro / Quotes
        </p>
        <p style={{ color: "#757575" }}> / {details?.uuid}</p>
      </div>
      <div className="details-content-page flex-row align-items-flex-start p-16">
        <div className="details-content flex-1 flex-column">
          <div className="detail-left-upper-container">
            <div className="quote-detail-enquiry-created flex-row align-items-center justify-content-space-between">
              <div className="detail-upper-left-in-container flex-row gap-8 align-items-center">
                <div className="enquiry-tag py-4 px-12">
                  <p>{details?.uuid}</p>
                </div>

                <div className="quote-details-date-container xetgo-font-tag">
                  <p style={{ color: "#4A4A4A" }}>
                    {details && formatDate(details.quote_created_at)},{" "}
                    <span style={{ color: "#949494" }}>
                      {details && formatTime(details.quote_created_at)}
                    </span>
                  </p>
                </div>
              </div>
              {details && (
                <OutsideAlerter action={() => setOpenStatusDropdown(false)}>
                  <div
                    className="status-holder-main-container cursor-pointer"
                    onClick={() => setOpenStatusDropdown((val) => !val)}
                  >
                    <p
                      style={{
                        color:
                          QUOTE_STATUS_PROPERTY_MAP[details.quote_status].color,
                        backgroundColor:
                          QUOTE_STATUS_PROPERTY_MAP[details.quote_status]
                            .backgroundColor,
                      }}
                      className="xetgo-font-tag status-container px-10 py-6 status-tag flex-row align-items-center gap-4"
                    >
                      <img
                        src={
                          QUOTE_STATUS_PROPERTY_MAP[details.quote_status]?.url
                        }
                        alt={details.quote_status}
                        width={16}
                        height={16}
                      />
                      {details.quote_status}
                      <img
                        className="cursor-pointer"
                        src={
                          QUOTE_STATUS_PROPERTY_MAP[details.quote_status]
                            ?.dropdown_icon
                        }
                        alt="dropdown-icon"
                        width={16}
                        height={16}
                      />
                    </p>
                    {openStatusDropdown && (
                      <div className="status-dropdown-container p-12 flex-column -align-items-center justify-content-center  gap-8">
                        {details.statuses_allowed.map((status, index) => {
                          return (
                            <>
                              {QUOTE_STATUS_PROPERTY_MAP[details.quote_status]
                                .url !==
                                QUOTE_STATUS_PROPERTY_MAP[status].url && (
                                <p
                                  onClick={() => handleStatusChange(status)}
                                  key={index}
                                  style={{
                                    color:
                                      QUOTE_STATUS_PROPERTY_MAP[status].color,
                                    backgroundColor:
                                      QUOTE_STATUS_PROPERTY_MAP[status]
                                        .backgroundColor,
                                  }}
                                  className="xetgo-font-tag status-container px-10 py-6 status-tag flex-row align-items-center gap-4 cursor-pointer"
                                >
                                  <img
                                    src={QUOTE_STATUS_PROPERTY_MAP[status]?.url}
                                    alt={details.quote_status}
                                    width={16}
                                    height={16}
                                  />
                                  {status}
                                </p>
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </OutsideAlerter>
              )}
            </div>
          </div>

          <div className="quote-product-list-grid">
            <p className="header-row-item flex-row align-items-center gap-4 xetgo-font-tag bold py-12 px-8">
              {" "}
            </p>

            <div className="flex-row">
              <p className="header-row-item flex-row align-items-center gap-4 xetgo-font-tag bold py-12 px-8 flex-1">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-table-properties.svg"
                  alt="name"
                />
                Name
              </p>
              <p className="header-row-item flex-row align-items-center gap-4 xetgo-font-tag bold py-12 px-8 flex-1">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-building-2.svg"
                  alt="address-icon"
                />
                Location
              </p>
            </div>
            <div className="flex-row">
              <p className="header-row-item flex-row align-items-center gap-4 xetgo-font-tag bold py-12 px-8 flex-1">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-calendar.svg"
                  alt="quantity-icon"
                />
                Quantity
              </p>
              <p className="header-row-item flex-row align-items-center gap-4 xetgo-font-tag bold py-12 px-8 flex-1">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240709111945-%E2%82%B9.svg"
                  alt="rupee-icon"
                />
                Target Price
              </p>
            </div>
            {ScreenSizes.MINI_TABLET !== breakpoint &&
              ScreenSizes.MOBILE !== breakpoint && (
                <p className="header-row-item flex-row align-items-center gap-4 xetgo-font-tag bold py-12 px-8">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710053743-building-2.svg"
                    alt="qty"
                  />
                  Description
                </p>
              )}
          </div>
          <div className="quote-address-detail-main-container">
            {details &&
              details.product_details.map((list, index) => {
                return (
                  <div key={index} className="product-row-container">
                    {list.product_details.map((row, pindex) => {
                      return (
                        <div
                          className="quote-hover-product quote-detail-product-container flex-row cursor-pointer"
                          key={`${index}-${pindex}`}
                          onClick={() => {
                            handleShowDetails(pindex, list.address_id);
                          }}
                        >
                          <p
                            style={{
                              color: "#4A4A4A",
                              borderRight: "1px solid #E8E8E8",
                              borderBottom: "1px solid #E8E8E8",
                            }}
                            className="p-12 px-8 xetgo-font-tag index"
                          >
                            {(cumulativeIndex.toString().length === 2
                              ? "0"
                              : cumulativeIndex.toString().length > 2
                                ? ""
                                : "00") + ++cumulativeIndex}
                          </p>
                          <div className="flex-column">
                            <div className="flex-1 quote-detail-product-list">
                              <div className="flex-row">
                                <p className="xetgo-font-tag product-detail-cell flex-column gap-4 py-12 px-8 flex-1">
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openSkuDetailPage(row.product_id);
                                    }}
                                    className="sku-name"
                                  >
                                    {" "}
                                    {row.product_name}
                                  </span>
                                  <span
                                    style={{
                                      color: "#D58131",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {row.uuid}
                                  </span>
                                </p>
                                <p className="xetgo-font-tag product-detail-cell flex-column gap-4 py-12 px-8 flex-1">
                                  <span className="location-city">
                                    {list.address_city.toUpperCase()}
                                  </span>
                                  <span className="location-line">
                                    {list.address_line +
                                      ", " +
                                      list.address_pincode}
                                  </span>
                                </p>
                              </div>

                              <div className="flex-row">
                                <p className="xetgo-font-tag product-detail-cell py-12 px-8 flex-1">
                                  <span>
                                    {commaFormat(row.product_quantity)}
                                  </span>
                                  <span style={{ color: "#949494" }}>
                                    {" "}
                                    {row.unit}
                                  </span>
                                </p>
                                <p className="xetgo-font-tag product-detail-cell py-12 px-8 flex-1">
                                  {row.target_price > 0
                                    ? `₹ ${row.target_price}`
                                    : "--"}
                                </p>
                              </div>

                              {ScreenSizes.MINI_TABLET !== breakpoint &&
                                ScreenSizes.MOBILE !== breakpoint && (
                                  <div className="product-detail-cell flex-row justify-content-space-between align-items-flex-end xetgo-font-tag py-12 px-8">
                                    <p className="product-description">
                                      {row.product_description}
                                    </p>

                                    {showDetailsIndex.product_index ===
                                      pindex &&
                                      showDetailsIndex.address_id ===
                                        list.address_id && (
                                        <div className="flex-row align-items-end gap-12 px-8">
                                          <div
                                            onClick={() =>
                                              handleDeleteProduct(row.qpm_id)
                                            }
                                            className="delete-option  cursor-pointer"
                                          >
                                            <img
                                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-trash-2.svg"
                                              alt="delete"
                                              width={16}
                                              height={16}
                                            />
                                          </div>
                                          <div
                                            className="edit-option cursor-pointer"
                                            onClick={() =>
                                              handleEditProduct(
                                                row,
                                                list.address_id,
                                              )
                                            }
                                          >
                                            <img
                                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
                                              alt="edit"
                                              width={16}
                                              height={16}
                                            />
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                            </div>

                            {showDetailsIndex.product_index === pindex &&
                              showDetailsIndex.address_id ===
                                list.address_id && (
                                <div key={index} className=" full-width">
                                  {row.vendors.map((vendor, index) => {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="xetgo-font-tag flex-row expanded-vendor-list align-items-center"
                                        >
                                          <div
                                            className="py-12 px-16"
                                            style={{
                                              borderRight: "1px solid #E8E8E8",
                                              height: "100%",
                                              boxSizing: "border-box",
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "#111111",
                                                fontWeight: 500,
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleVendorDetailPageOpen(
                                                  vendor.vendor_id,
                                                );
                                              }}
                                              className=" vendor-title-name"
                                            >
                                              {vendor.name}
                                            </p>
                                          </div>

                                          <div
                                            onClick={(e) => e.stopPropagation()}
                                            className="vendor-time-and-price-pill flex-row "
                                            key={vendor.id}
                                          >
                                            <div
                                              style={{
                                                position: "relative",
                                                flex: 1,
                                              }}
                                            >
                                              {editingVendor === vendor.id &&
                                              editingField === "lead_time" ? (
                                                <input
                                                  ref={inputRef}
                                                  type="text"
                                                  defaultValue={Number(
                                                    vendor.lead_time,
                                                  )}
                                                  onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleVendorDetailsChange(
                                                      vendor.id,
                                                      "lead_time",
                                                      e.target.value,
                                                    );
                                                  }}
                                                  onKeyDown={(e) =>
                                                    handleKeyDownOnVendorDetails(
                                                      row.qpm_id,
                                                      vendor.id,
                                                      "lead_time",
                                                      e,
                                                    )
                                                  }
                                                  className="editable-vendor-inputs"
                                                  style={{
                                                    border: "4px solid #FFEFFE",
                                                  }}
                                                />
                                              ) : (
                                                <p
                                                  className="py-6 px-16 flex-row align-items-center justify-content-space-between"
                                                  style={{
                                                    background: "#FFEFFE",
                                                    flex: 1,
                                                    height: "100%",
                                                    boxSizing: "border-box",
                                                  }}
                                                  onClick={() =>
                                                    handleVendorDetailEditState(
                                                      vendor.id,
                                                      "lead_time",
                                                    )
                                                  }
                                                >
                                                  <span
                                                    style={{ color: "#54004F" }}
                                                  >
                                                    {vendor.lead_time || "--"}
                                                  </span>
                                                  <span
                                                    style={{ color: "#9D4D98" }}
                                                  >
                                                    days
                                                  </span>
                                                </p>
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                position: "relative",
                                                flex: 1,
                                              }}
                                            >
                                              {editingVendor === vendor.id &&
                                              editingField === "price" ? (
                                                <input
                                                  ref={inputRef}
                                                  type="text"
                                                  defaultValue={Number(
                                                    vendor.price,
                                                  )}
                                                  onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleVendorDetailsChange(
                                                      vendor.id,
                                                      "price",
                                                      e.target.value,
                                                    );
                                                  }}
                                                  onKeyDown={(e) =>
                                                    handleKeyDownOnVendorDetails(
                                                      row.qpm_id,
                                                      vendor.id,
                                                      "price",
                                                      e,
                                                    )
                                                  }
                                                  className="editable-vendor-inputs"
                                                  style={{
                                                    border: "4px solid #FDFFE3",
                                                  }}
                                                />
                                              ) : (
                                                <p
                                                  className="py-6 px-16 flex-row align-items-center"
                                                  style={{
                                                    background: "#FDFFE3",
                                                    flex: 1,
                                                    color: "#5B6200",
                                                    height: "100%",
                                                    boxSizing: "border-box",
                                                  }}
                                                  onClick={() =>
                                                    handleVendorDetailEditState(
                                                      vendor.id,
                                                      "price",
                                                    )
                                                  }
                                                >
                                                  ₹{" "}
                                                  {vendor.price
                                                    ? vendor.price.toString()
                                                    : "--"}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex-row justify-content-space-between">
                                            <div
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenVendorTimelineCommentIndex(
                                                  index,
                                                );
                                              }}
                                              className="vendor-status-pill-container py-10 px-16"
                                            >
                                              <p
                                                style={{
                                                  background:
                                                    QUOTE_STATUS_PROPERTY_MAP[
                                                      vendor.status
                                                    ].backgroundColor,
                                                  color:
                                                    QUOTE_STATUS_PROPERTY_MAP[
                                                      vendor.status
                                                    ].color,
                                                }}
                                                className="vendor-status-pill xetgo-font-tag bolder px-6 py-4"
                                              >
                                                {vendor.status.toUpperCase()}
                                              </p>
                                            </div>
                                            <div className="vendor-toggle-container flex-row align-items-center justify-content-flex-end py-10 px-16">
                                              <div
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                                className="toggle-box p-2 border-box"
                                              >
                                                <img
                                                  onClick={() =>
                                                    handleVendorRejection(
                                                      vendor.id,
                                                      row.qpm_id,
                                                    )
                                                  }
                                                  height={24}
                                                  width={24}
                                                  src={
                                                    vendor.approved
                                                      ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240429094053-Icon+%281%29.svg"
                                                      : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240429094700-Icon+%282%29.svg"
                                                  }
                                                  alt="cross-icon"
                                                />
                                                <img
                                                  onClick={() =>
                                                    handleVendorApproved(
                                                      vendor.id,
                                                      row.qpm_id,
                                                    )
                                                  }
                                                  height={24}
                                                  width={24}
                                                  src={
                                                    vendor.approved
                                                      ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240429094053-Icon.svg"
                                                      : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240429094700-Icon+%283%29.svg"
                                                  }
                                                  alt="check-icon"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {openVendorTimelineCommentIndex ===
                                          index &&
                                          details && (
                                            <div
                                              onClick={(e) => {
                                                e.stopPropagation(),
                                                  setOpenVendorTimelineCommentIndex(
                                                    -1,
                                                  );
                                              }}
                                              className="comment-floating-section"
                                            >
                                              <VendorTimelineComment
                                                vendorDetail={
                                                  row.vendors[
                                                    openVendorTimelineCommentIndex
                                                  ]
                                                }
                                                handleClose={() =>
                                                  setOpenVendorTimelineCommentIndex(
                                                    -1,
                                                  )
                                                }
                                                qpmId={row.qpm_id}
                                                quoteId={Number(id)}
                                              />
                                            </div>
                                          )}
                                      </>
                                    );
                                  })}
                                </div>
                              )}
                            {[
                              ScreenSizes.MINI_TABLET,
                              ScreenSizes.MOBILE,
                              ScreenSizes.TABLET,
                            ].includes(breakpoint as ScreenSizes) &&
                              showDetailsIndex.product_index === pindex &&
                              showDetailsIndex.address_id ===
                                list.address_id && (
                                <div className=" specification-below flex-row justify-content-space-between align-items-flex-end xetgo-font-tag py-12 px-8">
                                  <p>{row.product_description}</p>
                                  {showDetailsIndex.product_index === pindex &&
                                    showDetailsIndex.address_id ===
                                      list.address_id && (
                                      <div className="flex-row align-items-end gap-12 px-8">
                                        <div
                                          onClick={() =>
                                            handleDeleteProduct(row.tpm_id)
                                          }
                                          className="delete-option  cursor-pointer"
                                        >
                                          <img
                                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-trash-2.svg"
                                            alt="delete"
                                            width={16}
                                            height={16}
                                          />
                                        </div>
                                        <div
                                          className="edit-option cursor-pointer"
                                          onClick={() =>
                                            handleEditProduct(
                                              row,
                                              list.address_id,
                                            )
                                          }
                                        >
                                          <img
                                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
                                            alt="edit"
                                            width={16}
                                            height={16}
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
          {details && (
            <div
              style={{ borderTop: "1px solid #E8E8E8" }}
              className="flex-column px-16 py-12 gap-28"
            >
              <div className="flex-column gap-12">
                <div className="flex-row gap-32 full-width justify-content-space-between ">
                  <div className="flex-row gap-12 align-items-center ">
                    <p className="xetgo-font-tag bold">
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516131144-folder+1.svg"
                        alt="files"
                      />
                    </p>
                    <div className="flex-row align-items-center flex-wrap">
                      {details.asset_records?.length > 0 ? (
                        details.asset_records.map((file, index) => {
                          return (
                            <div
                              className="quote-file-pill flex-row gap-4 py-4 px-8 align-items-center"
                              key={index}
                            >
                              <a
                                href={file.url}
                                className="quote-attachment-file-name xetgo-font-tag"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.name}
                              </a>
                              <img
                                height={12}
                                width={12}
                                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                                alt="icon"
                                className="cursor-pointer"
                                onClick={() => removeFile(file.id)}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <>--</>
                      )}
                    </div>
                  </div>
                  <input
                    onChange={(event) => {
                      handleFileChange(event);
                    }}
                    id="fileInput"
                    name="file"
                    type="File"
                    style={{ display: "none" }}
                    multiple
                  />
                  <label htmlFor="fileInput">
                    <p className="p-4 cursor-pointer">
                      <img
                        height={24}
                        width={24}
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240229103446-arrow-up-to-line.svg"
                        alt="upload-icon"
                      />
                    </p>
                  </label>
                </div>
                <div
                  ref={descriptionRef}
                  className="xetgo-font-tag files-description"
                  style={{ outline: "none" }}
                  onKeyDown={onEnterPress}
                  contentEditable={true}
                ></div>
              </div>
            </div>
          )}
        </div>

        {openUpdateForm && details && (
          <div className="update-quote-sidebar flex-row justify-content-flex-end">
            <OutsideAlerter
              action={() => setOpenUpdateForm(false)}
              className="update-form-wrapper"
            >
              <QuoteContext.Provider
                value={{
                  quoteProductList: productToUpdate,
                  currentQuoteProduct: productToUpdate,
                  setCurrentQuoteProduct: setProductToUpdate,
                  updateProductList: () => console.log("Add Product To List"),
                  setEditMode: (val) => setOpenUpdateForm(val),
                  deleteProductFromList: () =>
                    console.log("Delete Product To List"),
                  editMode: openUpdateForm,
                  updateMode: true,
                }}
              >
                <div className="quote-enquiry-main-container update-product-details p-16 flex-row justify-content-flex-end gap-12">
                  <RaiseQuoteForm />
                </div>
              </QuoteContext.Provider>
            </OutsideAlerter>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteDetails;
