export const ALL_COUNTRIES = [
  { id: 1, code: "AD", name: "Andorra" },
  { id: 2, code: "AE", name: "United Arab Emirates" },
  { id: 3, code: "AF", name: "Afghanistan" },
  { id: 4, code: "AG", name: "Antigua and Barbuda" },
  { id: 5, code: "AI", name: "Anguilla" },
  { id: 6, code: "AL", name: "Albania" },
  { id: 7, code: "AM", name: "Armenia" },
  { id: 8, code: "AN", name: "Netherlands Antilles" },
  { id: 9, code: "AO", name: "Angola" },
  { id: 10, code: "AQ", name: "Antarctica" },
  { id: 11, code: "AR", name: "Argentina" },
  { id: 12, code: "AS", name: "American Samoa" },
  { id: 13, code: "AT", name: "Austria" },
  { id: 14, code: "AU", name: "Australia" },
  { id: 15, code: "AW", name: "Aruba" },
  { id: 16, code: "AX", name: "Åland" },
  { id: 17, code: "AZ", name: "Azerbaijan" },
  { id: 18, code: "BA", name: "Bosnia and Herzegovina" },
  { id: 19, code: "BB", name: "Barbados" },
  { id: 20, code: "BD", name: "Bangladesh" },
  { id: 21, code: "BE", name: "Belgium" },
  { id: 22, code: "BF", name: "Burkina Faso" },
  { id: 23, code: "BG", name: "Bulgaria" },
  { id: 24, code: "BH", name: "Bahrain" },
  { id: 25, code: "BI", name: "Burundi" },
  { id: 26, code: "BJ", name: "Benin" },
  { id: 27, code: "BL", name: "Saint Barthélemy" },
  { id: 28, code: "BM", name: "Bermuda" },
  { id: 29, code: "BN", name: "Brunei" },
  { id: 30, code: "BO", name: "Bolivia" },
  { id: 31, code: "BQ", name: "Bonaire, Sint Eustatius, and Saba" },
  { id: 32, code: "BR", name: "Brazil" },
  { id: 33, code: "BS", name: "Bahamas" },
  { id: 34, code: "BT", name: "Bhutan" },
  { id: 35, code: "BV", name: "Bouvet Island" },
  { id: 36, code: "BW", name: "Botswana" },
  { id: 37, code: "BY", name: "Belarus" },
  { id: 38, code: "BZ", name: "Belize" },
  { id: 39, code: "CA", name: "Canada" },
  { id: 40, code: "CC", name: "Cocos [Keeling] Islands" },
  { id: 41, code: "CD", name: "DR Congo" },
  { id: 42, code: "CF", name: "Central African Republic" },
  { id: 43, code: "CG", name: "Congo Republic" },
  { id: 44, code: "CH", name: "Switzerland" },
  { id: 45, code: "CI", name: "Ivory Coast" },
  { id: 46, code: "CK", name: "Cook Islands" },
  { id: 47, code: "CL", name: "Chile" },
  { id: 48, code: "CM", name: "Cameroon" },
  { id: 49, code: "CN", name: "China" },
  { id: 50, code: "CO", name: "Colombia" },
  { id: 51, code: "MP", name: "Northern Mariana Islands" },
  { id: 52, code: "MQ", name: "Martinique" },
  { id: 53, code: "MR", name: "Mauritania" },
  { id: 54, code: "MS", name: "Montserrat" },
  { id: 55, code: "MT", name: "Malta" },
  { id: 56, code: "MU", name: "Mauritius" },
  { id: 57, code: "MV", name: "Maldives" },
  { id: 58, code: "MW", name: "Malawi" },
  { id: 59, code: "MX", name: "Mexico" },
  { id: 60, code: "MY", name: "Malaysia" },
  { id: 61, code: "MZ", name: "Mozambique" },
  { id: 62, code: "NA", name: "Namibia" },
  { id: 63, code: "NC", name: "New Caledonia" },
  { id: 64, code: "NE", name: "Niger" },
  { id: 65, code: "NF", name: "Norfolk Island" },
  { id: 66, code: "NG", name: "Nigeria" },
  { id: 67, code: "NI", name: "Nicaragua" },
  { id: 68, code: "NL", name: "Netherlands" },
  { id: 69, code: "NO", name: "Norway" },
  { id: 70, code: "NP", name: "Nepal" },
  { id: 71, code: "NR", name: "Nauru" },
  { id: 72, code: "NU", name: "Niue" },
  { id: 73, code: "NZ", name: "New Zealand" },
  { id: 74, code: "OM", name: "Oman" },
  { id: 75, code: "PA", name: "Panama" },
  { id: 76, code: "PE", name: "Peru" },
  { id: 77, code: "PF", name: "French Polynesia" },
  { id: 78, code: "PG", name: "Papua New Guinea" },
  { id: 79, code: "PH", name: "Philippines" },
  { id: 80, code: "PK", name: "Pakistan" },
  { id: 81, code: "PL", name: "Poland" },
  { id: 82, code: "PM", name: "Saint Pierre and Miquelon" },
  { id: 83, code: "PN", name: "Pitcairn Islands" },
  { id: 84, code: "PR", name: "Puerto Rico" },
  { id: 85, code: "PS", name: "Palestine" },
  { id: 86, code: "PT", name: "Portugal" },
  { id: 87, code: "PW", name: "Palau" },
  { id: 88, code: "PY", name: "Paraguay" },
  { id: 89, code: "QA", name: "Qatar" },
  { id: 90, code: "RE", name: "Réunion" },
  { id: 91, code: "RO", name: "Romania" },
  { id: 92, code: "RS", name: "Serbia" },
  { id: 93, code: "RU", name: "Russia" },
  { id: 94, code: "RW", name: "Rwanda" },
  { id: 95, code: "SA", name: "Saudi Arabia" },
  { id: 96, code: "SB", name: "Solomon Islands" },
  { id: 97, code: "SC", name: "Seychelles" },
  { id: 98, code: "SD", name: "Sudan" },
  { id: 99, code: "SE", name: "Sweden" },
  { id: 100, code: "SG", name: "Singapore" },
  { id: 101, code: "HT", name: "Haiti" },
  { id: 102, code: "HU", name: "Hungary" },
  { id: 103, code: "ID", name: "Indonesia" },
  { id: 104, code: "IE", name: "Ireland" },
  { id: 105, code: "IL", name: "Israel" },
  { id: 106, code: "IM", name: "Isle of Man" },
  { id: 107, code: "IN", name: "India" },
  { id: 108, code: "IO", name: "British Indian Ocean Territory" },
  { id: 109, code: "IQ", name: "Iraq" },
  { id: 110, code: "IR", name: "Iran" },
  { id: 111, code: "IS", name: "Iceland" },
  { id: 112, code: "IT", name: "Italy" },
  { id: 113, code: "JE", name: "Jersey" },
  { id: 114, code: "JM", name: "Jamaica" },
  { id: 115, code: "JO", name: "Jordan" },
  { id: 116, code: "JP", name: "Japan" },
  { id: 117, code: "KE", name: "Kenya" },
  { id: 118, code: "KG", name: "Kyrgyzstan" },
  { id: 119, code: "KH", name: "Cambodia" },
  { id: 120, code: "KI", name: "Kiribati" },
  { id: 121, code: "KM", name: "Comoros" },
  { id: 122, code: "KN", name: "St Kitts and Nevis" },
  { id: 123, code: "KP", name: "North Korea" },
  { id: 124, code: "KR", name: "South Korea" },
  { id: 125, code: "KW", name: "Kuwait" },
  { id: 126, code: "KY", name: "Cayman Islands" },
  { id: 127, code: "KZ", name: "Kazakhstan" },
  { id: 128, code: "LA", name: "Laos" },
  { id: 129, code: "LB", name: "Lebanon" },
  { id: 130, code: "LC", name: "Saint Lucia" },
  { id: 131, code: "LI", name: "Liechtenstein" },
  { id: 132, code: "LK", name: "Sri Lanka" },
  { id: 133, code: "LR", name: "Liberia" },
  { id: 134, code: "LS", name: "Lesotho" },
  { id: 135, code: "LT", name: "Lithuania" },
  { id: 136, code: "LU", name: "Luxembourg" },
  { id: 137, code: "LV", name: "Latvia" },
  { id: 138, code: "LY", name: "Libya" },
  { id: 139, code: "MA", name: "Morocco" },
  { id: 140, code: "MC", name: "Monaco" },
  { id: 141, code: "MD", name: "Moldova" },
  { id: 142, code: "ME", name: "Montenegro" },
  { id: 143, code: "MF", name: "Saint Martin" },
  { id: 144, code: "MG", name: "Madagascar" },
  { id: 145, code: "MH", name: "Marshall Islands" },
  { id: 146, code: "MK", name: "North Macedonia" },
  { id: 147, code: "ML", name: "Mali" },
  { id: 148, code: "MM", name: "Myanmar" },
  { id: 149, code: "MN", name: "Mongolia" },
  { id: 150, code: "MO", name: "Macao" },
  { id: 151, code: "CR", name: "Costa Rica" },
  { id: 152, code: "CS", name: "Serbia and Montenegro" },
  { id: 153, code: "CU", name: "Cuba" },
  { id: 154, code: "CV", name: "Cabo Verde" },
  { id: 155, code: "CW", name: "Curaçao" },
  { id: 156, code: "CX", name: "Christmas Island" },
  { id: 157, code: "CY", name: "Cyprus" },
  { id: 158, code: "CZ", name: "Czechia" },
  { id: 159, code: "DE", name: "Germany" },
  { id: 160, code: "DJ", name: "Djibouti" },
  { id: 161, code: "DK", name: "Denmark" },
  { id: 162, code: "DM", name: "Dominica" },
  { id: 163, code: "DO", name: "Dominican Republic" },
  { id: 164, code: "DZ", name: "Algeria" },
  { id: 165, code: "EC", name: "Ecuador" },
  { id: 166, code: "EE", name: "Estonia" },
  { id: 167, code: "EG", name: "Egypt" },
  { id: 168, code: "EH", name: "Western Sahara" },
  { id: 169, code: "ER", name: "Eritrea" },
  { id: 170, code: "ES", name: "Spain" },
  { id: 171, code: "ET", name: "Ethiopia" },
  { id: 172, code: "FI", name: "Finland" },
  { id: 173, code: "FJ", name: "Fiji" },
  { id: 174, code: "FK", name: "Falkland Islands" },
  { id: 175, code: "FM", name: "Micronesia" },
  { id: 176, code: "FO", name: "Faroe Islands" },
  { id: 177, code: "FR", name: "France" },
  { id: 178, code: "GA", name: "Gabon" },
  { id: 179, code: "GB", name: "United Kingdom" },
  { id: 180, code: "GD", name: "Grenada" },
  { id: 181, code: "GE", name: "Georgia" },
  { id: 182, code: "GF", name: "French Guiana" },
  { id: 183, code: "GG", name: "Guernsey" },
  { id: 184, code: "GH", name: "Ghana" },
  { id: 185, code: "GI", name: "Gibraltar" },
  { id: 186, code: "GL", name: "Greenland" },
  { id: 187, code: "GM", name: "Gambia" },
  { id: 188, code: "GN", name: "Guinea" },
  { id: 189, code: "GP", name: "Guadeloupe" },
  { id: 190, code: "GQ", name: "Equatorial Guinea" },
  { id: 191, code: "GR", name: "Greece" },
  { id: 192, code: "GS", name: "South Georgia and South Sandwich Islands" },
  { id: 193, code: "GT", name: "Guatemala" },
  { id: 194, code: "GU", name: "Guam" },
  { id: 195, code: "GW", name: "Guinea-Bissau" },
  { id: 196, code: "GY", name: "Guyana" },
  { id: 197, code: "HK", name: "Hong Kong" },
  { id: 198, code: "HM", name: "Heard Island and McDonald Islands" },
  { id: 199, code: "HN", name: "Honduras" },
  { id: 200, code: "HR", name: "Croatia" },
  { id: 201, code: "SH", name: "Saint Helena" },
  { id: 202, code: "SI", name: "Slovenia" },
  { id: 203, code: "SJ", name: "Svalbard and Jan Mayen" },
  { id: 204, code: "SK", name: "Slovakia" },
  { id: 205, code: "SL", name: "Sierra Leone" },
  { id: 206, code: "SM", name: "San Marino" },
  { id: 207, code: "SN", name: "Senegal" },
  { id: 208, code: "SO", name: "Somalia" },
  { id: 209, code: "SR", name: "Suriname" },
  { id: 210, code: "SS", name: "South Sudan" },
  { id: 211, code: "ST", name: "São Tomé and Príncipe" },
  { id: 212, code: "SV", name: "El Salvador" },
  { id: 213, code: "SX", name: "Sint Maarten" },
  { id: 214, code: "SY", name: "Syria" },
  { id: 215, code: "SZ", name: "Eswatini" },
  { id: 216, code: "TC", name: "Turks and Caicos Islands" },
  { id: 217, code: "TD", name: "Chad" },
  { id: 218, code: "TF", name: "French Southern Territories" },
  { id: 219, code: "TG", name: "Togo" },
  { id: 220, code: "TH", name: "Thailand" },
  { id: 221, code: "TJ", name: "Tajikistan" },
  { id: 222, code: "TK", name: "Tokelau" },
  { id: 223, code: "TL", name: "Timor-Leste" },
  { id: 224, code: "TM", name: "Turkmenistan" },
  { id: 225, code: "TN", name: "Tunisia" },
  { id: 226, code: "TO", name: "Tonga" },
  { id: 227, code: "TR", name: "Turkey" },
  { id: 228, code: "TT", name: "Trinidad and Tobago" },
  { id: 229, code: "TV", name: "Tuvalu" },
  { id: 230, code: "TW", name: "Taiwan" },
  { id: 231, code: "TZ", name: "Tanzania" },
  { id: 232, code: "UA", name: "Ukraine" },
  { id: 233, code: "UG", name: "Uganda" },
  { id: 234, code: "UM", name: "U.S. Minor Outlying Islands" },
  { id: 235, code: "US", name: "United States" },
  { id: 236, code: "UY", name: "Uruguay" },
  { id: 237, code: "UZ", name: "Uzbekistan" },
  { id: 238, code: "VA", name: "Vatican City" },
  { id: 239, code: "VC", name: "St Vincent and Grenadines" },
  { id: 240, code: "VE", name: "Venezuela" },
  { id: 241, code: "VG", name: "British Virgin Islands" },
  { id: 242, code: "VI", name: "U.S. Virgin Islands" },
  { id: 243, code: "VN", name: "Vietnam" },
  { id: 244, code: "VU", name: "Vanuatu" },
  { id: 245, code: "WF", name: "Wallis and Futuna" },
  { id: 246, code: "WS", name: "Samoa" },
  { id: 247, code: "XK", name: "Kosovo" },
  { id: 248, code: "YE", name: "Yemen" },
  { id: 249, code: "YT", name: "Mayotte" },
  { id: 250, code: "ZA", name: "South Africa" },
  { id: 251, code: "ZM", name: "Zambia" },
  { id: 252, code: "ZW", name: "Zimbabwe" },
];
