import { createContext, useContext } from "react";
import { IQuoteProduct, NEW_QUOTE_PRODUCT } from "../models/QuoteProduct";

export type IQuoteContext = {
  quoteProductList: IQuoteProduct[];
  currentQuoteProduct: IQuoteProduct[];
  updateProductList: (product: IQuoteProduct) => void;
  deleteProductFromList: (id: number, address_id: number) => void;
  setCurrentQuoteProduct: (product: IQuoteProduct[]) => void;
  editMode: boolean;
  setEditMode: (val: boolean) => void;
  clearQuoteList?: () => void;
  updateMode?: boolean;
};

export const QuoteContext = createContext<IQuoteContext>({
  quoteProductList: [],
  currentQuoteProduct: [NEW_QUOTE_PRODUCT],
  updateProductList: () => {},
  deleteProductFromList: () => {},
  setCurrentQuoteProduct: () => {},
  editMode: false,
  setEditMode: () => {},
  clearQuoteList: () => {},
});

export const errorToasts = (invalidKeys: string[]): string[] => {
  const errors: string[] = [];
  if (invalidKeys.indexOf("product") > -1) {
    errors.push("Please select a product");
  }
  if (invalidKeys.indexOf("quantity") > -1) {
    errors.push("Please add quantity for your product");
  }
  if (invalidKeys.indexOf("unit") > -1) {
    errors.push("Please select unit for the quantity");
  }
  if (invalidKeys.indexOf("dimension") > -1) {
    errors.push("Please add dimension of your product");
  }
  if (invalidKeys.indexOf("specification") > -1) {
    errors.push("Please add specification of your product");
  }
  if (invalidKeys.indexOf("address") > -1) {
    errors.push("Please select address for delivery");
  }
  return errors;
};

export const useQuoteContext = () => useContext(QuoteContext);
