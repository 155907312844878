import { FilterOptionSchema } from "../../components/Filter/filter.data";
import {
  FetchProductsAction,
  FetchProductsSuccessAction,
  ProductActions,
} from "./productTypes";

export const fetchProducts = (): FetchProductsAction => ({
  type: ProductActions.FETCH_PRODUCTS_REQUEST,
});

export const fetchProductsSuccess = (response: {
  products: FilterOptionSchema[];
}): FetchProductsSuccessAction => ({
  type: ProductActions.FETCH_PRODUCTS_SUCCESS,
  payload: response.products,
});

export const fetchProductsFailure = (error: string) => ({
  type: ProductActions.FETCH_PRODUCTS_FAILURE,
  error,
});
