import React, { useEffect, useState } from "react";
import "./SkuAnalytics.scss";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import { useSelector } from "react-redux";
import { getWarehouseUserAddresses } from "../../redux/user/userSelector";
import { UserAddress } from "../../redux/user/userTypes";
import { Chart } from "chart.js/auto";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { GraphBackgrounds, GraphBorders } from "../../constants/Graph";

interface SkuAnalyticsProps {
  sku: SkuDetailSchema;
}

const SkuAnalytics: React.FC<SkuAnalyticsProps> = ({ sku }) => {
  const warehouseAddresses = useSelector(getWarehouseUserAddresses);
  const [skuAddresses, setSkuAddresses] = useState<UserAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(
    skuAddresses[0],
  );

  const handleAddressSelection = (val: any) => {
    if (selectedAddress && selectedAddress.id === val.id) {
      setSelectedAddress(null);
    } else {
      setSelectedAddress(val);
    }
  };

  useEffect(() => {
    getSkuWiseData();
    const skuAddressIds = sku.locations.map((row) => row.id);
    const addresses = warehouseAddresses.filter(
      (row) => skuAddressIds.indexOf(row.id) > -1,
    );
    setSkuAddresses(addresses);
    setSelectedAddress(addresses[0]);
  }, []);

  useEffect(() => {
    getVendorWiseData();
  }, [selectedAddress]);

  const getVendorWiseData = async () => {
    try {
      const res = await ApiService().client.get(
        URLS.SKU.VENDOR_WISE_CONSUMPTION(sku.id),
        {
          params: {
            address_id: selectedAddress?.id,
          },
        },
      );
      if (res.status === 200) {
        vendorConsumptionRender(res.data.chart, res.data.months);
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const getSkuWiseData = async () => {
    try {
      const res = await ApiService().client.get(
        URLS.SKU.SKU_WISE_CONSUMPTION(sku.id),
      );
      if (res.status === 200) {
        skuConsumptionRender(res.data.chart, res.data.months);
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const vendorConsumptionRender = async (
    data: { [key: string]: number[] },
    months: string[],
  ) => {
    await clearVendorChart();
    const canvas = document.getElementById("vendor-graph");
    if (canvas) {
      canvas.setAttribute("height", `${10 * 34 + 24}px`);
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "bar",
        data: {
          labels: months,
          datasets: Object.keys(data).map((row, index) => {
            return {
              label: row,
              data: data[row] || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              backgroundColor:
                GraphBackgrounds[index % GraphBackgrounds.length],
              barThickness: 10,
              borderColor: GraphBorders[index % GraphBorders.length],
              borderWidth: 1,
              borderRadius: 10,
            };
          }),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              align: "end",
              labels: {
                font: {
                  size: 12,
                  weight: 400,
                  family: "Inter",
                },
                color: "#4a4a4a",
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: "#9CA3AF",
                font: {
                  size: 12,
                  weight: 500,
                  family: "Inter",
                },
                align: "center",
              },
              grid: {
                display: true,
              },
              border: {
                display: false,
              },
            },
            x: {
              offset: true,
              grid: {
                display: false,
              },
              ticks: {
                color: "#AA7000",
                font: {
                  size: 12,
                  lineHeight: 1.5,
                  weight: 500,
                  family: "Inter",
                },
              },
            },
          },
        },
      });
    }
  };

  const clearVendorChart = async () => {
    const chartStatus = Chart.getChart("vendor-graph"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  const skuConsumptionRender = async (
    data: { [key: number]: number[] },
    months: string[],
  ) => {
    await clearSkuChart();
    const stepSize = stepSizeValue(data);
    const canvas = document.getElementById("sku-consumption-graph");
    if (canvas) {
      canvas.setAttribute("height", `${10 * 34 + 24}px`);
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "line",
        data: {
          labels: months,
          datasets: sku.locations.map((row, index) => {
            return {
              label: row.address_city,
              data: data[row.id] || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              borderColor: GraphBorders[index % GraphBorders.length],
              borderWidth: 1,
              pointStyle: "circle",
              pointRadius: 4,
              backgroundColor:
                GraphBackgrounds[index % GraphBackgrounds.length],
            };
          }),
        },
        options: {
          elements: {
            line: {
              tension: 0.5,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              align: "end",
              labels: {
                font: {
                  size: 12,
                  weight: 400,
                  family: "Inter",
                },
                color: "#4a4a4a",
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: "#9CA3AF",
                font: {
                  size: 12,
                  weight: 500,
                  family: "Inter",
                },
                stepSize,
              },
              grid: {
                display: true,
              },
              border: {
                display: false,
              },
            },
            x: {
              offset: true,
              grid: {
                display: false,
              },
              ticks: {
                color: "#AA7000",
                font: {
                  size: 12,
                  lineHeight: 1.5,
                  weight: 500,
                  family: "Inter",
                },
              },
            },
          },
        },
      });
    }
  };

  const clearSkuChart = async () => {
    const chartStatus = Chart.getChart("sku-consumption-graph"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  const stepSizeValue = (data: { [key: number]: number[] }) => {
    let max = 100;
    const rt = Object.keys(data);
    Object.keys(data).forEach((key: string) => {
      data[parseInt(key)].forEach((ent) => {
        max = Math.max(max, ent);
      });
    });
    let stepSize = Math.ceil(max / 10);
    stepSize = Math.ceil((stepSize + 1) / 10) * 10;
    return stepSize;
  };

  return (
    <div className="sku-analytics-detail-page flex-column full-width gap-36">
      <div className="vendor-graph-detail-container p-16 flex-column gap-16">
        <div className="flex-row justify-content-space-between align-items-center">
          <p className="xetgo-font-caption bold" style={{ color: "#000000" }}>
            Consumption / Vendor
          </p>
          <div className="flex-row align-items-center justify-content-flex-end gap-12">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
              alt="filter"
            />
            <AddressDropdown
              showId={false}
              selected={selectedAddress || null}
              onSelection={handleAddressSelection}
              addresses={skuAddresses}
              classname="sku-month-filter"
              placeholder="Location"
            />
          </div>
        </div>
        <div className="vendor-graph-container">
          <canvas id="vendor-graph"></canvas>
        </div>
      </div>
      <div className="sku-consumption-container flex-column gap-16 p-16">
        <p className="xetgo-font-caption bold" style={{ color: "#000000" }}>
          Consumption for Location / Month
        </p>
        <div className="">
          <canvas id="sku-consumption-graph"></canvas>
        </div>
      </div>
    </div>
  );
};

export default SkuAnalytics;
