export const MEASUREMENT_UNITS = [
  "pcs",
  "cm",
  "dz",
  "ft",
  "g",
  "in",
  "kg",
  "lbs",
  "mtr",
  "nos",
  "roll",
  "pkt",
];
