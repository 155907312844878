import React, { useState } from "react";
import "./TagsDropdown.scss";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { FilterOptionSchema } from "../Filter/filter.data";

interface TagsDropdownProps {
  allTags: FilterOptionSchema[];
  direction: string;
  addedTags: (data: FilterOptionSchema[]) => void;
  newTagAdd: (data: FilterOptionSchema) => void;
  selectedTags: number[];
  customClass?: string;
}
const TagsDropdown: React.FC<TagsDropdownProps> = ({
  addedTags,
  direction,
  selectedTags,
  customClass,
  allTags,
  newTagAdd,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchText, setSearchedText] = useState("");
  const [skuTags, setSkuTags] = useState<FilterOptionSchema[] | []>(allTags);
  const [filterSkuTags, setFilterSkuTags] = useState<FilterOptionSchema[] | []>(
    allTags,
  );
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(selectedTags);
  const [previousSelectedTagIds, setPreviousSelectedTagIds] =
    useState<number[]>(selectedTags);

  const handleNewTagAdd = async (tag: string) => {
    try {
      const { data } = await ApiService().client.post(URLS.SKU.ADD_NEW_TAG, {
        tagName: tag,
      });
      setSearchedText("");
      setSkuTags([...skuTags, data.tag]);
      setFilterSkuTags([...skuTags, data.tag]);
      newTagAdd(data.tag);
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);

    if (searchText.length <= 0) {
      setFilterSkuTags(skuTags);
      return;
    }

    const updatedFilterSku: FilterOptionSchema[] = skuTags.filter((tag) =>
      tag.name.toLowerCase().includes(searchText),
    );

    setFilterSkuTags(updatedFilterSku);
  };

  const handleAddTag = (tagId: number): void => {
    let updatedSelectedTags: number[];

    if (selectedTagIds.includes(tagId)) {
      updatedSelectedTags = selectedTagIds.filter((id) => id !== tagId);
    } else {
      updatedSelectedTags = [...selectedTagIds, tagId];
    }

    setSelectedTagIds(updatedSelectedTags);

    const tagData = skuTags.filter((tag) =>
      updatedSelectedTags.includes(tag.id),
    );

    addedTags(tagData);
  };

  return (
    <OutsideAlerter action={() => setOpenDropdown(false)}>
      <div className="tag-main-container">
        <div className="tag-dropdown-container full-width">
          <div
            onClick={(e) => setOpenDropdown(!openDropdown)}
            className={`sku-form-input px-12 py-8 xetgo-font-tag full-width border-box cursor-pointer ${
              customClass && "custom-dropdown-input"
            }`}
          >
            Select Tags
          </div>

          <img
            onClick={(e) => setOpenDropdown(!openDropdown)}
            className={`dropdown-arrow cursor-pointer ${
              customClass && "custom-dropdown-arrow"
            }`}
            height={16}
            width={16}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715100443-chevron-down.svg"
            alt="dropdown-icon"
          />
        </div>
        {openDropdown && (
          <div
            style={{ [direction]: 0 }}
            className="tag-option-main-box p-6 flex-column gap-8 xetgo-font-tag"
          >
            <input
              className="search-input px-12 py-8 full-width border-box xetgo-font-tag"
              value={searchText}
              onChange={(e) => handleSearchFile(e)}
              placeholder="Search..."
            />
            <p style={{ color: "#999898" }} className="px-8">
              Select a tag, or create
            </p>
            <div className="all-sku-tag-container flex-row gap-8 px-8">
              {filterSkuTags.map((tag, index) => (
                <div
                  onClick={() => {
                    if (!previousSelectedTagIds.includes(tag.id)) {
                      handleAddTag(tag.id);
                    }
                  }}
                  key={index}
                  className={`tag-pill-inactive px-6 py-4 xetgo-font-mini cursor-pointer ${
                    selectedTagIds.includes(tag.id) && "tag-pill-active"
                  }`}
                >
                  {tag.name}
                </div>
              ))}
            </div>
            {filterSkuTags.length <= 0 && searchText.length > 0 && (
              <div
                onClick={() => handleNewTagAdd(searchText)}
                className="flex-row align-items-center gap-8 cursor-pointer px-8"
              >
                <p className="xetgo-font-tag">CREATE</p>
                <p className="tag-pill-inactive px-6 py-4 xetgo-font-mini">
                  {searchText}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};

export default TagsDropdown;
