import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ClientPurchaseOrderProductDetails } from "../../models/ClientPurchaseOrderDetail";
import "./ClientPurchaseOrderProductCard.scss";
import { breakPointObserver } from "../../utils/BreakpointObserver";
import ClientPoProductCardDetail from "./ClientPoProductCardDetail/ClientPoProductCardDetail";

interface ClientPoProductProps {
  index: number;
  clientPurchaseOrder: ClientPurchaseOrderProductDetails;
}

const ClientPurchaseOrderProductCard: React.FC<ClientPoProductProps> = ({
  index,
  clientPurchaseOrder,
}) => {
  const [cpo, setCpo] = useState(clientPurchaseOrder);
  const [isProductExpanded, setIsProductExpanded] = useState(false);
  const [breakpoint, setBreakpoint] = useState("");
  const [scheduledDelivery, setScheduledDelivery] = useState("");

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  useEffect(() => {
    setCpo(clientPurchaseOrder);
  }, [clientPurchaseOrder]);

  useEffect(() => {
    let deliveryDate = "";
    cpo.delivery_schedules.forEach((schedule) => {
      if (deliveryDate == "" && schedule.actual_delivery_date == null) {
        deliveryDate = schedule.expected_delivery_date;
      }
    });
    setScheduledDelivery(deliveryDate);
  }, [cpo]);

  const handleExpand = () => {
    setIsProductExpanded(!isProductExpanded);
  };

  const isDeliveryScheduled = () => {
    if (scheduledDelivery === "") {
      return false;
    }
    // return (
    //   scheduledDelivery &&
    //   (dayjs(scheduledDelivery).isAfter(dayjs(new Date())) ||
    //     dayjs(scheduledDelivery).isSame(dayjs(new Date())))
    // );
    return true;
  };

  return (
    <div
      key={`po-product-card-${index}`}
      className="product-container xetgo-font-tag cursor-pointer"
      onClick={handleExpand}
    >
      <div className="product-card-wrapper flex-row align-items-center">
        <div className="product-index p-16">
          {(index.toString().length === 2
            ? "0"
            : index.toString().length > 2
            ? ""
            : "00") +
            (index + 1)}
        </div>

        <div className="product-card-content-wrapper">
          <div className="product-card-upper-section">
            <div className="product-name-price-details flex-column gap-4 p-16">
              <span className="product-name bold">{cpo.product_name}</span>
              <div className="price-details flex-row align-items-center gap-4 flex-wrap">
                {isDeliveryScheduled() && (
                  <p className="px-6 py-2 product-expected-delivery xetgo-font-mini bold">
                    {dayjs(scheduledDelivery).format("DD/MM/YYYY")}- Delivery
                    Scheduled
                  </p>
                )}
              </div>
            </div>

            <div className="total-price-details flex-column gap-4 p-16 ">
              <span style={{ width: "max-content" }}>
                <span> ₹ </span>
                {cpo.price}
              </span>
            </div>

            <div className="quantity-details flex-column gap-4 p-16">
              <p className="po-unit">
                {cpo.quantity} {cpo.unit}
              </p>
            </div>

            <div className="total-price-details flex-column gap-4 p-16 ">
              <span style={{ width: "max-content" }}>
                <span> ₹ </span>
                {(cpo.price * cpo.quantity).toLocaleString("en-IN", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>

          {isProductExpanded && (
            <ClientPoProductCardDetail cpo={clientPurchaseOrder} />
          )}

          <div className="po-description-single flex-row justify-content-space-between p-16">
            <p className="xetgo-font-tag">{cpo.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPurchaseOrderProductCard;
