import "./PurchaseOrders.scss";
import React, { useState, useEffect } from "react";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { PurchaseOrder } from "../../models/PurchaseOrder";
import { useSelector } from "react-redux";
import {
  getUserWarehouseAddresses,
  userDetails,
} from "../../redux/user/userSelector";
import PurchaseOrdersCard from "../../components/PurchaseOrderProductCard/PurchaseOrderCard";
import { UserAddress } from "../../redux/user/userTypes";
import AddressDropdown from "../../components/AddressDropdown/AddressDropdown";
import { Link } from "react-router-dom";
import Filter from "../../components/Filter/Filter";
import { FilterOptionSchema } from "../../components/Filter/filter.data";

const PurchaseOrders: React.FC = () => {
  const vendorAddresses = useSelector(getUserWarehouseAddresses);
  const user = useSelector(userDetails);
  const [selectedPoIndexes, setSelectedPoIndexes] = useState<number[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>();
  const [purchaseOrdersList, setPurchaseOrdersList] = useState<PurchaseOrder[]>(
    [],
  );
  const [vendorDataList, setVendorDataList] = useState<FilterOptionSchema[]>();
  const [filterVendorList, setFilterVendorList] = useState<
    FilterOptionSchema[]
  >([]);
  const [selectedVendorId, setSelectedVendorId] = useState(-1);

  const [filteredPurchaseOrdersList, setFilteredPurchaseOrdersList] = useState<
    PurchaseOrder[]
  >([]);
  const [productsSelected, setProductsSelected] = useState<PurchaseOrder[]>([]);
  const [selectedProductQuantities, setSelectedProductQuantities] = useState<{
    [key: number]: number;
  }>({});
  const [preSelectedVendorValue, setPreSelectedVendorValue] = useState<
    FilterOptionSchema[]
  >([]);

  useEffect(() => {
    fetchVendors().then((r) => {
      return r;
    });

    const storedList: PurchaseOrder[] =
      JSON.parse(localStorage.getItem("selectedProductsList") as string) || [];
    const selectedStoredAddress: UserAddress = JSON.parse(
      localStorage.getItem("selectedDeliveryAddress") as string,
    );
    const selectedStoredVendorId = storedList ? storedList[0]?.vendor_id : -1;
    const selectedStoredVendorName = storedList
      ? storedList[0]?.vendor_name
      : "Select Vendor";
    setProductsSelected(storedList);
    setSelectedVendorId(selectedStoredVendorId);
    setFilterVendorValue(selectedStoredVendorId, selectedStoredVendorName);
    if (selectedStoredAddress) {
      setSelectedAddress(selectedStoredAddress);
    }

    return () => {
      setProductsSelected([]);
    };
  }, []);

  useEffect(() => {
    const poIndexes = productsSelected.map((product) => product.sku_price_id);
    const productQuantitiesMap: { [key: number]: number } = {};

    productsSelected.forEach((product) => {
      const { id, quantity } = product;
      productQuantitiesMap[id] = quantity;
    });

    setSelectedProductQuantities(productQuantitiesMap);
    setSelectedPoIndexes(poIndexes);
    if (productsSelected.length === 0) {
      localStorage.removeItem("selectedProductsList");
      localStorage.removeItem("selectedDeliveryAddress");
    } else {
      localStorage.setItem(
        "selectedProductsList",
        JSON.stringify(productsSelected),
      );
      localStorage.setItem(
        "selectedDeliveryAddress",
        JSON.stringify(selectedAddress),
      );
    }
  }, [productsSelected]);

  useEffect(() => {
    if (selectedVendorId === -1) {
      return;
    }
    getPurchaseOrdersList(selectedAddress?.id).then((r) => {
      return r;
    });
  }, [selectedAddress, selectedVendorId]);

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    const filteredPurchaseOrdersList = purchaseOrdersList.filter((order) =>
      order.product_name.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredPurchaseOrdersList(filteredPurchaseOrdersList);
  };

  const getPurchaseOrdersList = async (address_id: number | undefined) => {
    if (!address_id || !selectedVendorId) {
      return;
    }

    try {
      const { data } = await ApiService().client.get(
        URLS.PURCHASE_ORDER.GET_PURCHASE_ORDERS_LIST,
        {
          params: {
            client_id: user.company_id,
            vendor_id: selectedVendorId,
            address_id: address_id,
          },
        },
      );
      const purchaseOrdersList = data.data;
      setPurchaseOrdersList(purchaseOrdersList);
      setFilteredPurchaseOrdersList(purchaseOrdersList);
    } catch (error) {
      console.error("Error fetching POs : ", error);
      return [];
    }
  };

  const fetchVendors = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDOR_LIST,
      );
      setVendorDataList(data.vendors);
      setFilterVendorList(data.vendors);
    } catch (e) {
      console.error(e);
    }
  };

  const updateOrAddProduct = (newProduct: PurchaseOrder, index: number) => {
    if (newProduct.quantity === 0) {
      setProductsSelected((prevState) =>
        prevState.filter(
          (product) => product.sku_price_id !== newProduct.sku_price_id,
        ),
      );
      return;
    }

    const existingProductIndex = productsSelected.findIndex(
      (product) => product.sku_price_id === newProduct.sku_price_id,
    );

    if (existingProductIndex !== -1) {
      setProductsSelected((prevState) => {
        const updatedProducts = [...prevState];
        updatedProducts[existingProductIndex] = newProduct;
        return updatedProducts;
      });
    } else {
      setProductsSelected((prevState) => [...prevState, newProduct]);
    }
    setSelectedPoIndexes((prevState) => [...prevState, index]);
  };

  const handleVendorSelection = (vendorId: number) => {
    if (productsSelected.length > 0) {
      const confirmation = window.confirm(
        "On changing address your cart will be cleared. Do you want to proceed ?",
      );
      if (confirmation) {
        setProductsSelected([]);
        setSelectedPoIndexes([]);
        setSelectedVendorId(vendorId);
        localStorage.removeItem("selectedDeliveryAddress");
        setFilterVendorValue(vendorId);
      } else {
        setSelectedVendorId(selectedVendorId);
        setFilterVendorValue(selectedVendorId);
      }
    } else {
      setSelectedVendorId(vendorId);
    }
  };

  const handleAddressSelection = (val: UserAddress) => {
    if (productsSelected.length > 0) {
      const confirmation = window.confirm(
        "On changing address your cart will be cleared. Do you want to proceed ?",
      );
      if (confirmation) {
        setSelectedAddress(val);
        setSelectedProductQuantities({});
        setSelectedPoIndexes([]);
        setProductsSelected([]);
        localStorage.removeItem("selectedDeliveryAddress");
        localStorage.removeItem("selectedProductsList");
      }
    } else {
      setSelectedAddress(val);
    }
  };

  const resetEverything = () => {
    setSelectedVendorId(-1);
    setSelectedAddress(null);
    setPurchaseOrdersList([]);
    setProductsSelected([]);
    setFilteredPurchaseOrdersList([]);
    setSelectedProductQuantities({});
    setSelectedPoIndexes([]);
    localStorage.removeItem("selectedDeliveryAddress");
    localStorage.removeItem("selectedProductsList");
  };

  const setFilterVendorValue = (
    vendorId: number,
    vendorName: string = "Select Vendor",
  ) => {
    setPreSelectedVendorValue([
      {
        id: vendorId,
        name:
          filterVendorList?.find((v) => v.id === vendorId)?.name || vendorName,
      },
    ]);
  };

  return (
    <div className="purchase-orders-content-container">
      <div
        className="purchase-orders-upper-container px-16 py-12 flex-row justify-content-space-between
                      align-items-center gap-12"
      >
        <div className="search-bar-container">
          <input
            className="py-8 px-34 search-input border-box xetgo-font-tag"
            onChange={handleSearchFile}
            placeholder="Search by name"
          />
          <img
            height={16}
            width={16}
            className="search-icon "
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
            alt="search-icon"
          />

          <Link
            className="checkout-link-container-mobile"
            to="/checkout"
            state={{
              purchaseOrders: productsSelected,
              selectedAddress: selectedAddress,
              selectedVendor: vendorDataList?.find(
                (vendor) => vendor.id === selectedVendorId,
              ),
            }}
          >
            <div className="cart-container flex-row align-items-center cursor-pointer">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240305110256-Frame+2609835.svg"
                alt="cart"
              />

              {productsSelected.length > 0 && (
                <div className="flex-row align-items-center">
                  <span className="cart-item-count flex-row align-items-center justify-content-center">
                    {productsSelected.length}
                  </span>

                  <span className="checkout-arrow">
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240305111123-chevrons-right.svg"
                      alt="checkout"
                    />
                  </span>
                </div>
              )}
            </div>
          </Link>
        </div>

        <div className="header-right-section flex-row align-items-center gap-8">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
            alt="filter"
            className="filter-icon"
          />
          <div style={{ flex: "1" }}>
            <Filter
              option={{
                key: "vendors",
                name: "Vendor",
              }}
              optionList={filterVendorList || []}
              multipleSelection={false}
              onSelection={(val, _option) => {
                if (val[0] && val[0].id !== selectedVendorId) {
                  handleVendorSelection(val[0].id);
                } else {
                  resetEverything();
                }
              }}
              preSelected={preSelectedVendorValue}
              mode="showNames"
              showPlaceholder={true}
              direction="right"
              addNew={false}
              classname="po-filter-vendor"
            />
          </div>

          <div style={{ flex: "1" }}>
            <AddressDropdown
              showId={false}
              selected={selectedAddress as UserAddress}
              onSelection={handleAddressSelection}
              addresses={vendorAddresses}
              placeholder="Location"
              classname="po-filter-address"
            />
          </div>
          <Link
            className="checkout-link-container"
            to="/checkout"
            state={{
              purchaseOrders: productsSelected,
              selectedAddress: selectedAddress,
              selectedVendor: vendorDataList?.find(
                (vendor) => vendor.id === selectedVendorId,
              ),
            }}
          >
            <div className="cart-container flex-row align-items-center cursor-pointer">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240305110256-Frame+2609835.svg"
                alt="cart"
              />

              {productsSelected.length > 0 && (
                <div className="flex-row align-items-center">
                  <span className="cart-item-count flex-row align-items-center justify-content-center">
                    {productsSelected.length}
                  </span>

                  <span className="checkout-arrow">
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240305111123-chevrons-right.svg"
                      alt="checkout"
                    />
                  </span>
                </div>
              )}
            </div>
          </Link>
        </div>
      </div>

      <div className="sku-purchase-container px-16">
        <div className="sku-product-row">
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
              alt="enquiry-icon"
              height={12}
              width={12}
            />
            <p>Sku Id</p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
              alt="calendar-icon"
              height={12}
              width={12}
            />
            <p>Sku Name</p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143408-building-2.svg"
              alt="building-icon"
              height={12}
              width={12}
            />
            <p>MOQ</p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
              alt="calendar-icon"
              height={12}
              width={12}
            />
            <p>Lead Time</p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
              alt="user-icon"
              height={12}
              width={12}
            />
            <p>Price</p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
              alt="status-icon"
              height={12}
              width={12}
            />
            <p>Order </p>
          </div>
        </div>
        <div id="scrollableDiv" className="purchase-orders-list-scroll-wrapper">
          <div className="purchase-orders-lower-container flex-column gap-4">
            {filteredPurchaseOrdersList.length > 0 &&
              filteredPurchaseOrdersList.map((purchaseOrder, index: number) => (
                <div className="flex-column pocard-container" key={index}>
                  <PurchaseOrdersCard
                    key={index}
                    serialNumber={purchaseOrder.uuid}
                    quantity={
                      selectedProductQuantities[purchaseOrder.sku_price_id]
                    }
                    onAdd={updateOrAddProduct}
                    purchaseOrder={purchaseOrder}
                    poIndex={purchaseOrder.sku_price_id}
                    selectedPoIndexes={selectedPoIndexes}
                  />
                </div>
              ))}

            {filteredPurchaseOrdersList.length === 0 && (
              <div className="empty-page flex-row align-items-start justify-content-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240306055312-Frame+2609676.svg"
                  alt="Not Found"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrders;
