export function formatDateWithYear(timestamp: string) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(timestamp);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function formatTime(timestamp: string) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12;

  return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
}

export function convertIntoWeekDays(days: number | null) {
  if (!days) {
    return `-`;
  } else {
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;
    if (weeks === 0) {
      if (remainingDays === 1) {
        return `${remainingDays} day`;
      } else {
        return `${remainingDays} days`;
      }
    } else if (weeks === 1) {
      if (remainingDays === 0) {
        return `${weeks} week`;
      } else if (remainingDays === 1) {
        return `${weeks} week ${remainingDays} day`;
      } else {
        return `${weeks} week ${remainingDays} days`;
      }
    } else {
      if (remainingDays === 0) {
        return `${weeks} weeks`;
      } else if (remainingDays === 1) {
        return `${weeks} weeks ${remainingDays} day`;
      } else {
        return `${weeks} weeks ${remainingDays} days`;
      }
    }
  }
}
