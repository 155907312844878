import { createSelector } from "reselect";
import { RootState } from "../store";
import { QuoteDetail } from "../../models/Quote";

const selectQuoteDetailsState = (state: RootState): QuoteDetail | null =>
  state.quote.quoteDetails;

export const selectQuoteDetails = createSelector(
  [selectQuoteDetailsState],
  (quoteState) => quoteState,
);

export const selectTaskComments = createSelector(
  [selectQuoteDetailsState],
  (filterState) => filterState?.comments || [],
);
