import { put, call, takeLatest, all } from "redux-saga/effects";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import {
  AddPurchaseOrderDeliverySchedule,
  AddPurchaseRequestCommentAction,
  AddPurchaseRequestDeliveryPOD,
  DeletePurchaseOrderDeliverySchedule,
  GetPurchaseOrderDetailAction,
  PurchaseOrderAction,
  UpdatePurchaseOrderDeliverySchedule,
} from "./purchaseOrderTypes";
import {
  addPurchaseOrderDeliveryScheduleFailure,
  addPurchaseOrderDeliveryScheduleSuccess,
  addPurchaseRequestCommentFailure,
  addPurchaseRequestCommentSuccess,
  addPurchaseRequestDeliveryPODFailure,
  addPurchaseRequestDeliveryPODSuccess,
  deletePurchaseOrderDeliveryScheduleFailure,
  deletePurchaseOrderDeliveryScheduleSuccess,
  getPurchaseOrderDetailsFailure,
  getPurchaseOrderDetailsSuccess,
  updatePurchaseOrderDeliveryScheduleFailure,
  updatePurchaseOrderDeliveryScheduleSuccess,
} from "./purchaseOrderAction";

function* getPurchaseOrderDetailSaga(action: GetPurchaseOrderDetailAction) {
  try {
    const { data } = yield call(
      ApiService().client.get,
      `${URLS.PURCHASE_ORDER.GET_CLIENT_PO_DETAILS(action.payload.id)}`,
    );

    yield put(getPurchaseOrderDetailsSuccess(data));
  } catch (error) {
    yield put(getPurchaseOrderDetailsFailure("Error fetching quote details."));
  }
}

function* watchGetPurchaseOrderDetail() {
  yield takeLatest(
    PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL,
    getPurchaseOrderDetailSaga,
  );
}

function* addPurchaseRequestComment(action: AddPurchaseRequestCommentAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URLS.PURCHASE_ORDER.ADD_CLIENT_PO_COMMENT(
        parseInt((action.payload.get("id") || "").toString()),
      ),
      action.payload,
    );
    yield put(addPurchaseRequestCommentSuccess(data.comment));
  } catch (error) {
    yield put(addPurchaseRequestCommentFailure("Comment addition failed!!"));
  }
}

export function* watchAddPurchaseRequestComment() {
  yield takeLatest(
    PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT,
    addPurchaseRequestComment,
  );
}

function* addPurchaseRequestDeliveryPOD(action: AddPurchaseRequestDeliveryPOD) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URLS.PURCHASE_ORDER.ADD_DELIVERY_POD(
        parseInt((action.payload.get("id") || "").toString()),
      ),
      action.payload,
    );
    yield put(addPurchaseRequestDeliveryPODSuccess(data));
  } catch (error) {
    yield put(addPurchaseRequestDeliveryPODFailure("POD Upload failed!!"));
  }
}

export function* watchAddPurchaseRequestDeliveryPOD() {
  yield takeLatest(
    PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD,
    addPurchaseRequestDeliveryPOD,
  );
}

function* addPurchaseOrderDeliverySchdeule(
  action: AddPurchaseOrderDeliverySchedule,
) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URLS.PURCHASE_ORDER.ADD_DELIVERY_SCHEDULE(
        parseInt((action.payload.purchase_request_id || "").toString()),
      ),
      action.payload,
    );
    yield put(addPurchaseOrderDeliveryScheduleSuccess(data.schedule));
  } catch (error) {
    yield put(addPurchaseOrderDeliveryScheduleFailure("POD Upload failed!!"));
  }
}

export function* watchAddPurchaseOrderDeliverySchdeule() {
  yield takeLatest(
    PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE,
    addPurchaseOrderDeliverySchdeule,
  );
}

function* deletePurchaseOrderDeliverySchdeule(
  action: DeletePurchaseOrderDeliverySchedule,
) {
  try {
    const { data } = yield call(
      ApiService().client.delete,
      URLS.PURCHASE_ORDER.DELETE_DELIVERY_SCHEDULE(
        action.payload.po_id,
        action.payload.id,
      ),
    );
    yield put(deletePurchaseOrderDeliveryScheduleSuccess(data.data));
  } catch (error) {
    yield put(
      deletePurchaseOrderDeliveryScheduleFailure(
        "Deleted PO Delivery Schedule Failed",
      ),
    );
  }
}

export function* watchDeletePurchaseOrderDeliverySchdeule() {
  yield takeLatest(
    PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE,
    deletePurchaseOrderDeliverySchdeule,
  );
}

function* updatePurchaseOrderDeliverySchdeule(
  action: UpdatePurchaseOrderDeliverySchedule,
) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URLS.PURCHASE_ORDER.UPDATE_DELIVERY_SCHEDULE(
        action.payload.po_id,
        action.payload.id,
      ),
      action.payload,
    );
    yield put(updatePurchaseOrderDeliveryScheduleSuccess(data.schedule));
  } catch (error) {
    yield put(
      updatePurchaseOrderDeliveryScheduleFailure(
        "Update PO Delivery Schedule Failed",
      ),
    );
  }
}

export function* watchUpdatePurchaseOrderDeliverySchdeule() {
  yield takeLatest(
    PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE,
    updatePurchaseOrderDeliverySchdeule,
  );
}

export default function* purchaseOrderSaga() {
  yield all([
    watchGetPurchaseOrderDetail(),
    watchAddPurchaseRequestComment(),
    watchAddPurchaseRequestDeliveryPOD(),
    watchAddPurchaseOrderDeliverySchdeule(),
    watchDeletePurchaseOrderDeliverySchdeule(),
    watchUpdatePurchaseOrderDeliverySchdeule(),
  ]);
}
