import { FilterOptionSchema } from "../../components/Filter/filter.data";
import { ProductActions, ProductsActionTypes } from "./productTypes";

export interface ProductsState {
  products: FilterOptionSchema[];
  loading: boolean;
  error: string | null | undefined;
}

const initialState: ProductsState = {
  products: [],
  loading: false,
  error: null,
};

const productsReducer = (
  state = initialState,
  action: ProductsActionTypes,
): ProductsState => {
  switch (action.type) {
    case ProductActions.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ProductActions.FETCH_PRODUCTS_SUCCESS: {
      const payload = action.payload as FilterOptionSchema[];
      return {
        ...state,
        loading: false,
        products: payload,
        error: null,
      };
    }
    case ProductActions.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default productsReducer;
