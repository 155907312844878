import "./Vendor.scss";
import React, { useEffect, useMemo, useState } from "react";
import { VendorSchema } from "../../models/Vendor";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";
import {
  ScreenSizes,
  breakPointObserver,
} from "../../utils/BreakpointObserver";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import XetModal from "../../components/shared/xet-modal/XetModal";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import AddVendorDropdown from "../../components/AddVendorDropdown/AddVendorDropdown";
import { VENDOR_FORM_STATUS_MAP } from "../../data/VendorForm.data";

const Vendor: React.FC = () => {
  const navigate = useNavigate();
  const client = useSelector(userDetails);
  const [openAddVendorBox, setOpenAddVendorBox] = useState(false);
  const [vendors, setVendors] = useState<VendorSchema[]>([]);
  const [filteredVendors, setFilteredVendors] = useState<VendorSchema[]>([]);
  const [breakpoint, setBreakpoint] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchedText, setSearchedText] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [reloadVendors, setReloadVendors] = useState(false);
  const perPage = 20;

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  useEffect(() => {
    fetchVendors(searchedText, pageNumber).then((r) => r);
  }, [searchedText, pageNumber, reloadVendors]);

  const fetchVendors = async (search_text: string, page_number: number) => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDORS,
        {
          params: {
            client_id: client.company_id,
            search_text: search_text,
            pageNumber: page_number,
            perPage: perPage,
          },
        },
      );
      let vendorList: VendorSchema[] = page_number > 1 ? [...vendors] : [];
      if (data.vendors.length > 0) {
        vendorList = [...vendorList, ...data.vendors];
      }
      setPageNumber(pageNumber);
      setVendors(vendorList);
      setFilteredVendors(vendorList);

      if (vendorList.length == perPage * pageNumber) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      fetchVendors(searchText, 1).then((r) => {
        return r;
      });
    }, 500);
  }, []);

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    setPageNumber(1);
    debouncedSearch(searchText);
  };

  const handleOnAddVendors = (val: boolean) => {
    setReloadVendors(val);
    setOpenAddVendorBox(false);
  };

  const handleNavigationToDetail = (vendor: VendorSchema) => {
    if (vendor.vendor_id === "-") {
      return;
    }
    navigate(`/vendor/details/${vendor.vendor_id}`);
  };

  return (
    <div className="vendor-content-container">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">Vendors</p>
      </div>
      <div className="vendor-upper-container flex-column  gap-12 p-16">
        <div className="flex-row flex-row justify-content-space-between align-items-center ">
          <div className="search-bar-container">
            <input
              className="py-8 px-34 search-input border-box xetgo-font-tag"
              value={searchedText}
              onChange={handleSearchFile}
              placeholder="Search ..."
            />
            <img
              height={16}
              width={16}
              className="search-icon "
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
              alt="search-icon"
            />
          </div>

          <button
            onClick={() => setOpenAddVendorBox(true)}
            className="vendor-add-btn flex-row align-items-center cursor-pointer px-12 py-8 gap-10"
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231007090447-white_add.svg"
              alt="add-icon"
            />
            {[ScreenSizes.MINI_TABLET, ScreenSizes.MOBILE].includes(
              breakpoint as ScreenSizes,
            )
              ? ""
              : "Add Vendors"}
          </button>
          <XetModal
            backdropClose={true}
            open={openAddVendorBox}
            handleClose={() => setOpenAddVendorBox(false)}
          >
            <OutsideAlerter action={() => setOpenAddVendorBox(false)}>
              <AddVendorDropdown
                onClose={() => setOpenAddVendorBox(false)}
                onAddVendors={handleOnAddVendors}
              />
            </OutsideAlerter>
          </XetModal>
        </div>

        <div className="vendor-list-lower-container flex-1 flex-column p-16">
          <div className="vendor-row vendor-list-heading-container">
            <p className="vendor-heading-vendorId border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
              Vendor Id
            </p>
            <div className="vendor-row-right-box">
              <div className="vendor-heading-name border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
                  alt="list-icon"
                  height={12}
                  width={12}
                />
                <p>Name </p>
              </div>
              <div className="vendor-heading-vendorId border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703142823-Frame+1321314713.svg"
                  alt="barcode-icon"
                  height={12}
                  width={12}
                />
                <p>GST No.</p>
              </div>
              <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
                  alt="user-icon"
                  height={12}
                  width={12}
                />
                <p>POC </p>
              </div>
              <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center flex-1">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
                  alt="user-icon"
                  height={12}
                  width={12}
                />
                <p>Status</p>
              </div>
            </div>
          </div>
          <div id="scrollableDiv" className="vendor-list-scroll-wrapper flex-1">
            <InfiniteScroll
              dataLength={filteredVendors.length}
              next={() => console.log("get vendors next ")}
              hasMore={hasMore}
              loader={<h4></h4>}
              className="infiniteScrollComponent"
              scrollableTarget="scrollableDiv"
            >
              {filteredVendors.length > 0 &&
                filteredVendors.map((vendor, index) => {
                  return (
                    <div
                      onClick={() => handleNavigationToDetail(vendor)}
                      key={index}
                      className="vendor-row vendor-products-wrapper flex-row xetgo-font-tag"
                    >
                      <div className="py-8 px-4 vendor-border-right flex-row align-items-center ">
                        <p className="px-12 py-4  content-center gst-pill">
                          {vendor.uuid}
                        </p>
                      </div>
                      <div>
                        <div>
                          <div className="vendor-row-right-box">
                            <p className="vendor-name vendor-border-right border-box flex-row gap-8 p-12 xetgo-font-tag align-items-center bold">
                              {vendor.vendor_name}
                            </p>
                            <p
                              style={{ color: "#5151EC" }}
                              className="vendor-border-right flex-row align-items-center px-12 py-4"
                            >
                              <span
                                className="px-8 py-4"
                                style={{ color: "#aa5200" }}
                              >
                                {vendor.gst_no}
                              </span>
                            </p>

                            <div className="flex-1 p-12 description flex-row align-items-center justify-content-space-between vendor-border-right">
                              {vendor.company_poc.poc_name !== "-" ? (
                                <p
                                  style={{ color: "#1F1F1F" }}
                                  className="flex-row align-items-center gap-4 "
                                >
                                  <span className="bold">
                                    {vendor.company_poc.poc_name},
                                  </span>
                                  <span
                                    style={{ color: "#5A5A5A" }}
                                    className="bold"
                                  >
                                    {vendor.company_poc.poc_phone}
                                  </span>
                                </p>
                              ) : (
                                "-"
                              )}
                            </div>

                            <p className="status-pill flex-row align-items-center px-12 py-4 bold">
                              <span
                                className="px-12 py-4"
                                style={{
                                  color:
                                    VENDOR_FORM_STATUS_MAP[
                                      vendor.vendor_form_status
                                    ]?.color,
                                  background:
                                    VENDOR_FORM_STATUS_MAP[
                                      vendor.vendor_form_status
                                    ]?.backgroundColor,
                                  borderRadius: "8px",
                                }}
                              >
                                {
                                  VENDOR_FORM_STATUS_MAP[
                                    vendor.vendor_form_status
                                  ]?.name
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
