import React, { useEffect, useState } from "react";
import "./CustomQuestion.scss";

import Switch from "@mui/material/Switch";
import { FilterOptionSchema } from "../Filter/filter.data";
import { INPUT_TYPE } from "../../constants/QuestionFormData";
import Filter from "../Filter/Filter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { QuestionSchema } from "../../models/QuestionForm";

interface CustomQuestionProps {
  handleDeleteQuestion: (questionId: number) => void;
  question: QuestionSchema;
  handleQuestionValueChanges: (questionValues: QuestionSchema) => void;
  update?: boolean;
  handlePermanentQuestionDelete: (QuestionID: number) => void;
}
const CustomQuestion: React.FC<CustomQuestionProps> = ({
  handleDeleteQuestion,
  question,
  handleQuestionValueChanges,
  update,
  handlePermanentQuestionDelete,
}) => {
  const [questionDetail, setQuestionDetail] =
    useState<QuestionSchema>(question);
  const [selectedQuestionCategory, setSelectedQuestionCategory] =
    useState<FilterOptionSchema>(INPUT_TYPE[0]);
  const [questionOptionList, setQuestionOptionList] =
    useState<FilterOptionSchema[]>(INPUT_TYPE);
  const [isRequired, setIsRequired] = useState(questionDetail.isRequired);
  const [noCategory, setNoCategory] = useState(false);
  const [questionValue, setQuestionValue] = useState(
    questionDetail.question_value,
  );
  const [questionNumberValue, setQuestionNumberValue] = useState(
    questionDetail.question_value,
  );

  const [options, setOptions] = useState(questionDetail.options || []);

  useEffect(() => {
    const inputTypeIndex = INPUT_TYPE.findIndex(
      (input) => questionDetail.input_type === input.code,
    );

    if (inputTypeIndex !== -1) {
      setSelectedQuestionCategory(INPUT_TYPE[inputTypeIndex]);
    }
  }, []);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRequired(event.target.checked);
    handleQuestionValueChanges({
      ...questionDetail,
      question_value: questionValue,
      input_type: selectedQuestionCategory.code || "",
      isRequired: event.target.checked,
      options: options,
    });
  };

  const handleOptionChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newOptions = options.map((option, i) =>
      i === index ? { ...option, name: event.target.value || "" } : option,
    );

    setOptions(newOptions);

    handleQuestionValueChanges({
      ...questionDetail,
      question_value: questionValue,
      input_type: selectedQuestionCategory.code || "",
      isRequired: isRequired,
      options: newOptions,
    });
  };

  const addOption = () => {
    setOptions([...options, { name: `option ${options.length + 1}` }]);
  };

  return (
    <div className="custom-form-detail-container p-16 flex-row gap-12">
      {noCategory && <div className="custom-form-left-box flex-1"></div>}
      {/* text  */}
      {selectedQuestionCategory.id == 1 && (
        <div className="custom-form-left-box flex-1 flex-column gap-14">
          <input
            className="question-input p-12 xetgo-font-tag full-width border-box"
            value={questionValue}
            onChange={(e) => {
              setQuestionValue(e.target.value);
              handleQuestionValueChanges({
                ...questionDetail,
                question_value: e.target.value,
                input_type: selectedQuestionCategory.code || "",
                isRequired: isRequired,
              });
            }}
            placeholder="Enter Question"
          />
          <input
            className="answer-input p-12 xetgo-font-tag full-width border-box "
            value={""}
            placeholder="Enter Answer"
            disabled
          />
        </div>
      )}
      {/* number  */}
      {selectedQuestionCategory.id == 2 && !noCategory && (
        <div className="custom-form-left-box flex-1 flex-column gap-14">
          <input
            type="text"
            className="question-input p-12 xetgo-font-tag full-width border-box"
            value={questionNumberValue}
            onChange={(e) => {
              setQuestionNumberValue(e.target.value);
              handleQuestionValueChanges({
                ...questionDetail,
                question_value: e.target.value.toString(),
                input_type: selectedQuestionCategory.code || "",
                isRequired: isRequired,
              });
            }}
            placeholder="Enter Question"
          />
          <input
            className="answer-input p-12 xetgo-font-tag full-width border-box "
            value={""}
            placeholder="Enter Answer"
            disabled
          />
        </div>
      )}

      {/* checkbox  */}
      {selectedQuestionCategory.id == 3 && !noCategory && (
        <div className="custom-form-left-box flex-1 flex-column gap-14">
          <input
            className="question-input p-12 xetgo-font-tag full-width border-box"
            value={questionValue}
            onChange={(e) => {
              setQuestionValue(e.target.value);
              handleQuestionValueChanges({
                ...questionDetail,
                question_value: e.target.value,
                options: options,
                input_type: selectedQuestionCategory.code || "",
                isRequired: isRequired,
              });
            }}
            placeholder="Enter Question"
          />

          {options.map((option, optionIndex) => (
            <div
              key={optionIndex}
              className="flex-row align-items-center gap-10 options-container"
            >
              <input type="checkbox" className="check-box" disabled />
              <input
                type="text"
                value={option.name}
                onChange={(e) => handleOptionChange(optionIndex, e)}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  padding: 0,
                  width: "auto",
                }}
                className="xetgo-font-tag"
              />
            </div>
          ))}

          <div
            style={{ width: "fit-content" }}
            className="flex-row align-items-center gap-10 xetgo-font-tag cursor-pointer"
            onClick={addOption}
          >
            <p>Add more option</p>
            <img
              height={18}
              width={18}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826210154-Group+1171276096.svg"
              alt="add-circle-icon"
            />
          </div>
        </div>
      )}

      {/* radio button  */}
      {selectedQuestionCategory.id == 4 && !noCategory && (
        <div className="custom-form-left-box flex-1 flex-column gap-14">
          <input
            className="question-input p-12 xetgo-font-tag full-width border-box"
            value={questionValue}
            onChange={(e) => {
              setQuestionValue(e.target.value);
              handleQuestionValueChanges({
                ...questionDetail,
                question_value: e.target.value,
                options: options,
                input_type: selectedQuestionCategory.code || "",
                isRequired: isRequired,
              });
            }}
            placeholder="Enter Question"
          />

          {options.map((option, optionIndex) => (
            <div
              key={optionIndex}
              className="flex-row align-items-center gap-10 options-container"
            >
              <input type="radio" className="check-box radio-btn" disabled />
              <input
                type="text"
                value={option.name}
                onChange={(e) => {
                  handleOptionChange(optionIndex, e);
                }}
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent",
                  padding: 0,
                  width: "auto",
                }}
                className="xetgo-font-tag"
              />
            </div>
          ))}

          <div
            style={{ width: "fit-content" }}
            className="flex-row align-items-center gap-10 xetgo-font-tag cursor-pointer"
            onClick={addOption}
          >
            <p>Add more option</p>
            <img
              height={18}
              width={18}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826210154-Group+1171276096.svg"
              alt="add-circle-icon"
            />
          </div>
        </div>
      )}
      {/* single file  */}
      {selectedQuestionCategory.id == 5 && !noCategory && (
        <div className="custom-form-left-box flex-1 flex-column gap-14">
          <input
            className="question-input p-12 xetgo-font-tag full-width border-box"
            value={questionValue}
            onChange={(e) => {
              setQuestionValue(e.target.value);
              handleQuestionValueChanges({
                ...questionDetail,
                question_value: e.target.value,
                input_type: selectedQuestionCategory.code || "",
                isRequired: isRequired,
              });
            }}
            placeholder="Enter Question"
          />
          <input
            id="single-fileInput"
            name="file"
            type="File"
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
              display: "none",
            }}
            disabled={true}
          />
          <label
            htmlFor="single-fileInput"
            className="answer-input p-12 xetgo-font-tag full-width border-box "
          >
            <p className="xetgo-font-tag flex-row justify-content-space-between">
              <p>Upload File</p>
              <img
                className="upload-icon"
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                alt="upload-icon"
              />
            </p>
          </label>
        </div>
      )}
      {/* multi file  */}
      {selectedQuestionCategory.id == 6 && !noCategory && (
        <div className="custom-form-left-box flex-1 flex-column gap-14">
          <input
            className="question-input p-12 xetgo-font-tag full-width border-box"
            value={questionValue}
            onChange={(e) => {
              setQuestionValue(e.target.value);
              handleQuestionValueChanges({
                ...questionDetail,
                question_value: e.target.value,
                input_type: selectedQuestionCategory.code || "",
                isRequired: isRequired,
              });
            }}
            placeholder="Enter Question"
          />
          <input
            id="multiple-fileInput"
            name="file"
            type="File"
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
              display: "none",
            }}
            disabled={true}
            multiple
          />
          <label
            htmlFor="multiple-fileInput"
            className="answer-input p-12 xetgo-font-tag full-width border-box "
          >
            <p className="xetgo-font-tag flex-row justify-content-space-between">
              <p>Upload File</p>
              <img
                className="upload-icon"
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                alt="upload-icon"
              />
            </p>
          </label>
        </div>
      )}

      {/* date time  */}
      {selectedQuestionCategory.id == 7 && !noCategory && (
        <div className="custom-form-left-box flex-1 flex-column gap-14">
          <input
            className="question-input p-12 xetgo-font-tag full-width border-box"
            value={questionValue}
            onChange={(e) => {
              setQuestionValue(e.target.value);
              handleQuestionValueChanges({
                ...questionDetail,
                question_value: e.target.value,
                input_type: selectedQuestionCategory.code || "",
                isRequired: isRequired,
              });
            }}
            placeholder="Enter Question"
          />

          <div className="custom-question-date-value xetgo-font-tag">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="answer-input p-12 xetgo-font-tag full-width border-box "
                value={dayjs("".split("-").reverse().join("-"))}
                format={"DD-MM-YYYY"}
                views={["year", "month", "day"]}
                // onChange={(event) =>
                //   handleDateChange(event?.toDate().toISOString())
                // }
                disabled
              />
            </LocalizationProvider>
          </div>
        </div>
      )}

      <div className="custom-form-right-box flex-column gap-24">
        <Filter
          option={{
            key: "question_category_type",
            name: "Question Category",
          }}
          optionList={questionOptionList}
          multipleSelection={false}
          onSelection={(val, _option) => {
            if (val.length <= 0) {
              setNoCategory(true);
              handleQuestionValueChanges({
                ...questionDetail,
                input_type: "text",
                isRequired: false,
              });
            } else {
              setNoCategory(false);
              setSelectedQuestionCategory(val[0]);
              setQuestionValue("");
              setQuestionNumberValue("");
              handleQuestionValueChanges({
                ...questionDetail,
                input_type: val[0].code || "",
                isRequired: isRequired,
              });
              setOptions([{ name: "option 1" }, { name: "option 2" }]);
            }
          }}
          preSelected={[selectedQuestionCategory]}
          mode="showNames"
          showPlaceholder={true}
          direction="right"
          addNew={false}
          classname="category-filter-input"
          sort={true}
        />

        <div className="flex-row align-items-center gap-18 xetgo-font-tag bold py-3 px-6">
          <div className="flex-row align-items-center gap-14">
            <p>Required</p>
            <Switch
              checked={isRequired}
              onChange={handleSwitchChange}
              size="small"
            />
          </div>
          {update ? (
            <img
              onClick={() => handlePermanentQuestionDelete(question.id)}
              className="cursor-pointer"
              height={22}
              width={22}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821104010-trash-2.svg"
              alt="trash-icon"
            />
          ) : (
            <img
              onClick={() => handleDeleteQuestion(question.id)}
              className="cursor-pointer"
              height={22}
              width={22}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821104010-trash-2.svg"
              alt="trash-icon"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomQuestion;
