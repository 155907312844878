import React, { useState } from "react";
import "./PreDefinedVendorForm.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface PredefinedProps {
  inputDisabled: boolean;
}
const PreDefinedVendorForm: React.FC<PredefinedProps> = ({ inputDisabled }) => {
  const [organizationName, setOrganizationName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [pocName, setPocName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedGstFile, setSelectedGstFile] = useState<File | null>(null);
  const [selectedPanFile, setSelectedPanFile] = useState<File | null>(null);
  const [selectedMachineryFile, setSelectedMachineryFile] = useState<
    File[] | null
  >(null);
  const [selectedFactoryFiles, setSelectedFactoryFiles] = useState<
    File[] | null
  >(null);
  const [category, setCategory] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [tradeLicenseNumber, setTradeLicenseNumber] = useState("");
  const [selectedtradeLicenseFile, setSelectedtradeLicenseFile] =
    useState<File | null>(null);

  const [expiryDate, setExpiryDate] = useState("");
  const [issueDate, setIssueDate] = useState("");

  const [directorName, setDirectorName] = useState("");
  const [directorEmail, setDirectorEmail] = useState("");

  const handleGSTFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedGstFile(event.target.files[0]);
    }
  };

  const handlePanFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedPanFile(event.target.files[0]);
    }
  };

  const handleMachineryFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setSelectedMachineryFile(files);
    }
  };

  const handleFactoryImagesFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setSelectedFactoryFiles(files);
    }
  };
  const handleTradeLicenseFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedtradeLicenseFile(event.target.files[0]);
    }
  };

  return (
    <div className="predefined-vendor-form-main-container flex-column gap-16">
      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-12">
        <p style={{ fontWeight: "300" }} className="xetgo-font-button">
          1.{" "}
          <span>
            Organization Name <span style={{ color: "red" }}>*</span>
          </span>
        </p>
        <input
          className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
            inputDisabled && "disabled-predefiner-input "
          }`}
          value={organizationName}
          onChange={(e) => setOrganizationName(e.target.value)}
          placeholder="Enter text"
          disabled={inputDisabled}
        />
      </div>

      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-12">
        <p style={{ fontWeight: "300" }} className="xetgo-font-button">
          2.{" "}
          <span>
            Owner Name <span style={{ color: "red" }}>*</span>
          </span>
        </p>
        <input
          className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
            inputDisabled && "disabled-predefiner-input "
          }`}
          value={ownerName}
          onChange={(e) => setOwnerName(e.target.value)}
          placeholder="Type Name "
          disabled={inputDisabled}
        />
      </div>
      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-20">
        <div className="flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            3. <span>Address</span>
          </p>
          <input
            className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
              inputDisabled && "disabled-predefiner-input "
            }`}
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
            placeholder="Type Name "
            disabled={inputDisabled}
          />
        </div>
        <div className="flex-row gap-36">
          <div className="flex-1 flex-column gap-12">
            <p style={{ fontWeight: "300" }} className="xetgo-font-button">
              <span>
                Country <span style={{ color: "red" }}>*</span>
              </span>
            </p>
            <input
              className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
                inputDisabled && "disabled-predefiner-input "
              }`}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Enter text "
              disabled={inputDisabled}
            />
          </div>
          <div className="flex-1 flex-column gap-12">
            <p style={{ fontWeight: "300" }} className="xetgo-font-button">
              <span>
                State <span style={{ color: "red" }}>*</span>
              </span>
            </p>
            <input
              className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
                inputDisabled && "disabled-predefiner-input "
              }`}
              value={state}
              onChange={(e) => setState(e.target.value)}
              placeholder="Enter text"
              disabled={inputDisabled}
            />
          </div>
        </div>
        <div className="flex-row gap-36">
          <div className="flex-1 flex-column gap-12">
            <p style={{ fontWeight: "300" }} className="xetgo-font-button">
              <span>
                Person of Contact (POC) <span style={{ color: "red" }}>*</span>
              </span>
            </p>
            <input
              className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
                inputDisabled && "disabled-predefiner-input "
              }`}
              value={pocName}
              onChange={(e) => setPocName(e.target.value)}
              placeholder="Enter text"
              disabled={inputDisabled}
            />
          </div>
          <div className="flex-1 flex-column gap-12">
            <p style={{ fontWeight: "300" }} className="xetgo-font-button">
              <span>
                POC Number <span style={{ color: "red" }}>*</span>
              </span>
            </p>
            <input
              type="number"
              className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
                inputDisabled && "disabled-predefiner-input "
              }`}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Enter Number "
              disabled={inputDisabled}
            />
          </div>
        </div>
        <div className="flex-row align-items-center justify-content-flex-end">
          <div
            className={`flex-row align-items-center xetgo-font-button gap-8 cursor-pointer  ${
              inputDisabled && "disabled-predefiner-input"
            }`}
          >
            <p>Add more Address</p>
            <img
              height={18}
              width={18}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826210154-Group+1171276096.svg"
              alt="add-icon"
            />
          </div>
        </div>
      </div>

      <div className="flex-row gap-14">
        <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-1 flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            4.{" "}
            <span>
              GSTIN <span style={{ color: "red" }}>*</span>
            </span>
          </p>
          <input
            className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
              inputDisabled && "disabled-predefiner-input "
            }`}
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            placeholder="Enter text"
            disabled={inputDisabled}
          />
        </div>
        <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-1 flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            5.{" "}
            <span>
              GSTIN File <span style={{ color: "red" }}>*</span>
            </span>
          </p>

          <input
            onChange={(event) => {
              handleGSTFileChange(event);
            }}
            id="gst-fileInput"
            name="file"
            type="File"
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
              display: "none",
            }}
            multiple={false}
            disabled={inputDisabled}
          />
          <label
            htmlFor="gst-fileInput"
            className={`edit-field-value file-label px-12 py-8 flex-row align-items-center cursor-pointer  ${
              inputDisabled && "disabled-predefiner-input "
            }`}
          >
            <p className="xetgo-font-button ">
              {selectedGstFile ? selectedGstFile.name : "Upload GSTIN File"}
              <img
                className="upload-icon"
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                alt="upload-icon"
              />
            </p>
          </label>
        </div>
      </div>

      <div className="flex-row gap-14">
        <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-1 flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            6.{" "}
            <span>
              PAN <span style={{ color: "red" }}>*</span>
            </span>
          </p>
          <input
            className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
              inputDisabled && "disabled-predefiner-input "
            }`}
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            placeholder="Enter text"
            disabled={inputDisabled}
          />
        </div>
        <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-1 flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            7.{" "}
            <span>
              PAN File <span style={{ color: "red" }}>*</span>
            </span>
          </p>

          <input
            onChange={(event) => {
              handlePanFileChange(event);
            }}
            id="pan-fileInput"
            name="file"
            type="File"
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
              display: "none",
            }}
            multiple={false}
            disabled={inputDisabled}
          />
          <label
            htmlFor="pan-fileInput"
            className={`edit-field-value file-label px-12 py-8 flex-row align-items-center cursor-pointer  ${
              inputDisabled && "disabled-predefiner-input "
            }`}
          >
            <p className="xetgo-font-button ">
              {selectedPanFile ? selectedPanFile.name : "Upload PAN File"}
              <img
                className="upload-icon"
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                alt="upload-icon"
              />
            </p>
          </label>
        </div>
      </div>

      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-12">
        <p style={{ fontWeight: "300" }} className="xetgo-font-button">
          8.{" "}
          <span>
            Categories <span style={{ color: "red" }}>*</span>
          </span>
        </p>
        <input
          className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
            inputDisabled && "disabled-predefiner-input "
          }`}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Type category"
          disabled={inputDisabled}
        />
      </div>

      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-1 flex-column gap-12">
        <p style={{ fontWeight: "300" }} className="xetgo-font-button">
          9.{" "}
          <span>
            Select Machinery <span style={{ color: "red" }}>*</span>
          </span>
        </p>

        <input
          onChange={(event) => {
            handleMachineryFileChange(event);
          }}
          id="machinery-fileInput"
          name="file"
          type="File"
          style={{
            position: "absolute",
            top: "12px",
            right: "12px",
            display: "none",
          }}
          disabled={inputDisabled}
        />
        <label
          htmlFor="machinery-fileInput"
          className={`edit-field-value file-label px-12 py-8 flex-row align-items-center cursor-pointer  ${
            inputDisabled && "disabled-predefiner-input "
          }`}
        >
          <p className="xetgo-font-button ">
            <p>
              {selectedMachineryFile && selectedMachineryFile.length > 0
                ? selectedMachineryFile.map((file, index) => (
                    <span key={index}>
                      {file.name}
                      {index < selectedMachineryFile.length - 1 ? ", " : ""}
                    </span>
                  ))
                : "Upload  Files"}
            </p>
            <img
              className="upload-icon"
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
              alt="upload-icon"
            />
          </p>
        </label>
      </div>

      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-12">
        <p style={{ fontWeight: "300" }} className="xetgo-font-button">
          10.{" "}
          <span>
            Upload Factory Images <span style={{ color: "red" }}>*</span>
          </span>
        </p>
        <div
          className={`upload-factory-image-container p-16 flex-column py-27 align-items-center justify-content-center gap-12 ${
            inputDisabled && "disabled-predefiner-input "
          }`}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826215215-Upload+icon.svg"
            width={46}
            height={40}
            alt="cloud-upload"
          />
          <input
            type="file"
            id="select-file"
            onChange={(e) => handleFactoryImagesFileChange(e)}
            multiple
            style={{ display: "none" }}
            accept=".jpg, .png, .jpeg, .gif, .mp4, .pdf, .psd, .ai, .doc, .docx, .ppt, .pptx"
            disabled={inputDisabled}
          />
          <div className="flex-column gap-4 align-items-center">
            <label htmlFor="select-file" className="select-file-label">
              <div
                className={`xetgo-font-button bolder cursor-pointer ${
                  inputDisabled && "disabled-predefiner-input "
                }`}
                style={{ color: "#5151EC" }}
              >
                Drag & drop files or Browse
              </div>
            </label>
            <p className="xetgo-font-button" style={{ color: "#475467" }}>
              Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
            </p>
          </div>
        </div>
      </div>

      <div className="flex-row gap-14">
        <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-1 flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            11.{" "}
            <span>
              Business/Company Type <span style={{ color: "red" }}>*</span>
            </span>
          </p>
          <input
            className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
              inputDisabled && "disabled-predefiner-input "
            }`}
            value={companyType}
            onChange={(e) => setCompanyType(e.target.value)}
            placeholder="Enter text"
            disabled={inputDisabled}
          />
        </div>
        <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-1 flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            12.{" "}
            <span>
              Number of Employees <span style={{ color: "red" }}>*</span>
            </span>
          </p>
          <input
            type="number"
            className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
              inputDisabled && "disabled-predefiner-input "
            }`}
            value={employeeCount}
            onChange={(e) => setEmployeeCount(e.target.value)}
            placeholder="Enter Number"
            disabled={inputDisabled}
          />
        </div>
      </div>

      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-20">
        <div className="flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            13.{" "}
            <span>
              Trade License Number <span style={{ color: "red" }}>*</span>
            </span>
          </p>
          <input
            className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
              inputDisabled && "disabled-predefiner-input "
            }`}
            value={tradeLicenseNumber}
            onChange={(e) => setTradeLicenseNumber(e.target.value)}
            placeholder="Type Name "
            disabled={inputDisabled}
          />
        </div>
        <div className="flex-row gap-36">
          <div className="flex-1 flex-column gap-12">
            <p style={{ fontWeight: "300" }} className="xetgo-font-button">
              <span>
                Issue Date <span style={{ color: "red" }}>*</span>
              </span>
            </p>
            <div className="tl-issue-date-value xetgo-font-button">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="edit-field-value "
                  value={dayjs(issueDate || "".split("-").reverse().join("-"))}
                  disablePast
                  format={"DD-MM-YYYY"}
                  views={["year", "month", "day"]}
                  onChange={(event) => {
                    if (event) {
                      setIssueDate(event?.toDate().toISOString());
                    }
                  }}
                  disabled={inputDisabled}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="flex-1 flex-column gap-12">
            <p style={{ fontWeight: "300" }} className="xetgo-font-button">
              <span>
                Expiry Date <span style={{ color: "red" }}>*</span>
              </span>
            </p>

            <div className="tl-issue-date-value xetgo-font-button">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="edit-field-value "
                  value={dayjs(expiryDate || "".split("-").reverse().join("-"))}
                  disablePast
                  format={"DD-MM-YYYY"}
                  views={["year", "month", "day"]}
                  onChange={(event) => {
                    if (event) {
                      setExpiryDate(event?.toDate().toISOString());
                    }
                  }}
                  disabled={inputDisabled}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className="predefined-form-info-main-container xetgo-font-button flex-column gap-12">
          <p style={{ fontWeight: "300" }} className="xetgo-font-button">
            <span>
              Upload Trade License <span style={{ color: "red" }}>*</span>
            </span>
          </p>

          <input
            onChange={(event) => {
              handleTradeLicenseFileChange(event);
            }}
            id="tradeLicense-fileInput"
            name="file"
            type="File"
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
              display: "none",
            }}
            disabled={inputDisabled}
          />
          <label
            htmlFor="tradeLicense-fileInput"
            className={`edit-field-value file-label px-12 py-8 flex-row align-items-center cursor-pointer  ${
              inputDisabled && "disabled-predefiner-input "
            }`}
          >
            <p className="xetgo-font-button ">
              <p>
                {selectedtradeLicenseFile
                  ? selectedtradeLicenseFile.name
                  : "Upload  Files"}
              </p>
              <img
                className="upload-icon"
                height={16}
                width={16}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                alt="upload-icon"
              />
            </p>
          </label>
        </div>
      </div>

      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-12">
        <p style={{ fontWeight: "300" }} className="xetgo-font-button">
          14.{" "}
          <span>
            Director Name <span style={{ color: "red" }}>*</span>
          </span>
        </p>
        <input
          className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
            inputDisabled && "disabled-predefiner-input "
          }`}
          value={directorName}
          onChange={(e) => setDirectorName(e.target.value)}
          placeholder="Enter text"
          disabled={inputDisabled}
        />
      </div>
      <div className="predefined-form-info-main-container xetgo-font-button p-20 flex-column gap-12">
        <p style={{ fontWeight: "300" }} className="xetgo-font-button">
          16.{" "}
          <span>
            Director Email <span style={{ color: "red" }}>*</span>
          </span>
        </p>
        <input
          className={`pre-defined-vendor-detail-form-input p-12 xetgo-font-button full-width border-box ${
            inputDisabled && "disabled-predefiner-input "
          }`}
          value={directorEmail}
          onChange={(e) => setDirectorEmail(e.target.value)}
          placeholder="Enter email"
          disabled={inputDisabled}
        />
      </div>
    </div>
  );
};

export default PreDefinedVendorForm;
