import React, { useEffect, useState, useRef } from "react";
import "./CreateVendorForm.scss";
import VendorFormDetail from "../../components/VendorFormDetail/VendorFormDetail";
import CustomQuestionForm from "../../components/CustomQuestionForm/CustomQuestionForm";
import VendorFormReview from "../../components/VendorFormReview/VendorFormReview";
import { useLocation, useNavigate } from "react-router-dom";
import { QuestionSchema } from "../../models/QuestionForm";
import { INPUT_TYPE } from "../../constants/QuestionFormData";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

interface VendorFormProps {
  active?: number;
  update?: boolean;
  vendorFormId?: number;
}

const CreateVendorForm: React.FC<VendorFormProps> = () => {
  const location = useLocation();
  const { update, active, vendorFormId } = location.state || {};

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(active || 0);
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [allQuestions, setAllQuestions] = useState<QuestionSchema[]>([
    {
      id: 1,
      input_type: INPUT_TYPE[0].name,
      question_value: "",
      isRequired: false,
    },
  ]);
  const containerRef = useRef<HTMLDivElement>();

  const getVendorFormdetail = async (vendorFormId: number) => {
    const { data } = await ApiService().client.get(
      URLS.FORMS.GET_VENDOR_FORM_DETAIL(Number(vendorFormId)),
    );

    setAllQuestions(data.response.questions);
  };

  useEffect(() => {
    if (update) {
      getVendorFormdetail(vendorFormId || -1);
    }
  }, []);
  // functions below

  const handleAddNewQuestion = () => {
    setAllQuestions([
      ...allQuestions,
      {
        id: allQuestions.length + 1,
        input_type: INPUT_TYPE[0].name,
        question_value: "",
        isRequired: false,
      },
    ]);
  };

  const handleDeleteQuestion = (questionId: number) => {
    setAllQuestions((prevQuestions) =>
      prevQuestions.filter((question) => question.id !== questionId),
    );
  };

  const handleQuestionValueChanges = (questionValues: QuestionSchema) => {
    const updatedQuestionsWithValues = allQuestions.map((question) => {
      if (question.id === questionValues.id) {
        const updatedQuestion = {
          id: questionValues.id,
          input_type: questionValues.input_type,
          question_value: questionValues.question_value,
          isRequired: questionValues.isRequired,
          options:
            questionValues.input_type === "checkbox" ||
            questionValues.input_type === "radio_button"
              ? questionValues.options
              : undefined,
        };
        return updatedQuestion;
      }
      return question;
    });

    setAllQuestions(updatedQuestionsWithValues);
  };

  const payloadConversion = async (questions: QuestionSchema[]) => {
    return questions.map((question) => {
      const options = question.options?.map((opt) => opt.name) || [];

      return {
        question: question.question_value,
        input_type: question.input_type,
        options: options,
        required: question.isRequired,
      };
    });
  };
  const handleSubmitData = async () => {
    const questions = await payloadConversion(allQuestions);
    const { data } = await ApiService().client.post(
      URLS.FORMS.CREATE_VENDOR_FORM,
      {
        title: formTitle,
        description: formDescription,
        questions: questions,
        form_status: "PUBLISHED",
      },
    );
  };

  const handleBack = () => {
    if (activeStep > 0) {
      if (update && activeStep === 1) {
        navigate("/forms");
        return;
      }
      setActiveStep(activeStep - 1);
      containerRef?.current?.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleNext = () => {
    if (activeStep === 1 && allQuestions[0].question_value.length <= 0) {
      return;
    }
    if (activeStep < 2) {
      setActiveStep(activeStep + 1);
      containerRef?.current?.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleUpdate = () => {
    console.log("UPDATEE API NEED CALLING");
  };
  const handlePermanentQuestionDelete = async (questionID: number) => {
    try {
      // const { data } = await ApiService().client.delete(
      //   URLS.SKU.UPDATE_SKU_QUALITY_CHECK_TEST(questionID)
      // );
      setAllQuestions((prevQuestions) =>
        prevQuestions.filter((question) => question.id !== questionID),
      );
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };
  return (
    <div className="create-vendor-form-page flex-column full-width ">
      {!update && (
        <div className="upper-bar">
          <p
            onClick={() => navigate("/forms")}
            className="xetgo-font-tag bold px-16 py-12 cursor-pointer"
          >
            VENDORS{" "}
            <span style={{ color: "#7B7D84" }}>/ Create Vendor Form</span>
          </p>
        </div>
      )}
      <div className="vendor-main-form-container full-width flex-column flex-1 overflow-scroll">
        <div className="xet-form-upper-container flex-row align-items-center justify-content-center px-27 py-14 gap-16">
          <div className="left-box flex-row gap-16 align-items-center justify-content-center">
            {!update && (
              <>
                {" "}
                <div className="step-detail-container flex-row gap-8 xetgo-font-tag">
                  <p
                    className={`form-step flex-row align-items-center justify-content-center p-2 border-box ${
                      activeStep === 0 && "form-step-active"
                    }`}
                  >
                    1
                  </p>
                  <p className={`${activeStep === 0 && "title-active"}`}>
                    Form Details & Predefined Questions
                  </p>
                </div>
                <img
                  height={32}
                  width={10}
                  src={
                    activeStep === 0
                      ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826093549-Vector+2.svg"
                      : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826093549-Vector+3.svg"
                  }
                  alt="side-arrow"
                />{" "}
              </>
            )}
            <div className="step-detail-container flex-row gap-8 xetgo-font-tag">
              <p
                className={`form-step flex-row align-items-center justify-content-center p-2 border-box ${
                  activeStep === 1 && "form-step-active"
                }`}
              >
                {update ? 1 : 2}
              </p>
              <p className={`${activeStep === 1 && "title-active"}`}>
                Add Custom Questions
              </p>
            </div>
            <img
              height={32}
              width={10}
              src={
                activeStep === 1
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826093549-Vector+2.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826093549-Vector+3.svg"
              }
              alt="side-arrow"
            />
            <div className="step-detail-container flex-row gap-8 xetgo-font-tag">
              <p
                className={`form-step flex-row align-items-center justify-content-center p-2 border-box ${
                  activeStep === 2 && "form-step-active"
                }`}
              >
                {update ? 2 : 3}
              </p>
              <p className={`${activeStep === 2 && "title-active"}`}>
                Review & Publish
              </p>
            </div>
          </div>
        </div>
        <div
          className="component-wrapper-container flex-1 p-16 overflow-scroll"
          id="vendor-create-form-container"
        >
          {activeStep === 0 && (
            <VendorFormDetail
              formTitle={formTitle}
              setFormTitle={setFormTitle}
              formDescription={formDescription}
              setFormDescription={setFormDescription}
            />
          )}
          {activeStep === 1 && (
            <CustomQuestionForm
              allQuestions={allQuestions}
              handleAddNewQuestion={handleAddNewQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              handleQuestionValueChanges={handleQuestionValueChanges}
              update={update}
              handlePermanentQuestionDelete={handlePermanentQuestionDelete}
            />
          )}
          {activeStep === 2 && (
            <VendorFormReview
              allQuestions={allQuestions}
              formTitle={formTitle}
              formDescription={formDescription}
            />
          )}
          <div className="buttons-container flex-row gap-12  justify-content-space-between align-items-center xetgo-font-tag">
            <div onClick={handleBack} className="back-btn cursor-pointer py-8">
              Back
            </div>
            {activeStep === 2 ? (
              <div
                onClick={update ? handleUpdate : handleSubmitData}
                className="back-btn next-btn cursor-pointer py-12"
              >
                {update ? "Update & Publish" : "Publish"}
              </div>
            ) : (
              <div
                onClick={handleNext}
                className={`back-btn next-btn cursor-pointer py-8 ${
                  allQuestions[0].question_value.length <= 0 &&
                  activeStep == 1 &&
                  "inactive-next"
                }`}
              >
                Next
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateVendorForm;
