import React, { useState } from "react";
import "./CustomQuestionForm.scss";
import Filter from "../Filter/Filter";
import { INPUT_TYPE } from "../../constants/QuestionFormData";
import Switch from "@mui/material/Switch";
import { FilterOptionSchema } from "../Filter/filter.data";
import CustomQuestion from "../CustomQuestion/CustomQuestion";
import { QuestionSchema } from "../../models/QuestionForm";

interface CustomQuestionFormProps {
  allQuestions: QuestionSchema[];
  handleAddNewQuestion: () => void;
  handleDeleteQuestion: (questionId: number) => void;
  handleQuestionValueChanges: (questionValues: QuestionSchema) => void;
  update?: boolean;
  handlePermanentQuestionDelete: (questionID: number) => void;
}

const CustomQuestionForm: React.FC<CustomQuestionFormProps> = ({
  allQuestions,
  handleAddNewQuestion,
  handleDeleteQuestion,
  handleQuestionValueChanges,
  update,
  handlePermanentQuestionDelete,
}) => {
  return (
    <div className="custom-question-form-main-page flex-column">
      <div className="custom-question-form-main-container p-18 flex-column gap-18 border-box flex-1">
        <div className="flex-row align-items-center justify-content-space-between">
          <p className="bolder xetgo-font-button">Add a Custom Question</p>
          <div
            onClick={handleAddNewQuestion}
            className="add-question-btn flex-row gap-10 cursor-pointer align-items-center py-8 px-12 border-box xetgo-font-tag bold"
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618101041-plus_white.svg"
              alt="add-icon"
              width={12}
              height={12}
            />
            <span className="">Add Question</span>
          </div>
        </div>
        {allQuestions.map((question, questionIndex) => (
          <CustomQuestion
            key={question.id}
            question={question}
            handleDeleteQuestion={handleDeleteQuestion}
            handleQuestionValueChanges={handleQuestionValueChanges}
            update={update}
            handlePermanentQuestionDelete={handlePermanentQuestionDelete}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomQuestionForm;
