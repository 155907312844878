import { OptionListSchema } from "../models/OptionListSchema";

export const QUOTE_OPTION_DATA: OptionListSchema[] = [
  {
    id: 1,
    name: "Raise Enquiry",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212091539-file-box-grey.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129112554-file-box.svg",
    tabOptionName: "raiseEnquiry",
    redirectionUrl: "/quote/raise",
  },
  {
    id: 2,
    name: "Status",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212093042-activity.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129144253-activity+%281%29.svg",
    tabOptionName: "status",
    redirectionUrl: "/quotes",
  },
];

export const PURCHASE_ORDER_OPTION_DATA: OptionListSchema[] = [
  {
    id: 1,
    name: "Order",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212093013-book-open-check.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129144440-book-open-check+%281%29.svg",
    tabOptionName: "order",
    redirectionUrl: "/purchase-order/orders",
  },
  {
    id: 2,
    name: "Track",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212093042-activity.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129144253-activity+%281%29.svg",
    tabOptionName: "status",
    redirectionUrl: "/purchase-orders",
  },
];

export const VENDOR_OPTION_DATA: OptionListSchema[] = [
  {
    id: 1,
    name: "Forms",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212093013-book-open-check.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129144440-book-open-check+%281%29.svg",
    tabOptionName: "forms",
    redirectionUrl: "/forms",
  },
  {
    id: 2,
    name: "Manage",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212093042-activity.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129144253-activity+%281%29.svg",
    tabOptionName: "manage",
    redirectionUrl: "/vendors",
  },
];

export const SIDEBAR_DATA = [
  {
    name: "SKU",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240417144546-list-outline.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240417144546-list-outline.svg",
    tabName: "sku",
    redirectionUrl: "/skus",
    options: [],
  },

  {
    name: "Vendors",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826080435-hammer-outline.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240826080435-hammer-outline.svg",
    tabName: "vendors",
    redirectionUrl: "/vendors",
    options: VENDOR_OPTION_DATA,
  },
  {
    name: "Quote",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131090019-cube-outline.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212092652-cube-white.svg",
    tabName: "quotes",
    options: QUOTE_OPTION_DATA,
    redirectionUrl: "-",
  },
  {
    name: "Purchase Order",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131090225-cart-outline.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212092652-cart-white.svg",
    tabName: "purchaseOrder",
    redirectionUrl: "-",
    options: PURCHASE_ORDER_OPTION_DATA,
  },

  {
    name: "Delivery Calendar",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-calendar.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-calendar.svg",
    tabName: "deliveryCalendar",
    redirectionUrl: "/delivery-calendar",
    options: [],
  },
  {
    name: "Quality Check",
    iconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240730112901-traffic-cone.svg",
    activeIconUrl:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240730112901-traffic-cone.svg",
    tabName: "qualityCheck",
    redirectionUrl: "/quality-checks",
    options: [],
  },
];
