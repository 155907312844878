import { FilterOptionSchema } from "../../components/Filter/filter.data";

export const ProductActions = {
  FETCH_PRODUCTS_REQUEST: "FETCH_PRODUCTS_REQUEST",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_FAILURE: "FETCH_PRODUCTS_FAILURE",
};

export interface FetchProductsAction {
  type: typeof ProductActions.FETCH_PRODUCTS_REQUEST;
  payload?: {
    search: string;
  };
  error?: string | null;
}

export interface FetchProductsSuccessAction {
  type: typeof ProductActions.FETCH_PRODUCTS_SUCCESS;
  payload: FilterOptionSchema[];
  error?: string | null;
}

export interface FetchProductsFailureAction {
  type: typeof ProductActions.FETCH_PRODUCTS_FAILURE;
  payload?: null;
  error?: string | null;
}

export type ProductsActionTypes =
  | FetchProductsAction
  | FetchProductsSuccessAction
  | FetchProductsFailureAction;
