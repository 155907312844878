import React, { useEffect, useState } from "react";
import "./SkuForm.scss";
import DeliveryLocationSelector from "../DeliveryLocationSelector/DeliveryLocationSelector";
import { useSelector } from "react-redux";
import { getUserWarehouseAddresses } from "../../redux/user/userSelector";
import { toastOptions } from "../../utils/toast";
import { toast } from "react-toastify";
import ApiService from "../../services/apiService";
import environment from "../../environments/environment";
import { FilterOptionSchema } from "../Filter/filter.data";
import TagsDropdown from "../TagsDropdown/TagsDropdown";
import URLS from "../../urls/Urls";
import { useNavigate } from "react-router-dom";

interface FileObject {
  file: File;
  preview: string;
}

const SkuForm: React.FC = () => {
  const navigate = useNavigate();
  const addresses = useSelector(getUserWarehouseAddresses);

  const [skuName, setSkuName] = useState("");
  const [message, setMessage] = useState("");
  const [measurementUnit, setMeasurementUnit] = useState("");

  const [artworkFiles, setArtworkFiles] = useState<File[]>([]);
  const [artworkDisplayNames, setArtworkDisplayNames] = useState<string[]>([]);
  const [fileList, setFileList] = useState<FileObject[]>([]);
  const [displayNames, setDisplayNames] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<number[]>([]);
  const [selectedSkuTags, setSelectedSkuTags] = useState<
    FilterOptionSchema[] | []
  >([]);
  const [skuTags, setSkuTags] = useState<FilterOptionSchema[] | []>();
  const [filterSkuTags, setFilterSkuTags] = useState<FilterOptionSchema[] | []>(
    [],
  );

  useEffect(() => {
    return () => {
      fileList.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [fileList]);

  useEffect(() => {
    getAllTags();
  }, []);

  const getAllTags = async () => {
    try {
      const { data } = await ApiService().client.get(
        `${environment.baseUrl}/pacpro/sku/tags`,
      );
      setSkuTags(data.tags);
      setFilterSkuTags(data.tags);
    } catch (error) {
      console.error("Error fetching tags:", error);
      return [];
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadType: string,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });

      if (uploadType === "artwork") {
        setArtworkFiles((prevState) => [...prevState, ...fileList]);
        setArtworkDisplayNames((prevState) => [
          ...prevState,
          ...displayNameList,
        ]);
      } else {
        const updatedFileList = files.map((file) => {
          return {
            file,
            preview: URL.createObjectURL(file),
          };
        });
        setFileList((prevFileList) => [...prevFileList, ...updatedFileList]);
      }
    }
  };

  const removeFile = (index: number, fileType: string) => {
    if (fileType === "artwork") {
      setArtworkFiles((prevFileList) =>
        prevFileList.filter((_, i) => i !== index),
      );
      setArtworkDisplayNames((prevDisplayNames) =>
        prevDisplayNames.filter((_, i) => i !== index),
      );
    } else {
      setFileList((prevFileList) => prevFileList.filter((_, i) => i !== index));
      setDisplayNames((prevDisplayNames) =>
        prevDisplayNames.filter((_, i) => i !== index),
      );
    }
  };
  const addAddress = (id: number) => {
    if (selectedAddress.indexOf(id) > -1) {
      setSelectedAddress((val) => val.filter((row) => row !== id));
    } else {
      setSelectedAddress((val) => [...val, id]);
    }
  };
  const handleTagAddition = (tagData: FilterOptionSchema[]) => {
    setSelectedSkuTags(tagData);
  };

  const handleCreateSku = async () => {
    if (skuName.length <= 0) {
      toast.error("Add SKU name !", toastOptions);
      return;
    } else if (message.length <= 0) {
      toast.error("Add message !", toastOptions);
      return;
    } else if (selectedAddress.length <= 0) {
      toast.error("Select address !", toastOptions);
      return;
    } else if (measurementUnit.length <= 0) {
      toast.error("Add measurement unit !", toastOptions);
      return;
    }

    try {
      const tagIds = selectedSkuTags.map((tag) => tag.id);
      const formData = new FormData();
      formData.append("skuName", skuName);
      formData.append("description", message);
      formData.append("unit", measurementUnit);
      tagIds.forEach((id) => {
        formData.append("tagIds[]", id.toString());
      });
      artworkFiles.map((artwork, index) => {
        formData.append("artwork[]", artwork);
        formData.append("artworkFileNames[]", artwork.name);
      });
      fileList.map((file, index) => {
        formData.append("skuImages[]", file.file);
        formData.append("skuImagesNames[]", file.file.name);
      });
      selectedAddress.map((address, index) => {
        formData.append("addressIds[]", address.toString());
      });

      const res = await ApiService().client.post(URLS.SKU.CREATE_SKU, formData);
      if (res.status === 200) {
        navigate(`/skus/${res.data.sku.id}`);
      }
    } catch (error) {
      console.error("Error Creating SKU:", error);
    }
  };

  const newTagAdd = (tag: FilterOptionSchema) => {
    if (skuTags) {
      setSkuTags([...skuTags, tag]);
      setFilterSkuTags([...skuTags, tag]);
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="sku-form p-16 flex-column gap-24 border-box"
    >
      <div className="xetgo-font-tag bolder form-main-heading flex-row align-items-center gap-4">
        <img
          height={15}
          width={16}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240717142153-list-plus.svg"
          alt="sku-icon"
        />
        <p>Add SKU</p>
      </div>
      <div className="sku-form-main-container flex-column gap-12 xetgo-font-tag flex-1">
        <div className="flex-row gap-12">
          <div className="form-container flex-column gap-8 flex-1">
            <p className="bold">SKU Name</p>
            <input
              onChange={(e) => setSkuName(e.target.value)}
              className={`sku-form-input px-12 py-8 xetgo-font-tag ${
                skuName.length > 0 && "bold sku-form-input-filled"
              }`}
              placeholder="Enter Name"
            />
          </div>
          <div className="form-container flex-column gap-8 measurement-container">
            <p className="bold">Unit</p>
            <input
              onChange={(e) => setMeasurementUnit(e.target.value)}
              className={`sku-form-input px-12 py-8 xetgo-font-tag ${
                measurementUnit.length > 0 && "bold sku-form-input-filled"
              }`}
              placeholder="Enter Unit"
            />
          </div>
        </div>
        <div className="form-container flex-column gap-8">
          <p className="bold">Message</p>
          <input
            onChange={(e) => setMessage(e.target.value)}
            className={`sku-form-input px-12 py-8 xetgo-font-tag ${
              message.length > 0 && "bold sku-form-input-filled"
            }`}
            placeholder="Enter Description"
          />
        </div>
        {skuTags && (
          <div className="form-container flex-column gap-8">
            <p className="bold">Tags</p>
            <TagsDropdown
              allTags={skuTags}
              direction="left"
              addedTags={handleTagAddition}
              selectedTags={selectedSkuTags.map((tag) => tag.id)}
              newTagAdd={newTagAdd}
            />
            {selectedSkuTags.length > 0 && (
              <div className="all-tag-container flex-row gap-4 px-8">
                {selectedSkuTags.map((tag, index) => (
                  <p
                    key={index}
                    className="tag-pill-active px-6 py-4 xetgo-font-mini"
                  >
                    {tag.name}
                  </p>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="form-container flex-column gap-8">
          <p>Artwork</p>
          <div>
            <input
              onChange={(event) => {
                handleFileChange(event, "artwork");
              }}
              id="sku-raise-fileInput"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="sku-raise-fileInput">
              <p className="sku-form-input xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                Upload Artwork
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>
          {artworkFiles.length > 0 && (
            <div className="flex-row gap-8 all-attachment-container">
              {artworkFiles.map((file, index) => {
                return (
                  <div
                    key={index}
                    className="sku-raise-attachment-pill px-8 py-4 flex-row gap-10 align-items-center"
                  >
                    <p>{artworkDisplayNames[index]}</p>
                    <img
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507184147-x.svg"
                      alt="remove-icon"
                      className="cursor-pointer"
                      onClick={() => removeFile(index, "artwork")}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="form-container flex-column gap-8">
          <p>Add SKU Images</p>
          <div>
            <input
              onChange={(event) => {
                handleFileChange(event, "skuImages");
              }}
              id="sku-images"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="sku-images">
              <p className="sku-form-input xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                Upload Image
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>

          {fileList.length > 0 && (
            <div className="all-attachment-container flex-row gap-8">
              {fileList.map((file, index) => (
                <div
                  key={index}
                  className="sku-image-container position-relative"
                >
                  <img
                    className="sku-detail-image"
                    height={61}
                    width={61}
                    src={file.preview}
                    alt="images"
                  />
                  <img
                    onClick={() => removeFile(index, "skuImages")}
                    className="remove-sku-image cursor-pointer"
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715055536-Frame+1321314633.svg"
                    alt="remove-icon"
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="form-container flex-column gap-8">
          <p>Add Locations</p>
          <DeliveryLocationSelector
            addAddress={addAddress}
            selectedAddress={selectedAddress}
            addresses={addresses}
          />

          {selectedAddress.length > 0 && (
            <div className="all-address-container flex-column gap-8">
              {addresses.map((address, index) => {
                if (selectedAddress.includes(address.id)) {
                  return (
                    <div
                      key={index}
                      className="address-container flex-column gap-4 p-12"
                    >
                      <p className="city bold">{address.city}</p>
                      <p className="address-line">
                        {`${address.address_line}, ${address.city}, ${address.pincode}`}
                      </p>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
      <div
        onClick={handleCreateSku}
        className="create-sku-btn xetgo-font-tag bold py-6 px-12 flex-row align-items-center justify-content-center gap-10 border-box cursor-pointer"
      >
        <img
          height={10}
          width={10}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240717130726-Frame+1321314714.svg"
          alt="sku-icon"
        />
        <p>Create SKU</p>
      </div>
    </div>
  );
};
export default SkuForm;
