import {
  QuoteDeletePayload,
  QuoteDetail,
  QuoteDetailUpdatePayload,
  TaskCommentSchema,
} from "../../models/Quote";
import {
  AddTaskCommentAction,
  AddTaskCommentFailureAction,
  AddTaskCommentSuccessAction,
  DeleteQuoteAssetRecordAction,
  DeleteQuoteAssetRecordFailureAction,
  DeleteQuoteAssetRecordSuccessAction,
  DeleteQuoteProductAction,
  DeleteQuoteProductFailureAction,
  DeleteQuoteProductSuccessAction,
  GetQuoteDetailAction,
  GetQuoteDetailSuccessAction,
  QuoteActions,
  UpdateQuoteAssetRecordsAction,
  UpdateQuoteAssetRecordsFailureAction,
  UpdateQuoteAssetRecordsSuccessAction,
  UpdateQuoteDetailsAction,
  UpdateQuoteDetailsFailureAction,
  UpdateQuoteDetailsSuccessAction,
  UpdateQuoteProductAction,
  UpdateQuoteProductFailureAction,
  UpdateQuoteProductSuccessAction,
} from "./quoteTypes";

export const getQuoteDetails = (id: number): GetQuoteDetailAction => ({
  type: QuoteActions.GET_QUOTE_DETAIL,
  payload: {
    id: id,
  },
});

export const getQuoteDetailsSuccess = (
  response: QuoteDetail,
): GetQuoteDetailSuccessAction => ({
  type: QuoteActions.GET_QUOTE_DETAIL_SUCCESS,
  payload: response,
});

export const getQuoteDetailsFailure = (error: string) => ({
  type: QuoteActions.GET_QUOTE_DETAIL_FAILURE,
  error,
});

export const addTaskComment = (payload: FormData): AddTaskCommentAction => ({
  type: QuoteActions.ADD_TASK_COMMENT,
  payload: payload,
});

export const addTaskCommentSuccess = (
  payload: TaskCommentSchema,
): AddTaskCommentSuccessAction => ({
  type: QuoteActions.ADD_TASK_COMMENT_SUCCESS,
  payload: payload,
});

export const addTaskCommentFailure = (
  error: string,
): AddTaskCommentFailureAction => ({
  type: QuoteActions.ADD_TASK_COMMENT_FAILURE,
  error: error,
});

export const updateQuoteProduct = (
  payload: QuoteDetailUpdatePayload,
): UpdateQuoteProductAction => ({
  type: QuoteActions.UPDATE_QUOTE_PRODUCT,
  payload: payload,
});

export const updateQuoteProductSuccess = (
  payload: QuoteDetail,
): UpdateQuoteProductSuccessAction => ({
  type: QuoteActions.UPDATE_QUOTE_PRODUCT_SUCCESS,
  payload: payload,
});

export const updateQuoteProductFailure = (
  error: string,
): UpdateQuoteProductFailureAction => ({
  type: QuoteActions.UPDATE_QUOTE_PRODUCT_FAILURE,
  error: error,
});

export const deleteQuoteProduct = (
  payload: QuoteDeletePayload,
): DeleteQuoteProductAction => ({
  type: QuoteActions.DELETE_QUOTE_PRODUCT,
  payload: payload,
});

export const deleteQuoteProductSuccess = (payload: {
  tpm_id: number;
  product_id: number;
}): DeleteQuoteProductSuccessAction => ({
  type: QuoteActions.DELETE_QUOTE_PRODUCT_SUCCESS,
  payload: payload,
});

export const deleteQuoteProductFailure = (
  error: string,
): DeleteQuoteProductFailureAction => ({
  type: QuoteActions.DELETE_QUOTE_PRODUCT_FAILURE,
  error: error,
});

export const updateQuoteAssetRecords = (
  payload: FormData,
): UpdateQuoteAssetRecordsAction => ({
  type: QuoteActions.UPDATE_QUOTE_ASSET_RECORDS,
  payload: payload,
});

export const updateQuoteAssetRecordsSuccess = (payload: {
  asset_records: { name: string; url: string; id: number }[];
}): UpdateQuoteAssetRecordsSuccessAction => ({
  type: QuoteActions.UPDATE_QUOTE_ASSET_RECORDS_SUCCESS,
  payload: payload,
});

export const updateQuoteAssetRecordsFailure = (
  error: string,
): UpdateQuoteAssetRecordsFailureAction => ({
  type: QuoteActions.UPDATE_QUOTE_ASSET_RECORDS_FAILURE,
  error: error,
});

export const deleteQuoteAssetRecord = (payload: {
  id: number;
}): DeleteQuoteAssetRecordAction => ({
  type: QuoteActions.DELETE_QUOTE_ASSET_RECORD,
  payload: payload,
});

export const deleteQuoteAssetRecordSuccess = (payload: {
  id: number;
}): DeleteQuoteAssetRecordSuccessAction => ({
  type: QuoteActions.DELETE_QUOTE_ASSET_RECORD_SUCCESS,
  payload: payload,
});

export const deleteQuoteAssetRecordFailure = (
  error: string,
): DeleteQuoteAssetRecordFailureAction => ({
  type: QuoteActions.DELETE_QUOTE_ASSET_RECORD_FAILURE,
  error: error,
});

export const updateQuoteDetails = (payload: {
  id: number;
  description: string;
}): UpdateQuoteDetailsAction => ({
  type: QuoteActions.UPDATE_QUOTE_DETAILS,
  payload: payload,
});

export const updateQuoteDetailsSuccess = (payload: {
  id: number;
  description: string;
}): UpdateQuoteDetailsSuccessAction => ({
  type: QuoteActions.UPDATE_QUOTE_DETAILS_SUCCESS,
  payload: payload,
});

export const updateQuoteDetailsFailure = (
  error: string,
): UpdateQuoteDetailsFailureAction => ({
  type: QuoteActions.UPDATE_QUOTE_DETAILS_FAILURE,
  error: error,
});
