import React, { useEffect, useState } from "react";
import { QuoteContext, errorToasts } from "../../context/quote-context";
import { IQuoteProduct, NEW_QUOTE_PRODUCT } from "../../models/QuoteProduct";
import "./QuoteEnquiry.scss";
import { breakPointObserver } from "../../utils/BreakpointObserver";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { isNullOrUndefined } from "../../utils/Helper";
import RaiseQuoteSKU from "../../components/RaiseQuoteSKU/RaiseQuoteSKU";

const QuoteEnquiry: React.FC = () => {
  const [quoteProductList, setQuoteProductList] = useState<IQuoteProduct[]>([]);
  const [currentQuoteProduct, setCurrentQuoteProduct] = useState<
    IQuoteProduct[]
  >([{ ...NEW_QUOTE_PRODUCT }]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  const addProductToList = (product: IQuoteProduct) => {
    const isValid = checkValidity(product);

    if (isValid) {
      if (editMode) {
        setQuoteProductList((row) => {
          return row.map((entry) => {
            if (entry.id === product.id) {
              return product;
            }
            return entry;
          });
        });
      } else {
        setQuoteProductList((row) => [...row, product]);
      }
    }
  };

  const deleteProductFromList = (id: number) => {
    setQuoteProductList(quoteProductList.filter((row) => row.id !== id));
  };

  const updateCurrentQuoteProduct = (key: string, value: any) => {
    setCurrentQuoteProduct((row) => {
      return { ...row, [key]: value };
    });
  };

  const checkValidity = (quoteProduct: IQuoteProduct) => {
    const invalids: string[] = [];
    if (quoteProduct.sku == null) {
      invalids.push("product");
    }
    if (quoteProduct.quantity === "") {
      invalids.push("quantity");
    }
    if (quoteProduct.unit === "") {
      invalids.push("unit");
    }
    if (isNullOrUndefined(quoteProduct.address_id)) {
      invalids.push("address");
    }
    if (invalids.length > 0) {
      const messages = errorToasts(invalids);
      messages.forEach((msg) => {
        toast.error(msg, toastOptions);
      });
    }
    return invalids.length === 0;
  };

  return (
    <>
      <QuoteContext.Provider
        value={{
          quoteProductList: quoteProductList,
          currentQuoteProduct: currentQuoteProduct,
          updateProductList: addProductToList,
          deleteProductFromList: deleteProductFromList,
          setCurrentQuoteProduct: setCurrentQuoteProduct,
          editMode: editMode,
          setEditMode: setEditMode,
          clearQuoteList: () => setQuoteProductList([]),
        }}
      >
        <div className="quote-enquiry-main-container ">
          <RaiseQuoteSKU />
        </div>
      </QuoteContext.Provider>
    </>
  );
};

export default QuoteEnquiry;
