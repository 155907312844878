import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import "./NewAddressForm.scss";
import React, { useState } from "react";
import { ALL_COUNTRIES } from "./AllCountries.data";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import { NewCompanyAddressPayloadSchema } from "../../redux/user/userTypes";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/user/userSelector";

interface NewAddressFormProps {
  onCancel: () => void;
  onAddAddress: (payload: NewCompanyAddressPayloadSchema) => void;
}
const initForm = (company_id: number) => ({
  line: "",
  city: "",
  state: "",
  pincode: "",
  country: "India",
  map_link: "",
  company_id: company_id,
});

type NewAddressKeys =
  | "line"
  | "city"
  | "state"
  | "pincode"
  | "country"
  | "map_link"
  | "company_id";

const NewAddressForm: React.FC<NewAddressFormProps> = ({
  onCancel,
  onAddAddress,
}) => {
  const userDetail = useSelector(userDetails);
  const [formData, setFormData] = useState<NewCompanyAddressPayloadSchema>(
    initForm(userDetail.company_id || 1),
  );
  const [pincode, setPincode] = useState("");
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [country, setCountry] = useState<FilterOptionSchema>(
    ALL_COUNTRIES.find((val) => val.code === "IN") || ALL_COUNTRIES[0],
  );

  const getPincodeDetails = async (pin: string) => {
    if (country) {
      try {
        setPincodeLoading(true);
        const options = {
          method: "GET",
          headers: {
            "X-RapidAPI-Key":
              "f96dea3bcamsh4aa42a373e8d194p1f4323jsnc2319becab7e",
            "X-RapidAPI-Host":
              "global-zip-codes-with-lat-and-lng.p.rapidapi.com",
          },
        };
        const response = await fetch(
          `https://global-zip-codes-with-lat-and-lng.p.rapidapi.com/api/v1/geocode?code=${pin}&country=${country.code}`,
          options,
        );

        const data = await response.json();

        const pincodeDetails = data[0];
        {
          pincodeDetails
            ? setFormData((formData) => ({
                ...formData,
                state: pincodeDetails.state,
                city: pincodeDetails.district,
                country: ALL_COUNTRIES.filter(
                  (country) => pincodeDetails.country_code == country.code,
                )[0].name,
              }))
            : setFormData((formData) => ({
                ...formData,
                state: "",
                city: "",
                country: "",
              }));
        }
        setPincodeLoading(false);
        if (data.length <= 0) {
          toast.error("Invalid Pincode!", toastOptions);
        }
      } catch (error) {
        toast.error("Invalid Pincode!", toastOptions);
      }
    }
  };

  const onAddAddressHandler = () => {
    if (
      Object.keys(formData).filter(
        (key) => formData[key as NewAddressKeys] === "",
      ).length > 0
    ) {
      toast.error("All fields are compulsory", toastOptions);
    } else {
      onAddAddress(formData);
    }
  };

  const updateFormEntry = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: NewAddressKeys,
  ) => {
    setFormData((data) => {
      return {
        ...data,
        [key]: (event.target as HTMLInputElement).value as string,
      };
    });
  };
  return (
    <div className="add-new-address-modal-box">
      <div className="flex-row px-16 py-12 new-adress-modal-title-container justify-content-space-between">
        <p className="xetgo-font-button bold ">Add new address</p>
        <img
          className="cursor-pointer"
          height={16}
          width={16}
          onClick={onCancel}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209103001-x.svg"
        />
      </div>
      <div className="flex-column gap-12 px-16 py-12">
        <div className=" flex-column gap-8">
          <p className="address-grey-font-color xetgo-font-tag bold">
            Delivery Address
          </p>
          <div className="  flex-row ">
            <input
              value={formData.line}
              onChange={(e) => updateFormEntry(e, "line")}
              className="address-form-input px-12 py-10 xetgo-font-button flex-1 border-box "
            />
          </div>
        </div>
        <div className=" flex-row gap-12 full-width">
          <div className="flex-column gap-8 flex-1 overflow-hidden">
            <p className="address-grey-font-color label xetgo-font-tag bold">
              Country
            </p>
            <Filter
              option={{
                key: "country",
                name: "Country",
              }}
              optionList={ALL_COUNTRIES}
              multipleSelection={false}
              onSelection={(val, _option) => {
                setCountry(val[0]);
              }}
              mode="showNames"
              showPlaceholder={false}
              preSelected={country ? [country] : []}
              direction="left"
              addNew={false}
              classname="filter-input"
            />
          </div>
          <div className=" flex-column gap-8 flex-1 overflow-hidden">
            <p className="address-grey-font-color xetgo-font-tag bold">
              Pin Code
            </p>
            <div className="address-form-input-box flex-row ">
              <input
                value={formData.pincode}
                onChange={(e) => {
                  updateFormEntry(e, "pincode");
                  setPincode(e.target.value);
                }}
                className="address-form-input px-12 py-10 xetgo-font-button flex-1 border-box "
              />
              {pincodeLoading ? (
                <img
                  height={16}
                  width={16}
                  onClick={() => getPincodeDetails(pincode)}
                  className="pincode_forward cursor-pointer"
                  src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca.gif"
                  alt="forward"
                />
              ) : (
                <img
                  onClick={() => getPincodeDetails(pincode)}
                  className="pincode_forward cursor-pointer"
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231130104346-arrow-forward.svg"
                  alt="forward"
                />
              )}
            </div>
          </div>
        </div>

        <div className=" flex-row gap-12 full-width">
          <div className=" flex-column gap-8 flex-1 overflow-hidden">
            <p className="address-grey-font-color xetgo-font-tag bold">City</p>
            <input
              disabled
              value={formData.city}
              onChange={(e) => updateFormEntry(e, "city")}
              className="address-form-input px-12 py-10 xetgo-font-button flex-1 border-box "
            />
          </div>
          <div className=" flex-column gap-8 flex-1 overflow-hidden">
            <p className="address-grey-font-color xetgo-font-tag bold">State</p>
            <input
              disabled
              value={formData.state}
              onChange={(e) => updateFormEntry(e, "state")}
              className="address-form-input px-12 py-10 xetgo-font-button flex-1 border-box  "
            />
          </div>
        </div>
        <div style={{ position: "relative" }} className=" flex-column gap-8">
          <p className="address-grey-font-color xetgo-font-tag bold">
            Map Link
          </p>
          <div className="  flex-row ">
            <input
              value={formData.map_link}
              onChange={(e) => updateFormEntry(e, "map_link")}
              className="address-form-input px-12 py-10 xetgo-font-button flex-1 border-box "
            />
            <img
              className="address-location-icon cursor-pointer"
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212095105-map-pin.svg"
            />
          </div>
        </div>
      </div>
      <div className="flex-row gap-12 new-form-btn-container justify-content-flex-end px-16 py-12">
        <div
          className="cancel-add-btn xetgo-font-tag py-6 px-10 cursor-pointer"
          onClick={onCancel}
        >
          Cancel
        </div>
        <div
          className="add-address-btn xetgo-font-tag py-6 px-10 cursor-pointer"
          onClick={onAddAddressHandler}
        >
          Add Address
        </div>
      </div>
    </div>
  );
};

export default NewAddressForm;
