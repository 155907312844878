export const INPUT_TYPE = [
  {
    name: "Text",
    id: 1,
    code: "text",
  },
  {
    name: "Number",
    id: 2,
    code: "number",
  },
  {
    name: "Checkbox",
    id: 3,
    code: "checkbox",
  },
  {
    name: "Radio Button",
    id: 4,
    code: "radio_button",
  },
  {
    name: "File Upload",
    id: 5,
    code: "file_upload",
  },
  {
    name: "Multiple File Upload",
    id: 6,
    code: "multi_file_upload",
  },
  {
    name: "Date & Time",
    id: 7,
    code: "datetime",
  },
];
