import { useNavigate, useParams } from "react-router-dom";
import PreDefinedVendorForm from "../../components/PreDefinedVendorForm/PreDefinedVendorForm";
import VendorFormDetail from "../../components/VendorFormDetail/VendorFormDetail";
import "./VendorFormDetailPage.scss";
import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { INPUT_TYPE } from "../../constants/QuestionFormData";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { QuestionSchema } from "../../models/QuestionForm";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { formatDateWithYear } from "../../utils/date-util";

const VendorFormDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formTitle, setFormTitle] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [publishActive, setPublishActive] = useState(false);
  const [allQuestions, setAllQuestions] = useState<QuestionSchema[]>([]);

  useEffect(() => {
    getFormDetail();
  }, []);

  const getFormDetail = async () => {
    const { data } = await ApiService().client.get(
      URLS.FORMS.GET_VENDOR_FORM_DETAIL(Number(id)),
    );
    setFormTitle(data.response.title);
    setFormDescription(data.response.description);
    setCreatedDate(data.response.created_at);
    setAllQuestions(data.response.questions);
  };

  return (
    <div className="vendor-form-detail-page full-width flex-column">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">
          <span className="cursor-pointer" onClick={() => navigate("/forms")}>
            Form
          </span>
          <span className="sub-title xetgo-font-tag"> / {formTitle}</span>
        </p>
      </div>
      <div className="vendor-form-detail-lower-container-main flex-column gap-12 p-16 flex-1 overflow-scroll">
        <div
          style={{ background: "white" }}
          className="flex-row align-items-center justify-content-space-between"
        >
          <div className="flex-column gap-12">
            <p className="xetgo-font-h3 bolder">{formTitle}</p>
            <p className="xetgo-font-tag">{formDescription}</p>
          </div>
          <div className="flex-column gap-20 xetgo-font-tag">
            <p>
              Created Date:{" "}
              <span style={{ color: "#585858" }}>
                {formatDateWithYear(createdDate)}
              </span>
            </p>
            <div className="edit-container flex-row align-items-center gap-14 ">
              {/* <img
                className="cursor-pointer"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240908105528-Group+300.svg"
                alt="edit"
                width={16}
                height={16}
                // onClick={() => editProduct(row.id)}
              /> */}
              <img
                className="cursor-pointer"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821104010-trash-2.svg"
                width={16}
                height={16}
              />
            </div>
          </div>
        </div>
        <div className="predefined-main-container p-16 flex-column gap-18">
          <p className="xetgo-font-body">Predefined Questions</p>
          <PreDefinedVendorForm inputDisabled />
          <div className="custom-question-main-container flex-column gap-18">
            <p className="xetgo-font-body">Custom Questions</p>
            {allQuestions.map((question, questionIndex) => (
              <div
                key={questionIndex}
                className="form-detail-question-container flex-column gap-20 p-20 xetgo-font-button"
              >
                <p className="question">
                  {`${16 + questionIndex + 1}. ${question.question_value}`}{" "}
                  {question.isRequired && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </p>

                {question.input_type == INPUT_TYPE[0].code && (
                  <input
                    className="answer-input p-12 xetgo-font-button full-width border-box "
                    value={""}
                    placeholder="Enter Answer"
                    disabled
                  />
                )}

                {question.input_type == INPUT_TYPE[1].code && (
                  <input
                    className="answer-input p-12 xetgo-font-button full-width border-box "
                    value={""}
                    placeholder="Enter Answer"
                    disabled
                  />
                )}

                {question.input_type === INPUT_TYPE[2].code &&
                  question.options && (
                    <div className="option-container flex-column gap-16">
                      {question.options.map((option, optionIndex) => (
                        <div
                          key={optionIndex}
                          className="flex-row align-items-center gap-10 options-container"
                        >
                          <input
                            type="checkbox"
                            className="check-box"
                            disabled
                          />
                          <input
                            type="text"
                            value={option.name}
                            className="check-text xetgo-font-button "
                            disabled
                          />
                        </div>
                      ))}
                    </div>
                  )}

                {question.input_type === INPUT_TYPE[3].code &&
                  question.options && (
                    <div className="option-container flex-column gap-16">
                      {question.options.map((option, optionIndex) => (
                        <div
                          key={optionIndex}
                          className="flex-row align-items-center gap-10 options-container"
                        >
                          <input
                            type="radio"
                            className="check-box radio-btn "
                            disabled
                          />
                          <input
                            type="text"
                            value={option.name}
                            className="check-text xetgo-font-button "
                            disabled
                          />
                        </div>
                      ))}
                    </div>
                  )}

                {question.input_type == INPUT_TYPE[4].code && (
                  <div className="custom-form-left-box flex-1 flex-column gap-14">
                    <input
                      id="single-fileInput"
                      name="file"
                      type="File"
                      style={{
                        position: "absolute",
                        top: "12px",
                        right: "12px",
                        display: "none",
                      }}
                      disabled={true}
                    />
                    <label
                      htmlFor="single-fileInput"
                      className="answer-input p-12 xetgo-font-button full-width border-box "
                    >
                      <p className="xetgo-font-button flex-row justify-content-space-between">
                        <p>Upload File</p>
                        <img
                          className="upload-icon"
                          height={16}
                          width={16}
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                          alt="upload-icon"
                        />
                      </p>
                    </label>
                  </div>
                )}

                {question.input_type == INPUT_TYPE[5].code && (
                  <div className="custom-form-left-box flex-1 flex-column gap-14">
                    <input
                      id="single-fileInput"
                      name="file"
                      type="File"
                      style={{
                        position: "absolute",
                        top: "12px",
                        right: "12px",
                        display: "none",
                      }}
                      disabled={true}
                    />
                    <label
                      htmlFor="single-fileInput"
                      className="answer-input p-12 xetgo-font-button full-width border-box "
                    >
                      <p className="xetgo-font-button flex-row justify-content-space-between">
                        <p>Upload File</p>
                        <img
                          className="upload-icon"
                          height={16}
                          width={16}
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                          alt="upload-icon"
                        />
                      </p>
                    </label>
                  </div>
                )}

                {question.input_type == INPUT_TYPE[6].code && (
                  <div className="custom-question-date-value xetgo-font-button">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="answer-input p-12 xetgo-font-button full-width border-box "
                        value={dayjs("".split("-").reverse().join("-"))}
                        format={"DD-MM-YYYY"}
                        views={["year", "month", "day"]}
                        disabled
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorFormDetailPage;
