import React, { useEffect, useRef, useState } from "react";
import "./DeliveryCalendar.scss";
import environment from "../../environments/environment";
import { formatDate } from "../../utils/Helper";
import dayjs from "dayjs";
import { FilterOptionSchema } from "../../components/Filter/filter.data";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { DELIVERY_CARD_STATUSES } from "./DeliveryCalendar.data";
import Filter from "../../components/Filter/Filter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DeliverySchedule } from "../../models/DeliveryCalendar";

const DeliveryCalendar: React.FC = () => {
  const [today, _setToday] = useState<string>(
    formatDate(dayjs().toDate().toISOString()),
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Date>(dayjs().toDate());
  const [startDateOpen, setStartDateOpen] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date>(dayjs().add(7, "days").toDate());
  const [endDateOpen, setEndDateOpen] = useState<boolean>(false);
  const [deliveryScheduleData, setDeliveryScheduleData] = useState<
    DeliverySchedule[]
  >([]);
  const [vendors, setVendors] = useState<FilterOptionSchema[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<FilterOptionSchema[]>(
    [],
  );
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<
    string | null
  >();

  const fetchVendors = async () => {
    const response = await ApiService().client.get(
      URLS.VENDORS.FETCH_VENDOR_LIST,
    );
    setVendors(response.data.vendors);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    getDeliveryScheduleData().then((r) => {
      return r;
    });
  }, [startDate, endDate, selectedVendor, selectedDeliveryType]);

  const filterByClient = (value: FilterOptionSchema[]) => {
    setSelectedVendor(value);
  };

  const getDeliveryScheduleData = async () => {
    const client_ids = selectedVendor.map((val) => val.id);
    const response = await ApiService().client.get(
      URLS.DELIVERY.DELIVERY_SCHEDULE_DATA,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          client_ids,
          delivery_type: selectedDeliveryType,
        },
      },
    );
    setDeliveryScheduleData(response.data.delivery_schedules);
  };

  const startDateSelected = (event: any) => {
    setStartDate(event.toDate());
    toggleStartDate();
  };

  const endDateSelected = (event: any) => {
    setEndDate(event.toDate());
    toggleEndDate();
  };

  const toggleStartDate = () => {
    setStartDateOpen((val) => !val);
  };

  const toggleEndDate = () => {
    setEndDateOpen((val) => !val);
  };

  const isMissed = (
    expected_date: string,
    delivered_date: string,
    current_date: string,
  ) => {
    return (
      delivered_date !== current_date &&
      dayjs(current_date, "DD-MM-YYYY") < dayjs(today, "DD-MM-YYYY") &&
      expected_date !== delivered_date
    );
  };

  const cardClassSelector = (
    dueDays: number,
    expected_date: string,
    delivered_date: string,
    current_date: string,
  ) => {
    if (dueDays === 0) {
      return DELIVERY_CARD_STATUSES.same_day;
    } else if (dueDays > 0) {
      if (isMissed(expected_date, delivered_date, current_date)) {
        return DELIVERY_CARD_STATUSES.delayed_expected_yellow;
      } else {
        return DELIVERY_CARD_STATUSES.delayed_actual_red;
      }
    } else {
      if (delivered_date === current_date) {
        return DELIVERY_CARD_STATUSES.early_actual;
      } else {
        return DELIVERY_CARD_STATUSES.early_expected;
      }
    }
  };

  const toggleSelectedDeliveryType = (val: string) => {
    if (selectedDeliveryType !== val) {
      setSelectedDeliveryType(val);
    } else {
      setSelectedDeliveryType(null);
    }
  };

  const getDayLabel = (numberOfDays: number) => {
    return Math.abs(numberOfDays) === 1 ? "day" : "days";
  };

  const openDeliveryDetailPage = (po_id: number) => {
    const url = `${environment.clientUrl}/purchase-orders/${po_id}`;
    window.open(url, "_blank")?.focus();
  };

  const handleFileUpload = async (
    date: string,
    po_id: number,
    scheduleId: number,
    event: any,
  ) => {
    const fileList: File[] = Array.from(event.target.files);
    const formData = new FormData();
    const displayNames = fileList.map((file) => file.name);
    formData.append("file", fileList[0]);
    formData.append("displayNames", displayNames[0]);

    const response = await ApiService().client.put(
      URLS.DELIVERY.UPDATE_DELIVERY_SCHEDULE(po_id, scheduleId),
      formData,
    );

    const updatedData: DeliverySchedule[] = deliveryScheduleData.map(
      (deliveryDate, index) => {
        deliveryDate.data.map((delivery_schedule, scheduleIndex) => {
          if (
            delivery_schedule.id == scheduleId &&
            delivery_schedule.procurement_request_id == po_id
          ) {
            delivery_schedule.pod = response.data.url;
            delivery_schedule.actual_delivery_date =
              response.data.actual_delivery_date;
          }
          return delivery_schedule;
        });
        return deliveryDate;
      },
    );
    setDeliveryScheduleData(updatedData);
  };

  const handleTrackLinkOpen = (track_link: string) => {
    window.open(track_link, "_blank");
  };

  const navigateTofile = (url: string) => {
    window.open(url, "_blank")?.focus();
  };

  const handleCalendarScroll = (direction: "toLeft" | "toRight") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 236;
      const currentScroll = scrollContainerRef.current.scrollLeft;
      const newScrollPosition =
        direction === "toLeft"
          ? currentScroll - scrollAmount
          : currentScroll + scrollAmount;

      const scrollOptions: ScrollToOptions = {
        top: 0,
        left: newScrollPosition,
        behavior: "smooth",
      };
      scrollContainerRef.current.scroll(scrollOptions);
    }
  };

  return (
    <div className="ds-calender-container-page">
      <div className="ds-calender-content-body align-items-start flex-row">
        <div className="ds-content-section flex-column gap-24 p-16">
          <div className="ds-header flex-row align-items-center justify-content-space-between">
            <div className="delivery-calendar-status-main-container flex-row justify-content-space-between">
              <div
                onClick={() => handleCalendarScroll("toLeft")}
                className="arrow-icons-container previous-container p-4 flex-row align-items-center justify-content-center cursor-pointer"
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308090308-arrow-right+%281%29.svg"
                  alt="previous-icon"
                  height={16}
                  width={16}
                />
              </div>
              <div className="ds-status-tabs-container flex-row align-items-center gap-4">
                <span
                  className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                    selectedDeliveryType === "Overdue" && "active-tab"
                  }`}
                  onClick={() => toggleSelectedDeliveryType("Overdue")}
                >
                  Overdue
                </span>
                <span
                  className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                    selectedDeliveryType === "Due" && "active-tab"
                  }`}
                  onClick={() => toggleSelectedDeliveryType("Due")}
                >
                  Due
                </span>
                <span
                  className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                    selectedDeliveryType === "Delivered" && "active-tab"
                  }`}
                  onClick={() => toggleSelectedDeliveryType("Delivered")}
                >
                  Delivered
                </span>
              </div>
              <div
                onClick={() => handleCalendarScroll("toRight")}
                className="arrow-icons-container flex-row align-items-center justify-content-center p-4 cursor-pointer"
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308090308-arrow-right+%281%29.svg"
                  alt="next-icon"
                  height={16}
                  width={16}
                />
              </div>
            </div>

            <div className="ds-filters flex-row align-items-center gap-16">
              <img
                className="delivery-calendar-filter-icon"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231010064729-grey_filter.svg"
                alt="filter-icon"
                height={19}
                width={19}
              />
              <div className="delivery-calendar-multi-filter-box  gap-8">
                <Filter
                  option={{ key: "vendor", name: "Vendor" }}
                  optionList={vendors}
                  multipleSelection={false}
                  onSelection={(val, _option) => {
                    filterByClient(val);
                  }}
                  showPlaceholder={true}
                  mode="showNames"
                  addNew={false}
                  showId={false}
                  preSelected={selectedVendor}
                  classname="delivery-filter-container"
                  direction="left"
                />
                <div className="xetgo-font-tag flex-row align-items-center gap-12 date-container px-8 py-4">
                  {formatDate(startDate?.toISOString())}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(startDate)}
                      format={"DD-MM-YYYY"}
                      views={["year", "month", "day"]}
                      onChange={startDateSelected}
                      open={startDateOpen}
                      className="delivery-date-selector"
                    />
                  </LocalizationProvider>
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107114755-calendar-range.svg"
                    onClick={toggleStartDate}
                    className="cursor-pointer"
                    height={16}
                    width={16}
                  />
                </div>
                <div className="date-container xetgo-font-tag flex-row align-items-center gap-12 px-8 py-4">
                  {formatDate(endDate?.toISOString())}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(endDate)}
                      format={"DD-MM-YYYY"}
                      views={["year", "month", "day"]}
                      onChange={endDateSelected}
                      open={endDateOpen}
                      className="delivery-date-selector"
                    />
                  </LocalizationProvider>
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107114755-calendar-range.svg"
                    onClick={toggleEndDate}
                    className="cursor-pointer"
                    height={16}
                    width={16}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* WEEKLY CALENDAR VIEW */}

          <div
            ref={scrollContainerRef}
            className="ds-weekly-calendar-container"
          >
            <div className="ds-weekly-calendar-view flex-row align-items-center">
              {deliveryScheduleData?.map((deliverySchedule, index) => {
                return (
                  <div
                    key={`${deliverySchedule.date} ${index}`}
                    className="ds-date-column flex-column"
                  >
                    <div
                      className={`date-heading flex-column gap-8 ${
                        deliverySchedule.date === today && "active-date"
                      }`}
                    >
                      <span className="ds-day">
                        {dayjs(deliverySchedule.date, "DD-MM-YYYY").format(
                          "ddd",
                        )}
                      </span>
                      <span className="ds-date">
                        {dayjs(deliverySchedule.date, "DD-MM-YYYY").format("D")}
                      </span>
                    </div>
                    <div className="flex-column ds-list">
                      {deliverySchedule.data.map((schedule, scheduleIndex) => {
                        return (
                          <div
                            key={`${deliverySchedule.date} ${schedule.id} ${scheduleIndex}`}
                          >
                            <div
                              key={scheduleIndex}
                              className={`delivery-schedule-card cursor-pointer flex-row gap-8 ${
                                cardClassSelector(
                                  schedule.due_days,
                                  schedule.expected_delivery_date,
                                  schedule.actual_delivery_date || "",
                                  deliverySchedule.date,
                                ).custom_class
                              } `}
                              title={schedule.description}
                              onClick={() =>
                                openDeliveryDetailPage(
                                  schedule.procurement_request_id,
                                )
                              }
                            >
                              <div className={`phrase-line`}></div>
                              <div className={`flex-column gap-8  flex-1 `}>
                                <div className="flex-column gap-4">
                                  <span className="delivery-title xetgo-font-tag bolder">
                                    {schedule.po_number}
                                  </span>
                                  <span className="xetgo-font-tag ">
                                    {schedule.vendor_company_name}
                                  </span>
                                </div>
                                <div className="flex-column gap-4">
                                  <span className="delivery-title  xetgo-font-tag bolder">
                                    {schedule.city},{" "}
                                    {schedule.delivered_quantity} U
                                  </span>
                                  <span className={`xetgo-font-tag `}>
                                    {schedule.product_name}
                                  </span>
                                </div>
                                {schedule.due_days !== 0 && (
                                  <p className="delivery-status-box px-6 py-4 xetgo-font-mini bold flex-row align-items-center status-box">
                                    {`${
                                      schedule.actual_delivery_date
                                        ? cardClassSelector(
                                            schedule.due_days,
                                            schedule.expected_delivery_date,
                                            schedule.actual_delivery_date || "",
                                            deliverySchedule.date,
                                          ).text
                                        : "Overdue"
                                    } ${Math.abs(
                                      schedule.due_days,
                                    )} ${getDayLabel(schedule.due_days)}`}
                                  </p>
                                )}
                              </div>
                              <div
                                className="flex-column align-items-center justify-content-space-between"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {schedule.actual_delivery_date ? (
                                  <img
                                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240123141342-check-circle-2.svg"
                                    alt="delivered"
                                    width={16}
                                    height={16}
                                  />
                                ) : (
                                  <img
                                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107123640-badge-check.svg"
                                    alt="not-delivered"
                                    className="cursor-pointer"
                                    width={16}
                                    height={16}
                                  />
                                )}
                                <div className="flex-column gap-8">
                                  {schedule.pod ? (
                                    <img
                                      onClick={() =>
                                        navigateTofile(schedule.pod || "")
                                      }
                                      src={
                                        cardClassSelector(
                                          schedule.due_days,
                                          schedule.expected_delivery_date,
                                          schedule.actual_delivery_date || "",
                                          deliverySchedule.date,
                                        ).fileIconUrl
                                      }
                                      alt=""
                                      height={16}
                                      width={16}
                                    />
                                  ) : (
                                    <>
                                      <input
                                        onChange={(event) => {
                                          handleFileUpload(
                                            deliverySchedule.date,
                                            schedule.procurement_request_id,
                                            schedule.id,
                                            event,
                                          );
                                        }}
                                        id={`fileInput-${schedule.id}`}
                                        name="file"
                                        type="File"
                                        style={{ display: "none" }}
                                      />
                                      <label
                                        className="middle-input-track-link cursor-pointer"
                                        htmlFor={`fileInput-${schedule.id}`}
                                      >
                                        <img
                                          src={
                                            cardClassSelector(
                                              schedule.due_days,
                                              schedule.expected_delivery_date,
                                              schedule.actual_delivery_date ||
                                                "",
                                              deliverySchedule.date,
                                            ).uploadIconUrl
                                          }
                                          alt="upload-file-icon"
                                          height={16}
                                          width={16}
                                        />
                                      </label>
                                    </>
                                  )}
                                  {schedule.track_link && (
                                    <img
                                      onClick={() =>
                                        handleTrackLinkOpen(
                                          schedule.track_link || "",
                                        )
                                      }
                                      src={
                                        cardClassSelector(
                                          schedule.due_days,
                                          schedule.expected_delivery_date,
                                          schedule.actual_delivery_date || "",
                                          deliverySchedule.date,
                                        ).imageUrl
                                      }
                                      alt="location-icon"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryCalendar;
