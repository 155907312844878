import React, { useEffect, useState } from "react";
import "./VendorDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import { VendorDetailSchema } from "../../models/Quote";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import VendorDetailCard from "../../components/VendorDetailCard/VendorDetailCard";
import VendorQuotes from "../../components/VendorQuotes/VendorQuotes";
import VendorDelivery from "../../components/VendorDelivery/VendorDelivery";
import VendorAnalytics from "../../components/VendorAnalytics/VendorAnalytics";
import { UserAddress } from "../../redux/user/userTypes";
import VendorPurchaseOrders from "../../components/VendorsPurchaseOrders/VendorsPurchaseOrders";
import VendorPricing from "../../components/VendorPricing/VendorPricing";
import VendorFormResult from "../../components/VendorFormResult/VendorFormResult";

const VendorDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [vendorDetail, setVendorDetail] = useState<VendorDetailSchema>();
  const [addressData, setAddressData] = useState<UserAddress[] | null>();

  useEffect(() => {
    getVendorDetails();
  }, []);

  const getVendorDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDOR_DETAIL(Number(id)),
      );
      setVendorDetail(data.vendor_detail);
      const addressData = data.vendor_detail.vendor_addresses.map(
        (address: any) => {
          return {
            address_line: address.address_line,
            city: address.city,
            pincode: address.pincode,
            state: address.state,
            gstin: address.gstin,
            address_poc: {
              contact_number: address.poc_phone_number,
              poc_name: address.poc_name,
            },
          };
        },
      );
      setAddressData(addressData);
    } catch (error) {
      console.error("error fetching Sku Detail", error);
    }
  };

  return (
    <div className="vendor-detail-main-page flex-column full-width">
      <div className="upper-bar">
        <p
          className="xetgo-font-tag bold px-16 py-12 flex-row gap-4 cursor-pointer"
          onClick={() => navigate("/vendors")}
        >
          Vendor
          <span className="slash">/</span>
          <span className="sub-title xetgo-font-tag">
            {vendorDetail?.vendor_name}
          </span>
        </p>
      </div>
      <div className="vendor-detail-Lower-main-container flex-column gap-12 p-16 flex-1">
        <div className="vendor-details-tab-main-container flex-row justify-content-space-between xetgo-font-tag">
          <div className="all-tab-container flex-row align-items-center p-2">
            <p
              onClick={() => setActiveTab(0)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 0 && "active"
              } `}
            >
              Details
            </p>
            <p
              onClick={() => setActiveTab(1)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 1 && "active"
              } `}
            >
              Form
            </p>
            <p
              onClick={() => setActiveTab(2)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 2 && "active"
              } `}
            >
              SKU - Pricing
            </p>
            <p
              onClick={() => setActiveTab(3)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 3 && "active"
              } `}
            >
              Delivery
            </p>
            <p
              onClick={() => setActiveTab(4)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 4 && "active"
              } `}
            >
              Quotes
            </p>
            <p
              onClick={() => setActiveTab(5)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 5 && "active"
              } `}
            >
              Purchase Orders
            </p>
            <p
              onClick={() => setActiveTab(6)}
              className={`px-12 py-6 cursor-pointer bold ${
                activeTab === 6 && "active"
              } `}
            >
              Analytics
            </p>
          </div>
        </div>
        <div className="flex-1 tab-details-container">
          {activeTab === 0 && vendorDetail && (
            <VendorDetailCard vendorDetails={vendorDetail} />
          )}
          {activeTab === 1 && vendorDetail && (
            <VendorFormResult form_id={vendorDetail.form_id} />
          )}
          {activeTab === 2 && vendorDetail && (
            <VendorPricing vendor={vendorDetail} />
          )}

          {activeTab === 3 && vendorDetail && addressData && (
            <VendorDelivery
              vendorId={Number(id)}
              vendorAddresses={addressData}
            />
          )}

          {activeTab === 4 && vendorDetail && (
            <VendorQuotes vendor_id={vendorDetail.vendor_id} />
          )}

          {activeTab === 5 && vendorDetail && (
            <VendorPurchaseOrders vendorDetail={vendorDetail} />
          )}
          {activeTab === 6 && vendorDetail && (
            <VendorAnalytics vendorDetail={vendorDetail} />
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorDetail;
