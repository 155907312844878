import { VendorDetailSchema } from "../../models/Quote";
import "./VendorDetailCard.scss";
import React from "react";

interface VendorDetailProps {
  vendorDetails: VendorDetailSchema;
}

const VendorDetailCard: React.FC<VendorDetailProps> = ({ vendorDetails }) => {
  return (
    <div className="vendor-detail-card-main-container">
      <div className="vendor-detail-container flex-column xetgo-font-tag">
        <div className=" flex-row align-items-center justify-content-space-between p-12">
          <p className="xetgo-font-caption  bold" style={{ color: "#111111" }}>
            {vendorDetails.vendor_name}
          </p>
          <div className="flex-row gap-8">
            <p className="uuid px-8 py-4 bold">{vendorDetails.vendor_uuid}</p>
            <p className="pan-gst px-8 py-4">
              PAN :
              <span className="sub-title bold">{vendorDetails.vendor_pan}</span>
            </p>
            <p className="pan-gst px-8 py-4">
              GSTIN :
              <span className="sub-title bold">{vendorDetails.vendor_gst}</span>
            </p>
          </div>
        </div>
        <div className="vendor-detail-poc-main-container p-12 flex-row justify-content-space-between align-items-center">
          <p>
            <span style={{ color: "#1F1F1F" }} className="bold">
              {vendorDetails.vendor_poc_name}{" "}
            </span>
            <span style={{ color: "#5A5A5A" }}>
              Mob : {vendorDetails.vendor_poc_contact}
            </span>
          </p>
          <img
            className="display"
            height={24}
            width={24}
            src={vendorDetails.vendor_avatar}
            alt="profile-pic"
          />
        </div>
      </div>
      <div className="machinery-main-container p-12 xetgo-font-tag flex-column gap-12">
        <div className="flex-row align-items-center justify-content-space-between">
          <p>Machinery:</p>
        </div>
        <div className="all-machinery-container flex-row gap-4">
          {vendorDetails.machinery.length > 0 &&
            vendorDetails.machinery.map((machine, index) => (
              <div
                key={index}
                className="machinery-pill py-4 px-8 flex-row gap-4 align-items-center "
              >
                <p>{machine}</p>
              </div>
            ))}
        </div>
      </div>
      <div className="location-main-container flex-column gap-12 xetgo-font-tag p-12">
        <div
          className="flex-row align-items-center justify-content-space-between"
          style={{ height: "26px" }}
        >
          <p>Location:</p>
        </div>

        {vendorDetails.vendor_addresses.length > 0 && (
          <div className="all-location-container flex-row gap-8">
            {vendorDetails.vendor_addresses.map((location, index) => (
              <div key={index} className="location-card xetgo-font-tag">
                <p className="city bolder p-8 flex-row align-items-center justify-content-space-between">
                  {location.city}{" "}
                </p>
                <div className="p-8 flex-column gap-34 flex-1 justify-content-space-between">
                  <p className="line-pin-code flex-column gap-4">
                    {location.address_line} <span>{location.pincode}</span>
                  </p>
                  <div className="flex-column gap-4">
                    <p className="poc-name">
                      {location.poc_name}, {location.poc_phone_number}
                    </p>
                    <p>{location.gstin || ""}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="image-main-container flex-column gap-12 xetgo-font-tag p-12">
        <div className="flex-row align-items-center justify-content-space-between">
          <p>Factory Images:</p>
        </div>
        <div className="all-images-container flex-row gap-8">
          {vendorDetails.vendor_attachments.length > 0 &&
            vendorDetails.vendor_attachments.map((attachment, index) => (
              <a
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                className="sku-image-container position-relative"
              >
                <img
                  className="sku-detail-image"
                  height={61}
                  width={61}
                  src={attachment.url}
                  alt="images"
                />
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VendorDetailCard;
