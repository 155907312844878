import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "TypoPRO Montserrat",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-MediumItalic.ttf",
      fontStyle: "italic",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: 700,
    },
  ],
});
// Define styles
export const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: "8px",
    color: "#545454",
    fontFamily: "TypoPRO Montserrat",
  },
  borderOut: {
    position: "absolute",
    padding: "20px",
    height: "100vh",
    width: "100vw",
    top: "0",
    left: "0",
  },
  borderIn: {
    border: "1px solid #8E8E8E",
    height: "100%",
    width: "100%",
  },
  pageHeader: {
    padding: 8,
    backgroundColor: "#FFF1E3",
    color: "#000059",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  pageHeaderLeftField: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-start",
  },
  pageHeaderRightField: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-end",
  },
  pageHeaderKey: {
    color: "#393A3A",
    fontWeight: 400,
    fontSize: "8px",
  },
  pageHeaderValue: {
    color: "#000059",
    fontSize: "8px",
    fontStyle: "italic",
    fontWeight: 600,
  },
  pageHeaderTitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "12px",
  },
  vendorPOC: {
    fontWeight: 700,
    color: "#000000",
  },
  addressSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "30px",
  },
  addressLeft: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "250px",
    gap: "8px",
  },
  addressRight: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "250px",
    gap: "8px",
    alignItems: "flex-end",
  },
  addressDescription: {
    color: "#545454",
    display: "flex",
    flexDirection: "column",
  },
  addressDescriptionRight: {
    color: "#545454",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  skuSection: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    padding: "6px",
  },
  headerCell: {
    color: "#000059",
    backgroundColor: "#F1EFFF",
    fontSize: "8px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #DDD5F6",
  },
  sNoHeader: {
    width: 50,
  },
  skuDataContainerOdd: {
    borderColor: "#FEE8D0",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
  },
  skuDataContainerEven: {
    borderColor: "#DDD5F6",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
  },
  answerCell: {
    borderRightWidth: "1px",
  },
  sNo: {
    width: 50,
  },
  name: {
    width: 93,
    borderBottomWidth: "1px",
  },
  quantity: {
    width: 77,
    borderBottomWidth: "1px",
  },
  location: {
    width: 127,
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    borderBottomWidth: "1px",
  },
  description: {
    flex: 1,
    borderBottomWidth: "1px",
    textAlign: "right",
  },
  subRowContainer: {
    display: "flex",
    flexDirection: "row",
    color: "#484848",
  },
  subRowLabel: {
    width: 297,
    paddingHorizontal: 6,
    paddingVertical: 4,
    height: "100%",
    borderBottomWidth: "1px",
  },
  subRowLabelQualityCheckTest: {
    width: 297,
  },
  subRowValue: {
    flex: 1,
    paddingHorizontal: 6,
    paddingVertical: 4,
    textAlign: "right",
    borderBottomWidth: "1px",
  },
  subRowLabelLast: {
    width: 297,
    paddingHorizontal: 6,
    paddingVertical: 4,
  },
  subRowValueLast: {
    flex: 1,
    paddingHorizontal: 6,
    paddingVertical: 4,
    textAlign: "right",
  },
  pageFooter: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 24,
  },
  pageFooterName: {
    fontWeight: 500,
    color: "#000000",
  },
  skuAttachmentsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
});
