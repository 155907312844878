import React, { useEffect, useState } from "react";
import "./RaiseQuoteForm.scss";
import Filter from "../Filter/Filter";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import QuantityDropdown from "../QuantityDropdown/QuantityDropdown";
import { getUserAddresses, userDetails } from "../../redux/user/userSelector";
import { useQuoteContext } from "../../context/quote-context";
import { IQuoteProduct, NEW_QUOTE_PRODUCT } from "../../models/QuoteProduct";
import { NewCompanyAddressPayloadSchema } from "../../redux/user/userTypes";
import XetModal from "../shared/xet-modal/XetModal";
import NewAddressForm from "../NewAddressForm/NewAddressForm";
import { newCompanyAddressStart } from "../../redux/user/userAction";
import {
  ScreenSizes,
  breakPointObserver,
} from "../../utils/BreakpointObserver";
import { updateQuoteProduct } from "../../redux/quote/quoteAction";
import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

interface Sku {
  id: number;
  uuid?: string;
  name: string;
  description?: string;
  unit?: string;
}

const RaiseQuoteForm: React.FC = () => {
  const dispatch = useDispatch();

  const [skus, setSkus] = useState<Sku[]>([]);
  const userDetail = useSelector(userDetails);
  const addresses = useSelector(getUserAddresses);
  const [openNewAddressForm, setOpenNewAddressForm] = useState<boolean>(false);
  const [selectedAddresses, setSelectedAddresses] = useState<number[]>([]);
  const [skuDetailEntry, setSkuDetailEntry] = useState<IQuoteProduct | null>();
  const [targetPrice, setTargetPrice] = useState(
    skuDetailEntry?.target_price || 0,
  );
  const { id } = useParams();
  const {
    currentQuoteProduct,
    quoteProductList,
    updateProductList,
    setCurrentQuoteProduct,
    editMode,
    setEditMode,
    updateMode,
  } = useQuoteContext();
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  useEffect(() => {
    fetchSkus();
  }, []);

  useEffect(() => {
    if (currentQuoteProduct.length > 0) {
      setSkuDetailEntry(currentQuoteProduct[0]);
      setTargetPrice(currentQuoteProduct[0].target_price || 0);
      setSelectedAddresses(
        currentQuoteProduct.reduce((val: number[], entry) => {
          if (entry.address_id) {
            val.push(entry.address_id);
          }
          return val;
        }, []),
      );
    }
  }, [currentQuoteProduct]);

  const fetchSkus = async () => {
    try {
      const { data } = await ApiService().client.get(URLS.SKU.OPTIONS);
      setSkus(data.skus);
    } catch (e) {
      console.error(e);
    }
  };

  const handleQuantityChange = (newQuantity: string, measurement: string) => {
    updateSkuDetail("quantity", newQuantity);
    updateSkuDetail("unit", measurement);
  };

  const handleCheckboxChange = (addressId: number) => {
    if (editMode) {
      return;
    }
    const isChecked = selectedAddresses.includes(addressId);
    let newAddressList = [];
    if (isChecked) {
      newAddressList = selectedAddresses.filter((item) => item !== addressId);
    } else {
      newAddressList = [...selectedAddresses, addressId];
    }
    setSelectedAddresses(newAddressList);
  };

  const addProduct = () => {
    let lastId = quoteProductList[quoteProductList.length - 1]?.id || 1;
    selectedAddresses.forEach((row) => {
      if (skuDetailEntry) {
        const entry: IQuoteProduct = {
          ...skuDetailEntry,
          id: lastId + 1,
          address_id: row,
        };
        updateProductList(entry);
        lastId = lastId + 1;
      }
    });
    setSkuDetailEntry(null);
    setCurrentQuoteProduct([{ ...NEW_QUOTE_PRODUCT }]);
  };

  const cancelEditProduct = () => {
    setCurrentQuoteProduct([{ ...NEW_QUOTE_PRODUCT }]);
    setEditMode(false);
  };

  const saveEditProduct = () => {
    selectedAddresses.forEach((row) => {
      if (skuDetailEntry) {
        const entry: IQuoteProduct = {
          ...skuDetailEntry,
          address_id: row,
        };
        updateProductList(entry);
      }
    });
    setEditMode(false);
    setCurrentQuoteProduct([{ ...NEW_QUOTE_PRODUCT }]);
  };

  const handleUpdateQuoteProduct = () => {
    if (skuDetailEntry && skuDetailEntry?.sku) {
      dispatch(
        updateQuoteProduct({
          id: Number(id),
          qpm_id: skuDetailEntry.qpm_id,
          sku_id: skuDetailEntry.sku.id,
          quantity: Number(skuDetailEntry.quantity),
          address_ids: selectedAddresses,
          unit: skuDetailEntry.unit,
          target_price: targetPrice,
        }),
      );
    }
    if (editMode) {
      setEditMode(false);
    }
  };

  const addNewAddress = (payload: NewCompanyAddressPayloadSchema) => {
    if (userDetail.company_id) {
      payload.company_id = userDetail.company_id;
    }
    dispatch(newCompanyAddressStart(payload));
    setOpenNewAddressForm(false);
  };

  const updateSkuDetail = (
    key: "sku" | "quantity" | "unit" | "target_price",
    value: any,
  ) => {
    setSkuDetailEntry((val: IQuoteProduct | undefined | null) => {
      if (val) {
        return { ...val, [key]: value };
      }
      return val;
    });
  };

  return (
    <div
      className={`raise-quote-left-main-container flex-column gap-12 quote-up-left-scroll ${
        updateMode && "form-update-row no-padding"
      } `}
    >
      <div
        className={`raise-quote-left-upper-container flex-column xetgo-font-button bold  ${
          updateMode && "sidebar-width upper-container-height"
        }`}
      >
        <div className="left-upper-container-Bar flex-row px-16 py-12 justify-content-space-between xetgo-font-button bold">
          <p>Raise SKU Enquiry</p>
          {!editMode && (
            <div className="flex-row gap-4 cursor-pointer align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
                alt="add-icon"
                width={12}
                height={12}
              />
              <span
                onClick={addProduct}
                className="blue-font-color xetgo-font-tag"
              >
                Add Next Product
              </span>
            </div>
          )}
          {editMode && !updateMode && (
            <div className="flex-row justify-content-center align-items-center gap-12 edit-options-product-entry">
              <div
                className="cancel-edit-product xetgo-font-tag bold cursor-pointer"
                onClick={cancelEditProduct}
              >
                Cancel
              </div>
              <div
                className="save-edit-product xetgo-font-tag bold py-4 px-12 cursor-pointer"
                onClick={saveEditProduct}
              >
                Save
              </div>
            </div>
          )}

          {editMode && updateMode && (
            <div className="flex-row justify-content-center align-items-center gap-12 edit-options-product-entry">
              <div
                className="save-edit-product xetgo-font-tag bold py-4 px-12 cursor-pointer"
                onClick={handleUpdateQuoteProduct}
              >
                Update
              </div>
            </div>
          )}
        </div>
        <div
          className={`p-12 flex-column gap-12 ${
            updateMode && "update-scrolling"
          }`}
        >
          <div className="input-container-box flex-column gap-8">
            <p className="grey-font-color xetgo-font-tag bold">SKU Name</p>
            <Filter
              option={{
                key: "skuName",
                name: "Sku Name",
              }}
              optionList={skus}
              multipleSelection={false}
              onSelection={(val, _option) => updateSkuDetail("sku", val[0])}
              mode="showNames"
              showPlaceholder={false}
              showId={false}
              preSelected={skuDetailEntry?.sku ? [skuDetailEntry.sku] : []}
              direction="left"
              addNew={false}
            />
          </div>

          <div className="input-container-box flex-column gap-8">
            <p className="grey-font-color xetgo-font-tag bold">Quantity</p>
            <QuantityDropdown
              value={skuDetailEntry?.quantity || ""}
              unit={skuDetailEntry?.unit || ""}
              onQuantityChange={(newQuantity, newMeasurementUnit) => {
                handleQuantityChange(newQuantity, newMeasurementUnit);
              }}
            />
          </div>

          {updateMode && (
            <div className="input-container-box flex-column gap-8">
              <p className="grey-font-color xetgo-font-tag bold">
                Target Price
              </p>
              <div
                style={{ position: "relative" }}
                className="quote-form-input-box flex-row "
              >
                <input
                  type="number"
                  style={{ paddingRight: "30px" }}
                  value={targetPrice}
                  onChange={(e) => setTargetPrice(parseFloat(e.target.value))}
                  className="quote-form-input px-12 py-10 xetgo-font-tag flex-1 "
                />
                <p className="raise-quote-rupee xetgo-font-tag bold">₹</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          updateMode && "sidebar-width"
        } raise-quote-left-upper-container flex-1 overflow-hidden`}
      >
        <div className="left-upper-container-Bar raise-quote-left-bottom-container flex-row px-16 py-12 justify-content-space-between xetgo-font-button bold ">
          <p>Shipping addresses</p>
          <div className="flex-row gap-4 cursor-pointer align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
              alt="add-icon"
              width={12}
              height={12}
            />
            <p
              className="blue-font-color xetgo-font-tag"
              onClick={() => setOpenNewAddressForm(!openNewAddressForm)}
            >
              Add New Address
            </p>
          </div>
        </div>
        <div className="raise-quote-bottom-left-container p-12 flex-column gap-8">
          {addresses.map((address, index) => {
            return (
              <div
                key={index}
                className={`raise-quote-address-container flex-row gap-8 py-3 px-7 ${
                  selectedAddresses.includes(address.id)
                    ? "selectedAddress"
                    : ""
                } cursor-pointer`}
                onClick={() => handleCheckboxChange(address.id)}
              >
                <span
                  className={`raise-quote-custom-checkbox cursor-pointer ${
                    selectedAddresses.includes(address.id)
                      ? "selectedCheckbox"
                      : ""
                  }`}
                ></span>

                <div className="flex-column gap-4 xetgo-font-tag bold flex-1">
                  <p>{address.city}</p>
                  <p
                    className={`xetgo-font-tag grey-font-color ${
                      selectedAddresses.includes(address.id)
                        ? "selectedAddress"
                        : ""
                    }`}
                  >
                    {address.address_line}, {address.state} {address.pincode}
                  </p>
                </div>
              </div>
            );
          })}
          {addresses.length === 0 && (
            <div className="raise-quote-bottom-left-container empty-ship-address xetgo-font-tag bolder flex-column align-items-center justify-content-center gap-12">
              <img
                height={24}
                width={24}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240327113954-Receipt.svg"
                alt="empty-icon"
              />
              <div className="flex-column align-items-center gap-2">
                <p>No Shipping address found</p>
                <p>Add new Address to raise quiry</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {openNewAddressForm &&
        (breakpoint === ScreenSizes.MOBILE ||
        breakpoint === ScreenSizes.MINI_TABLET ? (
          <div className="mobile-bottom-open-container flex-column justify-content-flex-end">
            <NewAddressForm
              onCancel={() => setOpenNewAddressForm(false)}
              onAddAddress={addNewAddress}
            />
          </div>
        ) : (
          <XetModal
            backdropClose={true}
            open={openNewAddressForm}
            handleClose={() => setOpenNewAddressForm(false)}
          >
            <NewAddressForm
              onCancel={() => setOpenNewAddressForm(false)}
              onAddAddress={addNewAddress}
            />
          </XetModal>
        ))}
    </div>
  );
};

export default RaiseQuoteForm;
