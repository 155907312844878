import {
  QuoteDeletePayload,
  QuoteDetail,
  QuoteDetailUpdatePayload,
  TaskCommentSchema,
} from "../../models/Quote";

export const QuoteActions = {
  GET_QUOTE_DETAIL: "GET_QUOTE_DETAIL",
  GET_QUOTE_DETAIL_SUCCESS: "GET_QUOTE_DETAIL_SUCCESS",
  GET_QUOTE_DETAIL_FAILURE: "GET_QUOTE_DETAIL_FAILURE",
  ADD_TASK_COMMENT: "ADD_TASK_COMMENT",
  ADD_TASK_COMMENT_SUCCESS: "ADD_TASK_COMMENT_SUCCESS",
  ADD_TASK_COMMENT_FAILURE: "ADD_TASK_COMMENT_FAILURE",
  UPDATE_QUOTE_PRODUCT: "UPDATE_QUOTE_PRODUCT",
  UPDATE_QUOTE_PRODUCT_SUCCESS: "UPDATE_QUOTE_PRODUCT_SUCCESS",
  UPDATE_QUOTE_PRODUCT_FAILURE: "UPDATE_QUOTE_PRODUCT_FAILURE",
  DELETE_QUOTE_PRODUCT: "DELETE_QUOTE_PRODUCT",
  DELETE_QUOTE_PRODUCT_SUCCESS: "DELETE_QUOTE_PRODUCT_SUCCESS",
  DELETE_QUOTE_PRODUCT_FAILURE: "DELETE_QUOTE_PRODUCT_FAILURE",
  UPDATE_QUOTE_ASSET_RECORDS: "UPDATE_QUOTE_ASSET_RECORDS",
  UPDATE_QUOTE_ASSET_RECORDS_SUCCESS: "UPDATE_QUOTE_ASSET_RECORDS_SUCCESS",
  UPDATE_QUOTE_ASSET_RECORDS_FAILURE: "UPDATE_QUOTE_ASSET_RECORDS_FAILURE",
  DELETE_QUOTE_ASSET_RECORD: "DELETE_QUOTE_ASSET_RECORD",
  DELETE_QUOTE_ASSET_RECORD_SUCCESS: "DELETE_QUOTE_ASSET_RECORD_SUCCESS",
  DELETE_QUOTE_ASSET_RECORD_FAILURE: "DELETE_QUOTE_ASSET_RECORD_FAILURE",
  UPDATE_QUOTE_DETAILS: "UPDATE_QUOTE_DETAILS",
  UPDATE_QUOTE_DETAILS_SUCCESS: "UPDATE_QUOTE_DETAILS_SUCCESS",
  UPDATE_QUOTE_DETAILS_FAILURE: "UPDATE_QUOTE_DETAILS_FAILURE",
};

export interface GetQuoteDetailAction {
  type: typeof QuoteActions.GET_QUOTE_DETAIL;
  payload: {
    id: number;
  };
  error?: string | null;
}

export interface GetQuoteDetailSuccessAction {
  type: typeof QuoteActions.GET_QUOTE_DETAIL_SUCCESS;
  payload: QuoteDetail;
  error?: string | null;
}

export interface GetQuoteDetailFailureAction {
  type: typeof QuoteActions.GET_QUOTE_DETAIL_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface AddTaskCommentAction {
  type: typeof QuoteActions.ADD_TASK_COMMENT;
  payload: FormData;
  error?: null;
}

export interface AddTaskCommentSuccessAction {
  type: typeof QuoteActions.ADD_TASK_COMMENT_SUCCESS;
  payload?: TaskCommentSchema;
  error?: null;
}

export interface AddTaskCommentFailureAction {
  type: typeof QuoteActions.ADD_TASK_COMMENT_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateQuoteProductAction {
  type: typeof QuoteActions.UPDATE_QUOTE_PRODUCT;
  payload: QuoteDetailUpdatePayload;
  error?: null;
}

export interface UpdateQuoteProductSuccessAction {
  type: typeof QuoteActions.UPDATE_QUOTE_PRODUCT_SUCCESS;
  payload?: QuoteDetail;
  error?: null;
}

export interface UpdateQuoteProductFailureAction {
  type: typeof QuoteActions.UPDATE_QUOTE_PRODUCT_FAILURE;
  payload?: null;
  error: string;
}

export interface DeleteQuoteProductAction {
  type: typeof QuoteActions.DELETE_QUOTE_PRODUCT;
  payload: QuoteDeletePayload;
  error?: null;
}

export interface DeleteQuoteProductSuccessAction {
  type: typeof QuoteActions.DELETE_QUOTE_PRODUCT_SUCCESS;
  payload?: { tpm_id: number; product_id: number };
  error?: null;
}

export interface DeleteQuoteProductFailureAction {
  type: typeof QuoteActions.DELETE_QUOTE_PRODUCT_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateQuoteAssetRecordsAction {
  type: typeof QuoteActions.UPDATE_QUOTE_ASSET_RECORDS;
  payload: FormData;
  error?: null;
}

export interface UpdateQuoteAssetRecordsSuccessAction {
  type: typeof QuoteActions.UPDATE_QUOTE_ASSET_RECORDS_SUCCESS;
  payload?: {
    asset_records: { name: string; url: string; id: number }[];
  };
  error?: null;
}

export interface UpdateQuoteAssetRecordsFailureAction {
  type: typeof QuoteActions.UPDATE_QUOTE_ASSET_RECORDS_FAILURE;
  payload?: null;
  error: string;
}

export interface DeleteQuoteAssetRecordAction {
  type: typeof QuoteActions.DELETE_QUOTE_ASSET_RECORD;
  payload: {
    id: number;
  };
  error?: null;
}

export interface DeleteQuoteAssetRecordSuccessAction {
  type: typeof QuoteActions.DELETE_QUOTE_ASSET_RECORD_SUCCESS;
  payload: {
    id: number;
  };
  error?: null;
}

export interface DeleteQuoteAssetRecordFailureAction {
  type: typeof QuoteActions.DELETE_QUOTE_ASSET_RECORD_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateQuoteDetailsAction {
  type: typeof QuoteActions.UPDATE_QUOTE_DETAILS;
  payload: { id: number; description: string };
  error?: null;
}

export interface UpdateQuoteDetailsSuccessAction {
  type: typeof QuoteActions.UPDATE_QUOTE_DETAILS_SUCCESS;
  payload: { id: number; description: string };
  error?: null;
}

export interface UpdateQuoteDetailsFailureAction {
  type: typeof QuoteActions.UPDATE_QUOTE_DETAILS_FAILURE;
  payload?: null;
  error: string;
}

export type QuotesActionTypes =
  | GetQuoteDetailAction
  | GetQuoteDetailSuccessAction
  | GetQuoteDetailFailureAction
  | AddTaskCommentAction
  | AddTaskCommentSuccessAction
  | AddTaskCommentFailureAction
  | UpdateQuoteProductAction
  | UpdateQuoteProductSuccessAction
  | UpdateQuoteProductFailureAction
  | DeleteQuoteProductAction
  | DeleteQuoteProductSuccessAction
  | DeleteQuoteProductFailureAction
  | UpdateQuoteAssetRecordsAction
  | UpdateQuoteAssetRecordsSuccessAction
  | UpdateQuoteAssetRecordsFailureAction
  | DeleteQuoteAssetRecordAction
  | DeleteQuoteAssetRecordSuccessAction
  | DeleteQuoteAssetRecordFailureAction
  | UpdateQuoteDetailsAction
  | UpdateQuoteDetailsSuccessAction
  | UpdateQuoteDetailsFailureAction;
