import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PurchaseOrder,
  PurchaseOrderProduct,
} from "../../models/PurchaseOrder";
import "./CheckoutPurchaseOrders.scss";
import { useSelector } from "react-redux";
import {
  getUserBillingAddresses,
  userDetails,
} from "../../redux/user/userSelector";
import AddressDropdown from "../../components/AddressDropdown/AddressDropdown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { UserAddress } from "../../redux/user/userTypes";
import { FilterOptionSchema } from "../../components/Filter/filter.data";
import { commaFormat } from "../../utils/Helper";

const CheckoutPurchaseOrders: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const deliveryAddress: UserAddress = state.selectedAddress;
  const selectedVendor: FilterOptionSchema = state.selectedVendor;
  const clientID = useSelector(userDetails).company_id;
  const [poNumber, setPoNumber] = useState("");
  const [description, setDescription] = useState("");
  const [deliveryOpen, setDeliveryOpen] = useState(false);
  const [poCreateDate, setPoCreateDate] = useState(dayjs(new Date()));
  const [fileList, setFileList] = useState<File[]>([]);
  const [displayNames, setDisplayNames] = useState<string[]>([]);
  const [checkoutPurchaseOrderList, setCheckoutPurchaseOrderList] = useState<
    PurchaseOrder[]
  >(state?.purchaseOrders || []);
  const addresses = useSelector(getUserBillingAddresses);
  const [selectedAddress, setSelectedAddress] = useState(addresses[0]);
  const [poValue, setPoValue] = useState(0);
  const [gstValue, setGstValue] = useState(0);

  useEffect(() => {
    const storedList: PurchaseOrder[] =
      JSON.parse(localStorage.getItem("selectedProductsList") as string) || [];
    setCheckoutPurchaseOrderList(storedList);
    localStorage.setItem(
      "selectedDeliveryAddress",
      JSON.stringify(deliveryAddress),
    );

    return () => {
      setCheckoutPurchaseOrderList([]);
      if (
        JSON.parse(localStorage.getItem("selectedProductsList") as string)
          ?.length === 0
      ) {
        localStorage.removeItem("selectedDeliveryAddress");
      }
    };
  }, []);

  useEffect(() => {
    let poValue = 0;
    checkoutPurchaseOrderList.forEach((cpo) => {
      poValue += cpo.price_per_unit * cpo.quantity;
    });
    const gstValue = (poValue * 18) / 100;
    setPoValue(poValue);
    setGstValue(gstValue);
    localStorage.setItem(
      "selectedProductsList",
      JSON.stringify(checkoutPurchaseOrderList),
    );
  }, [checkoutPurchaseOrderList]);

  const handleDeleteProduct = (sku_price_id: number) => {
    const updatedPurchaseOrders = checkoutPurchaseOrderList.filter(
      (po: PurchaseOrder) => po.sku_price_id !== sku_price_id,
    );
    setCheckoutPurchaseOrderList(updatedPurchaseOrders);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });

      setFileList(fileList);
      setDisplayNames(displayNameList);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("clientId", Number(clientID).toString());
    formData.append("vendorId", Number(selectedVendor.id).toString());
    formData.append("addressId", Number(deliveryAddress?.id).toString());
    formData.append("billingAddressId", Number(selectedAddress?.id).toString());
    formData.append("description", description);
    formData.append("poNumber", poNumber);
    formData.append("poValue", (poValue + gstValue).toString());
    formData.append("poDate", poCreateDate.toISOString());
    if (fileList.length > 0) {
      formData.append("file", fileList[0]);
      formData.append("displayName", displayNames[0]);
    } else {
      toast.error("Please upload PO File", toastOptions);
      return;
    }

    const products: PurchaseOrderProduct[] = [];
    checkoutPurchaseOrderList.forEach((order) => {
      products.push({
        quantity: order.quantity,
        sku_price_id: order.sku_price_id,
      });
    });
    formData.append("products", JSON.stringify(products));

    try {
      const { data } = await ApiService().client.post(
        URLS.PURCHASE_ORDER.CREATE_PURCHASE_ORDER,
        formData,
      );
      toast.success("Purchase Order created successfully!", toastOptions);
      localStorage.removeItem("selectedDeliveryAddress");
      localStorage.removeItem("selectedProductsList");
      navigate(`/purchase-orders/${data.data["procurement_request_id"]}`);
    } catch (error) {
      console.error(error);
      toast.error(" Files update failed. Please try again.", toastOptions);
    }
  };

  const handleBackNavigation = () => {
    navigate("/purchase-order/orders");
  };

  return (
    <>
      {
        <div className="checkout-page-content flex-column">
          <div className="checkout-page-breadcrumbs px-32 py-16">
            <span
              className="prev-page cursor-pointer  xetgo-font-tag bold"
              onClick={handleBackNavigation}
            >
              {" "}
              Purchase Order /{" "}
            </span>
            <span className="current-page xetgo-font-tag bold">
              {" "}
              Checkout - Cart Summary{" "}
            </span>
          </div>

          <div className="checkout-content-container flex-column flex-1 gap-16">
            <div className="checkout-lower-summary-content flex-row flex-1">
              <div className="summary-content-left-container p-16 flex-column gap-16">
                <div className="xetgo-font-button delivery-address-container">
                  <div className="address-detail-row flex-row">
                    <span className="address-detail-label xetgo-font-mini bold">
                      {" "}
                      DELIVERY ADDRESS{" "}
                    </span>
                    <span className="address-detail-value">
                      {checkoutPurchaseOrderList.length > 0 &&
                      deliveryAddress ? (
                        <p
                          style={{ fontWeight: "500" }}
                          className="flex-column xetgo-font-tag gap-2"
                        >
                          <span className="address-city bold">
                            {deliveryAddress?.city}
                          </span>
                          <span className="address-line xetgo-font-tag">
                            {`${
                              deliveryAddress?.address_line +
                              ", " +
                              deliveryAddress?.state +
                              ", " +
                              deliveryAddress?.pincode
                            }`}
                          </span>
                        </p>
                      ) : (
                        <span className="xetgo-font-tag"> N/A </span>
                      )}
                    </span>
                  </div>

                  <div className="address-detail-row flex-row">
                    <span className="address-detail-label xetgo-font-mini bold">
                      {" "}
                      VENDOR{" "}
                    </span>
                    <span className="address-detail-value xetgo-font-tag bold">
                      {checkoutPurchaseOrderList.length > 0 && selectedVendor
                        ? selectedVendor?.name
                        : "N/A"}
                    </span>
                  </div>
                </div>

                <div className="checkout-products-main-container flex-column flex-1">
                  {checkoutPurchaseOrderList.length > 0 &&
                    checkoutPurchaseOrderList.map((cpo, index) => (
                      <div
                        key={index}
                        className="checkout-product-container xetgo-font-tag"
                      >
                        <div className="flex-column gap-4 p-16">
                          <p className="checkout-title bold">
                            {cpo.product_name}
                          </p>
                          <div className="flex-row align-items-center gap-4 checkout-tags-container">
                            <p className=" px-6 py-2 checkout-ppu xetgo-font-mini bold">
                              ₹ {cpo.price_per_unit}
                            </p>
                          </div>
                        </div>
                        <div className="flex-column gap-4 p-16">
                          <p>
                            {commaFormat(cpo.quantity.toString())} {cpo.unit}
                          </p>
                          <p className="checkout-subtitle">Quantity</p>
                        </div>
                        <div className="flex-column gap-4 p-16 ">
                          <p>
                            <span>₹</span>{" "}
                            {(cpo.price_per_unit * cpo.quantity).toLocaleString(
                              "en-IN",
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              },
                            )}
                          </p>
                          <p className="checkout-subtitle">Total Price</p>
                        </div>
                        <div className="px-24 py-8 flex-column align-items-center">
                          <img
                            onClick={() =>
                              handleDeleteProduct(cpo.sku_price_id)
                            }
                            className="checkout-trash p-4 cursor-pointer "
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308111001-trash-2.svg"
                            alt="delete-icon"
                          />
                        </div>
                      </div>
                    ))}

                  {checkoutPurchaseOrderList.length === 0 && (
                    <div className="empty-heading flex-row align-items-center justify-content-center">
                      {" "}
                      Your cart is empty{" "}
                    </div>
                  )}
                </div>
              </div>

              <div className="summary-content-right-container px-24 py-16 flex-column xetgo-font-tag gap-16">
                <p className="xetgo-font-button bold py-8">
                  Enter billing information
                </p>
                <div className="summary-right-lower-container flex-column justify-content-space-between flex-1">
                  <div className="flex-column gap-16">
                    <div className="flex-column gap-8">
                      <p className="checkout-subtitle">
                        Select Billing Address
                      </p>
                      <AddressDropdown
                        showId={false}
                        selected={selectedAddress || addresses[0]}
                        onSelection={setSelectedAddress}
                        addresses={addresses}
                      />
                    </div>
                    <div className="flex-row gap-16">
                      <div className="flex-column gap-8 checkout-po-left">
                        <p className="checkout-subtitle">PO Number</p>
                        <input
                          value={poNumber}
                          onChange={(e) => setPoNumber(e.target.value)}
                          className="checkout-input-box xetgo-font-tag bold border-box p-10"
                        />
                      </div>
                      <div className="flex-column gap-8 checkout-po-left">
                        <p className="checkout-subtitle">PO Date</p>
                        <div className="po-box-input-container date-pick-main-container xetgo-font-tag bold">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={poCreateDate}
                              disablePast
                              format={"DD/MM/YYYY"}
                              views={["year", "month", "day"]}
                              onChange={(event) => {
                                const val = event?.toDate().toISOString();
                                if (val) {
                                  setPoCreateDate(dayjs(val));
                                  setDeliveryOpen(false);
                                }
                              }}
                              open={deliveryOpen}
                              className="delivery-date-selector"
                            />
                          </LocalizationProvider>
                          <img
                            onClick={() => setDeliveryOpen(!deliveryOpen)}
                            className="po-input-icon cursor-pointer"
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240312112958-calendar-days.svg"
                            alt="calendar"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex-column gap-8">
                      <p className="checkout-subtitle">Description</p>
                      <textarea
                        value={description}
                        placeholder="Message for us"
                        onChange={(e) => setDescription(e.target.value)}
                        className="checkout-input-box xetgo-font-tag bold border-box p-10 checkout-description-area"
                      />
                    </div>

                    <div className="flex-column gap-8">
                      <p className="checkout-subtitle">PO Upload</p>
                      <div className="po-box-input-container full-width">
                        <input
                          className="checkout-input-box border-box p-10 xetgo-font-tag bold"
                          placeholder="PO attachment"
                          disabled={true}
                          value={displayNames[0]}
                        />
                        <input
                          onChange={(event) => {
                            handleFileChange(event);
                          }}
                          id="fileInput"
                          name="file"
                          type="File"
                          style={{ display: "none" }}
                          multiple={false}
                        />
                        <label
                          className="xetgo-font-tag bold checkout-upload-label cursor-pointer"
                          htmlFor="fileInput"
                        >
                          <img
                            className="po-upload-icon cursor-pointer"
                            height={16}
                            width={16}
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308093408-upload.svg"
                            alt="upload-icon"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flex-column gap-20">
                    <div className="checkout-breakdown flex-column gap-2">
                      <span className="breakdown-detail flex-row align-items-center justify-content-space-between">
                        <span className="xetgo-font-tag bold"> Amount </span>
                        <span className="xetgo-font-tag bold">
                          {"₹ " +
                            poValue.toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                      </span>

                      <span className="breakdown-detail flex-row align-items-center justify-content-space-between">
                        <span className="xetgo-font-tag bold"> GST </span>
                        <span
                          className="xetgo-font-tag bold"
                          style={{ color: "#8B8686" }}
                        >
                          {"+ " +
                            "₹ " +
                            gstValue.toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                      </span>

                      <span className="breakdown-detail flex-row align-items-center justify-content-space-between">
                        <span className="xetgo-font-tag bold"> Total </span>
                        <span className="xetgo-font-tag bold">
                          {"= " +
                            "₹ " +
                            (poValue + gstValue).toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                      </span>
                    </div>

                    <button
                      onClick={handleSubmit}
                      className={`checkout-next-btn flex-row py-12 align-items-center justify-content-center gap-12 cursor-pointer ${
                        checkoutPurchaseOrderList.length === 0 ? "disabled" : ""
                      }`}
                      disabled={checkoutPurchaseOrderList.length === 0}
                    >
                      <p className="flex-row align-items-center justify-content-center">
                        Raise Order
                      </p>
                      <img
                        height={16}
                        width={16}
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308090308-arrow-right+%281%29.svg"
                        alt="forward-icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default CheckoutPurchaseOrders;
