import React, { useEffect, useState } from "react";
import "./QuoteTimeline.scss";
import { AllowedStatusSchema, TimelineSchema } from "../../models/Quote";
import { QUOTE_STATUS_PROPERTY_MAP } from "../../constants/QuotesData";
import StatusTimeline from "../StatusTimeline/StatusTimeline";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { useDispatch } from "react-redux";
import { updateQuoteProductSuccess } from "../../redux/quote/quoteAction";

interface QuoteTimelineProps {
  vendorId: number;
  qpmId: number;
  quoteId: number;
}

const QuoteTimeline: React.FC<QuoteTimelineProps> = ({
  vendorId,
  qpmId,
  quoteId,
}) => {
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [allowedStatuses, setAllowedStatuses] = useState<AllowedStatusSchema>(
    {},
  );
  const [timeline, setTimeline] = useState<TimelineSchema[]>([]);
  const [currentStatus, setCurrentStatus] = useState("");
  const dispatch = useDispatch();

  const changeQuoteStatus = async (id: number, status: string) => {
    try {
      const { data } = await ApiService().client.put(
        URLS.VENDORS.UPDATE_VENDOR_STATUS(quoteId),
        {
          id: quoteId,
          qpm_id: qpmId,
          quote_vendor_id: vendorId,
          new_vendor_status: status,
        },
      );
      dispatch(updateQuoteProductSuccess(data));
      const date = new Date();

      const obj = {
        value: status,
        created_at: date.toString(),
      };
      const updatedData = [obj, ...timeline];
      setTimeline(updatedData);
      setCurrentStatus(status);
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };
  const getStatuses = async (id: number) => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDOR_STATUSES(vendorId),
        {
          params: {
            id: vendorId,
          },
        },
      );
      setTimeline(data.timeline);
      setAllowedStatuses(data.allowed_status);
      setCurrentStatus(data.timeline[0].value);
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };

  useEffect(() => {
    getStatuses(vendorId);
  }, []);

  return (
    <main className="quote-timeline-component-wrapper flex-column">
      <div className="quote-timeline-heading flex-row align-items-center justify-content-space-between p-18">
        {currentStatus && (
          <span className="current-status-container flex-row align-items-center">
            <span
              className={`current-status xetgo-font-tag bold ${
                allowedStatuses[currentStatus]?.length > 0 &&
                "status-present-right-side"
              }`}
              style={{
                background:
                  QUOTE_STATUS_PROPERTY_MAP[currentStatus].backgroundColor,
                color: QUOTE_STATUS_PROPERTY_MAP[currentStatus].color,
              }}
            >
              {currentStatus}
            </span>

            {allowedStatuses[currentStatus]?.length > 0 && (
              <span
                className="statuses-dropdown flex-row align-items-center justify-content-center"
                style={{
                  background:
                    QUOTE_STATUS_PROPERTY_MAP[currentStatus].backgroundColor,
                  color: QUOTE_STATUS_PROPERTY_MAP[currentStatus].color,
                }}
                onClick={() => setOpenStatusDialog(!openStatusDialog)}
              >
                <svg
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 16l-6-6h12l-6 6z"
                    fill={QUOTE_STATUS_PROPERTY_MAP[currentStatus].color}
                  />
                </svg>
                {openStatusDialog && (
                  <span className="status-option-container flex-column gap-12 align-items-end p-8 xetgo-font-mini bolder">
                    {allowedStatuses[currentStatus].map(
                      (status, timelineIndex) => {
                        return (
                          <span
                            style={{
                              background:
                                QUOTE_STATUS_PROPERTY_MAP[status]
                                  .backgroundColor,
                              color: QUOTE_STATUS_PROPERTY_MAP[status].color,
                            }}
                            className="status-option-status px-6 py-4"
                            key={timelineIndex}
                            onClick={() => changeQuoteStatus(vendorId, status)}
                          >
                            {status}
                          </span>
                        );
                      },
                    )}
                  </span>
                )}
              </span>
            )}
          </span>
        )}
      </div>
      <div className="quote-status-timeline-wrapper px-16 py-24 flex-1 flex-column gap-22">
        <StatusTimeline statusTimelineData={timeline} />
      </div>
    </main>
  );
};

export default QuoteTimeline;
