import { createSelector } from "reselect";
import { RootState } from "../store";
import { ClientPurchaseOrderDetailSchema } from "../../models/ClientPurchaseOrderDetail";

const selectPurchaseOrderDetailsState = (
  state: RootState,
): ClientPurchaseOrderDetailSchema | null =>
  state.purchaseOrder.purchaseOrderDetails;

export const selectPurchaseOrderDetails = createSelector(
  [selectPurchaseOrderDetailsState],
  (purchaseOrderState) => purchaseOrderState,
);

export const selectPurchaseRequestComments = createSelector(
  [selectPurchaseOrderDetailsState],
  (filterState) => filterState?.comments || [],
);
