import React, { useRef, useState } from "react";
import "./QCDetaiQualityCheckCard.scss";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { useParams } from "react-router-dom";
import XetToggle from "../xet-toggle/XetToggle";

interface QualityCheckSchema {
  key: string;
  observed_val: string;
  sku_quality_check_id: number;
  unit: string;
  standard_val: string;
  vendor_val: string;
  approved: boolean;
  delivered_quality_check_id: number;
}
interface QualityCheckProps {
  QualityCheck: QualityCheckSchema;
}

const QCDetailQualityCheckCard: React.FC<QualityCheckProps> = ({
  QualityCheck,
}) => {
  const { id } = useParams();
  const [qualityCheck, setQualityCheck] =
    useState<QualityCheckSchema>(QualityCheck);
  const observeValueRef = useRef<HTMLInputElement>(null);
  const [observedValue, setObservedValue] = useState(qualityCheck.observed_val);
  const [observeValueId, setObserveValueId] = useState(-1);

  const handleObserveValueKeyDown = async (
    e: React.KeyboardEvent<HTMLDivElement>,
    deliveredQCID: number,
  ) => {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (observedValue.trim() == qualityCheck.observed_val) {
        observeValueRef.current?.blur();
        setObserveValueId(-1);
        return;
      }
      const { data } = await ApiService().client.put(
        URLS.QUALITY_CHECK.UPDATE_DELIVERED_QUALITY_CHECK(deliveredQCID),
        {
          key: "value",
          value: observedValue,
          skuQualityCheckId: qualityCheck.sku_quality_check_id,
          deliveryScheduleId: Number(id),
        },
      );
      setQualityCheck((prev) => {
        return {
          ...prev,
          delivered_quality_check_id: data.data.id,
        };
      });
      observeValueRef.current?.blur();
      setObserveValueId(-1);
    }
  };

  const handleToggleValue = async (deliveredQCId: number, value: boolean) => {
    try {
      if (qualityCheck.approved === value) {
        return;
      }
      const { data } = await ApiService().client.put(
        URLS.QUALITY_CHECK.UPDATE_DELIVERED_QUALITY_CHECK(deliveredQCId),
        {
          key: "approved",
          value: value,
          skuQualityCheckId: qualityCheck.sku_quality_check_id,
          deliveryScheduleId: Number(id),
        },
      );
      setQualityCheck((prev) => {
        return {
          ...prev,
          delivered_quality_check_id: data.data.id,
        };
      });
      setQualityCheck((prev) => {
        return {
          ...prev,
          approved: value,
        };
      });
    } catch (error) {
      console.error("Error Rejecting Quality Check", error);
    }
  };

  return (
    <div className="quality-check-grid-container qc-detail">
      <div className="qc-check-left-grid xetgo-font-tag">
        <p className="p-12 right-border">{qualityCheck.key}</p>
        <p className="p-12 right-border">{qualityCheck.standard_val}</p>
        <p className="p-12 right-border">{qualityCheck.vendor_val}</p>
      </div>
      <div className="observation-main-container flex-row ">
        <OutsideAlerter className="flex-1" action={() => setObserveValueId(-1)}>
          <input
            ref={observeValueRef}
            key={qualityCheck.sku_quality_check_id}
            onClick={() => setObserveValueId(qualityCheck.sku_quality_check_id)}
            className={`quality-check-observe-value-input xetgo-font-tag full-width p-12 border-box ${
              observeValueId === qualityCheck.sku_quality_check_id
                ? "observed-value-editing"
                : ""
            }`}
            onChange={(e) => setObservedValue(e.target.value)}
            onKeyDown={(e) =>
              handleObserveValueKeyDown(
                e,
                qualityCheck.delivered_quality_check_id,
              )
            }
            value={observedValue}
          />
        </OutsideAlerter>
        <div className="flex-row align-items-center justify-content-flex-end py-10 px-16">
          <XetToggle
            onToggle={(value: boolean) =>
              handleToggleValue(qualityCheck.delivered_quality_check_id, value)
            }
            toggleValue={qualityCheck.approved}
            backgroundColor="#f3f4f6"
            disableColor="#FF5252"
            enableColor="#00C414"
          />
        </div>
      </div>
    </div>
  );
};

export default QCDetailQualityCheckCard;
