import {
  QuoteAssetRecordsUpdateResponse,
  QuoteDeletePayload,
  QuoteDetail,
  TaskCommentSchema,
} from "../../models/Quote";
import { QuoteActions, QuotesActionTypes } from "./quoteTypes";

export interface QuotesState {
  quoteDetails: null | QuoteDetail;
  loading: boolean;
  error: string | null | undefined;
}

const initialState: QuotesState = {
  quoteDetails: null,
  loading: false,
  error: null,
};

const quotesReducer = (
  state = initialState,
  action: QuotesActionTypes,
): QuotesState => {
  switch (action.type) {
    case QuoteActions.GET_QUOTE_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case QuoteActions.GET_QUOTE_DETAIL_SUCCESS: {
      const payload = action.payload as QuoteDetail;
      return {
        ...state,
        quoteDetails: payload,
        loading: false,
        error: null,
      };
    }
    case QuoteActions.GET_QUOTE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case QuoteActions.UPDATE_QUOTE_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case QuoteActions.UPDATE_QUOTE_PRODUCT_SUCCESS: {
      const payload = action.payload as QuoteDetail;
      return {
        ...state,
        quoteDetails: payload,
        loading: true,
      };
    }

    case QuoteActions.UPDATE_QUOTE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case QuoteActions.DELETE_QUOTE_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case QuoteActions.DELETE_QUOTE_PRODUCT_SUCCESS: {
      const payload = action.payload as QuoteDeletePayload;

      if (!state.quoteDetails) {
        return state;
      }

      const updatedQuoteProductDetails = state.quoteDetails.product_details.map(
        (quoteProductDetail) => {
          const updatedProductDetails =
            quoteProductDetail.product_details.filter(
              (productDetail) =>
                productDetail.qpm_id !== payload.quote_product_id,
            );

          return {
            ...quoteProductDetail,
            product_details: updatedProductDetails,
          };
        },
      );

      return {
        ...state,
        quoteDetails: {
          ...state.quoteDetails,
          product_details: updatedQuoteProductDetails,
        },
        loading: true,
      };
    }

    case QuoteActions.DELETE_QUOTE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case QuoteActions.ADD_TASK_COMMENT:
      return {
        ...state,
        loading: true,
      };

    case QuoteActions.ADD_TASK_COMMENT_SUCCESS: {
      const payload = action.payload as TaskCommentSchema;
      const detail = state.quoteDetails ? { ...state.quoteDetails } : null;
      if (detail?.comments && payload) {
        detail.comments = [...detail.comments, payload];
      }
      return {
        ...state,
        quoteDetails: detail,
        loading: true,
      };
    }

    case QuoteActions.ADD_TASK_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case QuoteActions.UPDATE_QUOTE_ASSET_RECORDS:
      return {
        ...state,
        loading: true,
      };

    case QuoteActions.UPDATE_QUOTE_ASSET_RECORDS_SUCCESS: {
      const payload = action.payload as QuoteAssetRecordsUpdateResponse;

      if (!state.quoteDetails) {
        return state;
      }

      return {
        ...state,
        quoteDetails: {
          ...state.quoteDetails,
          asset_records: [
            ...state.quoteDetails.asset_records,
            ...payload.asset_records,
          ],
        },
        loading: false,
      };
    }

    case QuoteActions.UPDATE_QUOTE_ASSET_RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case QuoteActions.DELETE_QUOTE_ASSET_RECORD:
      return {
        ...state,
        loading: true,
      };

    case QuoteActions.DELETE_QUOTE_ASSET_RECORD_SUCCESS: {
      const payload = action.payload as { id: number };
      if (!state.quoteDetails) {
        return state;
      }
      return {
        ...state,
        quoteDetails: {
          ...state.quoteDetails,
          asset_records: state.quoteDetails.asset_records.filter(
            (val) => val.id !== payload.id,
          ),
        },
        loading: false,
      };
    }

    case QuoteActions.DELETE_QUOTE_ASSET_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case QuoteActions.UPDATE_QUOTE_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case QuoteActions.UPDATE_QUOTE_DETAILS_SUCCESS: {
      const payload = action.payload as {
        id: number;
        description: string;
      };
      if (!state.quoteDetails) {
        return state;
      }
      return {
        ...state,
        quoteDetails: {
          ...state.quoteDetails,
          description: payload.description,
        },
        loading: false,
      };
    }

    case QuoteActions.UPDATE_QUOTE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default quotesReducer;
