import { useSelector } from "react-redux";
import AddressDropdown from "../../components/AddressDropdown/AddressDropdown";
import "./ClientPurchaseOrderStatus.scss";
import React, { useEffect, useState } from "react";
import {
  getUserAddresses,
  getWarehouseUserAddresses,
} from "../../redux/user/userSelector";
import { useNavigate, useParams } from "react-router-dom";
import { UserAddress } from "../../redux/user/userTypes";
import { ClientPurchaseOrder } from "../../models/PurchaseOrder";
import { formatDateWithYear } from "../../utils/date-util";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { CLIENT_PO_PROPERTY_MAP } from "../../constants/PurchaseData";
import Filter from "../../components/Filter/Filter";
import { FilterOptionSchema } from "../../components/Filter/filter.data";
import { commaFormat, round } from "../../utils/Helper";

const ClientPurchaseOrderStatus: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const addresses = useSelector(getWarehouseUserAddresses);
  const currentAddress = useSelector(getUserAddresses).find(
    (address) => address.id == Number(id),
  );
  const [selectedAddress, setSelectedAddress] = useState(
    currentAddress || addresses[0],
  );
  const [totalVendors, setTotalVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState<FilterOptionSchema>();
  const [clientPos, setClientPos] = useState<ClientPurchaseOrder[]>([]);
  const [filteredClientPos, setFilteredClientPos] = useState<
    ClientPurchaseOrder[]
  >([]);

  useEffect(() => {
    fetchVendors().then((r) => {
      return r;
    });
  }, []);

  useEffect(() => {
    if (selectedAddress) {
      getClientPurchaseOrderList().then((r) => {
        return r;
      });
    }
  }, [selectedAddress, selectedVendor]);

  const fetchVendors = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDOR_LIST,
      );
      setTotalVendors(data.vendors);
    } catch (e) {
      console.error(e);
    }
  };

  const getClientPurchaseOrderList = async () => {
    let url = `${URLS.PURCHASE_ORDER.GET_CLIENT_PO_LIST(
      Number(id || selectedAddress.id),
    )}`;
    if (selectedVendor?.id) {
      url = `${URLS.PURCHASE_ORDER.GET_CLIENT_PO_LIST(
        Number(id || selectedAddress.id),
      )}?vendor_id=${selectedVendor.id}`;
    }
    try {
      const { data } = await ApiService().client.get(url);
      setClientPos(data.data);
      setFilteredClientPos(data.data);
    } catch (error) {
      console.error("Error fetching quotes:", error);
      return [];
    }
  };

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    const filteredClientPos = clientPos.filter((po) =>
      po.po_number.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredClientPos(filteredClientPos);
  };

  const handleAddressSelection = (val: UserAddress) => {
    setSelectedAddress(val);
    navigate(`/purchase-orders`);
  };

  const handleDetailPageNavigation = (val: number) => {
    navigate(`/purchase-orders/${val}`);
  };

  return (
    <div className="purchase-order-list-page-content flex-column ">
      <div className="purchase-order-list-breadcrumbs-container flex-row gap-4 px-32 py-16">
        <p
          className="xetgo-font-tag bold cursor-pointer"
          style={{ color: "#AA5200" }}
        >
          Purchase Order
        </p>
      </div>
      <div className="purchase-order-list-upper-container px-16 py-12 flex-row justify-content-space-between align-items-center gap-24">
        <div className="search-bar-container">
          <input
            className="py-8 px-34 search-input border-box xetgo-font-tag"
            onChange={handleSearchFile}
            placeholder="Search by PO Number"
          />
          <img
            height={16}
            width={16}
            className="search-icon"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
            alt="search-icon"
          />
        </div>

        <div className="header-right-section flex-row align-items-center gap-8">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
            alt="filter"
          />
          <Filter
            option={{
              key: "vendors",
              name: "Vendor",
            }}
            optionList={totalVendors}
            multipleSelection={false}
            onSelection={(val, _option) => {
              setSelectedVendor(val[0]);
            }}
            mode="showNames"
            showPlaceholder={true}
            direction="right"
            addNew={false}
            classname="category-filter-input"
            preSelected={selectedVendor ? [selectedVendor] : []}
          />
          <AddressDropdown
            showId={false}
            selected={selectedAddress}
            onSelection={handleAddressSelection}
            addresses={addresses}
            classname="category-filter-input"
          />
        </div>
      </div>
      <div className="purchase-order-list-lower-container p-16 ">
        <div className="po-status-list-container ">
          <div className="po-status-list-grid po-status-list-header-row  xetgo-font-tag bold">
            <p className="xetgo-font-tag bold py-12 px-8">#</p>
            <p className="xetgo-font-tag bold py-12 px-8">PO Number</p>
            <p className="xetgo-font-tag bold py-12 px-8 po-status-hid">
              Created on
            </p>{" "}
            <p className="xetgo-font-tag bold py-12 px-8 po-product-hid">
              Products
            </p>
            <p className="xetgo-font-tag bold py-12 px-8 ">PO Value</p>{" "}
            <p className="xetgo-font-tag py-12 px-8">Status</p>
          </div>

          <div>
            {filteredClientPos.length > 0 &&
              filteredClientPos.map(
                (purchaseOrder: ClientPurchaseOrder, index: number) => {
                  return (
                    <div
                      key={index}
                      className="full-width po-card-hover cursor-pointer"
                      onClick={() =>
                        handleDetailPageNavigation(purchaseOrder.id)
                      }
                    >
                      <div className="po-status-list-grid flex-row po-status-list-header-row xetgo-font-tag ">
                        <p className="px-8 py-12 flex-row justify-content-start align-items-center">
                          {index + 1}
                        </p>
                        <div className="py-12 px-8 flex-row justify-content-start align-items-center">
                          <p className="po-enquiry-tag py-4 px-12 flex-row justify-content-start align-items-center">
                            {purchaseOrder.po_number}
                          </p>
                        </div>
                        <p className="py-12 px-8 po-status-hid flex-row justify-content-start align-items-center">
                          {formatDateWithYear(purchaseOrder.po_date)}
                        </p>
                        <p className="py-12 px-8 po-product-hid flex-row justify-content-start align-items-center">
                          {purchaseOrder.product_count}
                        </p>
                        <p className="py-12 px-8 flex-row justify-content-start align-items-center">
                          ₹{" "}
                          {commaFormat(
                            round(
                              parseFloat(purchaseOrder.po_value),
                              2,
                            ).toString(),
                          )}
                        </p>
                        <div className="status-container py-12 px-8 flex-row justify-content-space-between">
                          <p
                            style={{
                              color:
                                CLIENT_PO_PROPERTY_MAP[purchaseOrder.status]
                                  .color,
                              backgroundColor:
                                CLIENT_PO_PROPERTY_MAP[purchaseOrder.status]
                                  .backgroundColor,
                            }}
                            className="px-10 py-6 po-status-tag flex-row justify-content-start align-items-center"
                          >
                            {purchaseOrder.status}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPurchaseOrderStatus;
