import React, { ChangeEvent, useEffect, useState } from "react";
import "./QuantityDropdown.scss";
import { MEASUREMENT_UNITS } from "./QuantityDropdown.data";

interface QuantityDropdownProps {
  onQuantityChange: (newQuantity: string, measurementUnit: string) => void;
  value: string;
  unit: string;
}

const QuantityDropdown: React.FC<QuantityDropdownProps> = ({
  onQuantityChange,
  value,
  unit,
}) => {
  const [quantityInputValue, setQuantityInputValue] = useState<string>(value);
  const [selectedMeasurementUnit, setSelectedMeasurementUnit] =
    useState<string>(unit || MEASUREMENT_UNITS[0]);

  useEffect(() => {
    setQuantityInputValue(value);
    setSelectedMeasurementUnit(unit || MEASUREMENT_UNITS[0]);
  }, [value]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = event.target.value.trim();
    setQuantityInputValue(newQuantity);
    onQuantityChange(newQuantity, selectedMeasurementUnit);
  };

  const handleMeasurementUnitChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const newMeasurementUnit = event.target.value;
    setSelectedMeasurementUnit(newMeasurementUnit);
    onQuantityChange(quantityInputValue, newMeasurementUnit);
  };

  return (
    <div className="flex-row position-relative">
      <input
        value={quantityInputValue || ""}
        onChange={handleInputChange}
        className="quote-form-input  px-12 py-10 xetgo-font-tag "
      />
      <span className="quantity-pipe xetgo-font-tag bold">|</span>
      <select
        value={selectedMeasurementUnit}
        onChange={handleMeasurementUnitChange}
        className="measurement-box xetgo-font-tag bold"
      >
        {MEASUREMENT_UNITS.map((unit) => (
          <option style={{ color: "black" }} key={unit} value={unit}>
            {unit}
          </option>
        ))}
      </select>
    </div>
  );
};

export default QuantityDropdown;
