import React, { useState } from "react";
import "./DeliveryLocationSelector.scss";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { UserAddress } from "../../redux/user/userTypes";

interface DeliveryLocationSelectorProps {
  selectedAddress: number[];
  addAddress: (id: number) => void;
  addresses: UserAddress[];
}

const DeliveryLocationSelector: React.FC<DeliveryLocationSelectorProps> = ({
  selectedAddress,
  addAddress,
  addresses,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <OutsideAlerter action={() => setOpenDropdown(false)}>
      <div className="location-selector-container py-8 px-12 xetgo-font-tag">
        <div
          onClick={() => setOpenDropdown((val) => !val)}
          className="cursor-pointer"
        >
          Choose Location
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715100443-chevron-down.svg"
            alt="down"
            height={16}
            width={16}
            className="down-arrow-drop"
          />
        </div>
        {openDropdown && (
          <div className="delivery-selector-options-list p-6">
            {addresses.map((address) => (
              <div
                className="delivery-location-option px-16 py-8 flex-row gap-12 cursor-pointer"
                key={address.id}
                onClick={() => addAddress(address.id)}
              >
                <div
                  className={`checkbox ${
                    selectedAddress.indexOf(address.id) > -1 &&
                    "selected-checkbox"
                  }`}
                ></div>
                <div className="flex-column gap-4">
                  <p
                    className="xetgo-font-tag bold"
                    style={{ color: "#AA5200" }}
                  >
                    {address.city}
                  </p>
                  <p className="xetgo-font-tag" style={{ color: "#999898" }}>
                    {address.address_line}, {address.city}, {address.state},{" "}
                    {address.country}, {address.pincode}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};

export default DeliveryLocationSelector;
