import {
  ClientPurchaseOrderDetailSchema,
  ClientPurchaseRequestComment,
  ProductDeliverySchedule,
} from "../../models/ClientPurchaseOrderDetail";

export const PurchaseOrderAction = {
  GET_PURCHASE_ORDER_DETAIL: "GET_PURCHASE_ORDER_DETAIL",
  GET_PURCHASE_ORDER_DETAIL_SUCCESS: "GET_PURCHASE_ORDER_DETAIL_SUCCESS",
  GET_PURCHASE_ORDER_DETAIL_FAILURE: "GET_PURCHASE_ORDER_DETAIL_FAILURE",
  ADD_PURCHASE_REQUEST_COMMENT: "ADD_PURCHASE_REQUEST_COMMENT",
  ADD_PURCHASE_REQUEST_COMMENT_SUCCESS: "ADD_PURCHASE_REQUEST_COMMENT_SUCCESS",
  ADD_PURCHASE_REQUEST_COMMENT_FAILURE: "ADD_PURCHASE_REQUEST_COMMENT_FAILURE",
  ADD_PURCHASE_REQUEST_DELIVERY_POD: "ADD_PURCHASE_REQUEST_DELIVERY_POD",
  ADD_PURCHASE_REQUEST_DELIVERY_POD_SUCCESS:
    "ADD_PURCHASE_REQUEST_DELIVERY_POD_SUCCESS",
  ADD_PURCHASE_REQUEST_DELIVERY_POD_FAILURE:
    "ADD_PURCHASE_REQUEST_DELIVERY_POD_FAILURE",
  ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE: "ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE",
  ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS:
    "ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS",
  ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE:
    "ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE",
  DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE:
    "DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE",
  DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS:
    "DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS",
  DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE:
    "DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE",
  UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE:
    "UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE",
  UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCCESS:
    "UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCCESS",
  UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE:
    "UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE",
};

export interface AddPurchaseDeliveryPODSchema {
  id: number;
  url: string;
  actual_delivery_date: string;
}

export interface AddPurchaseOrderDeliverySchedulePayload {
  purchase_request_id: number;
  prp_id: number;
  quantity: number;
  expected_date: string;
}

export interface GetPurchaseOrderDetailAction {
  type: typeof PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL;
  payload: { id: number };
  error?: string | null;
}

export interface GetPurchaseOrderDetailSuccessAction {
  type: typeof PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL_SUCCESS;
  payload: ClientPurchaseOrderDetailSchema;
  error?: string | null;
}

export interface GetPurchaseOrderDetailFailureAction {
  type: typeof PurchaseOrderAction.GET_PURCHASE_ORDER_DETAIL_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface AddPurchaseRequestCommentAction {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT;
  payload: FormData;
  error?: null;
}

export interface AddPurchaseRequestCommentSuccessAction {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT_SUCCESS;
  payload?: ClientPurchaseRequestComment;
  error?: null;
}

export interface AddPurchaseRequestCommentFailureAction {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_REQUEST_COMMENT_FAILURE;
  payload?: null;
  error: string;
}

export interface AddPurchaseRequestDeliveryPOD {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD;
  payload: FormData;
  error?: null;
}

export interface AddPurchaseRequestDeliveryPODSuccess {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD_SUCCESS;
  payload?: AddPurchaseDeliveryPODSchema;
  error?: null;
}

export interface AddPurchaseRequestDeliveryPODFailure {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_REQUEST_DELIVERY_POD_FAILURE;
  payload?: null;
  error: string;
}

export interface AddPurchaseOrderDeliverySchedule {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE;
  payload: AddPurchaseOrderDeliverySchedulePayload;
  error?: null;
}

export interface AddPurchaseOrderDeliveryScheduleSuccess {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS;
  payload?: ProductDeliverySchedule;
  error?: null;
}

export interface AddPurchaseOrderDeliveryScheduleFailure {
  type: typeof PurchaseOrderAction.ADD_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE;
  payload?: null;
  error: string;
}

export interface DeletePurchaseOrderDeliverySchedule {
  type: typeof PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE;
  payload: { po_id: number; id: number };
  error?: null;
}

export interface DeletePurchaseOrderDeliveryScheduleSuccess {
  type: typeof PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCESS;
  payload?: { purchase_request_product_id: number };
  error?: null;
}

export interface DeletePurchaseOrderDeliveryScheduleFailure {
  type: typeof PurchaseOrderAction.DELETE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdatePurchaseOrderDeliverySchedule {
  type: typeof PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE;
  payload: { po_id: number; id: number; track_link: string };
  error?: null;
}

export interface UpdatePurchaseOrderDeliveryScheduleSuccess {
  type: typeof PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_SUCCESS;
  payload?: { schedule: ProductDeliverySchedule };
  error?: null;
}

export interface UpdatePurchaseOrderDeliveryScheduleFailure {
  type: typeof PurchaseOrderAction.UPDATE_PURCHASE_ORDER_DELIVERY_SCHEDULE_FAILURE;
  payload?: null;
  error: string;
}

export type PurchaseOrderActionTypes =
  | GetPurchaseOrderDetailAction
  | GetPurchaseOrderDetailSuccessAction
  | GetPurchaseOrderDetailFailureAction
  | AddPurchaseRequestCommentAction
  | AddPurchaseRequestCommentSuccessAction
  | AddPurchaseRequestCommentFailureAction
  | AddPurchaseRequestDeliveryPOD
  | AddPurchaseRequestDeliveryPODSuccess
  | AddPurchaseRequestDeliveryPODFailure
  | AddPurchaseOrderDeliverySchedule
  | AddPurchaseOrderDeliveryScheduleSuccess
  | AddPurchaseOrderDeliveryScheduleFailure
  | DeletePurchaseOrderDeliverySchedule
  | DeletePurchaseOrderDeliveryScheduleSuccess
  | DeletePurchaseOrderDeliveryScheduleFailure
  | UpdatePurchaseOrderDeliverySchedule
  | UpdatePurchaseOrderDeliveryScheduleSuccess
  | UpdatePurchaseOrderDeliveryScheduleFailure;
