import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import CommentCard from "./CommentCard/CommentCard";
import "./CommentSection.scss";
import { useDispatch } from "react-redux";
import { getFileIcon } from "../../utils/Helper";
import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { Vendor } from "../../models/Quote";
import SocketService from "../../services/socketService";
import { ClientPurchaseRequestComment } from "../../models/ClientPurchaseOrderDetail";

interface CommentSectionProps {
  source: "PURCHASE_REQUEST" | "TASK" | "VENDOR";
  source_id: number;
  handleClose: () => void;
  vendorDetails?: Vendor;
  showHeader?: boolean;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  source,
  source_id,
  handleClose,
  vendorDetails,
  showHeader = false,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [fileList, setFileList] = useState<File[]>([]);
  const [showIndex, setShowIndex] = useState(-1);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const descriptionRef = useRef<null | HTMLDivElement>(null);
  const [allComments, setAllComments] = useState<
    ClientPurchaseRequestComment[]
  >([]);

  useEffect(() => {
    if (source === "VENDOR") {
      getVendorComments();
    } else {
      getPurchaseComments();
    }

    const socket = SocketService.getInstance().getSocket();
    socket.on("COMMENT_NOTIFICATIONS", (data: any) => {
      setAllComments((rows) => [...rows, data.message]);
    });
  }, []);

  useEffect(() => {
    todayIndex();
    scrollToBottom();
  }, [allComments]);

  const getVendorComments = async () => {
    try {
      if (vendorDetails) {
        const { data } = await ApiService().client.get(
          URLS.VENDORS.FETCH_VENDOR_COMMENTS(
            Number(id),
            vendorDetails?.vendor_id,
          ),
        );
        setAllComments(data.comments);
      }
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };

  const addVendorComment = async (formData: FormData) => {
    try {
      if (vendorDetails && descriptionRef.current) {
        const { data } = await ApiService().client.post(
          URLS.VENDORS.ADD_VENDOR_COMMENT(Number(id), vendorDetails.vendor_id),
          formData,
        );
        allComments.push(data.comment);
      }
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };

  const addPurchaseComment = async (formData: FormData) => {
    try {
      const { data } = await ApiService().client.post(
        URLS.PURCHASE_ORDER.ADD_CLIENT_PO_COMMENT(Number(id)),
        formData,
      );
      allComments.push(data.comment);
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };

  const getPurchaseComments = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.PURCHASE_ORDER.ADD_CLIENT_PO_COMMENT(Number(id)),
      );
      setAllComments(data.comments);
    } catch (error) {
      console.error("error fetching statuses", error);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current != null) {
        messagesEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 100);
  };

  const addComment = async () => {
    if (descriptionRef.current) {
      const formData = new FormData();
      formData.set("comment", descriptionRef.current?.innerHTML);
      if (fileList.length > 0) {
        fileList.forEach((file, index) => {
          formData.append("files[]", file);
          formData.append("displayNames[]", file.name);
        });
      }
      if (source === "PURCHASE_REQUEST") {
        formData.set("id", source_id.toString());
        formData.set("comment_type", "CLIENT_DISCUSSION");
        addPurchaseComment(formData);
      } else if (source === "VENDOR") {
        formData.set("vendor_id", vendorDetails?.vendor_id.toString() || "");
        formData.set("id", id || "");
        addVendorComment(formData);
      }
      descriptionRef.current.textContent = "";
      setFileList([]);
    }
  };

  const todayIndex = () => {
    const today = dayjs().format("DD-MM-YY");
    let found = false;
    allComments.forEach((comment, index) => {
      if (comment.timestamp.startsWith(today) && !found) {
        setShowIndex(index);
        found = true;
      }
    });
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      addComment().then((r) => {
        return r;
      });
      if (descriptionRef.current) {
        descriptionRef.current.innerHTML = "";
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setFileList(files);
      const displayNames = files.map((file) => file.name);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append("files[]", file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          formData.append("displayNames[]", displayName);
        }
      });
    }
  };

  const deleteFile = (file: File) => {
    setFileList((old) => old.filter((row) => row.name !== file.name));
  };

  return (
    <div className="task-comments-container border-box flex-column justify-content-space-between">
      {showHeader && (
        <div
          className="flex-row p-16 justify-content-space-between align-items-center"
          style={{ borderBottom: "1px solid #E1E1E1" }}
        >
          <div className="flex-row gap-12 align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240511083320-chatbox.svg"
              width={16}
              height={16}
              alt="comment"
            />
            <p style={{ color: "#111111" }} className="xetgo-font-tag bolder">
              Comments
            </p>
          </div>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240511083320-x+%281%29.svg"
            width={16}
            height={16}
            className="cursor-pointer"
            onClick={handleClose}
            alt="cross"
          />
        </div>
      )}
      <div className="comment-list-container flex-column gap-12 p-16">
        {allComments.map((comment, index) => {
          return (
            <div key={index}>
              {showIndex === index && (
                <div className="today-border">
                  <p className="today xetgo-font-mini px-4">TODAY</p>
                </div>
              )}
              <CommentCard
                avatar={comment.avatar}
                name={comment.name}
                timestamp={comment.timestamp}
                description={comment.comment || comment.description}
                commentFiles={comment.files}
                user_id={comment.user_id}
              />
            </div>
          );
        })}

        <div ref={messagesEndRef} />
      </div>
      <div className="add-comment-container full-width p-16 border-box">
        <div className="full-width p-8 border-box xetgo-font-tag comment-value">
          {fileList && (
            <div>
              {fileList.map((file: File, index: number) => {
                return (
                  <div
                    style={{ background: "white", height: "40px" }}
                    className="file-card flex-row p-8 justify-content-space-between border-box"
                    key={index}
                  >
                    <div className="file-left flex-row gap-16 align-items-center">
                      <img
                        src={getFileIcon(file)}
                        alt="file-icon"
                        height={24}
                        width={24}
                        className="file-preview"
                      />
                      <p className="xetgo-font-tag file-name">{file.name}</p>
                    </div>
                    <div className="flex-row gap-12 align-items-center">
                      <img
                        className="cursor-pointer"
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                        alt="close"
                        width={16}
                        height={16}
                        onClick={() => deleteFile(file)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div
            style={{ outline: "none" }}
            className="full-width p-8 border-box xetgo-font-tag "
            ref={descriptionRef}
            onKeyDown={onEnterPress}
            contentEditable={true}
          ></div>
        </div>

        <div className="attach-send-comment-container flex-row align-items-center justify-content-space-between">
          <input
            onChange={(event) => {
              handleFileChange(event).then((r) => {
                return r;
              });
            }}
            id="commentFileInput"
            name="file"
            type="File"
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="commentFileInput">
            <img
              className="cursor-pointer"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-_WYSIWYG-toolbar-button.svg"
              alt="attachment-icon"
            />
          </label>
          <img
            onClick={addComment}
            className="cursor-pointer"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-Send+Button.svg"
            alt="send-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
