import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import userReducer from "./user/userReducer";
import productsReducer from "./product/productReducer";
import quotesReducer from "./quote/quoteReducer";
import purchaseOrderReducer from "./purchase_order/purchaseOrderReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userReducer,
  products: productsReducer,
  quote: quotesReducer,
  purchaseOrder: purchaseOrderReducer,
});

export default persistReducer(persistConfig, rootReducer);
