import "./App.scss";
import "./helpers/_helperClasses.scss";
import { Suspense, useEffect, useState } from "react";
import AvailableRoutes from "./utils/AvailableRoutes";
import ApiService from "./services/apiService";
import Loader from "./components/Loader/Loader";
import Lottie from "lottie-react";
import loadingAnimationData from "./assets/images/loadingAnimationPack.json";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SocketService from "./services/socketService";
import { isNotNullOrUndefined } from "./utils/Helper";
import { useSelector } from "react-redux";
import { userDetails } from "./redux/user/userSelector";
import { UserState } from "./redux/user/userReducer";

function App() {
  const [loading, setLoading] = useState(false);
  const [globalLoader, setGlobalLoader] = useState(true);
  const user: UserState = useSelector(userDetails);

  useEffect(() => {
    setTimeout(() => {
      setGlobalLoader(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const socket = SocketService.getInstance().getSocket();
    socket.on("connect", function () {
      if (isNotNullOrUndefined(user?.id)) {
        socket.emit("CONFIG", user);
      }
    });

    const subscription = ApiService().loadingSubject.subscribe((res) => {
      setLoading(res);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div>
      {globalLoader && (
        <div className="lottie-animation-main-container">
          <Lottie
            animationData={loadingAnimationData}
            loop={true}
            className="lottie-container"
          />
        </div>
      )}
      {loading && <Loader />}
      <div className={`${globalLoader ? "hidden-content" : ""}`}>
        <Suspense fallback={<div>Loading...</div>}>
          <AvailableRoutes />
          <ToastContainer position="top-right" />
        </Suspense>
      </div>
    </div>
  );
}

export default App;
