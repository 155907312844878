import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "./rootReducer";
import { UserState } from "./user/userReducer";
import { FilterOptionSchema } from "../components/Filter/filter.data";
import { QuotesState } from "./quote/quoteReducer";
import { PurchaseOrderDetailState } from "./purchase_order/purchaseOrderReducer";

export interface RootState {
  user: UserState;
  products: FilterOptionSchema[];
  quote: QuotesState;
  purchaseOrder: PurchaseOrderDetailState;
}

const sagaMiddleWare = createSagaMiddleware();
/* eslint-disable  @typescript-eslint/no-explicit-any */
const middlewares: any = [sagaMiddleWare];

if (process.env.REACT_APP_ENV === "local") middlewares.push(logger);

const store: any = createStore(rootReducer, applyMiddleware(...middlewares));
sagaMiddleWare.run(rootSaga);
const persistor = persistStore(store);

export { store, persistor };
