import React, { useEffect, useState } from "react";
import "./AddressDropdown.scss";
import { UserAddress } from "../../redux/user/userTypes";
import OutsideAlerter from "../../utils/OutsideClickDetector";

const AddressDropdown = ({
  selected,
  showId,
  onSelection,
  addresses,
  classname,
  placeholder,
}: {
  selected: UserAddress | null;
  showId: boolean;
  onSelection: (val: UserAddress) => void;
  addresses: UserAddress[];
  classname?: string;
  placeholder?: string;
}) => {
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(
    selected,
  );
  const [openAddressBox, setOpenAddressBox] = useState(false);

  useEffect(() => {
    setSelectedAddress(selected);
  }, [selected]);

  const handleAddressSelection = (val: UserAddress) => {
    onSelection(val);
    setOpenAddressBox(false);
  };

  return (
    <>
      <OutsideAlerter action={() => setOpenAddressBox(false)}>
        <div className="address-dropdown-main">
          <div
            className={`${classname} flex-row address-filter-box align-items-center px-12 py-10 justify-content-space-between cursor-pointer`}
            onClick={() => {
              setOpenAddressBox(!openAddressBox);
            }}
          >
            {selectedAddress ? (
              <span
                className={`address-dropdown-box address-city flex-row align-items-center justify-content-space-between xetgo-font-tag bold`}
              >
                <span className="dropdown-value">{selectedAddress?.city}</span>
              </span>
            ) : (
              <span
                style={{ color: "rgb(153, 152, 152)" }}
                className="address-dropdown-box
                            flex-row align-items-center justify-content-space-between xetgo-font-tag bold"
              >
                {placeholder ? placeholder : "Select Address"}
              </span>
            )}

            <img
              className="address-dropdown-icon"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131100053-chevron-down.svg"
              alt="addressDropdown-icon"
            />
          </div>
          {openAddressBox && (
            <div className="address-dropdown-container p-4">
              <div className="options-container flex-column gap-2 mt-2">
                {addresses.map((value: UserAddress, index: number) => {
                  return (
                    <div
                      className={`address-option  px-16 py-12 flex-column gap-4 ${
                        selectedAddress?.id === value.id
                          ? "address-option-active"
                          : ""
                      }`}
                      key={index}
                      onClick={() => handleAddressSelection(value)}
                    >
                      <p className=" xetgo-font-tag bold address-city">
                        {showId && `${value.id} -`} {value.city}
                      </p>
                      <p className="address-subtitle  xetgo-font-tag">
                        {` ${value.address_line}, ${value.state}, ${value.pincode}.`}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </OutsideAlerter>
    </>
  );
};

export default AddressDropdown;
