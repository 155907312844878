import React, { useEffect, useMemo, useState } from "react";
import "./VendorQuote.scss";
import { breakPointObserver } from "../../utils/BreakpointObserver";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { Quote } from "../../models/Quote";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDateWithYear, formatTime } from "../../utils/date-util";
import {
  EXCLUDE_SEARCH_TERMS,
  QUOTE_STATUS_PROPERTY_MAP,
} from "../../constants/QuotesData";
import { debounce } from "lodash";
import environment from "../../environments/environment";

interface VendorQuotesProps {
  vendor_id: number;
}

const VendorQuotes: React.FC<VendorQuotesProps> = ({ vendor_id }) => {
  const navigate = useNavigate();
  const [quoteList, setQuoteList] = useState<Quote[]>([]);
  const [searchedText, setSearchedText] = useState("");
  const [breakpoint, setBreakpoint] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  const perPage = 20;
  const excludedSearchTerms = EXCLUDE_SEARCH_TERMS;

  useEffect(() => {
    getVendorQuotes(vendor_id, searchedText, pageNumber).then((r) => {
      return r;
    });
  }, [vendor_id]);

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  const getVendorQuotes = async (
    vendor_id: number,
    searchText: string,
    page: number,
  ) => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDOR_QUOTES(vendor_id),
        {
          params: {
            search_string: searchText,
            pageNumber: page,
            perPage: perPage,
          },
        },
      );
      if (data.vendor_quotes.length > 0) {
        const updatedQuoteList = [...quoteList, ...data.vendor_quotes];
        setQuoteList(updatedQuoteList);
        setPageNumber(pageNumber + 1);
        if (updatedQuoteList.length == perPage * pageNumber) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("Error fetching quotes:", error);
      return [];
    }
  };

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      if (!excludedSearchTerms.includes(searchText)) {
        setPageNumber(pageNumber);
        getVendorQuotes(vendor_id, searchText, pageNumber).then((r) => {
          return r;
        });
      }
    }, 500);
  }, []);

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    debouncedSearch(searchText);
  };

  const navigateToDetail = (clientId: number) => {
    const url = `${environment.clientUrl}/quotes/${clientId}`;
    window.open(url, "_blank");
  };

  return (
    <div className="vendor-sku-quote-card-main-content-container">
      <div
        className={`quote-list-lower-main-container flex-1 flex-column p-16}`}
      >
        <div className={`sku-product-row sku-product-tab-row`}>
          <p className="index"></p>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
              alt="enquiry-icon"
              height={12}
              width={12}
            />
            <p>Enquiry ID</p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
              alt="calendar-icon"
              height={12}
              width={12}
            />
            <p>Enquiry On</p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143408-building-2.svg"
              alt="building-icon"
              height={12}
              width={12}
            />
            <p>Location </p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
              alt="status-icon"
              height={12}
              width={12}
            />
            <p>SKUs </p>
          </div>
          <div className="flex-row gap-8 p-12 xetgo-font-tag align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
              alt="status-icon"
              height={12}
              width={12}
            />
            <p>Status </p>
          </div>
        </div>
        <div className="flex-1">
          <InfiniteScroll
            dataLength={quoteList.length}
            next={() => getVendorQuotes(vendor_id, searchedText, pageNumber)}
            hasMore={hasMore}
            loader={<h4></h4>}
            className="infiniteScrollComponent"
            scrollableTarget="scrollableDiv"
          >
            <div className="all-sku-products-main-container">
              {quoteList.length > 0 &&
                quoteList.map((quote, index) => {
                  return (
                    <div
                      key={index}
                      className={`sku-product-row sku-product-container xetgo-font-tag cursor-pointer quoteStatus-card-hover sku-product-tab-row`}
                      onClick={() => navigateToDetail(quote.id)}
                    >
                      <p className="index p-12 title content-center">
                        {index + 1 < 10
                          ? `00${index + 1}`
                          : index + 1 < 99
                            ? `0${index + 1}`
                            : `${index + 1}`}
                      </p>
                      <div className="px-12 py-8 uuid-container right-side-border content-center">
                        <p className="sku-id px-12 py-4">{quote.uuid}</p>
                      </div>
                      <p className="p-12 title right-side-border content-center">
                        {formatDateWithYear(quote.created_at)},
                        <span className="sub-title">
                          {" "}
                          {formatTime(quote.created_at)}
                        </span>
                      </p>
                      <p className="p-12 title right-side-border flex-row gap-4 address-container content-center">
                        {quote.addresses.map((address, addIndex) => {
                          return (
                            <p key={`${addIndex}`}>
                              {address}
                              {quote.addresses.length - 1 === addIndex
                                ? ""
                                : ","}
                            </p>
                          );
                        })}
                      </p>
                      <p className="p-12 right-side-border title content-center">
                        {quote.number_of_products}
                      </p>
                      <div className="status-container py-12 px-8 content-center">
                        <p
                          style={{
                            color:
                              QUOTE_STATUS_PROPERTY_MAP[quote.status].color,
                            backgroundColor:
                              QUOTE_STATUS_PROPERTY_MAP[quote.status]
                                .backgroundColor,
                          }}
                          className="px-10 py-6 status-tag flex-row align-items-center gap-4"
                        >
                          <img
                            src={QUOTE_STATUS_PROPERTY_MAP[quote.status]?.url}
                            alt={quote.status}
                            width={16}
                            height={16}
                          />
                          {quote.status}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default VendorQuotes;
