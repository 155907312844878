import React, { useState } from "react";
import "./VendorTimelineComment.scss";
import { Vendor } from "../../models/Quote";
import CommentSection from "../CommentSection/CommentSection";
import QuoteTimeline from "../QuoteTimeline/QuoteTimeline";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";

interface VendorTimelineCommentProps {
  vendorDetail: Vendor;
  handleClose: () => void;
  qpmId: number;
  quoteId: number;
}

const VendorTimelineComment: React.FC<VendorTimelineCommentProps> = ({
  vendorDetail,
  handleClose,

  qpmId,
  quoteId,
}) => {
  const [showTimeline, setShowTimeline] = useState(true);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="vendor-timeline-comment-page border-box p-12"
    >
      <div className="vendor-timeline-content-container flex-column justify-content-space-between">
        <div className="full-width flex-row align-items-center justify-content-flex-end timeline-cross-container">
          <img
            onClick={handleClose}
            className="cursor-pointer"
            height={16}
            width={16}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240508120308-x.svg"
            alt="cross-icon"
          />
        </div>
        <div className="vendor-timeline-heading-container p-8 xetgo-font-tag flex-row">
          <p
            onClick={(e) => {
              e.stopPropagation(), setShowTimeline(true);
            }}
            className={`${
              showTimeline && "tab-active"
            } header-tab p-4 flex-row align-items-center justify-content-center`}
          >
            Timeline
          </p>
          <p
            onClick={(e) => {
              e.stopPropagation(), setShowTimeline(false);
            }}
            className={`${
              !showTimeline && "tab-active"
            } header-tab p-4 flex-row align-items-center justify-content-center`}
          >
            Comments
          </p>
        </div>
        <div className="below-status-card-container ">
          {showTimeline && (
            <QuoteTimeline
              vendorId={vendorDetail.id}
              qpmId={qpmId}
              quoteId={quoteId}
            />
          )}
          {!showTimeline && (
            <CommentSection
              source={"VENDOR"}
              source_id={vendorDetail.id}
              handleClose={handleClose}
              vendorDetails={vendorDetail}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorTimelineComment;
