import { put, call, takeLatest, all } from "redux-saga/effects";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { FetchProductsAction, ProductActions } from "./productTypes";
import { fetchProductsFailure, fetchProductsSuccess } from "./productAction";

function* getProductsSaga(_action: FetchProductsAction) {
  try {
    const { data } = yield call(
      ApiService().client.get,
      `${URLS.PRODUCTS.ALL_PRODUCTS}`,
    );
    yield put(fetchProductsSuccess(data));
  } catch (error) {
    yield put(fetchProductsFailure("Error fetching products."));
  }
}

function* watchGetProducts() {
  yield takeLatest(ProductActions.FETCH_PRODUCTS_REQUEST, getProductsSaga);
}

export default function* productSaga() {
  yield all([watchGetProducts()]);
}
