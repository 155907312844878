import React, { useEffect, useState } from "react";
import "./CompanyProfile.scss";
import { useSelector } from "react-redux";
import {
  getUserBillingAddresses,
  getUserWarehouseAddresses,
  selectProfilePercentageCompletion,
  userDetails,
} from "../../redux/user/userSelector";
import XetModal from "../../components/shared/xet-modal/XetModal";
import AddNewCompanyAddress from "../../components/AddNewCompanyAddress/AddNewCompanyAddress";
import CompanyDetailsEdit from "../../components/CompanyDetailsEdit/CompanyDetailsEdit";
import dayjs from "dayjs";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { UserOrdersDetails } from "../../models/User";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { commaFormat } from "../../utils/Helper";

const CompanyProfile: React.FC = () => {
  const navigate = useNavigate();

  // REDUCER VARIABLES
  const billingAddresses = useSelector(getUserBillingAddresses);
  const warehouseAddresses = useSelector(getUserWarehouseAddresses);
  const profileCompletion = useSelector(selectProfilePercentageCompletion);
  const currentUser = useSelector(userDetails);

  // STATE VARIABLES
  const [selectedAddressType, setSelectedAddressType] = useState("BILLING");
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openBillingAddressDialog, setOpenBillingAddressDialog] = useState(false);
  const [openWarehouseAddressDialog, setOpenWarehouseAddressDialog] = useState(false);
  const [companyOrderDetails, setCompanyOrderDetails] = useState<UserOrdersDetails>();

  useEffect(() => {
    fetchOrderDetails().then((r) => {
      return r;
    });
  }, []);

  const fetchOrderDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.USERS.COMPANY_ORDER_DETAILS,
      );
      const orderDetails: UserOrdersDetails = data.data;
      setCompanyOrderDetails(orderDetails);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAddressTypeChange = () => {
    if (selectedAddressType === "BILLING") {
      setSelectedAddressType("WAREHOUSE");
    } else {
      setSelectedAddressType("BILLING");
    }
  };

  const handleAddressDialogOpen = () => {
    if (selectedAddressType === "BILLING") {
      setOpenBillingAddressDialog(true);
    } else {
      setOpenWarehouseAddressDialog(true);
    }
  };

  return (
    <div className="company-profile-content-page  border-box flex-column full-width">
      <p className="upper-heading-container xetgo-font-tag  py-12 px-32">
        Pacpro <span className="sub-heading">/ Company Profile</span>
      </p>
      <div className="lower-main-container flex-1 p-16 flex-column gap-12">
        <div className="complete-profile-container p-16 flex-column gap-8">
          <p className=" xetgo-font-button bold">Complete Your Profile</p>
          <p className="sub-heading xetgo-font-tag ">
            By completing all the details you have a higher chance of getting
            seen by Clients.
          </p>
          <div className="percentage-main-container flex-row gap-12 position-relative align-items-center xetgo-font-tag bold">
            <p>{profileCompletion} %</p>
            <div className="flex-1 profile-progress-background">
              <div
                className="profile-progress"
                style={{ width: `${profileCompletion}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="multi-boxes-main-container flex-row gap-4">
          <div className="box box-1 flex-column gap-4 border-box px-24 py-16">
            <p className="xetgo-font-h2 heading head-size ">
              ₹{" "}
              {commaFormat(
                companyOrderDetails?.total_order_value.toString() || "",
              )}
            </p>
            <p className="xetgo-font-tag">Total Orders Value</p>
          </div>
          <div className="box box-2 flex-column gap-24 border-box px-24 py-16">
            <div
              className="quote-list-hyperlink flex-row align-items-center gap-4 cursor-pointer xetgo-font-tag"
              onClick={() => navigate("/quotes")}
            >
              <p className="xetgo-font-tag"> Quotes List</p>
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231206070847-brown-arrow-forward.svg"
                alt="forward"
              />
            </div>
            <div className="flex-column gap-4">
              <p className="bold xetgo-font-h2 total-quote head-size ">
                {companyOrderDetails?.ongoing_quotes}
              </p>
              <p className="xetgo-font-tag">Quotes Sent</p>
            </div>
          </div>
          <div className="box box-3 border-box px-24 py-16 flex-column gap-24">
            <div
              className="po-hyperlink flex-row align-items-center gap-4 cursor-pointer xetgo-font-tag"
              onClick={() => navigate("/purchase-order/status")}
            >
              <p className="xetgo-font-tag">Orders Statuses</p>
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617130014-arrow-right+%282%29.svg"
                alt="forward"
              />
            </div>
            <div className="flex-column gap-4">
              <p className="bold xetgo-font-h2 total-po head-size ">
                {companyOrderDetails?.ongoing_orders}
              </p>
              <p className="xetgo-font-tag">Ongoing POs</p>
            </div>
          </div>
          <div className="box box-4 border-box px-24 flex-column gap-24 py-16">
            <div
              className="delivery-hyperlink flex-row align-items-center gap-4 cursor-pointer xetgo-font-tag"
              onClick={() => navigate("/purchase-order/status")}
            >
              <p className="xetgo-font-tag">Delivery Calendar</p>
              <img
                height={12}
                width={12}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617132833-arrow-right+%283%29.svg"
                alt="forward"
              />
            </div>
            <div className="flex-column gap-4">
              <p className="total-delivery bold xetgo-font-h2 head-size ">
                {dayjs(
                  companyOrderDetails?.next_delivery_scheduled_date,
                ).format("DD/MM/YYYY") == "Invalid Date"
                  ? "--"
                  : dayjs(
                    companyOrderDetails?.next_delivery_scheduled_date,
                  ).format("DD/MM/YYYY")}
              </p>
              <p className="detail-label xetgo-font-tag">Next Delivery</p>
            </div>
          </div>
        </div>

        <div className="company-detail-main-container p-16 flex-column gap-16">
          <div className="profile-gst-main-container flex-row justify-content-space-between">
            <div className="flex-row gap-12 align-items-center">
              <img
                width={40}
                height={40}
                className="profile-pic"
                src={
                  currentUser.avatar ||
                  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617132833-arrow-right+%283%29.svg"
                }
                alt="Profile"
              />
              <div className="flex-column gap-4 company-poc-container xetgo-font-tag">
                <p className="bolder">{currentUser.company_details?.name}</p>
                <p className="heading flex-row gap-8">
                  {currentUser.name}
                  <span className="sub-heading">
                    {currentUser.phone_number}
                  </span>
                </p>
              </div>
            </div>
            <p className=" gst-container py-12 px-16 xetgo-font-tag bold">
              {currentUser.company_details?.gst_number}
            </p>
            <img
              height={16}
              width={16}
              onClick={() => setOpenCompanyDialog(true)}
              className="cursor-pointer profile-edit-icon"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
              alt="edit-icon"
            />
          </div>
          <div className="xetgo-font-tag flex-row justify-content-space-between edit-main-container">
            <p style={{ color: "#484848" }}>Details </p>
            <img
              height={16}
              width={16}
              onClick={() => setOpenCompanyDialog(true)}
              className="cursor-pointer details-edit-icon"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
              alt="edit-icon"
            />
            <p className=" detail-gst py-12 px-16 xetgo-font-tag bold flex-row gap-2">
              GST: <span>{currentUser.company_details?.gst_number}</span>
            </p>
          </div>
          <div className="flex-column company-profile-details-container company-address-max">
            <div className="flex-column gap-12 p-12 ">
              <div className="flex-row justify-content-space-between">
                <p className="company-profile-heading"> Type</p>
                <p className="company-profile-sub-head">
                  {currentUser.company_details?.company_type}
                </p>
              </div>
              <div className="flex-row justify-content-space-between">
                <p className="company-profile-heading">Nature of Business</p>
                <p className="company-profile-sub-head">
                  {currentUser.company_details?.nature_of_business}
                </p>
              </div>

              <div className="flex-row justify-content-space-between">
                <p className="company-profile-heading">PAN Number</p>
                <p className="company-profile-sub-head">
                  {currentUser.company_details?.pan_number}
                </p>
              </div>

              <div className="flex-row justify-content-space-between">
                <p className="company-profile-heading">PAN Proof</p>
                {currentUser.company_details && currentUser.company_details.company_attachments?.pan && (
                  <a
                    href={currentUser.company_details.company_attachments?.pan.url}
                    target="_blank"
                    rel="noreferrer"
                    className="company-profile-attachments"
                  >
                    View
                  </a>
                )}
              </div>

              <div className="flex-row justify-content-space-between">
                <p className="company-profile-heading">GST Number</p>
                <p className="company-profile-sub-head">
                  {currentUser.company_details?.gst_number}
                </p>
              </div>

              <div className="flex-row justify-content-space-between">
                <p className="company-profile-heading">GST Proof</p>
                {currentUser.company_details?.company_attachments?.gst && (
                  <a
                    href={currentUser.company_details.company_attachments?.gst.url}
                    target="_blank"
                    rel="noreferrer"
                    className="company-profile-attachments"
                  >
                    View
                  </a>
                )}
              </div>
            </div>
          </div>
          <img
            className="boxes-img"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618094725-Group+16.svg"
            alt="4-box-icon"
            height={115}
            width={145}
          />
        </div>

        <div
          style={{ width: "100%" }}
          className="company-profile-details-container flex-column flex-1"
        >
          <div
            style={{ borderBottom: "1px solid rgb(238, 238, 238)" }}
            className="flex-row justify-content-space-between border-box py-12 px-21 company-detail-padded align-items-center"
          >
            <p className="xetgo-font-button bold"> Address</p>
            <div className="company-profile-address-right-box flex-row gap-12 align-items-center">
              <Tooltip
                title={` Add ${selectedAddressType.toLocaleLowerCase()} address`}
              >
                <img
                  onClick={handleAddressDialogOpen}
                  className="cursor-pointer"
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240408112655-package-plus.svg"
                  alt="add-icon"
                />
              </Tooltip>

              <div
                onClick={handleAddressTypeChange}
                className="toggle-address-box flex-row  xetgo-font-mini align-items-center bolder cursor-pointer p-2"
              >
                <p
                  className={`${selectedAddressType === "BILLING" &&
                    "active-address-company"
                    }  py-5 px-8`}
                >
                  Billing
                </p>
                <p
                  className={`${selectedAddressType === "WAREHOUSE" &&
                    "active-address-company"
                    }  py-5 px-8`}
                >
                  Warehouse
                </p>
              </div>
            </div>
          </div>

          {selectedAddressType === "BILLING" && billingAddresses.length > 0 && (
            <div className="flex-column all-addresses-container p-12 gap-8">
              {billingAddresses.map((address, index) => {
                return (
                  <div
                    key={index}
                    className="company-profile-address-container flex-row justify-content-space-between xetgo-font-tag p-12"
                  >
                    <div className="flex-column gap-4 address-poc-box">
                      <p className="bold city-name">{address.city}</p>
                      <div className="address-line">
                        <p>{`${address.address_line}, ${address.state}`}</p>
                        <p>{address.pincode}</p>
                      </div>
                    </div>
                    <div className="flex-column gap-8 align-items-end justify-items-center company-address-poc ">
                      {address.address_poc?.poc_name && (
                        <p className="bold">
                          POC -
                          <span className="xetgo-font-tag sub-head-poc">
                            {` ${address.address_poc?.poc_name}, ${address.address_poc?.contact_number}`}
                          </span>
                        </p>
                      )}
                      {address.gstin && (
                        <p className="bold">
                          GST -
                          <span className="xetgo-font-tag company-subtitle">
                            {` ${address.gstin}`}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {selectedAddressType === "WAREHOUSE" && (
            <div className="flex-column all-addresses-container p-12 gap-8">
              {warehouseAddresses.length > 0 &&
                warehouseAddresses.map((address, index) => {
                  return (
                    <div
                      key={index}
                      className="company-profile-address-container flex-row justify-content-space-between xetgo-font-tag p-12"
                    >
                      <div className="flex-column gap-4 ">
                        <p className="bold city-name">{address.city}</p>
                        <div className="address-line">
                          <p>{`${address.address_line}, ${address.state}, ${address.pincode}`}</p>
                        </div>
                      </div>
                      <div className="flex-column gap-8 align-items-end justify-items-center">
                        {address.address_poc.poc_name && (
                          <p className="bold">
                            POC -
                            <span className="xetgo-font-tag sub-head-poc">
                              {`  ${address.address_poc.poc_name}, ${address.address_poc.contact_number}`}
                            </span>
                          </p>
                        )}
                        {address.gstin && (
                          <p className="bold">
                            GST -
                            <span className="xetgo-font-tag ">
                              {` ${address.gstin}`}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          {selectedAddressType === "BILLING" &&
            billingAddresses.length === 0 && (
              <div className="company-profile-empty-adrress flex-column full-width  align-items-center gap-12 justify-content-center flex-1">
                <img
                  height={24}
                  width={24}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240409110035-Layers.svg"
                  alt="empty-icon"
                />
                <div className="xetgo-font-tag bold">
                  <p>No Billing Address found in the record</p>
                  <p style={{ textAlign: "center" }}>Add new billing address</p>
                </div>
              </div>
            )}
          {selectedAddressType === "WAREHOUSE" &&
            warehouseAddresses.length === 0 && (
              <div className="company-profile-empty-adrress flex-column full-width  align-items-center gap-12 justify-content-center flex-1">
                <img
                  height={24}
                  width={24}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240409110035-Layers.svg"
                  alt="empty-icon"
                />
                <div className="xetgo-font-tag bold">
                  <p>No Warehouse Address found in the record</p>
                  <p style={{ textAlign: "center" }}>
                    Add new Warehouse address
                  </p>
                </div>
              </div>
            )}
        </div>
      </div>
      <XetModal
        backdropClose={true}
        open={openBillingAddressDialog}
        handleClose={() => setOpenBillingAddressDialog(false)}
      >
        <AddNewCompanyAddress
          addressType="Billing"
          companyId={currentUser.company_id}
          handleClose={() => setOpenBillingAddressDialog(false)}
        />
      </XetModal>
      <XetModal
        backdropClose={true}
        open={openWarehouseAddressDialog}
        handleClose={() => setOpenWarehouseAddressDialog(false)}
      >
        <AddNewCompanyAddress
          addressType="Warehouse"
          companyId={currentUser.company_id}
          handleClose={() => setOpenWarehouseAddressDialog(false)}
        />
      </XetModal>
      <XetModal
        backdropClose={true}
        open={openCompanyDialog}
        handleClose={() => setOpenCompanyDialog(false)}
      >
        <CompanyDetailsEdit
          companyDetails={currentUser.company_details}
          companyId={currentUser.company_id}
          handleClose={() => setOpenCompanyDialog(false)}
          userAvatar={currentUser.avatar}
        />
      </XetModal>
    </div>
  );
};

export default CompanyProfile;
