import React, { useEffect, useState } from "react";
import "./FilterDropdown.scss";
import { FilterOptionSchema, FilterTypes } from "../Filter/filter.data";

import { SearchTypes } from "../Filter/SearchTypes.data";

const FilterDropdown = ({
  filter,
  options,
  multipleSelection,
  selected,
  selection,
  direction,
  addNew,
  addNewClicked,
  showId,
  vertical,
  sort,
}: {
  filter: FilterTypes | SearchTypes;
  options: FilterOptionSchema[];
  multipleSelection: boolean;
  selected: FilterOptionSchema[];
  selection: (val: FilterOptionSchema[]) => void;
  direction: "left" | "right";
  addNew: boolean;
  addNewClicked: () => void;
  showId: boolean;
  vertical: "top" | "bottom";
  sort: boolean;
}) => {
  const [search, setSearch] = useState("");
  const [selectedOptions, setSelectedOptions] =
    useState<FilterOptionSchema[]>(selected);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const sortOptionsAlphabetically = (options: FilterOptionSchema[]) => {
    if (!sort) {
      return options;
    }
    const sorted = options
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => {
        return (
          (selectedIds.includes(a.id) ? 0 : 1) -
          (selectedIds.includes(b.id) ? 0 : 1)
        );
      });
    return sorted;
  };

  const [optionList, setOptionList] = useState<FilterOptionSchema[]>(
    sortOptionsAlphabetically(options),
  );

  const searchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target?.value || "");
    setOptionList(
      sortOptionsAlphabetically(
        options.filter((val) =>
          val.name
            .toLowerCase()
            .includes(event.target?.value.toLowerCase() || ""),
        ),
      ),
    );
  };

  const toggleOption = (value: FilterOptionSchema) => {
    const isPresent = selectedIds.includes(value.id);
    let selectedValues: FilterOptionSchema[] = [];
    if (isPresent) {
      selectedValues = multipleSelection
        ? selectedOptions.filter((val) => val.id !== value.id)
        : [];
    } else {
      selectedValues = multipleSelection
        ? [...selectedOptions, value]
        : [value];
    }
    selection(selectedValues);
    setSelectedOptions(selectedValues);
  };

  useEffect(() => {
    setSelectedIds(selectedOptions.map((val) => val.id));
  }, [selectedOptions]);

  useEffect(() => {
    setOptionList((val) => sortOptionsAlphabetically(val));
  }, [selectedIds]);

  return (
    <div
      className={`dropdown-container p-4 ${vertical}-align-drop`}
      style={{ [direction]: 0 }}
    >
      <div className="flex-row gap-4">
        <input
          className="search-value full-width xetgo-font-tag"
          value={search}
          placeholder={`Search ${filter.name}`}
          onChange={searchValue}
        ></input>
        {addNew && (
          <div
            className="add-new-btn xetgo-font-tag flex-row gap-1 p-4"
            onClick={addNewClicked}
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
              alt="add"
              width={16}
              height={16}
            />
            Add New
          </div>
        )}
      </div>
      <div className="options-container flex-column gap-2 mt-2">
        {optionList.map((value: FilterOptionSchema, index: number) => {
          return (
            <div
              className={`option ${
                selectedIds.includes(value.id) ? "option-active" : ""
              }`}
              key={index}
              onClick={() => toggleOption(value)}
            >
              {multipleSelection && <div className="checkbox" />}
              <p className="value xetgo-font-tag">
                {showId && `${value.id} -`} {value.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterDropdown;
