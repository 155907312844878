import { useSelector } from "react-redux";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import "./VendorsPurchaseOrders.scss";
import React, { useEffect, useState } from "react";
import { getWarehouseUserAddresses } from "../../redux/user/userSelector";
import { useNavigate } from "react-router-dom";
import { UserAddress } from "../../redux/user/userTypes";
import { VendorPurchaseOrder } from "../../models/PurchaseOrder";
import { formatDateWithYear } from "../../utils/date-util";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { CLIENT_PO_PROPERTY_MAP } from "../../constants/PurchaseData";
import Filter from "../Filter/Filter";
import { FilterOptionSchema } from "../Filter/filter.data";
import { VendorDetailSchema } from "../../models/Quote";
import environment from "../../environments/environment";

type IVendorPurchaseOrders = {
  vendorDetail: VendorDetailSchema;
};

const VendorPurchaseOrders: React.FC<IVendorPurchaseOrders> = ({
  vendorDetail,
}) => {
  const navigate = useNavigate();
  const addresses = useSelector(getWarehouseUserAddresses);
  const [skuAddresses, setSkuAddresses] = useState<UserAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(
    skuAddresses[0],
  );
  const [skusList, setSkusList] = useState<FilterOptionSchema[]>([]);
  const [selectedSku, setSelectedSku] = useState<FilterOptionSchema>();
  const [vendorPos, setVendorPos] = useState<VendorPurchaseOrder[]>([]);
  const [filteredVendorPos, setFilteredVendorPos] = useState<
    VendorPurchaseOrder[]
  >([]);

  useEffect(() => {
    const skuAddress = addresses.filter(
      (row) => vendorDetail.sku_address_ids.indexOf(row.id) > -1,
    );
    setSkuAddresses(skuAddress);
    setSelectedAddress(skuAddress[0]);
  }, []);

  useEffect(() => {
    if (vendorDetail) {
      fetchSkus(vendorDetail.vendor_id);
    }
  }, [vendorDetail]);

  useEffect(() => {
    if (selectedAddress) {
      getVendorPurchaseOrderList().then((r) => {
        return r;
      });
    }
  }, [selectedAddress, selectedSku]);

  const fetchSkus = async (vendor_id: number) => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_SKU_LIST(vendor_id),
      );
      setSkusList(data.skus);
    } catch (e) {
      console.error(e);
    }
  };

  const getVendorPurchaseOrderList = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.VENDORS.FETCH_VENDOR_PURCHASE_ORDERS(vendorDetail.vendor_id),
        {
          params: {
            address_id: selectedAddress ? selectedAddress.id : null,
            sku_id: selectedSku ? selectedSku.id : null,
            vendor_id: vendorDetail.vendor_id,
          },
        },
      );
      setVendorPos(data.data);
      setFilteredVendorPos(data.data);
    } catch (error) {
      console.error("Error fetching sku pos:", error);
      return [];
    }
  };

  const handleSearchFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const searchText = event.target.value;
    const filteredClientPos = vendorPos.filter((po) =>
      po.po_number.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteredVendorPos(filteredClientPos);
  };

  const handleAddressSelection = (val: UserAddress) => {
    setSelectedAddress(val);
  };

  const handleDetailPageNavigation = (val: number) => {
    const url = `${environment.clientUrl}/purchase-orders/${val}`;
    window.open(url, "_blank");
  };

  return (
    <div className="sku-purchase-order-list-page-content flex-column">
      <div className="sku-purchase-order-list-upper-container py-12 flex-row justify-content-space-between align-items-center gap-24">
        <div className="search-bar-container">
          <input
            className="py-8 px-34 search-input border-box xetgo-font-tag"
            onChange={handleSearchFile}
            placeholder="Search by PO Number"
          />
          <img
            height={16}
            width={16}
            className="search-icon"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
            alt="search-icon"
          />
        </div>

        <div className="header-right-section flex-row align-items-center gap-8">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
            alt="filter"
          />
          <Filter
            option={{
              key: "skuName",
              name: "Sku",
            }}
            optionList={skusList}
            multipleSelection={false}
            onSelection={(val, _option) => {
              setSelectedSku(val[0]);
            }}
            mode="showNames"
            showPlaceholder={true}
            direction="right"
            addNew={false}
            classname="sku-po-category-filter-input"
            preSelected={selectedSku ? [selectedSku] : []}
          />
          <AddressDropdown
            showId={false}
            selected={selectedAddress}
            onSelection={handleAddressSelection}
            addresses={skuAddresses}
            placeholder="Location"
            classname="sku-po-category-filter-input"
          />
        </div>
      </div>
      <div className="sku-purchase-order-list-lower-container">
        <div className="sku-po-status-list-container">
          <div className="sku-po-status-list-grid sku-po-status-list-header-row xetgo-font-tag">
            <p
              className="flex-row align-items-center gap-8 sku-po-status-hid xetgo-font-tag py-12 px-8"
              style={{ color: "#9d9d9d" }}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
                alt="calendar"
              />
              Created on
            </p>
            <p
              className="flex-row align-items-center gap-8 xetgo-font-tag py-12 px-8"
              style={{ color: "#9d9d9d" }}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
                alt="calendar"
              />
              PO Number
            </p>
            <p
              className="flex-row align-items-center gap-8 sku-po-product-hid xetgo-font-tag py-12 px-8"
              style={{ color: "#9d9d9d" }}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143408-building-2.svg"
                alt="calendar"
              />
              Location
            </p>
            <p
              className="flex-row align-items-center gap-8 xetgo-font-tag py-12 px-8"
              style={{ color: "#9d9d9d" }}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
                alt="calendar"
              />
              Sku Quantity
            </p>
            <p
              className="flex-row align-items-center gap-8 xetgo-font-tag py-12 px-8"
              style={{ color: "#9d9d9d" }}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
                alt="calendar"
              />
              Sku Name
            </p>
            <p
              className="flex-row align-items-center gap-8 xetgo-font-tag py-12 px-8"
              style={{ color: "#9d9d9d" }}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
                alt="calendar"
              />
              Status
            </p>
          </div>

          <div>
            {filteredVendorPos.length > 0 &&
              filteredVendorPos.map(
                (purchaseOrder: VendorPurchaseOrder, index: number) => {
                  return (
                    <div
                      key={index}
                      className="sku-po-card-hover full-width cursor-pointer"
                      onClick={() =>
                        handleDetailPageNavigation(purchaseOrder.id)
                      }
                    >
                      <div className="sku-po-status-list-grid sku-po-status-list-header-row flex-row xetgo-font-tag">
                        <p className="po-col py-12 px-8 po-status-hid flex-row justify-content-start align-items-center">
                          {formatDateWithYear(purchaseOrder.po_created_at)}
                        </p>
                        <div className="po-col py-4 px-8 flex-row justify-content-start align-items-center">
                          <p className="py-4 px-12 po-number">
                            {purchaseOrder.po_number}
                          </p>
                        </div>
                        <p className="po-col py-12 px-8 po-product-hid flex-row justify-content-start align-items-center">
                          {purchaseOrder.location}
                        </p>
                        <p className="po-col py-12 px-8 flex-row justify-content-start align-items-center">
                          {purchaseOrder.sku_quantity +
                            " " +
                            purchaseOrder.sku_unit}
                        </p>
                        <p className="po-col py-12 px-8 flex-row justify-content-start align-items-center">
                          {purchaseOrder.sku_name}
                        </p>
                        <div className="sku-po-status-container py-12 px-8 flex-row justify-content-space-between">
                          <p
                            style={{
                              color:
                                CLIENT_PO_PROPERTY_MAP[purchaseOrder.po_status]
                                  .color,
                              backgroundColor:
                                CLIENT_PO_PROPERTY_MAP[purchaseOrder.po_status]
                                  .backgroundColor,
                            }}
                            className="px-10 py-6 sku-po-status-tag flex-row justify-content-start align-items-center"
                          >
                            {purchaseOrder.po_status}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorPurchaseOrders;
