import "./ClientPurchaseOrderDetail.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientPurchaseOrderProductCard from "../../components/ClientPurchaseOrderProductCard/ClientPurchaseOrderProductCard";
import { CLIENT_PO_PROPERTY_MAP } from "../../constants/PurchaseData";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseOrderDetails } from "../../redux/purchase_order/purchaseOrderAction";
import { selectPurchaseOrderDetails } from "../../redux/purchase_order/purchaseOrderSelector";
import CommentSection from "../../components/CommentSection/CommentSection";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { commaFormat, round } from "../../utils/Helper";

const ClientPurchaseOrderDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cpoDetail = useSelector(selectPurchaseOrderDetails);
  const [openCommentSection, setOpenCommentSection] = useState(false);

  useEffect(() => {
    dispatch(getPurchaseOrderDetails(Number(id)));
  }, []);

  return (
    <div className="cpo-details-content-container flex-column">
      <div className="cpo-breadcrumbs-container flex-row gap-4 px-32 py-12">
        <p
          className="xetgo-font-tag bold cursor-pointer"
          style={{ color: "#aeaeae" }}
          onClick={() => navigate("/purchase-orders")}
        >
          Purchase Order
        </p>
        <p className="xetgo-font-tag bold" style={{ color: "#aeaeae" }}>
          /
        </p>
        <p className="xetgo-font-tag bold" style={{ color: "#aa5200" }}>
          {cpoDetail?.po_number}
        </p>
      </div>
      {cpoDetail && (
        <div className="cpo-content-main flex-row flex-1 gap-16 p-16">
          <div className="cpo-left-main-container flex-column flex-1 gap-16">
            <div className="po-multi-boxes gap-12">
              <div className=" po-enquiry-id-box upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag justify-content-space-between">
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521092955-forklift.svg"
                  alt="lift-icon"
                />
                <div className="inner-text-box">
                  <p style={{ color: "#777777" }} className="bold">
                    Purchase Order
                  </p>
                  <p className="po-box-title xetgo-font-h3 box-title">
                    {"PO" + cpoDetail.po_number}
                  </p>
                </div>
              </div>
              <div className=" upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag justify-content-space-between">
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521094417-banknote.svg"
                  alt="cash-icon"
                />
                <div className="inner-text-box">
                  <p className="bold sub-title">PO Value</p>
                  <p className="xetgo-font-h3 box-title">
                    ₹ {commaFormat(cpoDetail.po_value.toString())}
                  </p>
                </div>
              </div>
              <div className="upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag justify-content-space-between">
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240516052108-user.svg"
                  alt="user-icon"
                />
                <div className="inner-text-box">
                  <p className="bold sub-title">Vendor</p>
                  <p className="xetgo-font-h3 box-title">{cpoDetail?.vendor}</p>
                </div>
              </div>

              <div className="flex-column gap-4">
                <div className=" upper-big-boxes flex-column gap-12 px-16 py-12 xetgo-font-tag">
                  <img
                    height={16}
                    width={16}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521092955-forklift.svg"
                    alt="lift-icon"
                  />
                  <div className="inner-text-box">
                    <p className="xetgo-font-h3 box-title">
                      ₹ {cpoDetail?.delivery_address.city}
                    </p>
                  </div>
                </div>
                <div className="purchase-order-status-main-container flex-row align-items-center gap-2">
                  <p
                    style={{
                      background:
                        CLIENT_PO_PROPERTY_MAP[cpoDetail.status]
                          .backgroundColor,
                      color: CLIENT_PO_PROPERTY_MAP[cpoDetail.status].color,
                    }}
                    className="flex-1 py-10 current-status xetgo-font-mini bolder"
                  >
                    {cpoDetail?.status}
                  </p>
                  {cpoDetail.available_statuses &&
                    cpoDetail.available_statuses.length > 0 && (
                      <div
                        style={{
                          background:
                            CLIENT_PO_PROPERTY_MAP[cpoDetail.status]
                              .backgroundColor,
                          color: CLIENT_PO_PROPERTY_MAP[cpoDetail.status].color,
                        }}
                        className="px-12 py-14 cursor-pointer status-dropdown-container flex-row align-items-center jusitfy-content-center"
                      >
                        <img
                          height={4}
                          width={8}
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114094003-Vector+%28Stroke%29.svg"
                          alt="dropdown-arrow"
                        />
                      </div>
                    )}
                  {/* {openDropdown && (
                    <PurchaseStatusDropdown
                      statuses={poDetails.available_statuses}
                      updateStatus={updateStatus}
                    />
                  )} */}
                </div>
              </div>
            </div>
            <div className="cpo-products-container flex-1">
              <div className="product-list-heading-container xetgo-font-tag bold">
                <p className="index py-12 px-16"></p>
                <div className="flex-row gap-8 align-items-center py-12 px-16">
                  <img
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063009-file-box+%282%29.svg"
                    alt="product-box-icon"
                  />
                  <p>Products</p>
                </div>
                <div className="flex-row gap-8 align-items-center py-12 px-16 ppu">
                  <img
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521112504-tabler-icon-brand-codepen.svg"
                    alt="icon"
                  />
                  <p>PPU</p>
                </div>
                <div className="flex-row gap-8 align-items-center py-12 px-16">
                  <img
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240521112354-boxes.svg"
                    alt="boxes-icon"
                  />
                  <p>Quantity</p>
                </div>
                <div className="flex-row gap-8 align-items-center py-12 px-16">
                  <img
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-rupee+1.svg"
                    alt="rupee-icon"
                  />
                  <p>PO Value</p>
                </div>
              </div>
              {cpoDetail.product_details.map((cpo, index: number) => (
                <ClientPurchaseOrderProductCard
                  key={index}
                  index={index}
                  clientPurchaseOrder={cpo}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {!openCommentSection && (
        <div
          className="comment-icon flex-row align-items-center justify-content-center"
          onClick={() => setOpenCommentSection(true)}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117070437-chatbubbles.svg"
            alt="chat-icon"
          />
        </div>
      )}

      {openCommentSection && cpoDetail && (
        <div className="comment-floating-section">
          <OutsideAlerter action={() => setOpenCommentSection(false)}>
            <div className="pr-comment-page">
              <CommentSection
                source="PURCHASE_REQUEST"
                showHeader={true}
                source_id={cpoDetail.procurement_request_id}
                handleClose={() => setOpenCommentSection(false)}
              />
            </div>
          </OutsideAlerter>
        </div>
      )}
    </div>
  );
};

export default ClientPurchaseOrderDetail;
