import React, { useEffect, useState } from "react";
import "./SkuDelivery.scss";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import { formatDate } from "../../utils/Helper";
import { FilterOptionSchema } from "../Filter/filter.data";
import dayjs from "dayjs";
import SkuDeliveryFilter from "../SkuDeliveryFilter/SkuDeliveryFilter";
import { UserAddress } from "../../redux/user/userTypes";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import environment from "../../environments/environment";

interface SkuPricingProps {
  sku: SkuDetailSchema;
}

interface SkuDelivery {
  actual_delivery_date: string;
  vendor_name: string;
  vendor_id: number;
  location: string;
  address_id: number;
  quantity: number;
  expected_delivery_date: string;
  status: string;
  diff: number;
  unit: string;
  delivery_id: number;
  po_number: string;
  procurement_request_id: number;
}

const SkuDelivery: React.FC<SkuPricingProps> = ({ sku }) => {
  const [startDate, setStartDate] = useState<Date>(dayjs().toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().add(7, "days").toDate());
  const [deliveryScheduleData, setDeliveryScheduleData] = useState<
    SkuDelivery[]
  >([]);
  const [selectedVendor, setSelectedVendor] = useState<FilterOptionSchema[]>(
    [],
  );
  const [selectedAddress, setSelectedAddress] = useState<UserAddress | null>(
    null,
  );
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<string>("");

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    getDeliverySchedule();
  }, [
    startDate,
    endDate,
    selectedDeliveryType,
    selectedVendor,
    selectedAddress,
  ]);

  const toggleSelectedDeliveryType = (val: string) => {
    if (selectedDeliveryType !== val) {
      setSelectedDeliveryType(val);
    } else {
      setSelectedDeliveryType("");
    }
  };

  const filterByClient = (value: FilterOptionSchema[]) => {
    setSelectedVendor(value);
  };

  const startDateSelected = (date: Date) => {
    setStartDate(date);
  };

  const endDateSelected = (date: Date) => {
    setEndDate(date);
  };

  const getDeliverySchedule = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.SKU.GET_SKU_DELIVERY_SCHEDULE(sku.id),
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            type: selectedDeliveryType,
            vendor_id: selectedVendor.map((row) => row.id),
            address_id: selectedAddress?.id,
          },
        },
      );
      const result: SkuDelivery[] = [];
      const today = dayjs().tz("Asia/Calcutta").startOf("day").toDate();
      data.schedule.forEach((entry: SkuDelivery) => {
        let status = "Planned";
        let diff = 0;
        const expected_delivery_date = dayjs(entry.expected_delivery_date)
          .tz("Asia/Calcutta")
          .startOf("day")
          .toDate();

        const actual_delivery_date =
          entry.actual_delivery_date == null
            ? null
            : dayjs(entry.actual_delivery_date)
                .tz("Asia/Calcutta")
                .startOf("day")
                .toDate();
        if (expected_delivery_date < today && actual_delivery_date == null) {
          status = "Overdue";
          diff = dayjs(today).diff(expected_delivery_date, "day");
        }
        if (actual_delivery_date != null) {
          if (expected_delivery_date < actual_delivery_date) {
            status = "Delayed Delivery";
            diff = dayjs(actual_delivery_date).diff(
              expected_delivery_date,
              "day",
            );
          } else if (expected_delivery_date > actual_delivery_date) {
            status = "Early Delivery";
            diff = dayjs(expected_delivery_date).diff(
              actual_delivery_date,
              "day",
            );
          } else {
            status = "On Time Delivery";
          }
        }
        console.log(
          "EXP: ",
          expected_delivery_date,
          actual_delivery_date,
          diff,
        );

        result.push({ ...entry, status, diff });
      });
      setDeliveryScheduleData(result);
    } catch (err) {
      console.error("Error: ", err);
    }
  };
  const handlePONavigation = (id: number) => {
    const url = `${environment.clientUrl}/purchase-orders/${id}`;
    window.open(url, "_blank");
  };

  return (
    <div className="sku-delivery-detail-page flex-column full-width">
      <SkuDeliveryFilter
        startDate={startDate}
        endDate={endDate}
        startDateSelected={startDateSelected}
        endDateSelected={endDateSelected}
        selectedDeliveryType={selectedDeliveryType}
        toggleSelectedDeliveryType={toggleSelectedDeliveryType}
        selectedVendor={selectedVendor}
        vendorFilter={filterByClient}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        skuAddresses={sku.locations.map((row) => row.id)}
      />
      <div className="sku-delivery-header sku-pricing-main-grid">
        <div className="flex-row gap-8 align-items-center p-12 justify-content-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Planned Delivery Date
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Vendor
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143408-building-2.svg"
            alt="warehouse"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Location
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143839-table-properties+%281%29.svg"
            alt="po-icon"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            PO Number
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12 justify-content-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240716182234-boxes.svg"
            alt="moq"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold " style={{ color: "#9D9D9D" }}>
            Quantity
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12 justify-content-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240703143713-calendar.svg"
            alt="calendar"
            height={12}
            width={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Actual Delivery Date
          </p>
        </div>
        <div className="flex-row gap-8 align-items-center p-12">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520062831-file-box+%281%29.svg"
            alt="source"
            width={12}
            height={12}
          />
          <p className="xetgo-font-tag bold" style={{ color: "#9D9D9D" }}>
            Status
          </p>
        </div>
      </div>
      <div>
        {deliveryScheduleData.map((row) => (
          <div
            className={`sku-delivery-entry cursor-pointer ${row.status
              .replaceAll(" ", "-")
              .toLowerCase()}`}
            key={`delivery-schedule-${row.delivery_id}`}
            onClick={() => handlePONavigation(row.procurement_request_id)}
          >
            <p className="px-12 py-8 xetgo-font-tag price-entry bold text-align-center">
              {formatDate(row.expected_delivery_date)}
            </p>
            <p className="px-12 py-8 xetgo-font-tag bold price-entry bold">
              {row.vendor_name}
            </p>
            <p className="px-12 py-8 xetgo-font-tag bold price-entry bold">
              {row.location}
            </p>
            <p className="px-12 py-8 xetgo-font-tag bold price-entry bold">
              {row.po_number}
            </p>
            <p className="px-12 py-8 xetgo-font-tag bold price-entry bold text-align-center">
              {row.quantity} {sku.unit}
            </p>
            <p className="px-12 py-8 xetgo-font-tag bold price-entry bold text-align-center">
              {formatDate(row.actual_delivery_date)}
            </p>
            <div className="px-12 py-4">
              <p className="px-12 py-4 xetgo-font-tag bold delivery-status flex-row align-items-center gap-8">
                {row.status}
                {row.diff > 0 && (
                  <p className="xetgo-font-mini bold diff-val flex-row align-items-center justify-content-center">
                    {row.diff}
                  </p>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkuDelivery;
