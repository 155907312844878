import React, { useRef, useState } from "react";
import "./QCDetailQualityCheckTestCard.scss";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { useParams } from "react-router-dom";
import XetToggle from "../xet-toggle/XetToggle";

interface QualityCheckTestProps {
  QualityCheckTest: {
    approved: boolean;
    description: string;
    name: string;
    remarks: string;
    sku_quality_check_test_id: number;
    test_method: string | null;
    delivered_quality_check_test_id: number;
  };
}

const QCDetailQualityCheckTestCard: React.FC<QualityCheckTestProps> = ({
  QualityCheckTest,
}) => {
  const { id } = useParams();
  const [qualityCheckTest, setQualityCheckTest] = useState(QualityCheckTest);
  const [testMethodEditingId, setTestMethodEditingId] = useState(-1);
  const testMethodRef = useRef<HTMLTextAreaElement>(null);
  const remarkRef = useRef<HTMLTextAreaElement>(null);
  const [testMethodValue, setTestMethodValue] = useState(
    qualityCheckTest.test_method || "",
  );
  const [remark, setRemark] = useState(qualityCheckTest.remarks || "");

  const handleTestMethodKeyDown = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      try {
        if (e.shiftKey && e.key === "Enter") {
          e.preventDefault();
          return;
        }

        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          if (testMethodValue === qualityCheckTest.test_method) {
            setTestMethodEditingId(-1);
            if (testMethodRef.current) {
              testMethodRef.current.blur();
            }
            return;
          }
          await ApiService().client.put(
            URLS.SKU.UPDATE_SKU_QUALITY_CHECK_TEST(
              qualityCheckTest.sku_quality_check_test_id,
            ),
            {
              test_method: testMethodValue,
            },
          );
          setQualityCheckTest((prev) => ({
            ...prev,
            test_method: testMethodValue,
          }));
        }
      } catch (error) {
        console.error("Error updating Quality Test");
      }
      setTestMethodEditingId(-1);
      if (testMethodRef.current) {
        testMethodRef.current.blur();
      }
    }
  };

  const handleRemarkUpdate = async (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (remark === qualityCheckTest.remarks) {
          remarkRef.current?.blur();
          return;
        }
        const { data } = await ApiService().client.put(
          URLS.QUALITY_CHECK.UPDATE_QUALITY_CHECK_TEST(
            qualityCheckTest.delivered_quality_check_test_id,
          ),
          {
            key: "remarks",
            value: remark,
            skuQualityCheckTestId: qualityCheckTest.sku_quality_check_test_id,
            deliveryScheduleId: Number(id),
          },
        );

        setQualityCheckTest((prev) => ({
          ...prev,
          remarks: remark,
          delivered_quality_check_test_id: data.data.id,
        }));
        remarkRef.current?.blur();
      }
    } catch (error) {
      console.error("error updating remark", error);
    }
  };

  const handleToggleValue = async (deliveredQCTID: number, value: boolean) => {
    try {
      if (qualityCheckTest.approved === value) {
        return;
      }
      const { data } = await ApiService().client.put(
        URLS.QUALITY_CHECK.UPDATE_QUALITY_CHECK_TEST(deliveredQCTID),
        {
          key: "approved",
          value: value,
          deliveryScheduleId: Number(id),
          skuQualityCheckTestId: qualityCheckTest.sku_quality_check_test_id,
        },
      );
      setQualityCheckTest((prev) => {
        return {
          ...prev,
          approved: value,
          delivered_quality_check_test_id: data.data.id,
        };
      });
    } catch (error) {
      console.error("Error Rejecting Quality Check", error);
    }
  };
  return (
    <div className="flex-column qct-main-container ">
      <div className="quality-check-grid-container qc-detail">
        <div className="qc-check-test-left-grid xetgo-font-tag">
          <p className="p-12 right-border bold">{qualityCheckTest.name}</p>
          <p className="p-12 right-border description">
            {qualityCheckTest.description}
          </p>
        </div>
        <div className="observation-main-container flex-row ">
          <OutsideAlerter
            className="flex-1"
            action={() => setTestMethodEditingId(-1)}
          >
            <textarea
              ref={testMethodRef}
              key={qualityCheckTest.sku_quality_check_test_id}
              onClick={() =>
                setTestMethodEditingId(
                  qualityCheckTest.sku_quality_check_test_id,
                )
              }
              className={`quality-check-observe-value-input test-method xetgo-font-tag full-width p-12 border-box ${
                testMethodEditingId ===
                qualityCheckTest.sku_quality_check_test_id
                  ? "observed-test-method-editing"
                  : ""
              }`}
              onChange={(e) => setTestMethodValue(e.target.value)}
              onKeyDown={(e) => handleTestMethodKeyDown(e)}
              value={testMethodValue || ""}
            />
          </OutsideAlerter>

          <div className="flex-row align-items-center justify-content-flex-end py-10 px-16">
            <XetToggle
              onToggle={(value: boolean) =>
                handleToggleValue(
                  qualityCheckTest.delivered_quality_check_test_id,
                  value,
                )
              }
              toggleValue={qualityCheckTest.approved}
              backgroundColor="#f3f4f6"
              disableColor="#FF5252"
              enableColor="#00C414"
            />
          </div>
        </div>
      </div>
      <div className="px-12 py-8 full-width border-box">
        <textarea
          className="px-12 py-8 qct-remark-area full-width border-box"
          ref={remarkRef}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          onKeyDown={handleRemarkUpdate}
          placeholder="Remarks..."
        ></textarea>
      </div>
    </div>
  );
};

export default QCDetailQualityCheckTestCard;
