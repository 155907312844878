import { debounce } from "lodash";
import "./VendorForms.scss";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";
import { formatDate } from "../../utils/Helper";

interface VendorFormItem {
  title: string;
  count: number;
  id: number;
  created_at: string;
  status: string;
}

const VendorForms: React.FC = () => {
  const [searchedText, setSearchedText] = useState("");
  const [formList, setFormList] = useState<VendorFormItem[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getVendorForms("");
  }, []);

  const handleSearchFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchedText(searchText);
    debouncedSearch(searchText);
  };

  const debouncedSearch = useMemo(() => {
    return debounce((searchText: string) => {
      getVendorForms(searchText);
    }, 500);
  }, []);

  const getVendorForms = async (searchText: string) => {
    try {
      const { data } = await ApiService().client.get(
        URLS.FORMS.GET_VENDOR_FORMS,
        {
          params: {
            search: searchText,
          },
        },
      );
      setFormList(data.data);
    } catch (error) {
      console.error("Error fetching quotes:", error);
    }
  };

  return (
    <div className="vendor-form-list-container full-width flex-column">
      <div className="upper-bar">
        <p className="xetgo-font-tag bold px-16 py-12">Forms</p>
      </div>
      <div className="flex-column full-height overflow-hidden">
        <div className="flex-row justify-content-space-between align-items-center p-16">
          <div className="search-bar-container">
            <input
              className="py-8 px-34 search-input border-box xetgo-font-tag"
              value={searchedText}
              onChange={handleSearchFile}
              placeholder="Search enquiry id, products, dates ..."
            />
            <img
              className="search-icon "
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821094925-search.svg"
              alt=""
            />
          </div>

          <div
            onClick={() => navigate("/forms/new")}
            className="create-form-btn flex-row gap-4 cursor-pointer align-items-center py-10 px-12 border-box"
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618101041-plus_white.svg"
              alt="add-icon"
              width={12}
              height={12}
            />
            <span className="xetgo-font-tag bold">Create New Form</span>
          </div>
        </div>

        <div
          className="overflow-scroll flex-1 vendor-form-card-container"
          style={{ background: formList.length > 0 ? "unset" : "#F3F3F3" }}
        >
          {formList.length > 0 ? (
            <div className="flex-row gap-16 flex-wrap">
              {formList.map((entry) => (
                <div
                  onClick={() => navigate(`/vendor-form/detail/${entry.id}`)}
                  className="flex-column gap-12 px-22 py-14 vendor-form-card cursor-pointer"
                  key={entry.id}
                >
                  <p
                    className="xetgo-font-body bold form-name"
                    style={{ color: "#17181A" }}
                  >
                    {entry.title}
                  </p>
                  <div className="flex-column gap-4">
                    <p className="xetgo-font-tag" style={{ color: "#333333" }}>
                      Vendors Assigned:{" "}
                      <span style={{ color: "#797C80" }}>{entry.count}</span>
                    </p>
                    <p className="xetgo-font-tag" style={{ color: "#333333" }}>
                      Date Created:{" "}
                      <span style={{ color: "#797C80" }}>
                        {formatDate(entry.created_at, true)}
                      </span>
                    </p>
                  </div>
                  <div
                    className="flex-row justify-content-space-between align-items-center"
                    style={{ marginTop: "8px" }}
                  >
                    <div className="flex-row gap-14">
                      {/* <img
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/forms/new", {
                            state: {
                              update: true,
                              active: 1,
                              vendorFormId: entry.id,
                            },
                          });
                        }}
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821104010-Group+300.svg"
                        alt="edit"
                        width={16}
                        height={16}
                      /> */}
                      {/* <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821104010-trash-2.svg"
                        alt="edit"
                        width={16}
                        height={16}
                      /> */}
                    </div>
                    {entry.status === "PUBLISHED" && (
                      <p className="published-pill xetgo-font-mini px-12 py-6 bolder">
                        Published
                      </p>
                    )}
                    {entry.status === "DRAFT" && (
                      <p className="draft-pill xetgo-font-mini px-12 py-6 bolder">
                        Draft
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="flex-column align-items-center justify-content-center full-height"
              style={{ gap: "100px" }}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240821110049-Group.svg"
                alt="no-data"
              />
              <p className="xetgo-font-button" style={{ color: "#6D6D6D" }}>
                You haven&lsquo;t created any forms yet.
                <span
                  onClick={() => navigate("/forms/new")}
                  className="cursor-pointer"
                  style={{ color: "#407BFF" }}
                >
                  {" "}
                  Click here{" "}
                </span>
                to create your first form.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorForms;
