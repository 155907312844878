import React from "react";
import "./VendorFormReview.scss";
import { QuestionSchema } from "../../models/QuestionForm";
import { INPUT_TYPE } from "../../constants/QuestionFormData";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface VendorFormReviewProps {
  allQuestions: QuestionSchema[];
  formTitle: string;
  formDescription: string;
}

const VendorFormReview: React.FC<VendorFormReviewProps> = ({
  allQuestions,
  formTitle,
  formDescription,
}) => {
  return (
    <div className="vendor-review-form-main-page flex-row justify-content-center">
      <div className="vendor-form-review-main-container p-18 flex-column gap-18">
        <p className="xetgo-font-h3 bolder">{formTitle}</p>
        <p
          style={{
            color: "#626262",
          }}
          className="xetgo-font-button "
        >
          {formDescription}
        </p>
        {allQuestions.map((question, questionIndex) => (
          <div
            key={questionIndex}
            className="form-detail-question-container flex-column gap-20 p-20"
          >
            <p className="question">
              {`${questionIndex + 1}. ${question.question_value}`}{" "}
              {question.isRequired && <span style={{ color: "red" }}>*</span>}
            </p>

            {question.input_type == INPUT_TYPE[0].code && (
              <input
                className="answer-input p-12 xetgo-font-tag full-width border-box "
                value={""}
                placeholder="Enter Answer"
                disabled
              />
            )}

            {question.input_type == INPUT_TYPE[1].code && (
              <input
                className="answer-input p-12 xetgo-font-tag full-width border-box "
                value={""}
                placeholder="Enter Answer"
                disabled
              />
            )}

            {question.input_type === INPUT_TYPE[2].code && question.options && (
              <div className="option-container flex-column gap-16">
                {question.options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className="flex-row align-items-center gap-10 options-container"
                  >
                    <input type="checkbox" className="check-box" disabled />
                    <input
                      type="text"
                      value={option.name}
                      className="check-text xetgo-font-tag "
                      disabled
                    />
                  </div>
                ))}
              </div>
            )}

            {question.input_type === INPUT_TYPE[3].code && question.options && (
              <div className="option-container flex-column gap-16">
                {question.options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className="flex-row align-items-center gap-10 options-container"
                  >
                    <input
                      type="radio"
                      className="check-box radio-btn "
                      disabled
                    />
                    <input
                      type="text"
                      value={option.name}
                      className="check-text xetgo-font-tag "
                      disabled
                    />
                  </div>
                ))}
              </div>
            )}

            {question.input_type == INPUT_TYPE[4].code && (
              <div className="custom-form-left-box flex-1 flex-column gap-14">
                <input
                  id="single-fileInput"
                  name="file"
                  type="File"
                  style={{
                    position: "absolute",
                    top: "12px",
                    right: "12px",
                    display: "none",
                  }}
                  disabled={true}
                />
                <label
                  htmlFor="single-fileInput"
                  className="answer-input p-12 xetgo-font-tag full-width border-box "
                >
                  <p className="xetgo-font-tag flex-row justify-content-space-between">
                    <p>Upload File</p>
                    <img
                      className="upload-icon"
                      height={16}
                      width={16}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                      alt="upload-icon"
                    />
                  </p>
                </label>
              </div>
            )}

            {question.input_type == INPUT_TYPE[5].code && (
              <div className="custom-form-left-box flex-1 flex-column gap-14">
                <input
                  id="single-fileInput"
                  name="file"
                  type="File"
                  style={{
                    position: "absolute",
                    top: "12px",
                    right: "12px",
                    display: "none",
                  }}
                  disabled={true}
                />
                <label
                  htmlFor="single-fileInput"
                  className="answer-input p-12 xetgo-font-tag full-width border-box "
                >
                  <p className="xetgo-font-tag flex-row justify-content-space-between">
                    <p>Upload File</p>
                    <img
                      className="upload-icon"
                      height={16}
                      width={16}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240828115442-upload+%287%29.svg"
                      alt="upload-icon"
                    />
                  </p>
                </label>
              </div>
            )}

            {question.input_type == INPUT_TYPE[6].code && (
              <div className="custom-question-date-value xetgo-font-tag">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="answer-input p-12 xetgo-font-tag full-width border-box "
                    value={dayjs("".split("-").reverse().join("-"))}
                    format={"DD-MM-YYYY"}
                    views={["year", "month", "day"]}
                    // onChange={(event) =>
                    //   handleDateChange(event?.toDate().toISOString())
                    // }
                    disabled
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VendorFormReview;
