import "./SignIn.scss";
import React, { useEffect, useState } from "react";
import { signInUser } from "../../redux/user/userAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/FormInput/FormInput";
import {
  ScreenSizes,
  breakPointObserver,
} from "../../utils/BreakpointObserver";

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    breakPointObserver(setBreakpoint);
  }, [breakpoint]);

  const handleSignIn = async () => {
    if (email.length === 0 || password.length === 0) {
      toast.error("Please enter valid email or password", toastOptions);
      return;
    }

    dispatch(
      signInUser({
        email: email,
        password: password,
      }),
    );
  };

  return (
    <div className="signin-page-container flex-row align-items-center">
      <div className="signin-left-section flex-row align-items-center justify-content-center">
        <div className="signin-form flex-column align-items-start justify-content-space-between">
          <span
            className="flex-column align-items-start"
            style={{ width: "100%" }}
          >
            {breakpoint !== ScreenSizes.MOBILE && (
              <span className="signin-header flex-column">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129094742-xetgo_horizontal-1+2.svg"
                  alt="logo"
                  height="40px"
                />
              </span>
            )}

            <span className="signin-description flex-column gap-4">
              <p className="welcome-heading xetgo-font-h2">Welcome back!</p>
              <p className="xetgo-font-button-bold">
                Please sign in to your email to continue
              </p>
            </span>

            <span className="signin-inputs flex-column gap-8">
              <FormInput
                id="email"
                placeholder="Enter your email address"
                inputChangeHandler={(e) => setEmail(e.target.value)}
                value={email}
              />
              <FormInput
                id="password"
                placeholder="Enter password"
                type="password"
                inputChangeHandler={(event) => setPassword(event.target.value)}
                value={password}
              />
              <button
                className="signin-button"
                style={{
                  background:
                    email.length > 0 && password.length > 0
                      ? "#5151EC"
                      : "#9696F4",
                  cursor:
                    email.length === 0 || password.length === 0
                      ? "pointer"
                      : "cursor",
                }}
                onClick={handleSignIn}
              >
                {" "}
                Sign In{" "}
              </button>
            </span>
          </span>

          <span className="go-to-signup xetgo-font-button-bold">
            <img
              className="logo-footer-mobile"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240129094742-xetgo_horizontal-1+2.svg"
              alt="logo"
              height="40px"
            />

            <span className="footer-signup flex-row align-items-center gap-4 xetgo-font-button">
              <span style={{ color: "#A5A5A5" }}> New to Xetgo Pacpro? </span>
              <span
                style={{ color: "#5151EC", cursor: "pointer" }}
                onClick={() => navigate("/signup")}
                className="get-started-button flex-row align-items-center gap-4"
              >
                <span className="xetgo-font-button-bold"> Get Started </span>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240131093622-arrow-right.svg"
                  alt="arrow"
                />
              </span>
            </span>

            <button
              className="signin-button-mobile flex-row justify-content-center align-items-center"
              style={{
                background:
                  email.length > 0 && password.length > 0
                    ? "#5151EC"
                    : "#9696F4",
              }}
              onClick={handleSignIn}
            >
              Sign In
            </button>
          </span>
        </div>
      </div>

      <div className="wallpaper"></div>
    </div>
  );
};

export default SignIn;
