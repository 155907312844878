import { FilterOptionSchema } from "../../components/Filter/filter.data";
import {
  User,
  SignupUserData,
  UserDetailsUpdatedResponseSchema,
} from "../../models/User";
import {
  LogOutUserAction,
  NewCompanyAddressFailureAction,
  NewCompanyAddressPayloadSchema,
  NewCompanyAddressStartAction,
  NewCompanyAddressSuccessAction,
  SignInUserAction,
  SignInUserFailureAction,
  SignInUserSuccessAction,
  SignupUserAction,
  SignupUserFailureAction,
  SignupUserSuccessAction,
  ToggleNewNotificationAction,
  UpdateUserDetailsAction,
  UpdateUserDetailsFailureAction,
  UpdateUserDetailsSuccessAction,
  UserActions,
  UserAddress,
} from "./userTypes";

export const signupUser = (data: SignupUserData): SignupUserAction => ({
  type: UserActions.SIGNUP_USER,
  payload: data,
});

export const signupUserSuccess = (user: User): SignupUserSuccessAction => ({
  type: UserActions.SIGNUP_USER_SUCCESS,
  payload: user,
});

export const signupUserFailure = (error: string): SignupUserFailureAction => ({
  type: UserActions.SIGNUP_USER_FAILURE,
  error,
});

export const signInUser = (data: {
  email?: string;
  password?: string;
  jwt_token?: string;
}): SignInUserAction => ({
  type: UserActions.SIGNIN_USER,
  payload: data,
});

export const signInUserSuccess = (user: User): SignInUserSuccessAction => ({
  type: UserActions.SIGNIN_USER_SUCCESS,
  payload: user,
});

export const signInUserFailure = (error: string): SignInUserFailureAction => ({
  type: UserActions.SIGNIN_USER_FAILURE,
  error,
});

export const updateUserDetails = (data: FormData): UpdateUserDetailsAction => ({
  type: UserActions.UPDATE_USER_DETAILS,
  payload: data,
});

export const updateUserDetailsSuccess = (
  user: UserDetailsUpdatedResponseSchema,
): UpdateUserDetailsSuccessAction => ({
  type: UserActions.UPDATE_USER_DETAILS_SUCCESS,
  payload: user,
});

export const updateUserDetailsFailure = (
  error: string,
): UpdateUserDetailsFailureAction => ({
  type: UserActions.UPDATE_USER_DETAILS_FAILURE,
  error,
});

export const logOutUser = (): LogOutUserAction => ({
  type: UserActions.LOGOUT_USER,
});

export const newCompanyAddressStart = (
  payload: NewCompanyAddressPayloadSchema,
): NewCompanyAddressStartAction => ({
  type: UserActions.NEW_COMPANY_ADDRESS_START,
  payload,
});

export const newCompanyAddressSuccess = (
  response: UserAddress,
): NewCompanyAddressSuccessAction => ({
  type: UserActions.NEW_COMPANY_ADDRESS_SUCCESS,
  payload: response,
});

export const newCompanyAddressFailure = (
  error: string,
): NewCompanyAddressFailureAction => ({
  type: UserActions.NEW_COMPANY_ADDRESS_FAILURE,
  error,
});

export const toggleNewNotification = (): ToggleNewNotificationAction => ({
  type: UserActions.TOGGLE_NEW_NOTIFICATION,
});
