export const CompanyTypes = [
  "Private Ltd Company",
  "Public Ltd Company",
  "Sole Proprietorship",
  "Limited Liability Partnership (LLP)",
  "One Person Company (OPC)",
  "Foreign Company",
];

export const BusinessNature = [
  "Manufacturer",
  "Wholesaler",
  "Retailer",
  "Exporter",
];
